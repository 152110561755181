import {
  IAgoraRTCClient,
  ICameraVideoTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
} from 'agora-rtc-sdk-ng';
import { CreditMinutes, LiveCallType, LiveSessionModality } from 'ts-frontend/types';
import { captionsLanguages } from '@talkspace/configs';

export enum VideoCreditType {
  free = 'free',
  therapy = 'therapy',
  psychiatry = 'psychiatry',
  introduction = 'introduction',
}

export enum VideoCallName {
  liveVideoSession = 'Live video session',
  liveAudioSession = 'Live audio session',
  freeVideoSession = 'Free video session',
  freeAudioSession = 'Free audio session',
  freeSession = 'Free video session',
  introductionSession = 'Introduction session',
  psychiatrySession = 'Psychiatry session',
}

export enum VideoCallEvent {
  sessionEnded = 'session_ended',
  callIsActive = 'call_is_active',
  callStarted = 'call_started',
  clientJoined = 'client_joined',
  therapistJoined = 'therapist_joined',
  therapistWaiting = 'therapist_waiting',
}

export enum StreamTypes {
  local = 'local',
  remote = 'remote',
  activeSpeaker = 'active_speaker',
}

export type VideoCallError = 'error' | 'browserNotSupported' | 'permissionsDenied' | undefined;

export interface AgoraCredentials {
  encryptionKey: string;
  channelName: string;
  userToken: string;
}

export interface RemoteUserTracks {
  audioTrack?: IRemoteAudioTrack | undefined;
  videoTrack?: IRemoteVideoTrack | undefined;
  userID: number | undefined;
}

interface Booking {
  type: VideoCreditType;
  creditMinutes: number;
  startTime: string;
}

interface Client {
  userID: number;
  pseudonym: string;
}

interface Therapist {
  userID: number;
  therapistFirstName: string;
  therapistLastName: string;
}

interface WakeLock {
  request(type: string): Promise<WakeLockSentinel>;
}

type WakeLockType = 'screen' | null;

export interface WakeLockSentinel {
  release(): Promise<void>;
  readonly WakeLocktype: WakeLockType;
  readonly released: boolean;
  addEventListener(event: string, callback: EventListenerOrEventListenerObject, options?: any);
  removeEventListener(event: string, callback: EventListenerOrEventListenerObject, options?: any);
}

export interface NavigatorExtended extends Navigator {
  readonly wakeLock: WakeLock;
}

export type CaptionsLanguage = keyof typeof captionsLanguages | '';

export type VirtualBackground = 'blurred' | 'branded' | '';

export interface VideoCallState {
  startTime?: string;
  creditMinutes?: CreditMinutes;
  videoCreditType?: VideoCreditType;
  videoCallID?: number;
  tokenExpiresAt?: string;
  therapistFirstName?: string;
  therapistLastName?: string;
  startDayString?: string;
  callTimeScheduleString?: string;
  videoCallDescription?: string;
  cameras: MediaDeviceInfo[];
  audioIns: MediaDeviceInfo[];
  audioOuts: MediaDeviceInfo[];
  selectedCamera?: MediaDeviceInfo;
  selectedAudioIn?: MediaDeviceInfo;
  selectedAudioOut?: MediaDeviceInfo;
  rating?: number;
  agoraClient?: IAgoraRTCClient;
  isAudioOn: boolean;
  isVideoOn: boolean;
  isLoadingVideoCall: boolean;
  isJoined: boolean;
  therapistUserID: number;
  roomID: number;
  error: VideoCallError;
  videoCall?: VideoCallAPIResponse;
  clientConnectionTime?: string;
  wakeLockSentinel?: WakeLockSentinel;
  videoCallEnded: boolean;
  modality?: LiveSessionModality;
  showTherapistScheduleNextSessionPopup: boolean;
  isLoading: boolean;
  isMinimized: boolean;
  callType?: LiveCallType;
  isCaptionsSelectionOpen: boolean;
  captionsLanguage: CaptionsLanguage;
  localVideoTrack?: ICameraVideoTrack;
  virtualBackground: VirtualBackground;
}

export interface VideoCallAPIResponse {
  roomID: number;
  booking: Booking;
  agora: AgoraCredentials;
  clients: Client[];
  therapist: Therapist;
  callStartedAt: string | null;
  videoCallID: number;
  modality: LiveSessionModality;
  videoCreditID: number | null;
}

export const STREAM_ID_PREFIX = 'videoStream_';

export const VIDEO_CALL_SESSION_STORAGE_KEY = 'lvsMetadata';
