import React, { FunctionComponent } from 'react';

import { useEmotionTheme } from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import BaseAccordion from './Base';
import NotificationTitle from '../NotificationTitle';
import NotificationPanelRow from '../NotificationPanelRow';
import { DailyReminder, EmailEnvelope } from '../../../components/icons';
import constructDescription from './helpers';

const arrowContainerStyles = {
  marginTop: 21,
};

interface Props {
  isOpen: boolean;
  handlePress: (value: boolean) => void;
  isMobile: boolean;
  superbills: { email: boolean; push: boolean };
  handlePushToggle: (value: number) => void;
  handleEmailToggle: (value: number) => void;
}

const SuperbillsAccordion: FunctionComponent<Props> = ({
  isOpen,
  handlePress,
  isMobile,
  superbills,
  handlePushToggle,
  handleEmailToggle,
}) => {
  const { colors, colorRoles } = useEmotionTheme();
  const useNewNav = useNewMemberNav();

  return (
    <BaseAccordion
      open={isOpen}
      onPress={handlePress}
      disabled={false}
      titleComponent={() => (
        <NotificationTitle
          title="Superbills"
          isOpen={isOpen}
          isMobile={isMobile}
          subTitle={
            isOpen
              ? 'Notifications when you have a new superbill available.'
              : constructDescription(superbills)
          }
        />
      )}
      arrowStyles={{
        fill: useNewNav ? colorRoles.icons.iconDefaultDefault : colors.periwinkleGrey,
      }}
      arrowContainerStyles={arrowContainerStyles}
    >
      <NotificationPanelRow
        label="Push"
        iconComponent={() => <DailyReminder />}
        isOn={superbills.push}
        onToggle={handlePushToggle}
        name="superbillsPush"
      />
      <NotificationPanelRow
        label="Email"
        iconComponent={() => <EmailEnvelope />}
        isOn={superbills.email}
        onToggle={handleEmailToggle}
        name="superbillsEmail"
      />
    </BaseAccordion>
  );
};

export default SuperbillsAccordion;
