import { FunctionComponent } from 'react';
import {
  View,
  Avatar,
  CouplesDefaultAvatar,
  ProfilePlaceholder,
  useEmotionTheme,
  TherapistSearch,
  RoundRectExclaimationMark,
} from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { RoomType, RoomStatus } from 'ts-frontend/entities/Room';
import {
  ETherapistInfo,
  Availability,
  TherapistType,
  isTherapistCT,
  INACTIVE_PHASE_VALUES,
} from 'ts-frontend/entities/Therapist';
import { roomHasRealProvider } from 'ts-frontend/helpers';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import styled from '../../core/styled';
import { CircleCheckmark, CircleCalendar, CircleClock } from '../icons';

export type TherapistAvatarSizeEnum = 'extraSmall' | 'small' | 'medium' | 'large';
export type NoMatchesAvatarSourceEnum = 'inRoom' | 'dashboard' | 'roomDetails';

interface SizeAndPadding {
  therapistAvatarSize: number;
  CTIconSize: number;
  padding: number;
}

const AvatarWrapper = styled(View)<
  { hasLargerIcon?: boolean; noVerticalPadding?: boolean } & Omit<SizeAndPadding, 'CTIconSize'>
>(({ hasLargerIcon, therapistAvatarSize, padding, noVerticalPadding }) => {
  const horizontalPadding = padding;
  const verticalPadding = noVerticalPadding || !hasLargerIcon ? 0 : padding;
  return {
    height: therapistAvatarSize + verticalPadding * 2,
    width: therapistAvatarSize + horizontalPadding * 2,
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${verticalPadding}px ${horizontalPadding}px`,
    position: 'relative',
    zIndex: 0,
  };
});

// TODO: put this on the avatar svg
const AvatarBorderWrapper = styled(View)<{ width: number; isActive: boolean }>(
  ({ width, isActive, theme: { colors } }) => {
    return {
      width: width + 6,
      height: width + 6,
      borderRadius: width / 2 + 3,
      zIndex: 2,
      left: 0,
      bottom: 0,
      position: 'absolute',
      backgroundColor: isActive ? colors.permaLinkWaterGrey : colors.white,
      padding: 3,
    };
  }
);

const AvatarStatusIconWrapper = styled(View)({
  bottom: 0,
  right: 0,
  ...webOnlyStyle({
    position: 'absolute',
    zIndex: 3,
  }),
});

function getWidthHeightAndPaddingBySize(size: TherapistAvatarSizeEnum): SizeAndPadding {
  const dict = {
    extraSmall: {
      therapistAvatarSize: 24,
      CTIconSize: 24,
      padding: 0,
    },
    small: {
      therapistAvatarSize: 42,
      CTIconSize: 25,
      padding: 5,
    },
    medium: {
      therapistAvatarSize: 54,
      CTIconSize: 33,
      padding: 8,
    },
    large: {
      therapistAvatarSize: 70,
      CTIconSize: 50,
      padding: 10,
    },
  };
  return dict[size];
}

const getNoMatchesAvatarDimensionsBySource = (source: NoMatchesAvatarSourceEnum) => {
  const dict = {
    inRoom: {
      width: 24,
      height: 22,
    },
    dashboard: {
      width: 34,
      height: 31,
    },
    roomDetails: {
      width: 46,
      height: 44,
    },
  };
  return dict[source];
};

const CouplesAvatar: FunctionComponent<{
  image: string;
  width: number;
  isActive?: boolean;
  alt: string;
}> = ({ image, width, isActive, alt }) => (
  <>
    <CouplesDefaultAvatar
      size={width / 1.28}
      style={{
        zIndex: 1,
        top: 0,
        right: 10,
        position: 'absolute',
      }}
    />
    <AvatarBorderWrapper width={width / 1.28} isActive={!!isActive}>
      <Avatar height={width / 1.28} width={width / 1.28} image={image} alt={alt} />
    </AvatarBorderWrapper>
  </>
);

const TherapistFinalAvatar: FunctionComponent<{
  alt: string;
  imageURL: string;
  roomType: RoomType;
  hasProvider: boolean;
  source?: NoMatchesAvatarSourceEnum | undefined;
  therapistAvatarSize: number;
  isActive?: boolean;
}> = ({
  alt,
  imageURL,
  hasProvider,
  roomType,
  therapistAvatarSize,
  source = 'dashboard',
  isActive = false,
}) => {
  const { colors, colorRoles } = useEmotionTheme();
  const useNewNav = useNewMemberNav();

  const backgroundColor = {
    psychiatry_room: colorRoles.surfaces.decorativePsych,
    couples_room: colorRoles.surfaces.decorativeCouples,
    private_room: colorRoles.surfaces.decorativeTealExtralite,
  };
  const placeholderBackgroundColor =
    roomType in backgroundColor ? backgroundColor[roomType] : backgroundColor.private_room;

  if (!hasProvider) {
    const { width, height } = getNoMatchesAvatarDimensionsBySource(source);
    return useNewNav ? (
      <ProfilePlaceholder
        iconColor={colorRoles.icons.iconBrandDefault}
        backgroundColor={placeholderBackgroundColor}
      />
    ) : (
      <View
        justify="center"
        align="center"
        style={{
          width: therapistAvatarSize,
          height: therapistAvatarSize,
          borderRadius: '50%',
          overflow: 'hidden',
          backgroundColor: colors.permaHawkesBlue,
        }}
      >
        <TherapistSearch width={width} height={height} />
      </View>
    );
  }

  if (roomType === 'couples_room') {
    return (
      <CouplesAvatar width={therapistAvatarSize} image={imageURL} isActive={isActive} alt={alt} />
    );
  }

  return (
    <Avatar height={therapistAvatarSize} width={therapistAvatarSize} image={imageURL} alt={alt} />
  );
};

const AvatarStatusIcon: FunctionComponent<{
  therapistAvailability: Availability;
  therapistType: TherapistType;
  therapistPhase: number;
  isActive?: boolean;
  hide?: boolean;
}> = ({ therapistAvailability, therapistType, therapistPhase, isActive, hide }) => {
  const { colors } = useEmotionTheme();
  if (hide || isTherapistCT(therapistType)) return null;
  const getIconComponent = () => {
    if (INACTIVE_PHASE_VALUES.includes(therapistPhase)) {
      return {
        icon: RoundRectExclaimationMark,
        parentWrapperProps: {
          style: {
            bottom: 3,
            right: 5,
          },
        },
        iconProps: {
          width: 18,
          height: 18,
        },
      };
    }

    if (therapistAvailability === 'time-off') {
      return {
        icon: CircleCalendar,
      };
    }

    if (therapistAvailability === 'unavailable') {
      return {
        icon: CircleClock,
      };
    }

    if (therapistAvailability === 'available') {
      return {
        icon: CircleCheckmark,
      };
    }

    return null;
  };
  const { icon: IconComponent, parentWrapperProps, iconProps } = getIconComponent() || {};

  if (!IconComponent) return null;

  return (
    <AvatarStatusIconWrapper {...parentWrapperProps}>
      <IconComponent
        secondaryColor={isActive ? colors.permaLinkWaterGrey : colors.white}
        {...iconProps}
      />
    </AvatarStatusIconWrapper>
  );
};

const TherapistAvatar: FunctionComponent<{
  therapist: ETherapistInfo;
  roomType: RoomType;
  roomStatus?: RoomStatus;
  source?: NoMatchesAvatarSourceEnum | undefined;
  isActive?: boolean;
  size?: TherapistAvatarSizeEnum;
  hideStatus?: boolean;
  noVerticalPadding?: boolean;
}> = ({
  roomType,
  roomStatus,
  therapist,
  isActive,
  size = 'medium',
  source = 'dashboard',
  hideStatus = false,
  noVerticalPadding,
}) => {
  const { therapistAvatarSize, padding } = getWidthHeightAndPaddingBySize(size);
  const imageAlt = therapist.imageURL ? 'care provider profile picture' : 'care provider avatar';
  const hideStatusIcon = !isTherapistCT(therapist.type) && size === 'small';
  const altWithStatus =
    hideStatus === false && !hideStatusIcon && therapist.availability
      ? `${imageAlt}, status: ${therapist.availability}`
      : imageAlt;
  return (
    <AvatarWrapper
      aria-hidden="true"
      hasLargerIcon={roomType === 'couples_room' || isTherapistCT(therapist.type)}
      therapistAvatarSize={therapistAvatarSize}
      padding={padding}
      noVerticalPadding={noVerticalPadding}
    >
      <TherapistFinalAvatar
        alt={altWithStatus}
        imageURL={therapist.imageURL}
        hasProvider={roomHasRealProvider({ room: { status: roomStatus }, provider: therapist })}
        roomType={roomType}
        therapistAvatarSize={therapistAvatarSize}
        isActive={isActive}
        source={source}
      />
      {hideStatus === false && (
        <AvatarStatusIcon
          isActive={isActive}
          therapistAvailability={therapist.availability}
          therapistType={therapist.type}
          therapistPhase={therapist.phase}
          hide={hideStatusIcon}
        />
      )}
    </AvatarWrapper>
  );
};

export default TherapistAvatar;
