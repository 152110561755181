import React, { useEffect, useState } from 'react';
import { View, spacing } from '@talkspace/react-toolkit';
import { fontWeight } from '@talkspace/react-toolkit/src/designSystems/tokens';
import { hasSeenDailyPromptToday } from '../utils/communityStorage';
import { LOCAL_STORAGE_LAST_CHECKED_DAILY_PROMPT_TIMESTAMP } from '../constants';
import WeightedSentence from './WeightedSentence';
import styled from '@/core/styled';

const { space200 } = spacing;
const { regular } = fontWeight;

interface DailyPromptProps {
  sentence: string;
  replyCount: number;
}

const Count = styled(View)(({ theme: { colorRoles } }) => {
  return {
    fontSize: '14px',
    fontWeight: regular,
    color: colorRoles.typography.textDefault,
    opacity: '0.7',
    marginTop: space200,
  };
});

const Prompt = styled(View)(() => {
  return {
    textAlign: 'center',
    lineHeight: 1,
  };
});

const DailyPrompt: React.FC<DailyPromptProps> = ({ sentence, replyCount }) => {
  const [hasRevealedDailyPrompt, setHasRevealedDailyPrompt] = useState(() =>
    hasSeenDailyPromptToday()
  );

  useEffect(() => {
    setHasRevealedDailyPrompt(hasRevealedDailyPrompt === true);
  }, [hasRevealedDailyPrompt]);

  const handleClick = () => {
    setHasRevealedDailyPrompt(true);
    localStorage.setItem(
      LOCAL_STORAGE_LAST_CHECKED_DAILY_PROMPT_TIMESTAMP,
      new Date().toISOString()
    );
  };

  const answerCountText = `${replyCount} answers`;
  const promptSentence =
    hasRevealedDailyPrompt && sentence ? sentence : 'Click to reveal the question';
  const communityFilteredPostCount = hasRevealedDailyPrompt && replyCount ? answerCountText : null;
  return (
    <View>
      <Prompt justify="center" align="center" onClick={handleClick}>
        <WeightedSentence sentence={promptSentence} />
        <Count>{communityFilteredPostCount} </Count>
      </Prompt>
    </View>
  );
};

export default DailyPrompt;
