import {
  Button,
  Col,
  Grid,
  ResponsiveLayoutWithHeader,
  Row,
  SimpleHeader,
  TextDS,
  View,
  spacing,
  useWindowWidthState,
} from '../..';
import { SafeAreaVariant } from '../../components/ResponsiveLayoutWithHeader';
import CircleUnderlay from '../components/CircleUnderlay';

const { space100, space300, space500, space800 } = spacing;

interface Props {
  headerTitle: string;
  illustration: React.ReactElement;
  title: string;
  description: string;
  onClose: () => void;
  buttonText: string;
  onPressButton: () => void;
  safeAreaVariant?: SafeAreaVariant;
}

const ErrorScreen = ({
  headerTitle,
  illustration,
  title,
  description,
  onClose,
  buttonText,
  safeAreaVariant,
  onPressButton,
}: Props) => {
  const { isMobile } = useWindowWidthState();
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => <SimpleHeader title={headerTitle} onClose={onClose} />}
      safeAreaVariant={safeAreaVariant}
    >
      <Grid>
        <Row>
          {/* TODO: currently responsive offsets are not working. When fixed, remove this col and add
        smOffset={1} and lgOffset={3} to the other one */}
          <Col xs={0} sm={1} lg={3}>
            {null}
          </Col>
          <Col xs={4} sm={6}>
            <View align="center" style={{ marginTop: space800, textAlign: 'center' }}>
              <CircleUnderlay size={136} colorRole="neutralDefault">
                {illustration}
              </CircleUnderlay>
              <TextDS variant="headingLg" style={{ marginTop: space300 }}>
                {title}
              </TextDS>
              <TextDS variant="bodySm" style={{ marginTop: space100 }}>
                {description}
              </TextDS>
              <Button
                text={buttonText}
                onPress={onPressButton}
                style={{ marginTop: space500, width: isMobile ? '100%' : undefined }}
              />
            </View>
          </Col>
        </Row>
      </Grid>
    </ResponsiveLayoutWithHeader>
  );
};

export default ErrorScreen;
