import { useMemo } from 'react';
import { Large, TextDS, View } from '@talkspace/react-toolkit';
import { Pen } from '@talkspace/react-toolkit/src/designSystems/icons';
import { contactRelationship } from '@talkspace/configs';
import { useNewMemberNav } from 'launchDarkly/hooks';
import styled, { EmotionStyle } from '@/core/styled';

import { EmergencyContactData } from '../../types';
import PersonalInformationDataRow from '../PersonalInformationDataRow/PersonalInformationDataRow';
import PersonalInformationDataSection from '../PersonalInformationDataSection';

const emergencyContactStyle = { marginBottom: -1, marginTop: 40 };

interface Props {
  data: EmergencyContactData;
  onEdit: () => void;
  wrapperStyle?: EmotionStyle;
}
const EditButtonContainer = styled(View)({
  position: 'absolute',
  right: 28,
  display: 'inline-flex',
  cursor: 'pointer',
  justifyContent: 'none',
});

const PersonalInformationDataSectionEmergencyContact = ({
  data,
  onEdit,
  wrapperStyle = {},
}: Props) => {
  const { contactName, contactPhone, relationship } = data;
  const relationshipFormatted = relationship && contactRelationship[relationship];
  const useNewNav = useNewMemberNav();
  const rowStyle = useMemo(
    () => (useNewNav ? { marginTop: 10, marginBottom: 10 } : { marginTop: 20 }),
    [useNewNav]
  );

  return useNewNav ? (
    <View style={wrapperStyle}>
      <View style={emergencyContactStyle}>
        <TextDS variant="headingLg">Emergency contact</TextDS>
      </View>

      <PersonalInformationDataSection
        title=""
        editLinkText=""
        onEditClick={onEdit}
        wrapperStyle={{ ...wrapperStyle, position: 'relative', display: 'flex' }}
      >
        <EditButtonContainer row>
          <Pen size="major" colorType="brand" />
          <Large
            onPress={onEdit}
            variant="largeBoldTSGreen"
            dataQa="personalInformationEditEmergencyContactButton"
          >
            Edit
          </Large>
        </EditButtonContainer>
        <View style={{ marginTop: -7 }}>
          <PersonalInformationDataRow title="Name" value={contactName} />
        </View>
        <PersonalInformationDataRow
          title="Phone number"
          value={contactPhone}
          wrapperStyle={rowStyle}
        />
        <PersonalInformationDataRow
          title="Relationship with emergency contact"
          value={relationshipFormatted}
          wrapperStyle={rowStyle}
        />
      </PersonalInformationDataSection>
    </View>
  ) : (
    <PersonalInformationDataSection
      title="Emergency contact"
      editLinkText="Change emergency contact"
      onEditClick={onEdit}
      wrapperStyle={wrapperStyle}
    >
      <PersonalInformationDataRow title="Name" value={contactName} />
      <PersonalInformationDataRow
        title="Phone number"
        value={contactPhone}
        wrapperStyle={rowStyle}
      />
      <PersonalInformationDataRow
        title="Relationship with emergency contact"
        value={relationshipFormatted}
        wrapperStyle={rowStyle}
      />
    </PersonalInformationDataSection>
  );
};

export default PersonalInformationDataSectionEmergencyContact;
