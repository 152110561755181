import * as React from 'react';
import Svg, { G, Circle, Rect, Path } from 'svgs';

interface ChatPlusProps {
  hide?: boolean;
  fillColor?: string;
}

const ChatPlus: React.FunctionComponent<ChatPlusProps> = ({ hide, fillColor, ...otherProps }) => {
  const titleText = 'plus sign';
  if (hide) return null;
  return (
    <Svg
      width="28px"
      height="28px"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 28 28"
      {...otherProps}
    >
      <G transform="translate(1 1)" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle stroke={fillColor} strokeWidth={2} cx={13} cy={13} r={13} />
        <Rect fill={fillColor} x={6} y={12} width={14} height={2} rx={1} />
        <Path
          d="M6,13 C6,12.4477153 6.44994876,12 7.00684547,12 L18.9931545,12 C19.5492199,12 20,12.4438648 20,13 L20,13 C20,13.5522847 19.5500512,14 18.9931545,14 L7.00684547,14 C6.45078007,14 6,13.5561352 6,13 L6,13 Z"
          fill={fillColor}
          transform="rotate(90 13 13)"
        />
      </G>
    </Svg>
  );
};

export default ChatPlus;
