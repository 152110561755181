import { FunctionComponent, useEffect } from 'react';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useHistory } from 'react-router';
import {
  ResponsiveLayoutWithHeader,
  PanelHeader,
  Breadcrumb,
  Col,
  Grid,
  SpacingView,
  TextDS,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { NAV_SHELL_HEADER_HEIGHT } from 'ts-frontend/constants';

import useNotificationPreferences from '../hooks/useNotificationPreferences';
import Notifications from '../components/Notifications';
import MobileHeader from '../../components/MobileLeftHeader';
import MyAccountPanelWrapper from '../components/MyAccountPanelWrapper/MyAccountPanelWrapper';
import ReactFrameService from '../../auth/reactFrame/ReactFrameService';

const breadcrumbRoutes = [
  { label: 'Account', path: '/home/account' },
  { label: 'Notifications', path: '' },
];

interface Props {
  onCloseButtonPress: () => void;
  onBackButtonPress: () => void;
}

const NotificationsContainer: FunctionComponent<Props> = ({
  onCloseButtonPress,
  onBackButtonPress,
}) => {
  const [state, { getNotificationPreferences, patchNotificationPreferences }] =
    useNotificationPreferences();

  const isInFrame = ReactFrameService.instance().isInFrame();
  const isiOS = ReactFrameService.isiOS();

  const { isMobile } = useWindowWidthState();

  const useNewNav = useNewMemberNav();

  const history = useHistory();
  const isHomeNav = useNewNav && history.location.pathname.includes('home');

  useEffect(() => {
    getNotificationPreferences();
  }, [getNotificationPreferences]);

  const newNavHeaderHeight = isMobile ? 0 : NAV_SHELL_HEADER_HEIGHT;

  if (isHomeNav)
    return (
      <ResponsiveLayoutWithHeader
        outsideHeaderHeight={useNewNav ? newNavHeaderHeight : undefined}
        headerHeight={!isMobile ? 0 : undefined}
        renderHeader={() => (
          <PanelHeader
            isNewNav={useNewNav}
            renderLeft={() => (
              <MobileHeader
                centerTitle={isInFrame && isiOS}
                title=""
                onBackButtonPress={() => history.goBack()}
              />
            )}
            onRightPress={isInFrame ? undefined : onCloseButtonPress}
          />
        )}
        noHeaderLine
      >
        <Grid fluid rowSpacing={isMobile ? 'space0' : 'space400'}>
          <Col sm={8} lg={8}>
            <SpacingView itemSpacing="space200" row={false}>
              {!isMobile && <Breadcrumb routes={breadcrumbRoutes} />}
              <TextDS variant="heading2xl">Notifications</TextDS>

              <MyAccountPanelWrapper alwaysRow={isInFrame} title="">
                {state.notificationPreferencesByName && (
                  <Notifications
                    notificationPreferencesByName={state.notificationPreferencesByName}
                    isError={state.isError}
                    patchNotificationPreferences={patchNotificationPreferences}
                  />
                )}
              </MyAccountPanelWrapper>
            </SpacingView>
          </Col>
        </Grid>
      </ResponsiveLayoutWithHeader>
    );

  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => (
        <PanelHeader
          isNewNav={useNewNav}
          renderLeft={() => (
            <MobileHeader
              centerTitle={isInFrame && isiOS}
              title="My Account"
              mobileTitle="Notifications"
              onBackButtonPress={onBackButtonPress}
            />
          )}
          onRightPress={isInFrame ? undefined : onCloseButtonPress}
        />
      )}
    >
      <MyAccountPanelWrapper alwaysRow={isInFrame} title="Notifications">
        {state.notificationPreferencesByName && (
          <Notifications
            notificationPreferencesByName={state.notificationPreferencesByName}
            isError={state.isError}
            patchNotificationPreferences={patchNotificationPreferences}
          />
        )}
      </MyAccountPanelWrapper>
    </ResponsiveLayoutWithHeader>
  );
};

export default NotificationsContainer;
