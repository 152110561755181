import { FunctionComponent, ElementType } from 'react';
import {
  View,
  TouchableView,
  Large,
  Swap,
  CaringProvider,
  PauseSign,
  StopSign,
} from '@talkspace/react-toolkit';
import { ShieldPlus } from '@talkspace/react-toolkit/src/designSystems/icons';
import { SuperbillsIcon } from '../icons';
import styled from '../../core/styled';

const ActionTouchableView = styled(TouchableView)({
  flexDirection: 'row',
  marginTop: 12,
  marginBottom: 12,
  width: 'fit-content',
  alignItems: 'center',
});

const IconWrapper = styled(View)({
  width: 24,
  alignItems: 'center',
});

const LargeBoldWide = styled(Large)(({ theme: { colors } }) => {
  return { fontWeight: 'bold', color: colors.greenText };
});

interface ClickableActionProps {
  onPress: () => void;
  IconComponent: ElementType;
  label: string;
  dataQa?: string;
}

const ClickableAction: FunctionComponent<ClickableActionProps> = ({
  onPress,
  label,
  IconComponent,
  dataQa,
}) => (
  <ActionTouchableView aria-label={label} onPress={onPress} dataQa={dataQa}>
    <IconWrapper>
      <IconComponent />
    </IconWrapper>
    <LargeBoldWide style={{ marginLeft: 11 }}>{label}</LargeBoldWide>
  </ActionTouchableView>
);

interface ActionButtonsProps {
  canChangeProvider?: boolean;
  canViewSuperbills?: boolean;
  canChangePlan?: boolean;
  canCancelPlan?: boolean;
  canPauseService: boolean;
  isPendingSubscription?: boolean;
  isB2B?: boolean;
  handlePauseButtonPress?: () => void;
  handleViewSuperbills?: () => void;
  handleChangeProviderButtonPress?: () => void;
  handleChangePlanButtonPress?: () => void;
  handleCancelPlanButtonPress?: () => void;
  handleUpdateCoverageButtonPress?: () => void;
}

const getCancelLabel = (isB2B: boolean | undefined, isPendingSubscription: boolean | undefined) => {
  if (isB2B) return 'Cancel my plan';
  return `${isPendingSubscription ? 'Cancel' : 'Stop'} subscription renewal`;
};

const SubscriptionActionButtons: FunctionComponent<ActionButtonsProps> = ({
  canChangeProvider,
  canViewSuperbills,
  canChangePlan,
  canCancelPlan,
  isPendingSubscription,
  isB2B,
  handlePauseButtonPress,
  handleChangePlanButtonPress,
  handleChangeProviderButtonPress,
  handleViewSuperbills,
  handleCancelPlanButtonPress,
  handleUpdateCoverageButtonPress,
  canPauseService,
}) => (
  <View style={{ marginBottom: 20 }}>
    {canChangeProvider && handleChangeProviderButtonPress ? (
      <ClickableAction
        label="Change provider"
        onPress={handleChangeProviderButtonPress}
        IconComponent={CaringProvider}
        dataQa="changeProvider"
      />
    ) : null}
    {handleUpdateCoverageButtonPress ? (
      <ClickableAction
        label="Update my coverage"
        onPress={handleUpdateCoverageButtonPress}
        IconComponent={() => <ShieldPlus size="major" colorType="brand" />}
        dataQa="updateCoverage"
      />
    ) : null}
    {canViewSuperbills && handleViewSuperbills ? (
      <ClickableAction
        label="View superbills"
        onPress={handleViewSuperbills}
        IconComponent={SuperbillsIcon}
        dataQa="viewSuperbills"
      />
    ) : null}
    {canPauseService && handlePauseButtonPress ? (
      <ClickableAction
        label="Pause therapy"
        onPress={handlePauseButtonPress}
        IconComponent={PauseSign}
        dataQa="pauseTherapy"
      />
    ) : null}
    {canChangePlan && handleChangePlanButtonPress ? (
      <ClickableAction
        label="Change plan"
        onPress={handleChangePlanButtonPress}
        IconComponent={Swap}
        dataQa="changePlan"
      />
    ) : null}
    {canCancelPlan && handleCancelPlanButtonPress ? (
      <ClickableAction
        label={getCancelLabel(isB2B, isPendingSubscription)}
        onPress={handleCancelPlanButtonPress}
        IconComponent={StopSign}
        dataQa="cancelSubscriptionButton"
      />
    ) : null}
  </View>
);

export default SubscriptionActionButtons;
