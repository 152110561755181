import { FunctionComponent } from 'react';
import { View, Large } from '@talkspace/react-toolkit';

import styled from '../../../core/styled';
import NotificationToggle from '../NotificationToggle';

const Wrapper = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: 310,
  justifyContent: 'space-between',
  marginBottom: 11,
});

const ExtraSmall = styled(View)(({ theme: { colors } }) => {
  return { fontSize: 11, paddingRight: 3, color: colors.slateGrey };
});

interface Props {
  label: string;
  isOn: boolean;
  iconComponent?: () => JSX.Element;
  isRequired?: boolean;
  onToggle: (value: number) => void;
  name: string;
}

const NotificationPanelRow: FunctionComponent<Props> = ({
  label,
  iconComponent,
  isOn,
  onToggle,
  isRequired = false,
  name,
}) => (
  <Wrapper>
    <View row>
      {iconComponent ? iconComponent() : <View style={{ marginRight: -11 }} />}
      <Large style={{ marginLeft: 11 }}>{label}</Large>
    </View>
    {isRequired ? (
      <ExtraSmall>Required</ExtraSmall>
    ) : (
      <NotificationToggle
        label={label}
        isOn={isOn}
        onChange={(value: boolean) => onToggle(Number(value))}
        name={name}
      />
    )}
  </Wrapper>
);

export default NotificationPanelRow;
