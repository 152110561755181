import { useEffect, useState } from 'react';

import {
  CommentRepository,
  getPostTopic,
  subscribeTopic,
  SubscriptionLevels,
} from '@amityco/ts-sdk';

import useCommunity from './useCommunity';
import { useCommunityState } from './CommunityContext';

const useCommunityPostComments = ({ post }: { post: Amity.Post }) => {
  const [commentsData, setCommentsData] = useState<Amity.Comment[]>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [onLoadMoreObj, setOnLoadMoreObj] = useState<{
    onLoadMore: () => void;
  }>({ onLoadMore: () => {} });

  const { isConnected, communityID } = useCommunityState();
  const { community } = useCommunity({ communityID });

  useEffect(() => {
    const disposers: Amity.Unsubscriber[] = [];
    if (isConnected && community && post) {
      const textOnlyParam: Amity.CommentLiveCollection = {
        referenceType: 'post',
        referenceId: post.postId,
        dataTypes: {
          values: ['text'],
          matchType: 'exact',
        },
        limit: 5,
      };

      disposers.push(
        CommentRepository.getComments(
          textOnlyParam,
          ({ data: comments, onNextPage, hasNextPage, loading, error }) => {
            setCommentsData(comments);
            setIsLoading(loading);
            setIsError(error);
            setHasMore(hasNextPage || false);
            if (onNextPage) setOnLoadMoreObj({ onLoadMore: onNextPage });
          }
        )
      );

      disposers.push(subscribeTopic(getPostTopic(post, SubscriptionLevels.COMMENT)));
    }
    return () => {
      disposers.forEach((fn) => fn());
    };
  }, [isConnected, community, post]);

  return {
    comments: commentsData,
    hasMore,
    onLoadMore: onLoadMoreObj?.onLoadMore,
    isLoading,
    isError,
  };
};

export default useCommunityPostComments;
