import { VideoCredit } from 'ts-frontend/types';
import {
  getPsychiatryReminders,
  getTherapistReminders,
  ReminderStatus,
} from 'ts-frontend/helpers/lvsReminderStatuses';
import { useEffect, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import { InRoomSchedulingCard, useQueryBookings } from 'inRoomScheduling';
import { useUniqueID, useEmotionTheme, View, Button, Big, Spinner } from '@talkspace/react-toolkit';

import { useNewMemberNav } from 'launchDarkly/hooks';
import { trackEvent } from '@/utils/analytics/eventTracker';
import ContextualReminderCard from '../ContextualReminderCard';
import extractRoomEntities from '../../utils/extractRoomEntitiesFromState';
import useSubscriptions from '../../myAccount/hooks/useSubscriptions';
import { useMainState } from '../../hooks/mainContext';
import { withRouter, RouteComponentProps } from '../../core/routerLib';
import useOpenModalSafe from '@/hooks/useOpenModalSafe';
import { useCloseModal } from '@/utils/ModalsContextProvider';

export interface StatusData {
  status: ReminderStatus;
  date?: string;
  remaining?: number;
}

const PanelLVSBlock = ({
  match,
  location,
  isCT,
  isNoMatchesState,
}: RouteComponentProps<
  {
    roomID: string;
  },
  {},
  { from?: string }
> & { isCT?: boolean; isNoMatchesState?: boolean }) => {
  const mainState = useMainState();
  const { therapist, room } = extractRoomEntities(mainState, Number(match.params.roomID));
  const { data: bookings, isLoading, isError } = useQueryBookings({ roomID: +match.params.roomID });
  const { subscriptionsByID } = useMainState();
  const [{ subscriptions }, { getSubscriptions }] = useSubscriptions();
  const [statusData, setStatusData] = useState<StatusData | undefined>(undefined);
  const openModal = useOpenModalSafe();
  const { colors } = useEmotionTheme();
  const closeModal = useCloseModal();
  const useNewNav = useNewMemberNav();

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions, match.params.roomID]);

  const roomSubscription =
    subscriptions && subscriptions.find((s) => `${s.id}` === match.params.roomID);

  useEffect(() => {
    const { liveVideoSessionStatus, videoCredits } = subscriptionsByID[match.params.roomID] || {};

    const statusDataToPersist =
      liveVideoSessionStatus &&
      (room.roomType === 'psychiatry_room'
        ? getPsychiatryReminders(liveVideoSessionStatus)
        : getTherapistReminders({
            ...liveVideoSessionStatus,
            videoCredits: videoCredits as VideoCredit[],
          }));
    if (statusDataToPersist?.status !== ReminderStatus.NO_SESSION) {
      trackEvent('Booking Reminder Sent', {
        source: 'In-room banner',
        therapistID: therapist.id,
        planID: roomSubscription?.subscription.planID,
        roomID: room.roomID,
      });
    }
    setStatusData(statusDataToPersist);
  }, [
    subscriptions,
    match.params.roomID,
    room.roomType,
    subscriptionsByID,
    room.roomID,
    therapist.id,
    roomSubscription,
  ]);

  const handleOnPress = () => {
    openModal(`/in-room-scheduling/room/${match.params.roomID}`, {
      from: location.state && location.state.from ? location.state.from : null,
    });
  };

  const hasAnyCredit =
    roomSubscription && roomSubscription.videoCredits && roomSubscription.videoCredits.length > 0;

  const canCreateBooking = roomSubscription?.subscription.canCreateBooking;

  const handleOnConfirmPress = (booking) => {
    if (!canCreateBooking) return;
    openModal(
      `/in-room-scheduling/room/${match.params.roomID}/confirm-booking/booking/${booking.id}?batch=true`,
      {
        from: location.state && location.state.from ? location.state.from : null,
      }
    );
  };

  const handleOnKebabPress = (booking) => {
    if (!canCreateBooking) return;
    if (booking.scheduledByUserType === 'provider' && booking.timekitBookingState === 'tentative') {
      openModal(
        `/in-room-scheduling/room/${match.params.roomID}/decline-booking/booking/${booking.id}`,
        {
          from: location.state && location.state.from ? location.state.from : null,
        }
      );
    } else if (
      booking.timekitBookingState === 'confirmed' ||
      booking.timekitBookingState === 'tentative'
    ) {
      if (useNewNav) {
        closeModal({
          navigateTo: 'cancelBooking',
          metadata: { roomID: Number(match.params.roomID), bookingID: booking.id },
        });
      } else {
        openModal(
          `/in-room-scheduling/room/${match.params.roomID}/cancel-booking/booking/${booking.id}`,
          {
            from: location.state && location.state.from ? location.state.from : null,
          }
        );
      }
    }
  };

  const lvsMessageID = useUniqueID('lvsMessageID');
  const showLVSMessage = !bookings || !bookings.length || isError;
  const { status, date, remaining } = statusData || {};

  const getCard = () => {
    if (isLoading || !status) {
      return (
        <View style={{ minHeight: 73 }}>
          <View align="center" justify="center" flex={1} style={{ height: 70 }}>
            <Spinner isLoading primaryColor={colors.chineseSilver} />
          </View>
        </View>
      );
    }
    if (
      status === ReminderStatus.NO_SESSION ||
      status === ReminderStatus.SCHEDULED_INITIAL_EVALUATION ||
      status === ReminderStatus.SCHEDULED_FOLLOW_UP ||
      status === ReminderStatus.NO_AVAILABLE_PSYCH_CREDIT ||
      bookings?.length
    ) {
      if (showLVSMessage) {
        return (
          <InRoomSchedulingCard
            lvsMessageID={lvsMessageID}
            actionIconType="dots"
            LVSIconSize={70}
            therapist={therapist}
            hasAnyCredit={hasAnyCredit}
            isDisabled
            hasNoBookings={!!bookings && !bookings.length}
            isLoading={isLoading}
            isError={isError}
            isEAP={room.isEAP}
            style={{ marginTop: 11 }}
          />
        );
      }
      return uniqBy(bookings, 'id').map((booking, i) => (
        <View
          key={booking.id}
          style={{
            borderTop: i > 0 ? '1px solid #D8D8D8' : undefined,
            marginBottom: 18,
            paddingTop: 15,
          }}
        >
          <InRoomSchedulingCard
            actionIconType={booking.modality === 'messaging' ? undefined : 'dots'}
            LVSIconSize={70}
            booking={booking}
            therapist={therapist}
            isLoading={isLoading}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            onPress={() => handleOnConfirmPress(booking)}
            onKebabPress={() => handleOnKebabPress(booking)}
          />
        </View>
      ));
    }

    if (therapist.type === 'primary' || therapist.type === 'psychiatrist') {
      return (
        <ContextualReminderCard
          status={status}
          date={date}
          remaining={remaining}
          videoCredits={subscriptionsByID?.[match.params.roomID]?.videoCredits}
        />
      );
    }
    return null;
  };

  const getContextualCardWithBookings = () => {
    // targeting b2c therapy users for 4lvs
    const videoCredit = subscriptionsByID?.[match.params.roomID]?.videoCredits?.find(
      ({ type }) => type === 'therapy'
    );
    if (
      bookings?.length &&
      room.roomType !== 'psychiatry_room' &&
      videoCredit?.creditAvailable !== 0 &&
      videoCredit?.creditTotal === 4
    ) {
      return (
        <ContextualReminderCard
          status={ReminderStatus.HAS_SCHEDULED_XSCHEDULED_4LVS}
          date={date}
          remaining={remaining}
          videoCredits={subscriptionsByID?.[match.params.roomID]?.videoCredits}
        />
      );
    }
    return null;
  };

  return (
    <View>
      {getContextualCardWithBookings()}
      <Big as="h2" style={{ marginBottom: 5 }}>
        Your schedule
      </Big>
      {getCard()}
      <Button
        aria-describedby={showLVSMessage ? lvsMessageID : undefined}
        text="Book a session"
        style={{ width: '100%' }}
        onPress={handleOnPress}
        disabled={!canCreateBooking || isNoMatchesState}
        dataQa="roomDetailsBookSession"
      />
    </View>
  );
};

export default withRouter(PanelLVSBlock);
