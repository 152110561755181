import { Col, Grid, Row } from '@talkspace/react-toolkit';
import TeenspaceCommunity from '../../community/containers/TeenspaceCommunity';
import useTeenspaceOnboarding from '../../community/hooks/useTeenspaceOnboarding';

const TeenspaceScreen = () => {
  useTeenspaceOnboarding();

  return (
    <Grid fluid rowSpacing="space200">
      <Row>
        <Col xs={4} sm={8}>
          <TeenspaceCommunity />
        </Col>
      </Row>
    </Grid>
  );
};

export default TeenspaceScreen;
