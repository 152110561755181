import React from 'react';
import { Moment } from 'moment';

import {
  TextDS,
  View,
  spacing,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';

import { Clock } from '@talkspace/react-toolkit/src/designSystems/icons';

const { space100, space150, space200 } = spacing;

interface Props {
  commentingEndedAt: Moment;
}
const CommentingEndedBanner = ({ commentingEndedAt }: Props) => {
  const { colorRoles } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  return (
    <View
      row
      justify="center"
      style={{
        backgroundColor: colorRoles.surfaces.infoSubtle,
        padding: space150,
        position: 'relative',
        bottom: 0,
        width: isMobile ? '100vw' : undefined,
        left: isMobile ? -space200 : 0,
      }}
    >
      <Clock colorType="info" />
      <TextDS variant="bodySm" style={{ marginLeft: space100 }}>
        Commenting ended {commentingEndedAt.format('MMMM DD, YYYY')}
      </TextDS>
    </View>
  );
};

export default CommentingEndedBanner;
