import {
  Big,
  Large,
  SparklingCalendar,
  Spinner,
  View,
  Button,
  useEmotionTheme,
} from '@talkspace/react-toolkit';

import BaseWrapper from './BaseWrapper';
import SwitchProviderBox from './SwitchProviderBox';

interface Props {
  roomID: number;
  isLoading: boolean;
  isBH?: boolean;
  isEAP?: boolean;
  isBookingTooSoon?: boolean;
  isBookingConfirmed?: boolean;
  canSwitchProvider?: boolean;
  onClose: () => void;
}
const CancelSessionConfirmation = ({
  roomID,
  isLoading,
  isBH,
  isEAP,
  isBookingTooSoon,
  isBookingConfirmed,
  canSwitchProvider,
  onClose,
}: Props) => {
  const { colors } = useEmotionTheme();

  let description;
  if (isBH && (!isBookingTooSoon || !isBookingConfirmed))
    description =
      'You will not be charged for this session and your session credit is refunded to your account and can be rescheduled.';
  if (isBH && isBookingTooSoon && isBookingConfirmed)
    description =
      'We know things come up. Unfortunately, you cancelled less than 24 hours before your session. As a result, your session credit is redeemed and you will be charged for the cost of the fee of service, which can be up to $175.';
  if (!isBH) {
    if (isEAP || !isBookingTooSoon || !isBookingConfirmed) {
      description = 'Your session credit is refunded to your account and can be rescheduled.';
    }
    if (!isEAP && isBookingTooSoon && isBookingConfirmed) {
      description =
        'We know things come up. Unfortunately, our 24 hour cancellation policy means that a session credit is redeemed for this cancellation.';
    }
  }
  return (
    <BaseWrapper>
      <>
        <SparklingCalendar />
        <Big style={{ marginTop: 24 }}>Your session is cancelled</Big>
        <View style={{ marginTop: 16, alignItems: 'center' }}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Large>{description}</Large>
              {canSwitchProvider ? (
                <SwitchProviderBox roomID={roomID} style={{ marginTop: 40 }} />
              ) : (
                <Button
                  dataQa="cancellationDoneCTA"
                  onPress={onClose}
                  isLoading={isLoading}
                  style={{ marginTop: 32, backgroundColor: colors.permaTalkspaceDarkGreen }}
                >
                  Done
                </Button>
              )}
            </>
          )}
        </View>
      </>
    </BaseWrapper>
  );
};

export default CancelSessionConfirmation;
