import { FunctionComponent } from 'react';
import {
  View,
  Button,
  Large,
  ExtraHuge,
  useWindowWidth,
  StepTip,
  AvatarWithFloatingShapes,
} from '@talkspace/react-toolkit';
import styled from '../../../core/styled';
import ErrorModal from '../ErrorModal/ErrorModal';

interface InPlatformMatchConfirmProps {
  therapistName: string;
  therapistImageURL: string;
  therapistIsUnavailable?: boolean;
  numberOfMatches: number;
  switchTherapist: () => void;
  handleSecondaryClick: () => void;
  goBackToMatches: () => void;
  shouldExplanationBlockAppear?: boolean;
  canBookIntroSession?: boolean;
  isB2B?: boolean;
}

const BoldSpan = styled.span({ fontWeight: 'bold' });

const TherapistReadyText = styled(Large)({
  maxWidth: 320,
  textAlign: 'center',
  marginTop: 13,
  paddingLeft: 5,
  paddingRight: 5,
});

const SelectButton = styled(Button)(({ theme: { colors } }) => {
  return {
    marginTop: 36,
    marginBottom: 42,
    width: 335,
    backgroundColor: colors.permaTalkspaceDarkGreen,
  };
});

const InPlatformMatchConfirm: FunctionComponent<InPlatformMatchConfirmProps> = ({
  therapistName,
  therapistImageURL,
  switchTherapist,
  therapistIsUnavailable,
  numberOfMatches,
  handleSecondaryClick,
  goBackToMatches,
  shouldExplanationBlockAppear = true,
  canBookIntroSession = false,
  isB2B = false,
}) => {
  const getUnavailableText = () => (isB2B ? 'Contact support' : 'Talk to a matching agent');

  // note: if unavailable, the therapist is removed from the array
  // that is why we look for 0 or greater than 0 here
  const onTherapistUnavailableButtonPress =
    numberOfMatches > 0 ? goBackToMatches : handleSecondaryClick;
  const onTherapistUnavailableButtonText =
    numberOfMatches > 0 ? 'Choose a different therapist' : getUnavailableText();
  const { isMobile } = useWindowWidth();

  return therapistIsUnavailable ? (
    <View flex={1} align="center">
      <ErrorModal
        error="Sorry, therapist is unavailable"
        errorMessage="Unfortunately the therapist you've chosen has just become unavailable."
        onButtonPress={onTherapistUnavailableButtonPress}
        buttonText={onTherapistUnavailableButtonText}
      />
    </View>
  ) : (
    <View flex={1} align="center">
      <View flex={isMobile ? 1 : 0} align="center" style={{ marginBottom: isMobile ? 0 : 45 }}>
        <AvatarWithFloatingShapes
          imageURL={therapistImageURL}
          showBookingIcon={canBookIntroSession}
        />

        <ExtraHuge style={{ maxWidth: 326, textAlign: 'center' }}>
          You've selected {therapistName}!
        </ExtraHuge>
        <TherapistReadyText variant="largeDarkGrey">
          {canBookIntroSession ? (
            <>
              To break the ice, Talkspace would like to offer you a{' '}
              <BoldSpan>10 minute live intro session.</BoldSpan>
            </>
          ) : (
            `${therapistName} is ready to help you on your journey towards feeling better.`
          )}
        </TherapistReadyText>
      </View>
      <View flex={isMobile ? 0 : 1} align="center">
        {shouldExplanationBlockAppear && (
          <StepTip tip="switchExplanation" style={{ maxWidth: 365 }} />
        )}
        <SelectButton
          text={`Continue with ${therapistName}`}
          onPress={switchTherapist}
          dataQa="inPlatformMatchContinueWithTherapist"
        />
      </View>
    </View>
  );
};

export default InPlatformMatchConfirm;
