import { suicideIdeationOrPlanning } from '@talkspace/configs';
import { WizardScheme } from 'stepWizard';
import EmergencyResourcesBodyText from '../components/EmergencyResourcesBodyText';

const teenIntakeNewSteps: WizardScheme['steps'] = [
  {
    title: 'If you’re in immediate danger, don’t use this app. Instead, use these resources:',
    bodyComponent: () => <EmergencyResourcesBodyText />,
    nextButton: {
      displayText: `Next`,
      actionDispatch: 'next',
      whenVisible: 'never',
    },
    secondaryButton: {
      displayText: `Next`,
      actionDispatch: 'next',
      whenVisible: 'always',
    },
    name: 'emergencyResources',
    backOption: 'back',
    displayCondition: 'selectedSuicidePlanning',
  },
  {
    title: 'Have you ever had thoughts about harming or killing others?',
    inputType: 'select',
    inputState: 'homicidalIdeation',
    nextButton: {
      displayText: 'Next',
      actionDispatch: 'next',
      whenVisible: 'onSelect',
    },
    inputOptions: 'homicidalIdeationOrPlanningOptions',
    name: 'homicidalIdeation',
    backOption: 'back',
    postAction: 'setHomicidalIdeation',
  },
  {
    title: 'Have you ever thought about how you might do this?',
    inputType: 'select',
    inputState: 'homicidalPlanning',
    nextButton: {
      displayText: 'Next',
      actionDispatch: 'next',
      whenVisible: 'onSelect',
    },
    inputOptions: 'homicidalIdeationOrPlanningOptions',
    name: 'homicidalPlanningPlanning',
    backOption: 'back',
    displayCondition: 'selectedHomicidalIdeation',
    postAction: 'setHomicidalPlanning',
  },

  {
    title: 'Do you have excessive anger, aggression, and/or violent behaviors?',
    inputType: 'select',
    inputState: 'angerAggression',
    nextButton: {
      displayText: 'Next',
      actionDispatch: (value, wizardContext) => {
        if (wizardContext.source === 'onboarding')
          return `/room/${wizardContext.roomID}/onboarding/mental-health/source/onboarding/submit`;
        return '/mental-health-wizard/submit';
      },
      whenVisible: 'onSelect',
    },
    inputOptions: 'angerAggressionOptions',
    name: 'angerAggression',
    backOption: 'back',
    postAction: 'setAngerAggression',
  },
];

const getMentalHealthScheme = (featureFlag: boolean): WizardScheme => {
  const teenIntakeSteps: WizardScheme['steps'] = featureFlag ? teenIntakeNewSteps : [];
  return {
    wizardModalTitle: 'mental intake',
    wizardVersion: 1,
    wizardType: 'mentalIntake',
    disablePersist: true,
    steps: [
      {
        title: 'How would you describe your sleep quality?',
        inputType: 'select',
        inputState: 'sleepQuality',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'sleepQualityOptions',
        name: 'sleepQuality',
      },
      {
        title: 'Do you use any sleep aids at night?',
        inputType: 'select',
        inputState: 'sleepAidUse',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'sleepAidUseOptions',
        name: 'sleepAidUse',
        backOption: 'back',
      },
      {
        title: 'How would you describe your social support system?',
        inputType: 'select',
        inputState: 'socialSupportSystem',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'socialSupportSystemOptions',
        name: 'socialSupportSystem',
        backOption: 'back',
      },
      {
        title: 'Are you seeing or have you previously seen a mental health professional?',
        inputType: 'select',
        inputState: 'previousMentalHealthTreatment',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'previousMentalHealthTreatmentOptions',
        name: 'previousMentalHealthTreatment',
        backOption: 'back',
        postAction: 'setPreviousMentalHealthTreatment',
      },
      {
        title:
          'Which (if any) of the following mental health conditions have you been diagnosed with?',
        inputTypePlaceholder: 'Select',
        inputType: 'searchableMultiSelect',
        inputState: 'previousMentalHealthIssues',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: 'Skip',
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        inputOptions: 'previousMentalHealthIssuesOptions',
        name: 'previousMentalHealthIssues',
        displayCondition: 'selectedPreviousMentalHealthTreatment',
        backOption: 'back',
      },
      {
        title: 'Have you ever been hospitalized for psychiatric care?',
        inputType: 'select',
        inputState: 'hospitalizationHistory',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'hospitalizationHistoryOptions',
        name: 'hospitalizationHistory',
        displayCondition: 'selectedPreviousMentalHealthTreatment',
        backOption: 'back',
      },
      {
        title:
          'Have any immediate relatives been diagnosed with or treated for any of the following mental health conditions?',
        inputType: 'searchableMultiSelect',
        inputState: 'previousRelativesMentalHealthIssues',
        inputTypePlaceholder: 'Select',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: 'Skip',
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        inputOptions: 'previousRelativesMentalHealthIssuesOptions',
        name: 'previousRelativesMentalHealthIssues',
        backOption: 'back',
      },
      {
        title: 'Have you ever experienced an especially frightening, horrible, or traumatic event?',
        inputType: 'select',
        inputState: 'traumaticExperience',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'traumaticExperienceOptions',
        name: 'traumaticExperience',
        backOption: 'back',
        postAction: 'setTraumaticExperience',
      },
      {
        title:
          'In the past month, have you struggled with situations, reminders, dreams and/or thoughts of the event?',
        inputType: 'select',
        inputState: 'traumaticFlashbacks',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'traumaticFlashbacksOptions',
        name: 'traumaticFlashbacks',
        backOption: 'back',
        postAction: 'setTraumaticFlashbacks',
        displayCondition: 'selectedTraumaticExperience',
      },
      {
        title:
          'In the past month, have you felt guilty or inclined to blame yourself for what happened? ',
        inputType: 'select',
        inputState: 'guilt',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'guiltOptions',
        name: 'guilt',
        backOption: 'back',
        postAction: 'setGuilt',
        displayCondition: 'selectedTraumaticExperience',
      },
      {
        title:
          'In the past month, have you felt more distant from those close to you or indifferent toward activities you used to enjoy?',
        inputType: 'select',
        inputState: 'isolation',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'isolationOptions',
        name: 'isolation',
        backOption: 'back',
        postAction: 'setIsolation',
        displayCondition: 'selectedTraumaticExperience',
      },
      {
        title: 'Have you ever had thoughts of killing yourself?',
        inputType: 'select',
        inputState: 'suicideIdeation',
        nextButton: {
          displayText: 'Next',
          actionDispatch: (value, wizardContext) => {
            if (featureFlag) {
              return 'next';
            }
            if (suicideIdeationOrPlanning[value] !== suicideIdeationOrPlanning.never) {
              return 'next';
            }
            if (wizardContext.source === 'onboarding')
              return `/room/${wizardContext.roomID}/onboarding/mental-health/source/onboarding/submit`;
            return '/mental-health-wizard/submit';
          },
          whenVisible: 'onSelect',
        },
        inputOptions: 'suicideIdeationOptions',
        name: 'suicideIdeation',
        backOption: 'back',
        postAction: 'setSuicideIdeation',
      },
      {
        title: 'Have you ever thought about how you might do this?',
        inputType: 'select',
        inputState: 'suicidePlanning',
        nextButton: {
          displayText: 'Next',
          actionDispatch: (_, wizardContext) => {
            if (featureFlag) {
              return 'next';
            }
            if (wizardContext.source === 'onboarding') {
              return `/room/${wizardContext.roomID}/onboarding/mental-health/source/onboarding/submit`;
            }
            return '/mental-health-wizard/submit';
          },
          whenVisible: 'onSelect',
        },
        inputOptions: 'suicidePlanningOptions',
        name: 'suicidePlanning',
        backOption: 'back',
        displayCondition: 'selectedSuicideIdeation',
        postAction: 'setSuicidePlanning',
      },
      ...teenIntakeSteps,
    ],
  };
};

export default getMentalHealthScheme;
