import { createContext, useContext, useReducer, useRef, useEffect, useCallback } from 'react';
import moment from 'moment';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { showAudioVideoPermDialog } from 'ts-ionic/plugins/dialog';
import appConfig from '@/utils/configs';
import { getUserData } from '@/auth/helpers/token';
import {
  AgoraCredentials,
  NavigatorExtended,
  VideoCallName,
  VideoCallEvent,
  VideoCallState,
  VideoCreditType,
  CaptionsLanguage,
  VirtualBackground,
} from '../types/videoCallTypes';
import ApiHelper from '../utils/ApiHelper';
import useCamera from './useCamera';
import useMicrophone from './useMicrophone';
import { getStoredCaptionsLanguage, setStoredCaptionsLanguage } from '../helpers';

interface Actions {
  getVideoCallAction: (roomID: number, videoCallID: number) => void;
  getLiveSessionStartedAtAction: (roomID: number, videoCallID: number) => void;
  createVideoCallEventAction: (
    roomID: number,
    videoCallID: number,
    eventType: VideoCallEvent
  ) => void;
  setVideoCallInitialStateAction: (state: Partial<VideoCallState>) => void;
  toggleAudioVideoAction: (isAudioOn: boolean, isVideoOn: boolean) => void;
  joinCallAction: (agoraCredentials: AgoraCredentials) => void;
  setSelectedAudioOutAction: (selectedAudioOut: MediaDeviceInfo) => void;
  setSelectedAudioInAction: (selectedAudioIn: MediaDeviceInfo) => void;
  setSelectedCameraAction: (selectedCamera: MediaDeviceInfo) => void;
  setMediaListsAction: (
    cameras: MediaDeviceInfo[] | undefined,
    audioOuts: MediaDeviceInfo[] | undefined,
    audioIns: MediaDeviceInfo[] | undefined
  ) => void;
  setTherapistScheduleNextSessionPopupAction: (
    showTherapistScheduleNextSessionPopup: boolean
  ) => void;
  errorOccurredAction: () => void;
  leaveCallAction: () => void;
  endCallAction: (startTime?: string) => void;
  resetVideoCallStateAction: () => void;
  requestWakeLockSentinelAction: () => void;
  removeWakeLockSentinelAction: () => void;
  toggleMinimizedAction: () => void;
  setIsCaptionsSelectionOpen: (isCaptionsSelectionOpen: boolean) => void;
  setCaptionsLanguage: (videoCallID: number, captionsLanguage: CaptionsLanguage) => void;
  setVirtualBackground: (value: VirtualBackground) => void;
}

const videoCreditToSessionNameMapping = {
  [`${VideoCreditType.free}video`]: VideoCallName.freeVideoSession,
  [`${VideoCreditType.free}audio`]: VideoCallName.freeAudioSession,
  [`${VideoCreditType.introduction}video`]: VideoCallName.introductionSession,
  [`${VideoCreditType.introduction}audio`]: VideoCallName.introductionSession,
  [`${VideoCreditType.therapy}video`]: VideoCallName.liveVideoSession,
  [`${VideoCreditType.therapy}audio`]: VideoCallName.liveAudioSession,
  [VideoCreditType.free]: VideoCallName.freeSession,
  [VideoCreditType.introduction]: VideoCallName.introductionSession,
  [VideoCreditType.psychiatry]: VideoCallName.psychiatrySession,
};

const VideoCallInitialState: VideoCallState = {
  startTime: undefined,
  creditMinutes: undefined,
  videoCreditType: undefined,
  videoCallID: undefined,
  tokenExpiresAt: undefined,
  therapistFirstName: undefined,
  therapistLastName: undefined,
  startDayString: undefined,
  callTimeScheduleString: undefined,
  videoCallDescription: undefined,
  cameras: [],
  audioIns: [],
  audioOuts: [],
  selectedCamera: undefined,
  selectedAudioIn: undefined,
  selectedAudioOut: undefined,
  rating: undefined,
  agoraClient: undefined,
  isAudioOn: false,
  isVideoOn: false,
  isJoined: false,
  therapistUserID: -1,
  roomID: -1,
  isLoadingVideoCall: false,
  error: undefined,
  clientConnectionTime: '',
  wakeLockSentinel: undefined,
  videoCallEnded: false,
  showTherapistScheduleNextSessionPopup: false,
  isLoading: false,
  isMinimized: false,
  isCaptionsSelectionOpen: false,
  captionsLanguage: '',
  virtualBackground: '',
};

const StateContext = createContext<VideoCallState>(VideoCallInitialState);
export { StateContext as VideoCallStateContext };

const ActionsContext = createContext<Actions | undefined>(undefined);

function VideoCallReducer(
  currentState: VideoCallState,
  action: { type: string; payload?: Partial<VideoCallState> }
): VideoCallState {
  return { ...currentState, ...action.payload };
}

export function VideoCallProvider({ children }) {
  const [state, dispatch] = useReducer(VideoCallReducer, VideoCallInitialState);
  const {
    videoCall,
    agoraClient,
    selectedCamera,
    isAudioOn,
    isVideoOn,
    isJoined,
    isMinimized,
    selectedAudioOut,
    selectedAudioIn,
    wakeLockSentinel,
  } = state;
  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;

  useEffect(
    () => () => {
      api.cancelAll();
    },
    [api]
  );

  useEffect(() => {
    if (!videoCall) {
      return;
    }

    const storedCaptionsLanguage = getStoredCaptionsLanguage();

    if (!storedCaptionsLanguage || !storedCaptionsLanguage[videoCall.videoCallID]) {
      return;
    }

    dispatch({
      type: 'setCaptionsLanguage',
      payload: {
        captionsLanguage: (storedCaptionsLanguage[videoCall.videoCallID] || '') as CaptionsLanguage,
      },
    });
  }, [videoCall]);

  const handleInputError = (error) => {
    dispatch({
      type: 'setState',
      payload: {
        error: error.code === 'PERMISSION_DENIED' ? 'permissionsDenied' : 'error',
      },
    });

    if (error.code === 'PERMISSION_DENIED') {
      showAudioVideoPermDialog({ isAudioOn, isVideoOn });
      return;
    }

    throw error;
  };

  const videoTrack = useCamera({
    agoraClient,
    isJoined,
    isVideoOn,
    onError: handleInputError,
    selectedCamera,
  });

  useEffect(() => {
    dispatch({
      type: 'setLocalVideoTrack',
      payload: { localVideoTrack: videoTrack },
    });
  }, [videoTrack]);

  useMicrophone({
    agoraClient,
    isAudioOn,
    isJoined,
    onError: handleInputError,
    selectedAudioIn,
  });

  function setVideoCallInitialState(payload: Partial<VideoCallState>): void {
    const startDayString = moment(payload.startTime).format('dddd, MMM D');
    const callStartTimeMoment = moment(payload.startTime);
    const callStartTimeString = callStartTimeMoment.format('h:mmA');
    const callTimeScheduleString = payload.creditMinutes
      ? `${callStartTimeString} - ${callStartTimeMoment
          .add(payload.creditMinutes, 'minute')
          .format('h:mmA')}`
      : callStartTimeString;

    let videoCallName: string | undefined;
    if (
      payload.videoCreditType &&
      [VideoCreditType.free, VideoCreditType.introduction, VideoCreditType.therapy].includes(
        payload.videoCreditType as VideoCreditType
      ) &&
      payload.modality &&
      ['video', 'audio'].includes(payload.modality)
    ) {
      videoCallName =
        videoCreditToSessionNameMapping[`${payload.videoCreditType}${payload.modality}`];
    } else {
      videoCallName =
        payload.videoCreditType && videoCreditToSessionNameMapping[payload.videoCreditType];
    }

    const videoCallDescription =
      payload.creditMinutes === 0
        ? videoCallName
        : `${videoCallName} (${payload.creditMinutes}min)`;

    dispatch({
      type: 'setInitialVideoCallState',
      payload: {
        startDayString,
        callTimeScheduleString,
        videoCallDescription,
        ...payload,
        videoCall: undefined,
      },
    });
  }

  async function getVideoCall(roomID: number, videoCallID: number) {
    dispatch({
      type: 'getVideoCall',
      payload: { isLoadingVideoCall: true, error: undefined },
    });

    try {
      const videoCallAPIResponse = await api.getVideoCall(roomID, videoCallID);
      AgoraRTC.setLogLevel(appConfig.agora.logLevel);
      const isChromebook = window.navigator.userAgent.includes('CrOS');
      const newAgoraClient = AgoraRTC.createClient({
        mode: 'rtc',
        codec: isChromebook ? 'vp8' : 'h264',
      });
      dispatch({
        type: 'getVideoCallSuccess',
        payload: {
          videoCall: videoCallAPIResponse,
          isLoadingVideoCall: false,
          agoraClient: newAgoraClient,
        },
      });

      const cameras = await AgoraRTC.getCameras();
      const audioIns = await AgoraRTC.getMicrophones();
      const audioOuts = await AgoraRTC.getPlaybackDevices();

      dispatch({
        type: 'updateMediaLists',
        payload: {
          cameras,
          audioIns,
          audioOuts,
          selectedCamera: cameras[0],
          selectedAudioIn: audioIns[0],
          selectedAudioOut: audioOuts[0],
        },
      });
    } catch (e) {
      if (e.code === 'PERMISSION_DENIED') {
        showAudioVideoPermDialog({ isAudioOn, isVideoOn });
      }

      await api.dismissIfCancelled(e);

      dispatch({
        type: 'getVideoCallError',
        payload: {
          isLoadingVideoCall: false,
          isAudioOn: false,
          isVideoOn: false,
          videoCall: undefined,
          agoraClient: undefined,
          isJoined: false,
          error: 'error',
        },
      });
    }
  }

  async function getLiveSessionStartedAt(roomID: number, videoCallID: number) {
    const videoCallAPIResponse = await api.getVideoCall(roomID, videoCallID);
    dispatch({
      type: 'getLiveSessionStartedAtSuccess',
      payload: {
        videoCall: videoCallAPIResponse,
      },
    });
  }

  async function createVideoCallEvent(
    roomID: number,
    videoCallID: number,
    eventType: VideoCallEvent
  ): Promise<boolean> {
    const isSessionEndedEvent = eventType === VideoCallEvent.sessionEnded;
    const createEventPayload = isSessionEndedEvent ? { isLoading: true } : {};
    dispatch({ type: 'createVideoCallEvent', payload: createEventPayload });
    await api.createVideoCallEvent(roomID, videoCallID, eventType);
    if (eventType === VideoCallEvent.sessionEnded) {
      dispatch({
        type: 'sessionEndSuccess',
        payload: {
          videoCallEnded: true,
          isLoading: false,
        },
      });
    }
    if (eventType === VideoCallEvent.callStarted) {
      document.dispatchEvent(new CustomEvent('liveSessionStarted'));
    }
    return true;
  }

  const joinCall = useCallback(async () => {
    if (!videoCall || !agoraClient) {
      return;
    }

    dispatch({ type: 'joiningCall', payload: { isLoadingVideoCall: true, isMinimized: false } });

    const {
      agora: { encryptionKey, userToken, channelName },
    } = videoCall;
    const localUserID: number = getUserData().id;

    try {
      agoraClient.setEncryptionConfig('aes-256-xts', encryptionKey);
      await agoraClient.join(appConfig.agora.appID, channelName, userToken, localUserID);
      dispatch({
        type: 'joinCallSuccess',
        payload: { isJoined: true, isLoadingVideoCall: false },
      });
    } catch (error) {
      const isBrowserNotSupported =
        error.type === 'error' &&
        error.msg === 'MEDIA_NOT_SUPPORT' &&
        error.info === 'Video/audio streams not supported yet';

      dispatch({
        type: 'joinVideoCallError',
        payload: {
          isLoadingVideoCall: false,
          error: isBrowserNotSupported ? 'browserNotSupported' : 'error',
        },
      });

      throw error;
    }
  }, [videoCall, agoraClient]);

  const leaveCall = useCallback(async () => {
    if (!isJoined || !agoraClient) {
      return;
    }

    await Promise.all(agoraClient.localTracks.map((x) => x.close()));
    await agoraClient.leave();

    dispatch({
      type: 'leavingCall',
      payload: {
        isAudioOn: false,
        isVideoOn: false,
        videoCall: undefined,
        agoraClient: undefined,
        isJoined: false,
        isMinimized: false,
      },
    });
  }, [isJoined, agoraClient]);

  const endCall = useCallback(
    async (startTime?: string) => {
      if (!isJoined || !agoraClient) {
        return;
      }

      await Promise.all(agoraClient.localTracks.map((x) => x.close()));
      await agoraClient.leave();

      dispatch({
        type: 'endingCall',
        payload: {
          isAudioOn: false,
          isVideoOn: false,
          videoCall: undefined,
          agoraClient: undefined,
          isJoined: false,
          clientConnectionTime: startTime,
          videoCallEnded: true,
          isMinimized: false,
        },
      });
    },
    [isJoined, agoraClient]
  );

  const requestWakeLockSentinel = useCallback(async () => {
    if (!wakeLockSentinel) {
      try {
        const newWakeLockSentinel = await (
          navigator as unknown as NavigatorExtended
        ).wakeLock.request('screen');
        dispatch({
          type: 'receiveWakeLockSentinel',
          payload: { wakeLockSentinel: newWakeLockSentinel },
        });
      } catch (e) {
        dispatch({
          type: 'receiveWakeLockSentinel',
          payload: { wakeLockSentinel: undefined },
        });
      }
    }
  }, [wakeLockSentinel]);

  const removeWakeLockSentinel = useCallback(async () => {
    if (wakeLockSentinel) {
      try {
        if (!wakeLockSentinel.released) await wakeLockSentinel.release();
        dispatch({
          type: 'removedWakeLockSentinel',
          payload: { wakeLockSentinel: undefined },
        });
      } catch (e) {
        // console.log('wakelock', e.message);
      }
    }
  }, [wakeLockSentinel]);

  const setMediaLists = useCallback(
    (
      cameras: MediaDeviceInfo[] | undefined,
      audioOuts: MediaDeviceInfo[] | undefined,
      audioIns: MediaDeviceInfo[] | undefined
    ) => {
      const payload: Partial<VideoCallState> = {};

      if (cameras) {
        payload.cameras = cameras.filter(
          (updatedCamera) => !!updatedCamera.deviceId && !!updatedCamera.label
        );

        if (
          selectedCamera &&
          !payload.cameras.find((x) => x.deviceId === selectedCamera.deviceId)
        ) {
          // eslint-disable-next-line prefer-destructuring
          payload.selectedCamera = payload.cameras[0];
        }
      }

      if (audioOuts) {
        payload.audioOuts = audioOuts.filter(
          (updatedAudioOut) => !!updatedAudioOut.deviceId && !!updatedAudioOut.label
        );

        if (
          selectedAudioOut &&
          !payload.audioOuts.find((x) => x.deviceId === selectedAudioOut.deviceId)
        ) {
          // eslint-disable-next-line prefer-destructuring
          payload.selectedAudioOut = payload.audioOuts[0];
        }
      }

      if (audioIns) {
        payload.audioIns = audioIns.filter(
          (updatedAudioIn) => !!updatedAudioIn.deviceId && !!updatedAudioIn.label
        );

        if (
          selectedAudioIn &&
          !payload.audioIns.find((x) => x.deviceId === selectedAudioIn.deviceId)
        ) {
          // eslint-disable-next-line prefer-destructuring
          payload.selectedAudioIn = payload.audioIns[0];
        }
      }

      dispatch({ type: 'updateMediaLists', payload });
    },
    [selectedCamera, selectedAudioOut, selectedAudioIn]
  );

  function resetVideoCallState() {
    dispatch({
      type: 'exitRateVideoCall',
      payload: {
        isAudioOn: false,
        isVideoOn: false,
        videoCall: undefined,
        agoraClient: undefined,
        isJoined: false,
        rating: undefined,
        videoCallEnded: false,
        captionsLanguage: '',
      },
    });
  }

  function toggleAudioVideo(newIsAudioOn: boolean, newIsVideoOn: boolean) {
    dispatch({
      type: 'toggleAudioVideo',
      payload: { isAudioOn: newIsAudioOn, isVideoOn: newIsVideoOn },
    });
  }

  function errorOccurred() {
    dispatch({
      type: 'errorOccurred',
      payload: { ...VideoCallInitialState, error: 'error' },
    });
  }

  function setSelectedAudioOut(newSelectedAudioOut: MediaDeviceInfo) {
    dispatch({
      type: 'setSelectedAudioOut',
      payload: { selectedAudioOut: newSelectedAudioOut },
    });
  }

  function setSelectedAudioIn(newSelectedAudioIn: MediaDeviceInfo) {
    dispatch({
      type: 'setSelectedAudioIn',
      payload: { selectedAudioIn: newSelectedAudioIn },
    });
  }

  function setSelectedCamera(newSelectedCamera: MediaDeviceInfo) {
    dispatch({
      type: 'setSelectedCamera',
      payload: { selectedCamera: newSelectedCamera },
    });
  }

  function toggleMinimized() {
    dispatch({
      type: 'toggleMinized',
      payload: { isMinimized: !isMinimized },
    });
  }

  function setTherapistScheduleNextSessionPopup(showTherapistScheduleNextSessionPopup: boolean) {
    dispatch({
      type: 'setTherapistScheduleNextSessionPopup',
      payload: { showTherapistScheduleNextSessionPopup },
    });
  }

  function setIsCaptionsSelectionOpen(isCaptionsSelectionOpen: boolean) {
    dispatch({
      type: 'setIsCaptionsSelectionOpen',
      payload: { isCaptionsSelectionOpen },
    });
  }

  function setCaptionsLanguage(videoCallID: number, value: CaptionsLanguage): void {
    dispatch({ type: 'setCaptionsLanguage', payload: { captionsLanguage: value } });
    setStoredCaptionsLanguage({ [videoCallID]: value });
  }

  function setVirtualBackground(value: VirtualBackground): void {
    dispatch({ type: 'setVirtualBackground', payload: { virtualBackground: value } });
  }

  const actions: Actions = {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getLiveSessionStartedAtAction: useCallback(getLiveSessionStartedAt, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getVideoCallAction: useCallback(getVideoCall, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    createVideoCallEventAction: useCallback(createVideoCallEvent, []),
    setVideoCallInitialStateAction: useCallback(setVideoCallInitialState, []),
    resetVideoCallStateAction: useCallback(resetVideoCallState, []),
    toggleAudioVideoAction: useCallback(toggleAudioVideo, []),
    errorOccurredAction: useCallback(errorOccurred, []),
    setSelectedAudioOutAction: useCallback(setSelectedAudioOut, []),
    setSelectedAudioInAction: useCallback(setSelectedAudioIn, []),
    setSelectedCameraAction: useCallback(setSelectedCamera, []),
    setTherapistScheduleNextSessionPopupAction: useCallback(
      setTherapistScheduleNextSessionPopup,
      []
    ),
    setMediaListsAction: setMediaLists,
    joinCallAction: joinCall,
    leaveCallAction: leaveCall,
    endCallAction: endCall,
    requestWakeLockSentinelAction: requestWakeLockSentinel,
    removeWakeLockSentinelAction: removeWakeLockSentinel,
    toggleMinimizedAction: toggleMinimized,
    setIsCaptionsSelectionOpen: useCallback(setIsCaptionsSelectionOpen, []),
    setCaptionsLanguage: useCallback(setCaptionsLanguage, []),
    setVirtualBackground: useCallback(setVirtualBackground, []),
  };

  return (
    <StateContext.Provider value={state}>
      <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>
    </StateContext.Provider>
  );
}

export function useVideoCallState(): VideoCallState {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('StateContext must be used within a ContextProvider');
  }
  return context;
}

export function useVideoCallActions() {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error('ActionsContext must be used within a ContextProvider');
  }
  return context;
}
