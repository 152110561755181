import { FunctionComponent, useEffect, useState } from 'react';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { View, Big, Standard, AddUser } from '@talkspace/react-toolkit';
import { isTherapistCT } from 'ts-frontend/entities/Therapist';
import { allowReview } from '@/switchWizard/utils/switchWizardApiHelper';
import { extractRoomEntitiesFromState } from '@/utils/extractRoomEntitiesFromState';
import { useMainState } from '@/hooks/mainContext';
import { getIsInviteRoomWithMissingPartner } from '@/roomInvites';
import { RouteComponentProps, withRouter } from '../../core/routerLib';
import { PencilIcon } from '../icons';
import HoverItem from '../MenuNavLink/MenuNavLink';
import useOpenModalSafe from '@/hooks/useOpenModalSafe';
import { trackCTAClick } from '../../utils/analytics/events';

type Props = RouteComponentProps<{ roomID: string; handleClosePanel: () => void }>;

const PanelMyRoomActions: FunctionComponent<Props> = ({ history, match }) => {
  const { roomID } = match.params;
  const mainState = useMainState();
  const { roomsByID, clientsByRoomID } = mainState;
  const [reviewAllowed, setReviewAllowed] = useState(false);
  const showAddPartnerButton = getIsInviteRoomWithMissingPartner(
    roomsByID[roomID],
    clientsByRoomID[roomID]
  );
  const useNewNav = useNewMemberNav();
  const { memberNewNav } = useFlags();

  useEffect(() => {
    (async () => {
      const allowReviewResponse = await allowReview(parseInt(roomID, 10));

      setReviewAllowed(allowReviewResponse.allow || false);
    })();
  }, [roomID]);

  const handleOnReviewTherapistClick = () => {
    if (memberNewNav && memberNewNav !== 'disabled') {
      trackCTAClick('Review your provider', 'messages', { roomID });
    }

    history.push({
      pathname: `/room/${roomID}/review-therapist`,
      state: {
        from: history.location.pathname,
      },
    });
  };

  const openModal = useOpenModalSafe();

  const handleAddPartner = () => {
    if (memberNewNav && memberNewNav !== 'disabled') {
      trackCTAClick('Add Your Partner', 'messages', { roomID });
    }

    if (useNewNav) openModal(`/room/${roomID}/invite-partner`, undefined, false, true);
    else {
      history.push(`/room/${roomID}/invite-partner`);
    }
  };
  const { therapist } = extractRoomEntitiesFromState(mainState, Number(roomID));

  const isNotCt = !isTherapistCT(therapist.type);
  const showReview = isNotCt && reviewAllowed;
  return showReview || showAddPartnerButton ? (
    <View style={{ marginTop: 27 }}>
      <Big as="h2" style={{ marginBottom: 16 }}>
        My room
      </Big>
      {showAddPartnerButton && (
        <HoverItem
          dataQa="panelMyRoomAddPartnerButton"
          onPress={handleAddPartner}
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          <View row justify="start" align="center" style={{ paddingLeft: 11 }}>
            <AddUser width={28} height={29} style={{ marginBottom: 10 }} />
            <Standard style={{ marginLeft: 8 }}>Add Your Partner</Standard>
          </View>
        </HoverItem>
      )}
      {showReview && (
        <HoverItem
          dataQa="panelMyRoomReviewProviderButton"
          onPress={handleOnReviewTherapistClick}
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          <View row justify="start" align="center" style={{ paddingLeft: 11 }}>
            <PencilIcon width={28} height={29} style={{ marginTop: 6 }} />
            <Standard style={{ marginLeft: 8 }}>Review your provider</Standard>
          </View>
        </HoverItem>
      )}
    </View>
  ) : null;
};

export default withRouter(PanelMyRoomActions);
