import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from '../../../core/routerLib';
import SharingPreferencesActionView from './SharingPreferencesActionView';
import ChatApiHelpers from '../../utils/ApiHelper';
import ChatModal from '../ChatModal';

const SharingPreferencesAction: FunctionComponent<
  RouteComponentProps<{
    roomID: number;
  }>
> = ({ history, match }) => {
  const [error, setError] = useState<string | null>(null);
  const [confirmDate, setConfirmDate] = useState<string | null>(null);
  const [isSharing, setIsSharing] = useState<boolean | null>(null);
  const apiRef = useRef<ChatApiHelpers>(new ChatApiHelpers());

  useEffect(() => () => apiRef.current.cancelAll(), []);

  useEffect(() => {
    apiRef.current
      .getSharingPreferences(match.params.roomID)
      .then(({ allowViewMessagesBeforeTherapistJoinedRoom, allowViewMessagesConfirmationDate }) => {
        if (allowViewMessagesBeforeTherapistJoinedRoom !== 'PENDING')
          setIsSharing(allowViewMessagesBeforeTherapistJoinedRoom === 'YES');
        if (
          allowViewMessagesConfirmationDate &&
          allowViewMessagesBeforeTherapistJoinedRoom !== 'PENDING'
        )
          setConfirmDate(allowViewMessagesConfirmationDate);
      })
      .catch(apiRef.current.dismissIfCancelled)
      .catch((err) => {
        setError(err.message);
      });
  }, [match.params.roomID]);

  const handleOnAgreePress = () => {
    apiRef.current
      .setSharingPreferences(match.params.roomID, isSharing ? 'YES' : 'NO')
      .then(() => {
        history.goBack();
      })
      .catch(apiRef.current.dismissIfCancelled)
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <ChatModal isVisible onBackdropPress={history.goBack}>
      <SharingPreferencesActionView
        isSharing={isSharing}
        setIsSharing={setIsSharing}
        confirmDate={confirmDate}
        onAgreePress={handleOnAgreePress}
        error={error}
      />
    </ChatModal>
  );
};

export default SharingPreferencesAction;
