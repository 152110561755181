import { ChargeType } from 'ts-frontend/types';

export const CANCELLATION_WINDOW_HOURS = 24;
export const CONFIRMATION_WINDOW_HOURS = 48;
export const chargeTypeNames: Record<ChargeType, string> = {
  copay: 'Copay',
  holdCharge: 'Hold charge',
  lateCancellation: 'Late cancellation',
  noShow: 'No show',
  postSession: 'Remaining balance',
  session: 'Session',
  setupFee: 'Setup fee',
  subscription: 'Subscription',
  videoCredit: 'Video credit',
  videoCreditBundle: 'Video credit bundle',
};
