import { Card, View, Tiny, Big, RadioButton } from '@talkspace/react-toolkit';
import { Room } from 'ts-frontend/entities/Room';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { useCallback } from 'react';
import TherapistAvatar from '../../../components/TherapistAvatar/TherapistAvatar';

interface RoomSelectionCardProps {
  tinyText: string;
  therapist: ETherapistInfo;
  room: Room;
  selectedRoom?: number;
  setSelectedRoom: (roomID: number) => void;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  isTabFirstOption?: boolean;
}

const RoomSelectionCard = ({
  tinyText,
  therapist,
  room,
  selectedRoom,
  setSelectedRoom,
  ariaLabelledBy,
  ariaDescribedBy,
  isTabFirstOption,
}: RoomSelectionCardProps) => {
  const onPress = useCallback(() => setSelectedRoom(room.roomID), [room.roomID, setSelectedRoom]);
  return (
    <Card onPress={onPress}>
      <View row align="center">
        <TherapistAvatar
          therapist={therapist}
          roomType={room.roomType}
          size="medium"
          hideStatus
          noVerticalPadding
        />
        <View style={{ marginLeft: 4, marginRight: 15, flex: 1, maxWidth: 181 }}>
          <Big style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} id={ariaLabelledBy}>
            {`${therapist?.firstName} ${therapist?.lastName}`}
          </Big>
          <Tiny id={ariaDescribedBy} style={{ width: 165 }}>
            {tinyText}
          </Tiny>
        </View>
        <RadioButton
          tabFirstOption={isTabFirstOption}
          isActive={selectedRoom === room.roomID}
          onPress={onPress}
          ariaLabelledBy={ariaLabelledBy}
          ariaDescribedBy={ariaDescribedBy}
        />
      </View>
    </Card>
  );
};

export default RoomSelectionCard;
