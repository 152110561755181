import Svg, { Defs, Path, G, Mask, Use, Text, TSpan } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const PDF = ({ width = 29, height = 37, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'PDF';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <Defs>
        <Path id="a" d="M-3.90798505e-14 0L825 0 825 105 -3.90798505e-14 105z" />
      </Defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <G transform="translate(-457 -729) translate(375 695)">
          <Mask id="a" fill={colors.white}>
            <Use href="#a" xlinkHref="#a" />
          </Mask>
          <Use fill={colors.white} href="#a" xlinkHref="#a" />
        </G>
        <Path
          fill={colors.white}
          stroke={colors.heatherGrey}
          d="M.5 20.004v28.992C.5 59.772 9.228 68.5 19.999 68.5H255c10.769 0 19.499-8.732 19.499-19.504V20.004C274.5 9.228 265.772.5 255.001.5H20C9.23.5.5 9.232.5 20.004z"
          transform="translate(-457 -729) translate(441 713) matrix(-1 0 0 1 275 0)"
        />
        <Text
          fill={colors.pink}
          fontFamily="Helvetica"
          fontSize="9px"
          fontWeight="normal"
          letterSpacing="0.3"
          transform="translate(-457 -729) translate(441 713) translate(16 16)"
        >
          <TSpan x="5.5" y="30">
            PD
          </TSpan>
          <TSpan x="18.602" y="30">
            F
          </TSpan>
        </Text>
        <Path
          stroke={colors.pink}
          strokeWidth="3"
          d="M6 1.5A4.5 4.5 0 001.5 6v25A4.5 4.5 0 006 35.5h17a4.5 4.5 0 004.5-4.5V12.485a4.5 4.5 0 00-1.318-3.182l-6.485-6.485A4.5 4.5 0 0016.515 1.5H6z"
          transform="translate(-457 -729) translate(441 713) translate(16 16)"
        />
      </G>
    </Svg>
  );
};

export default PDF;
