import { FunctionComponent } from 'react';
import { useTranslation } from '@talkspace/i18n';
import { SUPPORTED_LANGUAGES } from './types';
import EmotionThemeProvider from '../../../hooks/EmotionThemeProvider';
import FloatingMenuSimple, { OverflowMenuOption } from '../FloatingMenuSimple/FloatingMenuSimple';
import Globe from '../Globe';

interface LanguageSelectorProps {
  dataQa?: string;
  onLanguageChange?: (selectedLanguage: string) => void;
}

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = ({
  dataQa,
  onLanguageChange,
}) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const onPress = (languageCode: string) => {
    const newLang = SUPPORTED_LANGUAGES[languageCode];

    if (!newLang || newLang === language) {
      return;
    }

    i18n.changeLanguage(newLang);

    onLanguageChange?.(newLang);
  };

  const options: OverflowMenuOption[] = [
    {
      callback: () => onPress(SUPPORTED_LANGUAGES.en),
      selectedLabel: 'EN',
      optionLabel: 'English',
      optionValue: SUPPORTED_LANGUAGES.en,
    },
    {
      callback: () => onPress(SUPPORTED_LANGUAGES.es),
      selectedLabel: 'ES',
      optionLabel: 'Español',
      optionValue: SUPPORTED_LANGUAGES.es,
    },
  ];

  return (
    <EmotionThemeProvider version="1.0.0">
      <FloatingMenuSimple
        initialValue={language}
        options={options}
        Icon={Globe}
        iconConfig={{ path: ['fill'] }}
        dataQa={dataQa}
      />
    </EmotionThemeProvider>
  );
};

export default LanguageSelector;
