import { OptionType, shuffleArray } from '@talkspace/react-toolkit';
import { therapistGenderPreference, ethnicity, switchReasons } from '@talkspace/configs';
import { ValueType } from 'react-select';
import { BaseWizardState, baseWizardInitialState, keyValueToValueType } from 'stepWizard';
import { MatchingPreferences } from '@/entities/MatchingPreferences';

export interface State extends BaseWizardState {
  roomID: number;
  clientUserID: number;
  switchReason: string;
  newTherapistFocus: number[];
  countryState?: string;
  therapistGender?: string;
  therapistEthnicity?: string;
  newTherapistMatch: object;
  informedConsentGiven: boolean;
  isMessageHistoryShared: boolean;

  // we need this so we don't override existing preferences (when sending back)
  // which we don't allow editing in the wizard - such as planGroup
  existingPreferences?: MatchingPreferences;

  // input values
  therapistGenderOptions: ValueType<OptionType, true>;
  ethnicityOptions: ValueType<OptionType, true>;
  presentingProblemsOptions: ValueType<OptionType, true>;
  reasonForSwitch: ValueType<OptionType, true>;
  displayTherapistReview: boolean;
  displayReasonsForSwitching: boolean;
  eventCategory: string;
  preferredLanguage: number | null;
  preferredLanguageOptions: ValueType<OptionType, true>;
  preferredLanguageFilter: string | null;
  preferredLanguageFilterOptions: ValueType<OptionType, true>;
}

// eslint-disable-next-line import/prefer-default-export
export const getInitialState = (
  clientUserID: number,
  roomID: number,
  source: string,
  contextID: string
): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    roomID,
    clientUserID,
    switchReason: '',
    newTherapistFocus: [],
    countryState: 'US',
    therapistGender: undefined,
    therapistEthnicity: undefined,
    newTherapistMatch: {},
    informedConsentGiven: false,
    isMessageHistoryShared: false,
    existingPreferences: undefined,
    therapistGenderOptions: keyValueToValueType(therapistGenderPreference).filter((option) => {
      const castOption = option as OptionType;
      return castOption && castOption.label !== 'No preference';
    }),
    ethnicityOptions: keyValueToValueType(ethnicity),
    reasonForSwitch: shuffleArray(keyValueToValueType(switchReasons)),
    presentingProblemsOptions: [],
    displayTherapistReview: ![
      'cancellation',
      'b2b-cancellation',
      'cancellation-v2',
      'b2b-cancellation-v2',
    ].includes(source),
    displayReasonsForSwitching: !['cancellation-v2', 'b2b-cancellation-v2'].includes(source),
    eventCategory: 'Self Serve Switch',
    preferredLanguage: 15,
    preferredLanguageOptions: [],
    preferredLanguageFilter: null,
    preferredLanguageFilterOptions: null,
  };
};
