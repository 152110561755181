import { AccordionContainer } from '@talkspace/react-toolkit';

import styled from '../../../core/styled';

const BaseAccordion = styled(AccordionContainer)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    marginBottom: 0,
    borderRadius: 0,
    borderBottom: 0,
    maxWidth: isMobile ? '100%' : 500,
    width: 'unset',
    minWidth: isMobile ? '100%' : '335',
    padding: 'unset',
  };
});

export default BaseAccordion;
