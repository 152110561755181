import { EmotionStyle, TextDS, View, spacing } from '@talkspace/react-toolkit';
import { useCommunityState } from '../hooks/CommunityContext';
import { getAge } from '../utils/helpers';

const { space100 } = spacing;

interface Props {
  user: Amity.User | undefined;
  style?: EmotionStyle;
}
const UserTag = ({ user, style }: Props) => {
  const { userID: currentUserID } = useCommunityState();
  const { userId: userID, displayName, metadata } = user || {};
  const { state, dateOfBirth } = metadata || {};
  const age = getAge({ dateOfBirth });
  const isMe = userID === currentUserID;
  const infoString = isMe ? '(me)' : `${state}, ${age}`;
  return (
    <View row style={style}>
      <TextDS variant="headingMd">{displayName}</TextDS>
      <TextDS colorRole="textSubtlest" style={{ marginLeft: space100 }}>
        {infoString}
      </TextDS>
    </View>
  );
};

export default UserTag;
