import { RouteComponentProps, withRouter } from '@/core/routerLib';
import { trackEvent } from '@/utils/analytics/eventTracker';

import OnboardingPage from './OnboardingPage';
import MedicalHistory from '../assets/MedicalHistory';

const OnboardingPageMedicalHistory = ({
  match: {
    params: { roomID },
  },
  history,
  totalSteps,
  currentStep,
}: RouteComponentProps<{ roomID: number }> & { totalSteps: number; currentStep: number }) => (
  <OnboardingPage
    totalSteps={totalSteps}
    currentStep={currentStep}
    illustration={<MedicalHistory />}
    title="Complete your intake"
    description="To give you the best support we need to get to know you. We’ll ask you questions about your physical, mental, and emotional health."
    ctaText="Start now"
    onPress={() => {
      trackEvent(
        'Start Medical History',
        {
          actionName: 'onboardingStep',
          roomID,
        },
        ['tsAnalytics']
      );
      history.push(`/room/${roomID}/onboarding/mental-health/source/onboarding`);
    }}
  />
);

export default withRouter(OnboardingPageMedicalHistory);
