import { FunctionComponent } from 'react';
import { View, Huge, Small, Standard } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import ObjectivesListItem from '../components/ObjectivesListItem';
import { useTreatmentPlannerState } from '../hooks/treatmentPlannerContext';
import ClinicalProgressCard from '../components/ClinicalProgressCard';
import NoItemsCard from '../components/NoItemsCard';
import { ObjectivesIcon } from '../components/icons';
import { RouteComponentProps, withRouter } from '../../core/routerLib';

const Wrapper = styled(View)({
  marginTop: 20,
  marginBottom: 20,
  overflowY: 'hidden',
});

const ObjectivesList = styled(View)({
  flex: 1,
  marginTop: 20,
});

const Hr = styled(View)(({ theme: { colors } }) => {
  return {
    height: 1,
    alignSelf: 'stretch',
    backgroundColor: colors.periwinkleGrey,
    marginTop: 15,
    marginBottom: 15,
  };
});

const ResponsiveIconWrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return isMobile
    ? {
        marginBottom: 14,
        marginTop: -14,
      }
    : {
        marginRight: 24,
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
      };
});

const ObjectivesContainer: FunctionComponent<RouteComponentProps<{ roomID: number }>> = ({
  match,
}) => {
  const { treatmentPlansByRoomID } = useTreatmentPlannerState();

  const { objectives = [] } = treatmentPlansByRoomID[+match.params.roomID] || {};

  if (!objectives.length)
    return (
      <Wrapper>
        <Huge as="h2" style={{ marginBottom: 20, marginLeft: 24 }}>
          Short-term objectives
        </Huge>
        <NoItemsCard disabled>
          <ResponsiveIconWrapper>
            <ObjectivesIcon />
          </ResponsiveIconWrapper>
          <Standard variant="standardDarkGrey">
            Your therapist hasn’t added any objectives yet.
          </Standard>
        </NoItemsCard>
      </Wrapper>
    );

  const completedObjectives = objectives.filter((o) => o.completed);
  const activeObjectives = objectives.filter((o) => !o.completed);

  return (
    <Wrapper>
      <Huge style={{ marginBottom: 20, marginLeft: 24 }}>Short-term objectives</Huge>
      <ClinicalProgressCard>
        <Small>
          Discuss progress with your therapist. If you feel that you’ve accomplished an objective,
          let your therapist know so you can set new ones together.
        </Small>
        <ObjectivesList>
          {activeObjectives.map((objective, i) => (
            <ObjectivesListItem
              onPress={() => {}}
              index={i}
              key={objective.id}
              text={objective.description}
              completed={objective.completed}
            />
          ))}
          {!!completedObjectives.length && (
            <>
              <Hr />
              {completedObjectives.map((objective, i) => (
                <ObjectivesListItem
                  onPress={() => {}}
                  index={i}
                  key={objective.id}
                  text={objective.description}
                  completed
                />
              ))}
            </>
          )}
        </ObjectivesList>
      </ClinicalProgressCard>
    </Wrapper>
  );
};
export default withRouter(ObjectivesContainer);
