type Description =
  | 'Off'
  | 'Push only'
  | 'Email only'
  | 'SMS only'
  | 'Push, email'
  | 'Push, SMS'
  | 'Email, SMS'
  | 'Push, email, SMS'
  | 'On';

export default function constructDescription({
  email,
  push,
  sms,
}: {
  email: boolean;
  push: boolean;
  sms?: boolean;
}): Description {
  // Add only truthy elements to array to get count
  const truthyPrefs = [email, push, sms].filter(Boolean);

  if (truthyPrefs.length === 0) return 'Off';
  if (truthyPrefs.length === 1) {
    if (email) return 'Email only';
    if (push) return 'Push only';
    if (sms) return 'SMS only';
  } else if (truthyPrefs.length === 2) {
    if (email && push) return 'Push, email';
    if (email && sms) return 'Email, SMS';
    if (push && sms) return 'Push, SMS';
  }
  return 'On';
}
