import { FunctionComponent, useState, useEffect } from 'react';
import { reducePresentingProblemsOptionsByValue } from 'ts-frontend/helpers/presentingProblems';
import { useWizardState, useWizardActions } from 'stepWizard';
import { ClientMatchPresentingProblem } from 'ts-frontend/types';
import { MatchPayload } from '@/therapistMatches/matchingTypes';
import { MatchResultsProvider } from '@/therapistMatches/hooks/useMatchResults';
import { trackWizardEvent } from '@/utils/analytics/events';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';
import MatchResults from '../../therapistMatches/containers/MatchResults';
import useSwitchWizard from '../hooks/useSwitchWizard';
import ActionStatus from '../../components/ActionStatus/ActionStatus';

const MatchResultsContainer: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const [matchPayload, setMatchPayload] = useState<MatchPayload>();
  const [matchResultsError, setMatchResultsError] = useState<string | null>(null);
  const [selectedPresentingProblemAsString, setSelectedPresentingProblemsAsString] = useState('');
  const [selectedPresentingProblemsAsExpertise, setSelectedPresentingProblemsAsExpertise] =
    useState<ClientMatchPresentingProblem[]>([]);

  const {
    roomID,
    countryState,
    newTherapistFocus,
    therapistGender,
    therapistEthnicity,
    presentingProblemsOptions,
    existingPreferences,
    eventCategory,
    clientUserID,
    responses,
    therapistReview,
    preferredLanguage,
    preferredLanguageFilter,
  } = useWizardState();
  const [state, { patchClientInfo }] = useSwitchWizard();
  const { error, isLoading } = state;
  const { setState, onExitClick } = useWizardActions();
  let formerTherapistID;
  if (therapistReview) {
    formerTherapistID = therapistReview.id;
  }

  useEffect(() => {
    patchClientInfo(clientUserID, {
      countryState,
    });
    if (!error) {
      if (clientUserID && countryState) {
        const [clientCountry] = countryState.split('-');
        const presentingProblemsAsExpertise =
          reducePresentingProblemsOptionsByValue(presentingProblemsOptions);
        setMatchPayload({
          ...existingPreferences,
          fieldsOfExpertise: newTherapistFocus,
          therapistGender,
          therapistEthnicity,
          clientCountry,
          languages: preferredLanguageFilter === 'hard' ? [preferredLanguage] : [],
          languagesSoft: preferredLanguageFilter === 'soft' ? [preferredLanguage] : [],
        });
        setSelectedPresentingProblemsAsString(
          presentingProblemsOptions
            .filter((problem) => (newTherapistFocus || []).includes(problem.value))
            .map((problem) => problem.label)
            .join(', ')
        );
        setSelectedPresentingProblemsAsExpertise(
          presentingProblemsAsExpertise.filter((problem) =>
            (newTherapistFocus || []).includes(problem.expertiseID)
          )
        );
      }
    }
  }, [clientUserID]); // eslint-disable-line

  const handleOnExitClick = () => {
    const currentStepResponses = responses;
    currentStepResponses.lastStepName = 'Match results';
    setState({ responses: currentStepResponses });
    onExitClick();
  };

  const handleOnAgreePress = (
    therapistID: number,
    therapistFirstName: string,
    therapistImage: string,
    therapistType: string
  ) => {
    trackWizardEvent('Sign Informed Consent', eventCategory, {
      'Therapist ID': therapistID,
      'User ID': clientUserID,
      label: 'Sign Informed Consent',
      Application: eventCategory,
      eventProperty: therapistID.toString(),
      eventPropertyValue: 0.0,
    });
    const currentStepResponses = responses;
    currentStepResponses.lastStepName = 'Sharing Preferences';
    // TODO: @Abanob: Eventually remove the informedConsentGiven from the API side and remove all of its occurrences from switchWizard
    setState({
      newTherapistMatch: { therapistID, therapistFirstName, therapistImage, therapistType },
      informedConsentGiven: false,
      responses: currentStepResponses,
    });
    history.push('/switch-provider/sharing-preferences', {
      roomID,
    });
  };

  if (error || isLoading || matchResultsError) {
    return (
      <>
        <ActionStatus
          isLoading={isLoading}
          isError={!!error || !!matchResultsError}
          errorTitle="Something Went Wrong"
          errorSubTitle="Please check your internet connection and try again."
          errorButtonText="Close"
          errorButtonAction={handleOnExitClick}
          titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25 }}
          subTitleStyles={{
            fontSize: 16,
            color: '#454A5E',
            marginTop: 13,
            marginBottom: 42,
            width: 200,
            textAlign: 'center',
          }}
        />
      </>
    );
  }
  return (
    <>
      {matchPayload ? (
        <MatchResultsProvider>
          <MatchResults
            roomID={roomID}
            matchPayload={matchPayload}
            selectedPresentingProblemAsString={selectedPresentingProblemAsString}
            selectedPresentingProblemsAsExpertise={selectedPresentingProblemsAsExpertise}
            skipPayment
            handleSwitchWizardSelectedTherapist={handleOnAgreePress}
            eventCategory={eventCategory}
            clientUserID={clientUserID}
            formerTherapistID={formerTherapistID}
            setMatchResultsError={setMatchResultsError}
          />
        </MatchResultsProvider>
      ) : null}
    </>
  );
};

export default withRouter(MatchResultsContainer);
