import React from 'react';
import {
  Card,
  TextDS,
  TouchableViewProps,
  View,
  SpacingView,
  spacing,
} from '@talkspace/react-toolkit';
import { ChevronRight } from '@talkspace/react-toolkit/src/designSystems/icons';
import styled from '@/core/styled';

import { PsychRx } from '../../components/icons';

const { space150, space100 } = spacing;

const LeftColumn = styled(View)({
  width: 64,
  marginRight: space150,
});

export interface AddPsychCardProps extends Omit<TouchableViewProps, 'style'> {
  isLoading?: boolean;
  disabled?: boolean;
  onPress?: React.MouseEventHandler<HTMLDivElement> | undefined;
  dataQa?: string;
}

const AddPsychCard = ({ onPress, disabled, isLoading, dataQa }: AddPsychCardProps) => (
  <Card onPress={onPress} disabled={disabled || isLoading} dataQa={dataQa}>
    <View row flex={1}>
      <LeftColumn align="center">
        <PsychRx />
      </LeftColumn>
      <View row flex={1}>
        <SpacingView itemSpacing="space100">
          <TextDS variant="headingMd" colorRole="textDefault" style={{ marginTop: space100 }}>
            Find a medication manager
          </TextDS>
          <TextDS colorRole="textSubtle" variant="bodySm">
            Try adding a Psychiatry provider to your team for more support
          </TextDS>
        </SpacingView>
        <View justify="center">
          <ChevronRight />
        </View>
      </View>
    </View>
  </Card>
);

export default AddPsychCard;
