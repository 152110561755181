import { NotificationPreference, Preferences } from './notificationPreferences';

export interface MarketingCommunicationsByName {
  marketingBlog: NotificationPreference;
  marketingEducation: NotificationPreference;
  marketingNews: NotificationPreference;
  marketingPromotions: NotificationPreference;
}
export interface APINotificationPreference extends NotificationPreference {
  name: Preferences;
}

export interface APIResponse {
  data: { categories: APINotificationPreference[] };
}

export interface State {
  marketingCommunicationsByName: MarketingCommunicationsByName | null;
  isLoading: boolean;
  isEditing: boolean;
  isError: boolean;
}

export type Action =
  | { type: 'getMarketingCommunications' }
  | { type: 'receiveGetMarketingCommunications'; payload: Partial<State> }
  | { type: 'updateMarketingCommunications' }
  | { type: 'receiveUpdateMarketingCommunications'; payload: Partial<State> }
  | { type: 'setIsEditing'; payload: Partial<State> }
  | { type: 'setIsError' };

export const initialState: State = {
  marketingCommunicationsByName: null,
  isLoading: false,
  isEditing: true,
  isError: false,
};

export const marketingCommunicationsReducer = (
  state: State = initialState,
  action: Action
): State => {
  switch (action.type) {
    case 'getMarketingCommunications':
    case 'updateMarketingCommunications':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'receiveGetMarketingCommunications':
    case 'receiveUpdateMarketingCommunications':
    case 'setIsEditing':
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case 'setIsError':
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
