/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: @Eric remove above line
import { useFormContext, Controller } from 'react-hook-form';
import View from '../View';
import Tiny from '../Typography/Tiny';
import Checkbox from '../Checkbox';
import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';

// Temporary fix until https://github.com/react-hook-form/react-hook-form/discussions/6665
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Path<T> = any;

interface RHFCheckboxProps<
  TFieldValues extends Record<string, string>,
  FieldName extends Path<TFieldValues> = Path<TFieldValues>
> {
  fieldName: FieldName;
  label: string | JSX.Element;
  errorMessage?: string;
  wrapperStyle?: EmotionStyle;
  containerStyle?: EmotionStyle;
  labelStyle?: EmotionStyle;
  errorStyle?: EmotionStyle;
  checkboxStyle?: EmotionStyle;
  isLabelOnRight?: boolean;
  isRequired?: boolean;
  stretch?: boolean;
  alignCenter?: boolean;
}

const Wrapper = styled(View)({
  display: 'flex',
  width: '100%',
  maxWidth: 335,
});

export const OneFormEligibilityCheckbox = <TFieldValues extends Record<string, string>>(
  props: RHFCheckboxProps<TFieldValues>
) => {
  const {
    fieldName,
    label,
    // yup validation is wonky for checkboxes so we need to supply an errorMessage as a prop rather than get it from formState
    errorMessage = 'Required Field.',
    wrapperStyle,
    containerStyle,
    labelStyle,
    errorStyle,
    checkboxStyle,
    isLabelOnRight = true,
    isRequired,
    stretch = false,
    alignCenter = true,
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const isInvalid = !!errors[fieldName];
  const { colors } = useEmotionTheme();
  return (
    <Wrapper style={wrapperStyle}>
      <Controller
        control={control}
        name={fieldName}
        render={({ field }) => (
          <Checkbox
            dataQa={`${fieldName}Checkbox`}
            isRequired={isRequired}
            errorMessage={errorMessage}
            isLabelOnRight={isLabelOnRight}
            stretch={stretch}
            roundedFocusStyle
            primaryColor={isInvalid ? colors.torchRed : colors.permaWaikawaGrey}
            {...field}
            isChecked={field.value}
            shouldDisplayError={errors[fieldName]}
            setIsChecked={(newIsChecked) => {
              field.onChange(newIsChecked);
            }}
            label={<Tiny style={{ textAlign: 'left', ...labelStyle }}>{label}</Tiny>}
            containerStyle={containerStyle}
            errorStyle={errorStyle}
            checkboxStyle={checkboxStyle}
            alignCenter={alignCenter}
          />
        )}
      />
    </Wrapper>
  );
};

export default OneFormEligibilityCheckbox;
