import * as React from 'react';
import { Provider } from 'react-redux';
import createStore from './redux/chatStore';
import Routes from './Routes';

interface ChatProps {
  isTherapistChat: boolean;
  isAcking?: boolean;
  stopInputAutofocus?: boolean;
  shouldPlaySoundNotifications?: boolean;
  style?: {};
  banners?: JSX.Element[];
  priorityBanner?: JSX.Element;
  isFocusedAfterModal?: boolean;
  isOffline?: boolean;
  outsideHeaderHeight?: number;
  outsideFooterHeight?: number;
}

const Chat: React.FunctionComponent<ChatProps> = ({
  isTherapistChat,
  banners,
  priorityBanner,
  isFocusedAfterModal = true,
  outsideHeaderHeight = 0,
  outsideFooterHeight = 0,
  ...otherProps
}) => (
  <Provider store={createStore(isTherapistChat)}>
    <Routes
      {...otherProps}
      banners={banners}
      priorityBanner={priorityBanner}
      isTherapistChat={isTherapistChat}
      isFocusedAfterModal={isFocusedAfterModal}
      outsideHeaderHeight={outsideHeaderHeight}
      outsideFooterHeight={outsideFooterHeight}
    />
  </Provider>
);

export default Chat;
