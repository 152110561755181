import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import {
  Caption,
  Paragraph,
  View,
  ExtraHuge,
  MbaMagnifyingGlass,
  MbaSparkles,
  MbaCheckMark,
  useEmotionTheme,
  RoundEmptyContainer,
  RoundContainer,
  CheckMark,
  MbaPerson,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import loadingSpinner from '../../animations/loadingSpinner.json';

const Container = styled(View)(() => {
  return {
    alignItems: 'center',
    maxWidth: 495,
    alignSelf: 'center',
    flexGrow: 1,
    width: '100%',
  };
});

const Title = styled(Caption)(() => {
  return {
    textAlign: 'center',
    paddingBottom: 50,
    paddingTop: 13,
    fontWeight: 400,
    fontSize: 16,
  };
});

const Steps = styled(View)(() => {
  return {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const LeftContainer = styled(View)(() => {
  return {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

const RightContainer = styled(View)(() => {
  return {
    width: '300px',
    alignSelf: 'center',
    paddingTop: 40,
  };
});

const Row = styled(View)(() => {
  return {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 35,
  };
});

const StepIncomplete = styled(Paragraph)(({ theme: { colors } }) => {
  return { color: colors.baliHaiGrey };
});

const StepComplete = styled(Paragraph)(({ theme: { colors } }) => {
  return { color: colors.permaFiord };
});

interface Props {
  startBooking: () => void;
  handleAnimationAndBookingFinished: () => void;
  isBookingSuccess: boolean;
}

const MbaMatchInProgress: FunctionComponent<Props> = ({
  startBooking,
  handleAnimationAndBookingFinished,
  isBookingSuccess,
}) => {
  const { colors } = useEmotionTheme();
  const steps: { id: number; name: string; icon: ReactElement }[] = [
    {
      id: 0,
      name: 'Searching for matches',
      icon: <MbaMagnifyingGlass width={130} height={130} />,
    },
    {
      id: 1,
      name: 'Analyzing matches',
      icon: <MbaSparkles width={130} height={130} />,
    },
    {
      id: 2,
      name: 'Selecting your provider',
      icon: <MbaPerson width={130} height={130} />,
    },
    {
      id: 3,
      name: 'Booking session',
      icon: <MbaCheckMark width={130} height={130} />,
    },
  ];
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isAnimationFinished, setIsAnimationFinished] = useState<boolean>(false);
  const [isBookingStarted, setIsBookingStarted] = useState<boolean>(false);

  useEffect(() => {
    if (!isBookingStarted) {
      startBooking();
      setIsBookingStarted(true);
    }
  }, [isBookingStarted, startBooking]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep(currentStep + 1);
    }, 2000);

    return () => clearInterval(interval);
  }, [currentStep]);

  useEffect(() => {
    if (!isAnimationFinished && currentStep > steps.length) {
      setIsAnimationFinished(true);
    }
  }, [currentStep, isAnimationFinished, steps.length]);

  useEffect(() => {
    if (isAnimationFinished && isBookingSuccess) {
      handleAnimationAndBookingFinished();
    }
  }, [isAnimationFinished, isBookingSuccess, handleAnimationAndBookingFinished]);

  return (
    <Container>
      <ExtraHuge style={{ textAlign: 'center' }}>Finding your match...</ExtraHuge>
      <Title style={{ textAlign: 'center', paddingTop: 15, maxWidth: 335 }}>
        Give us a moment to find the right provider for you
      </Title>
      <Steps>
        <LeftContainer>
          <View style={{ position: 'absolute' }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loadingSpinner,
              }}
              height={160}
              width={160}
            />
          </View>
          {steps[currentStep]?.icon || <MbaCheckMark height={130} width={130} />}
        </LeftContainer>
        <RightContainer>
          {steps.map((step, stepIndex) =>
            currentStep < stepIndex ? (
              <Row key={step.id}>
                {currentStep < stepIndex ? (
                  <StepIncomplete>{step.name}</StepIncomplete>
                ) : (
                  <StepComplete>{step.name}</StepComplete>
                )}
                {currentStep < stepIndex ? (
                  <RoundEmptyContainer size={20} weight="1.54px" color={colors.permaLondonGray} />
                ) : (
                  <RoundContainer
                    size={20}
                    color={colors.permaTalkspaceDarkGreen}
                    icon={<CheckMark width={11.23} height={8.54} />}
                  />
                )}
              </Row>
            ) : (
              <Row key={step.id}>
                {currentStep < stepIndex ? (
                  <StepIncomplete>{step.name}</StepIncomplete>
                ) : (
                  <StepComplete>{step.name}</StepComplete>
                )}
                {currentStep <= stepIndex ? (
                  <RoundEmptyContainer size={20} weight="1.54px" color={colors.permaLondonGray} />
                ) : (
                  <RoundContainer
                    size={20}
                    color={colors.permaTalkspaceDarkGreen}
                    icon={<CheckMark width={11.23} height={8.54} />}
                  />
                )}
              </Row>
            )
          )}
        </RightContainer>
      </Steps>
    </Container>
  );
};

export default MbaMatchInProgress;
