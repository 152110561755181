import { History } from 'history';
import { WizardScreen, useWizardActions } from 'stepWizard';
import { Screen } from '@talkspace/react-toolkit';
import { Route, Switch } from '../core/routerLib';
import MatchResultsContainer from './containers/MatchResultsContainer';
import TherapistConfirmationContainer from './containers/TherapistConfirmationContainer';
import SharingPreferencesContainer from './containers/SharingPreferencesContainer';
import useWindowWidth from '../clientChat/hooks/useWindowWidth';
import switchWizardScheme from './switchWizardScheme';

const Routes = ({ history }: { history: History }) => {
  const showBackButton =
    history.location.pathname.includes('switch-provider/') &&
    !history.location.pathname.includes('select-timeslot');
  const { onExitClick, setState } = useWizardActions();
  const { isMobile } = useWindowWidth();
  return (
    <Switch>
      <Screen
        showBackButton={showBackButton}
        onCloseButtonClick={onExitClick}
        onBackButtonClick={() => {
          setState({ redirectParams: null });
          history.goBack();
        }}
        title={isMobile ? 'Change provider' : ''}
      >
        <Route exact path="/switch-provider/therapist-matches" component={MatchResultsContainer} />
        <Route
          path="/switch-provider/therapist-confirmation"
          component={TherapistConfirmationContainer}
        />
        <Route
          exact
          path="/switch-provider/sharing-preferences"
          component={SharingPreferencesContainer}
        />
        <Route
          exact
          path="/switch-provider"
          render={() => <WizardScreen wizardScheme={switchWizardScheme} />}
        />
      </Screen>
    </Switch>
  );
};

export default Routes;
