import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TeenspaceProfileSetup from '../components/TeenspaceProfileSetup';
import { generateUniqueName } from '../../utils/helpers';

interface DisplayName {
  displayName: string;
}

const schema: yup.SchemaOf<DisplayName> = yup.object().shape({
  displayName: yup
    .string()
    .required('Enter a display name')
    .max(20, 'Display name cannot exceed 20  characters')
    .min(5, 'Display name must be at least 5 characters'),
});

const TeenspaceProfileSetupContainer = () => {
  const methods = useForm<DisplayName>({
    resolver: yupResolver(schema),
    defaultValues: {
      displayName: generateUniqueName(),
    },
  });
  return (
    <FormProvider {...methods}>
      <form style={{ height: '100%' }}>
        <TeenspaceProfileSetup />
      </form>
    </FormProvider>
  );
};

export default TeenspaceProfileSetupContainer;
