/* eslint-disable import/prefer-default-export */
import { ReactionsEnum } from './types';

export const TEENSPACE_DAY_END_SHIFT_HOURS = 3;

export const ReactionsOrder: ReactionsEnum[] = [
  ReactionsEnum.CRYING_FACE,
  ReactionsEnum.RAISING_HANDS,
  ReactionsEnum.MENDING_HEART,
  ReactionsEnum.SMILING_FACE_WITH_OPEN_HANDS,
  ReactionsEnum.FACE_HOLDING_BACK_TEARS,
];

export const DISPLAY_NAME_ERRORS = {
  409: 'That display name is already taken. Please try another',
  undefined: 'Enter a display name',
};

export const GENERIC_ERROR = 'Something went wrong. Please try again.';

export const LOCAL_STORAGE_LAST_CHECKED_DAILY_PROMPT_TIMESTAMP = 'lastCheckedDailyPromptTimestamp';
