import { ClientMatchPresentingProblem, ImplicitBusinessHour } from 'ts-frontend/types';
import { View } from '@talkspace/react-toolkit';
import moment from 'moment';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { ClosePopupAction } from '@/auth/reactFrame/ReactFrameTypes';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import ssoHelper, { ZendeskPath } from '@/utils/sso';
import ErrorModal from '../../clientChat/components/ErrorModal/ErrorModal';
import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';
import styled from '../../core/styled';
import MatchInProgress from '../components/MatchInProgress';
import MatchResults from '../components/MatchResults';
import useMatchResults from '../hooks/useMatchResults';
import { MatchPayload, MatchResultsData } from '../matchingTypes';

const StyledErrorModalView = styled(View)({
  width: 315,
  height: 208,
  alignSelf: 'center',
});

interface MatchResultsContainerProps extends RouteComponentProps {
  roomID: number;
  matchPayload: MatchPayload;
  selectedPresentingProblemAsString: string;
  selectedPresentingProblemsAsExpertise: ClientMatchPresentingProblem[];
  skipPayment: boolean;
  handleSwitchWizardSelectedTherapist?: (
    therapistID: number,
    therapistFirstName: string,
    therapistImage: string,
    therapistType: TherapistType
  ) => void;
  eventCategory?: string;
  clientUserID?: number;
  formerTherapistID?: number;
  setMatchResultsError?: (error: string | null) => void;
}

const MatchResultsContainer: FunctionComponent<MatchResultsContainerProps> = ({
  history,
  roomID,
  matchPayload,
  selectedPresentingProblemAsString,
  selectedPresentingProblemsAsExpertise,
  skipPayment,
  handleSwitchWizardSelectedTherapist,
  eventCategory,
  clientUserID,
  formerTherapistID,
  setMatchResultsError,
}) => {
  const { matchResultsState, setMatchResultsState } = useMatchResults();
  const [isB2B, setIsB2B] = useState(false);
  const [apiCallDuration, setApiCallDuration] = useState(0);

  const closeModal = useCloseModal();

  useEffect(() => {
    (async () => {
      const startWatch = moment();
      try {
        const matchResult = (
          await apiWrapper.post(
            `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/suggest-therapist-in-platform`,
            { ...(matchPayload || {}) }
          )
        ).data.data as MatchResultsData;

        const therapistIds = matchResult.matches.map(({ userId }) => userId).join(',');

        const implicitBusinessHours: { [index: string]: ImplicitBusinessHour[] } = (
          await apiWrapper.get(
            `${
              apiHelper().apiEndpoint
            }/v2/therapist/implicit-business-hours?therapistIDs=${therapistIds}`
          )
        ).data.data.reduce((therapistHours, { therapistID, businessHours }) => {
          return { ...therapistHours, [therapistID]: businessHours };
        }, {});

        const stopWatch = moment();

        setApiCallDuration(moment.duration(stopWatch.diff(startWatch)).asMilliseconds());

        setMatchResultsState({
          matchResults: matchResult.matches.map((result) => {
            return {
              ...result,
              details: {
                ...result.details,
                businessHours: implicitBusinessHours[result.userId],
                image: `${apiHelper().cdnEndpoint}/images/application/therapist/440/${
                  result.userId
                }.png`,
                expertise: {
                  ...result.details.expertise,
                  mostRelevant: result.details.expertise.mostRelevant.length
                    ? result.details.expertise.mostRelevant
                    : result.details.allFieldsOfExpertise.slice(0, 5),
                },
              },
            };
          }),
        });
        setIsB2B(matchResult.isB2B);
      } catch (e) {
        if (setMatchResultsError) {
          setMatchResultsError(e.message);
        } else {
          // TODO trigger a toast message in the previous screen
          history.goBack();
        }
      }
    })();
  }, [history, setMatchResultsState, matchPayload, roomID, setMatchResultsError]);

  useEffect(() => {
    const LOADER_ANIMATION_DURATION = 5000;
    if (!matchResultsState.matchResults) return undefined;
    const timer = setTimeout(() => {
      setMatchResultsState({
        isLoading: false,
      });
    }, LOADER_ANIMATION_DURATION - apiCallDuration);
    return () => {
      clearTimeout(timer);
      setApiCallDuration(0);
    };
  }, [apiCallDuration, matchResultsState.matchResults, setMatchResultsState]);

  const handleOnContactSupportClick = useCallback(() => {
    const reactFrameService = ReactFrameService.instance();
    if (reactFrameService.isInFrame()) {
      const closingPayload = {
        navigateTo: 'zendesk',
        metadata: { path: 'CONTACT_US' },
      };
      reactFrameService.closePopup(closingPayload as ClosePopupAction);
    } else {
      ssoHelper.openZendesk(ssoHelper.ZendeskPath.CONTACT_US as ZendeskPath).finally(() => {
        closeModal({
          navigateTo: 'room',
          metadata: { roomID },
        });
      });
    }
  }, [closeModal, roomID]);

  if (matchResultsState.isLoading) return <MatchInProgress />;
  if (matchResultsState.matchResults && matchResultsState.matchResults.length > 0) {
    return (
      <MatchResults
        roomID={roomID}
        matchPayload={matchPayload}
        selectedPresentingProblemAsString={selectedPresentingProblemAsString}
        selectedPresentingProblemsAsExpertise={selectedPresentingProblemsAsExpertise}
        skipPayment={skipPayment}
        handleSwitchWizardSelectedTherapist={handleSwitchWizardSelectedTherapist}
        eventCategory={eventCategory}
        clientUserID={clientUserID}
        formerTherapistID={formerTherapistID}
        isB2B={isB2B}
      />
    );
  }
  return (
    <StyledErrorModalView>
      <ErrorModal
        error="Find your match"
        errorMessage={
          <>
            <b>Still searching for your match</b>
            <br />
            <br />
            Hello! Due to high demand, we're not currently able to match you with a provider. We
            know this can feel discouraging and hope you understand that we are doing our best to
            support our clients and providers.
            <br />
            <br />
            We value you as a Talkspace client and want to ensure this next match is a good fit for
            you! Our provider availability changes constantly so we recommend waiting a few days and
            then trying this process again.
          </>
        }
        showPrimaryButton={false}
        onButtonPress={handleOnContactSupportClick}
        buttonText="Contact customer support"
        doesErrorIconAppear={false}
        buttonDataQa="matchResultsErrorModalButton"
      />
    </StyledErrorModalView>
  );
};

export default withRouter(MatchResultsContainer);
