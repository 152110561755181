import * as React from 'react';
import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface ScriptsIconProps {
  hide?: boolean;
  fillColor?: string;
}

const ScriptsIcon = ({ hide, fillColor, ...otherProps }: ScriptsIconProps) => {
  const { colors } = useEmotionTheme();
  const color = fillColor || colors.green;
  const secondaryColor = colors.white;
  const titleText = 'script';
  if (hide) return null;
  return (
    <Svg
      width="46px"
      height="46px"
      viewBox="0 0 42 42"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <G
        transform="translate(-69 -14)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path fill={secondaryColor} d="M0 0L825 0 825 67 0 67z" />
        <G
          transform="translate(30 21) translate(44) translate(5.75 8.75)"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <Path d="M0.25 1.25L19.25 1.25" />
          <Path d="M0.25 6.25L19.25 6.25" />
          <Path d="M0.25 11.25L10.25 11.25" />
        </G>
      </G>
    </Svg>
  );
};

export default ScriptsIcon;
