import React from 'react';

import { RouteComponentProps, withRouter } from '@/core/routerLib';

import OnboardingPage from './OnboardingPage';
import EmergencyContact from '../assets/EmergencyContact';

const OnboardingPageEmergencyContact = ({
  match: {
    params: { roomID },
  },
  history,
  totalSteps,
  currentStep,
}: RouteComponentProps<{ roomID: number }> & { totalSteps: number; currentStep: number }) => (
  <OnboardingPage
    totalSteps={totalSteps}
    currentStep={currentStep}
    illustration={<EmergencyContact />}
    title="Fill out your emergency contact"
    description="We need to ensure that everyone is safe and supported in case of an emergency"
    ctaText="Continue"
    onPress={() => history.push(`/room/${roomID}/onboarding/emergency-contact/source/onboarding`)}
  />
);

export default withRouter(OnboardingPageEmergencyContact);
