import { TextDS, View, spacing, ColorRolesVersion, DSIcon } from '@talkspace/react-toolkit';

type GuideLineItem = {
  title: string;
  description: string;
  icon: DSIcon;
};

type GuideLines = {
  colorRoles: ColorRolesVersion;
  guideline: GuideLineItem;
};

const { space075, space100, space150, space200, space300 } = spacing;

const GuidelineItem = ({ guideline, colorRoles }: GuideLines): JSX.Element => {
  const Icon = guideline.icon;
  return (
    <View style={{ marginLeft: space200 }}>
      <View row>
        <View style={{ marginTop: space100 }}>
          <Icon size="standard" colorType="subtlest" />
        </View>
        <TextDS variant="headingMd" style={{ marginTop: space075, marginLeft: space150 }}>
          {guideline.title}
        </TextDS>
      </View>
      <TextDS
        variant="bodySm"
        style={{
          color: colorRoles.typography.textSubtle,
          marginLeft: space300,
          marginBottom: space200,
          marginRight: space150,
        }}
      >
        {guideline.description}
      </TextDS>
    </View>
  );
};
export default GuidelineItem;
