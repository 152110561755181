import { TextDS, Large, View } from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { Pen } from '@talkspace/react-toolkit/src/designSystems/icons';
import styled, { EmotionStyle } from '@/core/styled';

import { MyInformationData } from '../../types';
import PersonalInformationDataRow from '../PersonalInformationDataRow';
import PersonalInformationDataSection from '../PersonalInformationDataSection';

const rowStyle = { marginTop: 20 };
const myInfoStyle = {
  marginBottom: 12,
  marginTop: 15,
};
interface Props {
  data: MyInformationData;
  onEdit: () => void;
  wrapperStyle?: EmotionStyle;
}

const EditButtonContainer = styled(View)({
  position: 'absolute',
  right: 25,
  top: 23,
  display: 'inline-flex',
  cursor: 'pointer',
});
const PersonalInformationDataSectionMyInformation = ({
  data,
  onEdit,
  wrapperStyle = {},
}: Props) => {
  const { firstName, lastName, dateOfBirth, address, address2, city, state, country, zipcode } =
    data;
  const displayName = firstName && lastName && `${firstName} ${lastName}`;
  const displayAddress = `${address || ''}${address2 ? ` ${address2}` : ''}${
    city ? `, ${city}` : ''
  }${state ? ` ${state}` : ''}${zipcode ? `, ${zipcode}` : ''}${country ? ` ${country}` : ''}`;
  const useNewNav = useNewMemberNav();

  return useNewNav ? (
    <>
      <View style={myInfoStyle}>
        <TextDS variant="headingLg">My information</TextDS>
      </View>
      <PersonalInformationDataSection
        title=""
        editLinkText=""
        onEditClick={onEdit}
        wrapperStyle={{ ...wrapperStyle, position: 'relative', display: 'flex' }}
      >
        <EditButtonContainer row>
          <Pen size="major" colorType="brand" />
          <Large
            onPress={onEdit}
            variant="largeBoldTSGreen"
            dataQa="personalInformationEditMyInformationButtons"
          >
            Edit
          </Large>
        </EditButtonContainer>
        <View style={{ marginTop: -7 }}>
          <PersonalInformationDataRow title="Name" value={displayName} />
        </View>
        <PersonalInformationDataRow
          title="Date of birth"
          value={dateOfBirth}
          wrapperStyle={rowStyle}
        />
        <PersonalInformationDataRow
          title="Home address"
          value={displayAddress}
          wrapperStyle={rowStyle}
        />
      </PersonalInformationDataSection>
    </>
  ) : (
    <PersonalInformationDataSection
      title="My information"
      editLinkText="Change my details"
      onEditClick={onEdit}
      wrapperStyle={wrapperStyle}
    >
      <PersonalInformationDataRow title="Name" value={displayName} />
      <PersonalInformationDataRow
        title="Date of birth"
        value={dateOfBirth}
        wrapperStyle={rowStyle}
      />
      <PersonalInformationDataRow
        title="Home address"
        value={displayAddress}
        wrapperStyle={rowStyle}
      />
    </PersonalInformationDataSection>
  );
};

export default PersonalInformationDataSectionMyInformation;
