import { useState } from 'react';
import * as React from 'react';
import {
  View,
  Button,
  Modal,
  Large,
  TabRadioGroup,
  ScrollView,
  CalendarIcon,
  useEmotionTheme,
  useModalActions,
} from '@talkspace/react-toolkit';
import { TherapistInfo, isTherapistCT } from 'ts-frontend/entities/Therapist';
import { ERoom } from 'ts-frontend/entities/Room';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { useMainState } from '../../hooks/mainContext';
import RoomSelectionCard from '../../2storybook/components/RoomSelectionCard/RoomSelectionCard';
import { sortRoomsByLastMessageDate } from '../../utils/extractRoomEntitiesFromState';

const getTherapistText = (therapist: TherapistInfo, room: ERoom): string => {
  const therapistDisplayType = therapist.type === 'psychiatrist' ? 'Psychiatrist' : 'Therapist';

  let therapistLicenseText = `${therapistDisplayType}, ${therapist.licenses[0]}`;
  if (isTherapistCT(therapist.type)) {
    therapistLicenseText = 'Matching agent';
  } else if (room.roomType === 'couples_room') {
    therapistLicenseText = 'Couples therapy';
  }
  return therapistLicenseText;
};

const ID_THERAPIST_NAME = 'ID_THERAPIST_NAME';
const ID_IN_ROOM_SCHEDULING = 'ID_IN_ROOM_SCHEDULING';

const InRoomSchedulingRoomModal: React.FunctionComponent<
  RouteComponentProps<
    {},
    {},
    {
      currentRoomID: number;
    }
  >
> = ({ location }) => {
  const { colors } = useEmotionTheme();
  const closeModal = useCloseModal();
  const routeState = location.state || {};
  const { currentRoomID } = routeState;
  const [checkedRoom, setCheckedRoom] = useState<number>(currentRoomID);
  const { roomsByID, therapistsByID } = useMainState();
  const rooms = Object.values(roomsByID).sort(sortRoomsByLastMessageDate);
  const handleSubmit = () => {
    closeModal({
      navigateTo: 'roomDetails',
      from: 'dashboard',
      metadata: { roomID: checkedRoom },
    });
  };
  const { setModalTitle } = useModalActions();
  const titleText =
    'You have multiple rooms. Please select which live video session schedule to show.';
  setModalTitle(titleText);
  return (
    <Modal.Panel
      onBackdropPress={() => closeModal()}
      wrapperStyle={{ overflow: 'hidden' }}
      contentViewStyle={{ align: 'center', paddingBottom: 60 }}
    >
      <View
        row
        style={{
          justifyContent: 'center',
          marginTop: 9,
          marginBottom: 14,
        }}
      >
        <CalendarIcon width={85} height={85} color={colors.purple} iconColor={colors.patensBlue} />
      </View>
      <Large variant="largeDarkGrey" style={{ width: 326, marginBottom: 32, textAlign: 'center' }}>
        {titleText}
      </Large>
      <ScrollView style={{ maxHeight: 'calc(100vh - 400px)' }}>
        <TabRadioGroup
          legendText="Select a room to schedule a call"
          initialSelection={!!checkedRoom}
        >
          {rooms.map((room, i) => (
            <RoomSelectionCard
              room={room}
              therapist={therapistsByID[room.therapistID]}
              setSelectedRoom={setCheckedRoom}
              selectedRoom={checkedRoom}
              tinyText={getTherapistText(therapistsByID[room.therapistID], room)}
              key={room.roomID}
              ariaLabelledBy={`${ID_THERAPIST_NAME}-${i}`}
              ariaDescribedBy={`${ID_IN_ROOM_SCHEDULING}-${i}`}
              isTabFirstOption={i === 0 && !checkedRoom}
            />
          ))}
        </TabRadioGroup>
      </ScrollView>
      <Button text="Continue" onPress={handleSubmit} style={{ marginTop: 20, width: 335 }} />
    </Modal.Panel>
  );
};

export default withRouter(InRoomSchedulingRoomModal);
