import { View, TextDS, Button, spacing } from '@talkspace/react-toolkit';
import { useHistory } from 'react-router';
import DailyPromptView from '../../components/DailyPromptView';
import { useCloseModal } from '../../../utils/ModalsContextProvider';
import { useCommunityState } from '../../hooks/CommunityContext';

const { space150, space200, space300, space400, space600 } = spacing;

const DailyQuestion = () => {
  const { dailyPrompt, replyCount } = useCommunityState();
  const closeModal = useCloseModal();
  const history = useHistory();
  const onPress = () => {
    history.push('/community/teenspace/onboarding/guidelines');
  };
  return (
    <View align="center" justify="space-between" style={{ textAlign: 'center', height: '100%' }}>
      <View align="center">
        <View style={{ marginTop: space400 }}>
          <TextDS variant="heading2xl">Answer the daily question</TextDS>
        </View>
        <TextDS variant="body" style={{ marginTop: space150 }}>
          Each day, you can see a new topic, respond anonymously, and support others. Give it a try!
        </TextDS>
        <View style={{ marginTop: space600 }}>
          <DailyPromptView sentence={dailyPrompt && dailyPrompt.text} replyCount={replyCount} />
        </View>
      </View>

      <View align="center" style={{ marginBottom: space200, width: '100%' }}>
        <Button
          onPress={onPress}
          style={{ width: '100%', padding: `${space200}px ${space300}px`, maxWidth: undefined }}
          text="Share your thoughts"
        />
        <Button
          style={{ background: 'none', border: 'none', width: '100%' }}
          isSecondary
          onPress={() => closeModal({ navigateTo: 'home', metadata: { path: '/' } })}
          stretch
          variant="secondary"
          text="I’ll try it later"
        />
      </View>
    </View>
  );
};

export default DailyQuestion;
