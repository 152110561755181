import { useCallback, useEffect, createElement } from 'react';
import { Trans } from '@talkspace/i18n';

import * as yup from 'yup';
import { checkEmailValidity, checkNicknameValidity } from 'ts-frontend/helpers';
import { PasswordInputMode } from 'ts-frontend/types';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFReactToolkit, TFErrorsCommon } from '@talkspace/i18n/types';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import styled, { useEmotionTheme, EmotionStyle } from '../../core/styled';
import useObjectState from '../../hooks/useObjectState';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import { useUniqueID, useContainerFocus } from '../../hooks/a11yHelper';
import { AlignType } from '../../types';
import {
  isPhoneNumberValid,
  isCountryCodeValid,
  parseCountryCallingCode,
  parseFullPhoneNumber,
} from '../A11yPhoneInput';
import RHFCountryStateDropDown from '../CountryStateDropDown';
import RHFPassword from '../Password';
import ExtraHuge from '../Typography/ExtraHuge';
import Large from '../Typography/Large';
import ExtraTiny from '../Typography/ExtraTiny';
import Tiny from '../Typography/Tiny';
import Big from '../Typography/Big';
import Button from '../Button';
import View from '../View';
import StickyDrawer, { StickyDrawerParentContainer } from '../StickyDrawer';
import Link from '../Link';
import RHFInput from '../RHFInput';
import RHFPhoneInput from '../RHFPhoneInput';
import RHFCheckbox from '../RHFCheckbox';
import RHFSelect from '../RHFSelect';
import {
  CreateAccountFormFields,
  CreateAccountFormFieldNames,
  CreateAccountYupContext,
  SignUpData,
  ComponentState,
  MinimumAge,
} from './types';
import referralOptions from '../../constants/referralOptions';

// Comes from registration.js used in the homepage
const apiErrors = {
  0: {
    field: 'general-error',
    message: 'Please complete all the mandatory fields',
  },
  1: {
    field: 'general-error',
    message: 'Please complete all the mandatory fields',
  },
  103: {
    field: 'email',
    message: 'There was an error while registering.',
  },
  104: { field: 'nickname', message: 'Nickname already used' },
  106: {
    field: 'general-error',
    message: 'Please complete all the mandatory fields',
  },
  121: null,
  400: {
    field: 'general-error',
    message: 'There was an error while registering.',
  },
  500: {
    field: 'internal-server-error',
    message: 'An error occurred',
  },
};

const getCreateAccountSchema = ({
  tReactToolkit,
  tErrorsCommon,
}: {
  tReactToolkit: TFReactToolkit;
  tErrorsCommon: TFErrorsCommon;
}): yup.SchemaOf<CreateAccountFormFields> => {
  const emailError = tReactToolkit(
    'CreateAccountForm.emailError',
    'Please enter an email.',
    undefined
  );
  const passwordError = tReactToolkit(
    'CreateAccountForm.passwordError',
    'Please enter a password.',
    undefined
  );
  const nicknameError = tReactToolkit(
    'CreateAccountForm.nicknameError',
    `Please enter a nickname.`,
    undefined
  );
  const countryError = tReactToolkit(
    'CreateAccountForm.countryError',
    'Please select a country',
    undefined
  );
  const stateError = tReactToolkit(
    'CreateAccountForm.stateError',
    'Please select a state.',
    undefined
  );
  const phoneNumberError = tReactToolkit(
    'CreateAccountForm.phoneNumberError',
    'Please enter a phone number.',
    undefined
  );
  const defaultError = tReactToolkit(
    'CreateAccountForm.defaultError',
    'Missing or invalid.',
    undefined
  );
  return yup.object().shape({
    email: yup.string().when('$showEmailInput', {
      is: true,
      then: yup
        .string()
        .test(
          'is-email-valid',
          emailError,
          (userInput: CreateAccountFormFields['email']) =>
            !!userInput && checkEmailValidity(userInput)
        )
        .required(emailError),
      otherwise: yup.string().optional(),
    }),
    password: yup
      .string()
      .test(
        'is-password-valid',
        passwordError,
        (
          userInput: CreateAccountFormFields['password'],
          testContext: yup.TestContext<CreateAccountYupContext>
        ) => {
          testContext.options.context?.onSubmitPassword(true);
          return !testContext.options.context?.isPasswordInvalid();
        }
      )
      .required(defaultError),
    nickname: yup
      .string()
      .test(
        'is-nickname-valid',
        nicknameError,
        (
          userInput: CreateAccountFormFields['nickname'],
          testContext: yup.TestContext<CreateAccountYupContext>
        ) => {
          if (!userInput) return false;
          // Work around to dynamically set the error message with checkNickNameValidity rather than copying that function's logic over to .test() blocks within the schema
          const nicknameErrorMessage = checkNicknameValidity(userInput, tErrorsCommon);
          testContext.options.context?.setComponentState({ nicknameErrorMessage });
          return !nicknameErrorMessage;
        }
      )
      .required(defaultError),
    country: yup.string().when('$showCountryStateDropdown', {
      is: true,
      then: yup.string().required(countryError),
      otherwise: yup.string().optional(),
    }),
    state: yup
      .string()
      .test(
        'has-US-resident-selected-state',
        stateError,
        (
          userInput: CreateAccountFormFields['state'],
          testContext: yup.TestContext<CreateAccountYupContext>
        ) => {
          const isUSResident = testContext.parent.country === 'US';
          const isCountryStateRequired = testContext.options.context?.showCountryStateDropdown;
          if (!userInput && isUSResident && isCountryStateRequired) return false;
          return true;
        }
      ),
    phone: yup
      .string()
      .when('$showPhoneNumberInput', {
        is: true,
        then: yup.string().when('$phoneNumberOptional', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup.string().required(phoneNumberError),
        }),
        otherwise: yup.string().optional(),
      })
      .test(
        'is-valid-phone-number',
        defaultError,
        (userInput: string | undefined, testContext: yup.TestContext<CreateAccountYupContext>) => {
          if (!testContext.options.context?.showPhoneNumberInput) return true;
          if (testContext.options.context?.phoneNumberOptional && !userInput) return true;
          return isPhoneNumberValid(userInput);
        }
      ),
    referralSource: yup
      .object({
        value: yup.string().default(null).required(),
        label: yup.string().default(null).required(),
        key: yup.string().default(null).required(),
      })
      .default(null)
      .nullable(),
    smsAgreement: yup.bool().oneOf([true, false]).required(),
  });
};

interface CreateAccountFormProps {
  isUpdating: boolean;
  isError: boolean;
  errorMessage: string;
  minimumAge: MinimumAge;
  clientCountry?: string;
  clientState?: string;
  loginFooter?: () => JSX.Element;
  clientEmail?: string;
  headerCopy?: string;
  bodyCopy?: string;
  bodyStyle?: EmotionStyle;
  tinyStyle?: EmotionStyle;
  headerAlign?: AlignType;
  showEmailInput?: boolean;
  showPhoneNumberInput?: boolean;
  phoneNumberOptional?: boolean;
  canChangeEmail?: boolean;
  showCountryStateDropdown?: boolean;
  showReferralField?: boolean;
  onSubmit?: (formState: SignUpData, password: string) => void;
  headerWrapperStyle?: EmotionStyle;
  phoneNumber?: string;
  phoneNumberCountryCode?: string;
  submitText?: string;
  consent?: boolean;
}

const Styled = {
  LinkText: (props) =>
    createElement(
      styled(ExtraTiny)(() => {
        return {
          textDecoration: 'underline',
        };
      }),
      { ...props, variant: 'extraTinyBoldGreen' }
    ),
};
const ErrorMessage = styled(Tiny)(({ theme: { colors } }) => {
  return { marginBottom: 18, padding: 4, color: colors.torchRed };
});

const FormWrapper = styled(StickyDrawerParentContainer)({
  flex: 1,
  alignItems: 'center',
  textAlign: 'left',
  flexDirection: 'column',
  marginLeft: 0,
  marginRight: 0,
  maxWidth: 335,
  '&:focus': {
    outline: 'none',
  },
});

const Form = styled.form();

const CreateAccountForm = ({
  isUpdating,
  isError,
  errorMessage,
  minimumAge,
  loginFooter,
  onSubmit,
  clientCountry = 'US',
  clientState = '',
  clientEmail = '',
  phoneNumber,
  phoneNumberCountryCode,
  headerCopy = 'Create your account',
  bodyCopy = '',
  bodyStyle = {},
  tinyStyle = {},
  headerAlign = 'center',
  showEmailInput = true,
  showPhoneNumberInput = true,
  phoneNumberOptional = false,
  canChangeEmail = false,
  showCountryStateDropdown = false,
  showReferralField = false,
  submitText,
  consent,
}: CreateAccountFormProps) => {
  const { t: tReactToolkit } = useTranslation('react-toolkit');
  const { t: tErrorsCommon } = useTranslation('errors.common');

  const [componentState, setComponentState] = useObjectState<ComponentState>({
    signUpAlert: false,
    generalErrorMessage: '',
    showGeneralError: false,
    clientEmail,
    clientNickname: '',
    emailErrorMessage: '',
    nicknameErrorMessage: '',
  });

  const [{ isPasswordInvalid, onSubmitPassword }, passwordProps] = usePasswordValidation({
    passwordInputMode: PasswordInputMode.CREATE_ACCOUNT,
    email: componentState.clientEmail,
    nickname: componentState.clientNickname,
  });
  const methods = useForm<CreateAccountFormFields>({
    resolver: yupResolver(getCreateAccountSchema({ tReactToolkit, tErrorsCommon })),
    context: {
      showEmailInput,
      showPhoneNumberInput,
      phoneNumberOptional,
      showCountryStateDropdown,
      isPasswordInvalid,
      onSubmitPassword,
      setComponentState,
    } as CreateAccountYupContext,
    // defaultValues are necessary for proper error focus when using a resolver
    defaultValues: {
      // we should not set client email default value to null
      email: clientEmail || '',
      password: '',
      nickname: '',
      phone:
        phoneNumber && phoneNumberCountryCode && isCountryCodeValid(phoneNumberCountryCode)
          ? `${parseCountryCallingCode(phoneNumberCountryCode)}${phoneNumber}`
          : '',
      country: clientCountry,
      state: clientState,
      referralSource: null,
      smsAgreement: consent || false,
    } as CreateAccountFormFields,
  });

  const { handleSubmit, watch, setError } = methods;
  const nicknameValue = watch(CreateAccountFormFieldNames.nickname);
  const emailValue = watch(CreateAccountFormFieldNames.email);

  useEffect(() => {
    if (emailValue) {
      setComponentState({ clientEmail: emailValue });
    }
    if (nicknameValue) {
      setComponentState({ clientNickname: nicknameValue });
    }
  }, [emailValue, nicknameValue, setComponentState]);

  const { colors } = useEmotionTheme();
  const header1ID = useUniqueID('header1ID');
  const createAccountTextID = useUniqueID('createAccountTextID');
  const { containerRef } = useContainerFocus();

  const resetGeneralErrors = useCallback(() => {
    setComponentState({
      generalErrorMessage: '',
      signUpAlert: false,
    });
  }, [setComponentState]);

  usePasswordValidation({
    passwordInputMode: PasswordInputMode.CREATE_ACCOUNT,
    email: emailValue,
    nickname: nicknameValue,
  });

  const onSubmitHandler = (createAccountFormData: CreateAccountFormFields) => {
    onSubmitPassword(true);
    const {
      email,
      password: clientPassword,
      nickname,
      country,
      state,
      phone,
      referralSource,
      smsAgreement,
    } = createAccountFormData;
    const parsedPhoneNumber =
      phone && isPhoneNumberValid(phone) ? parseFullPhoneNumber(phone) : undefined;
    const signUpData = {
      email,
      nickname,
      country,
      state,
      phoneNumber: parsedPhoneNumber?.nationalNumber || undefined,
      phoneNumberCountryCode: parsedPhoneNumber?.country || undefined,
      referralSource,
      isChecked: smsAgreement,
    };
    if (onSubmit) onSubmit(signUpData, clientPassword);
  };

  useEffect(() => {
    if (
      isError &&
      errorMessage &&
      // errorMessage is either NaN (a string) or a number in apiErrors
      (Number.isNaN(Number(errorMessage)) || Number(errorMessage) in apiErrors)
    ) {
      const { field, message } = Number.isNaN(Number(errorMessage))
        ? { field: 'general-error', message: errorMessage }
        : apiErrors[Number(errorMessage)];
      if (field === 'email') {
        setError(CreateAccountFormFieldNames.email, {
          type: 'account-creation-error',
          message,
        });
      }
      if (field === 'nickname') {
        setError(CreateAccountFormFieldNames.nickname, { type: 'nickname-in-use', message });
      }
      if (field === 'general-error' || field === 'internal-server-error') {
        setComponentState({ showGeneralError: true, generalErrorMessage: message });
      } else {
        resetGeneralErrors();
      }
      // if the error is api error it's not associated with a particular input so it should be given role="alert" to allow a screen reader to announce it
      setComponentState({ signUpAlert: true });
    } else {
      resetGeneralErrors();
    }
  }, [isError, errorMessage, resetGeneralErrors, setComponentState, setError]);

  const isCountryStateDropDownDisabled =
    (clientCountry === 'US' && clientState) || (clientCountry && clientCountry !== 'US');
  const isEmailDisabled = !canChangeEmail && !!clientEmail;
  const marginBottom = showPhoneNumberInput ? 66 : 32;

  const defaultSubmitText = tReactToolkit(
    'CreateAccountForm.submitTextDefault',
    'Create account',
    undefined
  );
  const phoneInputLabel = phoneNumberOptional
    ? tReactToolkit(
        'CreateAccountForm.phoneNumberOptionalInputLabel',
        'Phone number (optional)',
        undefined
      )
    : tReactToolkit('CreateAccountForm.phoneNumberInputLabel', 'Phone number', undefined);
  const nicknameInputPlaceholder = tReactToolkit(
    'CreateAccountForm.nicknameInputPlaceholder',
    'Enter nickname',
    undefined
  );
  const communicationCheckboxLabel = tReactToolkit(
    'CreateAccountForm.communicationAgreementCheckbox',
    'I agree to receive email and text marketing (up to 5 texts/mo) and may opt out any time. Msg and data rates may apply.',
    undefined
  );
  return (
    <FormWrapper ref={containerRef} aria-labelledby={header1ID} tabIndex={-1}>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmitHandler)}>
          <View
            style={{
              alignItems: 'center',
            }}
          >
            <View style={{ headerAlign }}>
              {headerCopy && (
                <ExtraHuge
                  id={header1ID}
                  as="h1"
                  style={{
                    marginTop: 20,
                    marginBottom: 12,
                    textAlign: headerAlign,
                  }}
                >
                  {headerCopy}
                </ExtraHuge>
              )}
              {bodyCopy && (
                <View
                  style={{
                    textAlign: 'center',
                    whiteSpace: 'pre-line',
                    marginBottom: 24,
                    ...bodyStyle,
                  }}
                >
                  <Large as="h2" variant="largeDarkGrey" style={{ fontWeight: 400 }}>
                    {bodyCopy}
                  </Large>
                </View>
              )}
            </View>
            {showEmailInput && (
              <RHFInput
                fieldName={CreateAccountFormFieldNames.email}
                label={tReactToolkit('CreateAccountForm.emailInputLabel', 'Email', undefined)}
                inputType="email"
                isDisabled={isEmailDisabled}
                errorMessage={componentState.emailErrorMessage}
                isRequired
              />
            )}
            <RHFPassword
              {...passwordProps}
              fieldName={CreateAccountFormFieldNames.password}
              roundedFocusStyle
              dataQa="createAccountPasswordInput"
              outerContainerStyle={{ marginBottom: 12, maxWidth: 335 }}
            />
            <RHFInput
              fieldName={CreateAccountFormFieldNames.nickname}
              label={tReactToolkit('CreateAccountForm.nicknameInputLabel', 'Nickname', undefined)}
              placeholder={nicknameInputPlaceholder}
              errorMessage={componentState.nicknameErrorMessage}
              isRequired
            />
            {showPhoneNumberInput && (
              <RHFPhoneInput
                fieldName={CreateAccountFormFieldNames.phone}
                label={phoneInputLabel}
                containerStyle={{ width: '100%' }}
              />
            )}
            {showCountryStateDropdown && (
              <RHFCountryStateDropDown
                countryFieldName={CreateAccountFormFieldNames.country}
                stateFieldName={CreateAccountFormFieldNames.state}
                maxWidth={335}
                isDisabled={!!isCountryStateDropDownDisabled}
              />
            )}
            {showReferralField && (
              <RHFSelect
                fieldName={CreateAccountFormFieldNames.referralSource}
                label="How did you hear about us?"
                options={referralOptions}
              />
            )}
            <View style={{ width: 335 }}>
              {componentState.showGeneralError && (
                <ErrorMessage dataQa="generalErrorInfo">
                  {componentState.generalErrorMessage}
                </ErrorMessage>
              )}
              {showPhoneNumberInput && !consent && (
                <RHFCheckbox
                  fieldName={CreateAccountFormFieldNames.smsAgreement}
                  label={communicationCheckboxLabel}
                  isRequired
                  alignCenter={false}
                  containerStyle={{ marginTop: 12, color: colors.permaRiverBed }}
                  checkboxStyle={{ marginRight: 15, height: 24, width: 24 }}
                />
              )}
            </View>
            <View style={{ marginBottom }}>{loginFooter ? loginFooter() : null}</View>
          </View>
          <StickyDrawer>
            <Button
              aria-describedby={createAccountTextID}
              dataQa="createAccountSubmitButton"
              type="submit"
              disabled={isUpdating}
              isLoading={isUpdating}
              primaryColor={colors.green}
              roundedFocusStyle
              style={{ width: 335, minHeight: 55, marginTop: 0 }}
            >
              <Big variant="bigWide">{submitText || defaultSubmitText}</Big>
            </Button>

            <View style={{ marginTop: 15, textAlign: 'center', ...tinyStyle }}>
              <ExtraTiny id={createAccountTextID}>
                <Trans t={tReactToolkit} i18nKey="CreateAccountForm.consentFooter">
                  By clicking ‘Create account’ you consent to{' '}
                  <Link
                    dataQa="telemedicineLink"
                    stopPropagation
                    target="_blank"
                    href="https://www.talkspace.com/public/telemedicine"
                    style={{ marginLeft: 0, marginRight: 0 }}
                    primaryColor={colors.green}
                    roundedFocusStyle
                  >
                    <Styled.LinkText inline>Telemedicine</Styled.LinkText>
                  </Link>
                  , confirm that you are {{ minimum_age: minimumAge }} or older, and accept our{' '}
                  <Link
                    dataQa="createAccountTermsLink"
                    stopPropagation
                    target="_blank"
                    href="https://www.talkspace.com/public/terms"
                    style={{ marginLeft: 0, marginRight: 0 }}
                    primaryColor={colors.green}
                    roundedFocusStyle
                  >
                    <Styled.LinkText inline>Terms of use</Styled.LinkText>{' '}
                  </Link>
                  and{' '}
                  <Link
                    dataQa="createAccountPrivacyLink"
                    stopPropagation
                    target="_blank"
                    href="https://www.talkspace.com/public/privacy-policy"
                    style={{ marginLeft: 0, marginRight: 0 }}
                    primaryColor={colors.green}
                    roundedFocusStyle
                  >
                    <Styled.LinkText inline>Privacy policy</Styled.LinkText>.
                  </Link>
                </Trans>
              </ExtraTiny>
            </View>
          </StickyDrawer>
        </Form>
      </FormProvider>
    </FormWrapper>
  );
};

export default CreateAccountForm;
