import { FunctionComponent } from 'react';

import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import { ExtraHuge, View, Large, Paragraph, Spinner } from '@talkspace/react-toolkit';
import { getUserData } from '@/auth/helpers/token';
import { useParams } from '@/core/routerLib';

import Step3 from '../icons/MessagingInformationStep3';
import Step2 from '../icons/MessagingInformationStep2';
import Step1 from '../icons/MessagingInformationStep1';

export type StepTitle =
  | 'What is a Messaging Session?'
  | 'Go at your own pace'
  | 'Your 7-day session is about to start'
  | 'You’re about to start a Messaging Session'
  | 'Your session is about to start';

interface TextsData {
  title: StepTitle;
  subtitle: string | JSX.Element;
  subheader?: string;
}

interface StepData {
  icon: JSX.Element;
  text: TextsData;
}

const stepsDataEAP: Record<number, StepData> = {
  1: {
    icon: <Step1 />,
    text: {
      title: 'What is a Messaging Session?',
      subheader: '7 days = 1 session',
      subtitle: 'Messaging sessions consist of seven consecutive days of access to your provider.',
    },
  },
  2: {
    icon: <Step2 />,
    text: {
      title: 'Go at your own pace',
      subtitle: (
        <>
          <Paragraph>
            This week of back-and-forth messaging with your provider is a chance to explore and
            reflect on how you are feeling.
          </Paragraph>
          <Paragraph style={{ marginTop: 20 }}>
            The more you write, the more your provider can offer thoughtful feedback and treatment.
          </Paragraph>
        </>
      ),
    },
  },
  3: {
    icon: <Step3 />,
    text: {
      title: 'Your 7-day session is about to start',
      subtitle:
        'Messaging sessions cannot be canceled once started. After 7 days of back-and-forth messaging, your provider will submit this session as complete.',
    },
  },
};

const stepsDataBH: Record<number, StepData> = {
  1: {
    icon: <Step1 />,
    text: {
      title: 'You’re about to start a Messaging Session',
      subtitle:
        'Messaging sessions usually consist of about a week of back-and-forth messaging with your provider.',
    },
  },
  2: {
    icon: <Step2 />,
    text: {
      title: 'Go at your own pace',
      subtitle:
        'This is a chance for you and your provider to explore and reflect on topics at your own pace.',
    },
  },
  3: {
    icon: <Step3 />,
    text: {
      title: 'Your session is about to start',
      subtitle:
        'If you’re feeling stuck, try answering the following question: How am I feeling right now?',
    },
  },
};

export const getStepData = (step: number, isBH: boolean): StepData => {
  const stepsData = isBH ? stepsDataBH : stepsDataEAP;
  return stepsData[step];
};

const MessagingInformationStep: FunctionComponent<{ step: number }> = ({ step = 0 }) => {
  const { id: userID } = getUserData();
  const { roomID } = useParams<{ roomID: string }>();

  const { data: businessLineData, isLoading } = useQueryBusinessLine(roomID, userID);
  if (isLoading) {
    return <Spinner />;
  }
  const { isBH } = businessLineData || { isBH: false };
  const stepData = getStepData(step, isBH);
  const { title, subheader, subtitle } = stepData.text;

  return (
    <View style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
      <View style={{ margin: '0 32px' }}>{stepData.icon}</View>
      <View style={{ padding: '0px 42px' }}>
        <ExtraHuge style={{ marginBottom: '20px', textAlign: 'center' }}>{title} </ExtraHuge>
        {subheader ? (
          <Large variant="largeBoldWide" style={{ marginBottom: '20px', textAlign: 'center' }}>
            {subheader}
          </Large>
        ) : (
          <></>
        )}
        <Large style={{ textAlign: 'center' }}>{subtitle}</Large>
      </View>
    </View>
  );
};

export default MessagingInformationStep;
