import { FunctionComponent, useEffect } from 'react';
import moment from 'moment';
import { Avatar, View, Big, Standard } from '@talkspace/react-toolkit';

import styled from '@/core/styled';
import apiHelper from '@/core/api/apiHelper';
import { useHistory, useParams } from '@/core/routerLib';
import { TherapistInfo } from '../../types';
import {
  useInRoomSchedulingActions,
  useInRoomSchedulingState,
} from '../../hooks/inRoomSchedulingContext';
import AddToCalendar from '../AddToCalendar';

const CurrentTherapistAvatar = styled(Avatar)({
  zIndex: 1,
  marginRight: -16,
  borderRight: '6px solid white',
  borderRadius: '50%',
  marginLeft: 75,
});

const AvatarRow: FunctionComponent<{ therapistUserID: number }> = ({ therapistUserID }) => (
  <View
    align="center"
    style={{
      width: 300,
      marginTop: 15,
    }}
    row
  >
    <CurrentTherapistAvatar
      image={`${apiHelper().cdnEndpoint}/images/application/therapist/220/${therapistUserID}.jpg`}
      height={76}
      width={76}
    />
    <View style={{ width: 0 }} />
    <Avatar height={76} width={76} />
  </View>
);

const AddToCalendarScreen: FunctionComponent<{ therapist: TherapistInfo }> = ({ therapist }) => {
  const { roomID } = useParams<{ roomID: string; bookingID: string }>();
  const {
    location: { search },
  } = useHistory();
  const { selectedCancelBooking: selectedBooking } = useInRoomSchedulingState();
  const { dispatchGetSelectedBooking } = useInRoomSchedulingActions();

  const bookingID = new URLSearchParams(search).get('bookingID');

  useEffect(() => {
    if (bookingID) {
      dispatchGetSelectedBooking(Number(roomID), bookingID, false);
    }
  }, [roomID, bookingID, dispatchGetSelectedBooking]);

  if (!selectedBooking) {
    return null;
  }

  const startTime = moment(selectedBooking.startTime);

  return (
    <View flex={1} align="center" justify="space-between">
      <View align="center">
        <AvatarRow therapistUserID={therapist.id} />
        <Big style={{ marginTop: 25 }}>{startTime.format('dddd, MMM D')}</Big>
        <Standard style={{ marginBottom: 40 }} variant="standardDarkGrey">
          {startTime.format('h:mm A')} {' – '}
          {startTime.add(selectedBooking.creditMinutes, 'minutes').format('h:mm A')}
        </Standard>
      </View>
      <AddToCalendar
        bookingID={selectedBooking.id}
        selectedBooking={selectedBooking}
        videoCreditType={selectedBooking.type}
        therapistName={`${therapist?.firstName} ${therapist?.lastName}`}
      />
    </View>
  );
};

export default AddToCalendarScreen;
