import { useEffect } from 'react';
import { useHistory } from 'core/routerLib';

import { useClientAuthActions, useClientAuthState } from '../../hooks/clientAuthContext';
import LoadingScreen from '../../screens/LoadingScreen';

const TwoFactorAuthenticationReminder = () => {
  const { phoneNumberLast2Digits, twoFAStatus } = useClientAuthState();
  const { get2FAStatusAction } = useClientAuthActions();
  const history = useHistory();

  useEffect(() => {
    if (!twoFAStatus) {
      get2FAStatusAction();
    } else {
      history.push(
        phoneNumberLast2Digits ? '/2fa/reminder/verify-number' : '/2fa/reminder/change-number'
      );
    }
  }, [phoneNumberLast2Digits, twoFAStatus, get2FAStatusAction, history]);

  return <LoadingScreen />;
};
export default TwoFactorAuthenticationReminder;
