import { RouteComponentProps, withRouter } from '@/core/routerLib';
import { trackEvent } from '@/utils/analytics/eventTracker';

import OnboardingPage from './OnboardingPage';
import TeenMedicalHistory from '../assets/TeenMedicalHistory';

const OnboardingPageTeenTreatmentIntake = ({
  match: {
    params: { roomID },
  },
  history,
  totalSteps,
  currentStep,
}: RouteComponentProps<{ roomID: number }> & { totalSteps: number; currentStep: number }) => (
  <OnboardingPage
    totalSteps={totalSteps}
    currentStep={currentStep}
    illustration={<TeenMedicalHistory />}
    title="Lastly, let’s talk about your mental health"
    description="Some of these questions may be tough to answer, but they will help your therapist best know how to support you"
    ctaText="Continue"
    onPress={() => {
      trackEvent(
        'Start Medical History',
        {
          actionName: 'onboardingStep',
          roomID,
        },
        ['tsAnalytics']
      );
      history.push(`/room/${roomID}/onboarding/teen-mental-health/source/onboarding`);
    }}
  />
);

export default withRouter(OnboardingPageTeenTreatmentIntake);
