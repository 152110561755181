import {
  Button,
  ClientMeditating,
  ExtraHuge,
  Large,
  Spinner,
  StickyDrawer,
  View,
  styled,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import React, { useCallback, useEffect } from 'react';
import { useWizardActions, useWizardState } from 'stepWizard';
import { CheckInSource } from 'chat/screens/VideoCallScreen';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import useQueryVideoCreditOffers from 'inRoomScheduling/hooks/useQueryVideoCreditOffers';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import { checkAvailableCredits } from 'ts-frontend/helpers/creditHelpers';
import {
  trackViewPostLVSBookSessionPrompt,
  trackClickPostLVSBookSessionPrompt,
} from 'ts-analytics/mixpanel/events';
import { useHistory } from '@/core/routerLib';

import useQueryClientUsageStats from '../../hooks/useQueryClientUsageStats';

const Styled = {
  Wrapper: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '100%',
      padding: '0 20px 0',
    };
  }),
  ContentContainer: styled(View)({ alignItems: 'center', maxWidth: 430 }),
  Illustration: styled(ClientMeditating)(({ isMobile }: { isMobile: boolean }) => {
    return { marginTop: isMobile ? 64 : 144 };
  }),
  Title: styled(ExtraHuge)({ marginTop: 24 }),
  Description: styled(Large)(({ theme: { colorRoles } }) => {
    return {
      marginTop: 12,
      color: colorRoles.typography.textSubtle,
    };
  }),
  BookButton: styled(Button)(({ theme: { colors } }) => {
    return {
      width: '100%',
      marginTop: 40,
      backgroundColor: colors.permaTalkspaceDarkGreen,
    };
  }),
  SkipButton: styled(Button)(({ theme: { colors, colorRoles } }) => {
    return {
      width: '100%',
      backgroundColor: colors.white,
      color: colorRoles.typography.textBrandDefault,
    };
  }),
};

const ButtonsContainer = ({ children }: { children: React.ReactNode }) => {
  const { isMobile } = useWindowWidthState();
  if (isMobile) {
    return <StickyDrawer noBorder>{children}</StickyDrawer>;
  }
  return (
    <View style={{ marginTop: 40, alignItems: 'center', width: '100%', gap: 8 }}>{children}</View>
  );
};
const CheckInBookingFirst = () => {
  const {
    roomID,
    checkInSource,
    videoCallID,
    therapistInfo: { id: therapistID },
    clientUserID: clientID,
  } = useWizardState<{
    roomID: number;
    checkInSource: CheckInSource;
    videoCallID: string;
    therapistInfo: ETherapistInfo;
    clientUserID: number;
  }>();
  const { data: clientUsageStats, isLoading: isLoadingClientUsageStats } = useQueryClientUsageStats(
    {
      clientID,
      therapistID,
    }
  );
  const { completedSessionsWithTherapist, completedSessionsTotal } = clientUsageStats || {
    completedSessionsTotal: -1,
    completedSessionsWithTherapist: -1,
  };

  const { data: videoCreditOffers, isLoading: isLoadingVideoCreditOffers } =
    useQueryVideoCreditOffers({ roomID });

  const hasAvailableCredits = checkAvailableCredits(videoCreditOffers?.liveSessions || []);

  const { data: { isBH } = {}, isLoading: isLoadingBusinessLine } = useQueryBusinessLine(
    roomID,
    clientID
  );
  const isLoading =
    isLoadingClientUsageStats || isLoadingVideoCreditOffers || isLoadingBusinessLine;

  const history = useHistory();
  const { isMobile } = useWindowWidthState();
  const { setState } = useWizardActions();

  const source = 'post-lvs-check-in';

  const schedulingPath = `/in-room-scheduling/room/${roomID}?${new URLSearchParams({
    source,
  }).toString()}`;

  const onPressBook = useCallback(() => {
    // track click
    trackClickPostLVSBookSessionPrompt({
      buttonName: 'CTA',
      therapistID,
      videoCallID,
      roomID,
      clientID,
    });
    setState({ isClosable: true, hideHeader: false });
    // redirect to in room scheduling
    history.push(schedulingPath);
  }, [clientID, history, roomID, schedulingPath, therapistID, videoCallID, setState]);

  const redirectToRatingFlow = useCallback(() => {
    history.push(
      `/check-in/room/${roomID}/source/${source}/check-in-source/${checkInSource}/video-call/${videoCallID}?bookingCompleted=true`
    );
  }, [checkInSource, history, roomID, videoCallID]);

  const onPressSkip = useCallback(() => {
    // track click
    trackClickPostLVSBookSessionPrompt({
      buttonName: 'Skip',
      therapistID,
      videoCallID,
      roomID,
      clientID,
    });
    setState({ isClosable: true, hideHeader: false });
    // go to rating flow
    redirectToRatingFlow();
  }, [clientID, redirectToRatingFlow, roomID, therapistID, videoCallID, setState]);

  useEffect(() => {
    if (!isLoading) {
      // If user has no credits left skip the booking and go straight to rating flow
      if (!isBH && !hasAvailableCredits) {
        redirectToRatingFlow();
      } else {
        // track page view
        trackViewPostLVSBookSessionPrompt({
          completedSessionsWithTherapist,
          therapistID,
          videoCallID,
          roomID,
          clientID,
        });
        // if with therapist for long enough, open the in room scheduling immediately
        if (completedSessionsWithTherapist > 3) {
          history.replace(schedulingPath);
        }
      }
    }
  }, [
    schedulingPath,
    history,
    isLoading,
    completedSessionsWithTherapist,
    onPressSkip,
    hasAvailableCredits,
    isBH,
    redirectToRatingFlow,
    therapistID,
    videoCallID,
    roomID,
    clientID,
  ]);

  useEffect(() => {
    setState({ isClosable: false, hideHeader: true });
  }, [setState]);

  return (
    <Styled.Wrapper isMobile={isMobile}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Styled.ContentContainer>
            <Styled.Illustration isMobile={isMobile} />
            <Styled.Title>Every session is a step forward</Styled.Title>
            <Styled.Description>
              You’ve completed{' '}
              {completedSessionsTotal > 1
                ? `${completedSessionsTotal} therapy sessions`
                : 'your first therapy session'}
              ! Book another session to keep your momentum.
            </Styled.Description>
          </Styled.ContentContainer>
          <ButtonsContainer>
            <Styled.BookButton
              text="Book another session"
              dataQa="bookAnotherSessionButton"
              onPress={onPressBook}
            />
            <Styled.SkipButton text="Skip" dataQa="skipButton" onPress={onPressSkip} />
          </ButtonsContainer>
        </>
      )}
    </Styled.Wrapper>
  );
};

export default CheckInBookingFirst;
