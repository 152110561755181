import { FunctionComponent, useEffect, useCallback, useState } from 'react';
import { History } from 'history';
import { StepWizardProvider } from 'stepWizard';
import { RouteComponentProps } from '@/core/routerLib';
import { MainContextProvider } from '@/hooks/mainContext';
import { useGenericWizardActions } from '@/utils/genericWizardActions';
import { getUserData } from '@/auth/helpers/token';
import Routes from './Routes';
import { getInitialState, State } from './reducers/mentalHealthWizardState';
import mentalHealthActions from './hooks/mentalHealthActions';

interface MatchingIntakeRouteParams {
  roomID: number;
  source: string;
  contextID?: string;
  history: History;
}

const MentalHealthWizard: FunctionComponent<RouteComponentProps<MatchingIntakeRouteParams>> = ({
  match: {
    params: { roomID, source, contextID = '' },
  },
}) => {
  const { id } = getUserData();

  const getFirstState = useCallback(getInitialState, [roomID, source, contextID]);

  const [initialState, setInitialState] = useState<State | undefined>();

  const genericMatchingIntakeActions = useGenericWizardActions(mentalHealthActions);

  useEffect(() => {
    setInitialState(getFirstState(id, roomID, source, contextID));
  }, [contextID, getFirstState, id, roomID, source]);

  return (
    <>
      {initialState ? (
        <MainContextProvider>
          <StepWizardProvider
            initialState={initialState}
            genericActions={genericMatchingIntakeActions}
          >
            <Routes />
          </StepWizardProvider>
        </MainContextProvider>
      ) : null}
    </>
  );
};

export default MentalHealthWizard;
