import {
  Button,
  Huge,
  PanelHeader,
  ResponsiveLayoutWithHeader,
  Large,
  View,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { isTestAccount } from 'ts-frontend/helpers';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import { useMainState } from '../../hooks/mainContext';
import ReactFrameService from '../../auth/reactFrame/ReactFrameService';
import ProgressBar from './ProgressBar';
import { setIsOnboardingDismissedTestAccount } from '../util/onboardingStorage';
import useNavHelpers from '../../hooks/useNavHelpers';

interface Props extends RouteComponentProps<{ roomID: number }> {
  illustration: JSX.Element;
  title: string;
  description: string;
  totalSteps: number;
  currentStep: number;
  ctaText: string;
  onPress: () => void;
}

const OnboardingPage = ({
  history,
  match: {
    params: { roomID },
  },
  illustration,
  totalSteps,
  currentStep,
  title,
  description,
  ctaText,
  onPress,
}: Props) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const { me } = useMainState();
  const { navigateToDefault } = useNavHelpers({ history, roomID });

  const isDismissable = isTestAccount(me?.email);
  const onDismiss = isDismissable
    ? () => {
        setIsOnboardingDismissedTestAccount(roomID);
        if (ReactFrameService.isMobile()) {
          ReactFrameService.instance().closePopup();
        } else {
          navigateToDefault();
        }
      }
    : undefined;
  return (
    <ResponsiveLayoutWithHeader
      safeAreaVariant="setHeightAndAddTop"
      shouldScroll={false}
      renderHeader={() => (
        <PanelHeader isMobile={isMobile} onRightPress={onDismiss} dataQa="dismissOnboarding" />
      )}
    >
      <View align="center" style={{ margin: '0 20px' }}>
        <View style={{ marginTop: 16 }}>{illustration}</View>
        <ProgressBar
          totalSteps={totalSteps}
          currentStep={currentStep}
          wrapperStyle={{ marginTop: 45 }}
        />
        <View style={{ maxWidth: 308, textAlign: 'center' }}>
          <Huge style={{ color: colors.black, marginTop: 16 }}>{title}</Huge>
          <Large style={{ marginTop: 16, color: colors.permaFiord }}>{description}</Large>
        </View>
        <Button
          text={ctaText}
          style={{ marginTop: 32, backgroundColor: colors.permaTalkspaceDarkGreen }}
          onPress={onPress}
          dataQa="primaryButton"
        />
      </View>
    </ResponsiveLayoutWithHeader>
  );
};

export default withRouter(OnboardingPage);
