import {
  User,
  LockKeyhole,
  CreditCard,
  ShieldPlus,
  CirclePlus,
  UserPen,
  BellOutline,
  MessagePlus,
  UniversalAccess,
  SquarePlus,
  CircleQuestion,
  ShieldHalved,
  ArrowRightFromBracket,
} from '@talkspace/react-toolkit/src/designSystems/icons';
import { AccountCard } from '../../types';

export const profileCards: AccountCard[] = [
  {
    titleText: 'Personal Information',
    Icon: <User size="major" colorType="brand" />,
    hideCaret: false,
    route: '/home/account/personal-information',
    dataQa: 'accountProfilePersonalInformation',
    trackUserSettingsEvent: {
      CTA: 'View Personal Information',
      source: 'Personal Information',
    },
  },
  {
    titleText: 'Login and security',
    Icon: <LockKeyhole size="major" colorType="brand" />,
    hideCaret: false,
    route: '/home/account/account-details',
    dataQa: 'accountProfileLoginSecurity',
    trackUserSettingsEvent: {
      CTA: 'View Login Security',
      source: 'Personal Information',
    },
  },
];

export const planAndCoverageCards: AccountCard[] = [
  {
    titleText: 'Payment and plan',
    bodyText: 'Check your billing details and plan information or change providers',
    Icon: <CreditCard size="major" colorType="brand" />,
    hideCaret: false,
    route: '/home/account/manage-subscription',
    dataQa: 'accountPlanPaymentAndPlan',

    trackUserSettingsEvent: {
      CTA: 'View Payment Plan',
      source: 'Personal Information',
    },
  },
  {
    titleText: 'Update coverage',
    bodyText: 'Add, change, or update insurance and EAP details or switch to self-pay ',
    Icon: <ShieldPlus size="major" colorType="brand" />,
    hideCaret: false,
    hideIfHasPendingMatchRoom: true,
    route: '/home/account/update-coverage',
    dataQa: 'accountPlanUpdateCoverage',
    trackUserSettingsEvent: {
      CTA: 'Update Coverage',
      source: 'Account Dropdown',
    },
  },
  {
    titleText: 'Add a service',
    bodyText: 'We offer therapy, psychiatry, and couples therapy',
    Icon: <CirclePlus size="major" colorType="brand" />,
    hideCaret: false,
    hideIfHasPendingMatchRoom: true,
    isAddService: true,
    dataQa: 'accountPlanAddAService',
    trackUserSettingsEvent: {
      CTA: 'Add Service',
      source: 'Account Dropdown',
    },
  },
  {
    titleText: 'Change provider',
    bodyText: "It's common to try a few providers before finding the right fit",
    Icon: <UserPen size="major" colorType="brand" />,
    hideCaret: false,
    hideIfHasPendingMatchRoom: true,
    route: '/home/account/change-provider',
    dataQa: 'accountPlanChangeProvider',
    trackUserSettingsEvent: {
      CTA: 'Change Provider',
      source: 'Account Dropdown',
    },
  },
];

export const settingsCards: AccountCard[] = [
  {
    titleText: 'Notifications',
    Icon: <BellOutline size="major" colorType="brand" />,
    hideCaret: false,
    route: '/home/account/notifications',
    dataQa: 'accountSettingsNotifications',
    trackUserSettingsEvent: {
      CTA: 'View Notifications',
      source: 'Personal Information',
    },
  },
  {
    titleText: 'Read receipts',
    bodyText: 'If you turn off read receipts, you won’t see when someone has read your message',
    Icon: <MessagePlus size="major" colorType="brand" />,
    dataQa: 'accountSettingsReadReceipts',
  },
  {
    titleText: 'High contrast mode',
    Icon: <UniversalAccess size="major" colorType="brand" />,
    dataQa: 'accountSettingsHighContrast',
  },
];

export const moreCards: AccountCard[] = [
  {
    titleText: 'Emergency resources',
    Icon: <SquarePlus size="major" colorType="brand" />,
    hideCaret: false,
    windowRoute: 'https://helpnow.talkspace.com/',
    dataQa: 'accountMoreEmergencyResources',
  },
  {
    titleText: 'Customer support',
    Icon: <CircleQuestion size="major" colorType="brand" />,
    hideCaret: false,
    route: '/home/account/customer-support',
    dataQa: 'accountMoreCustomerSupport',
    trackUserSettingsEvent: {
      CTA: 'Customer Support',
      source: 'Account Dropdown',
    },
  },
  {
    titleText: 'Privacy policy',
    Icon: <ShieldHalved size="major" colorType="brand" />,
    hideCaret: false,
    windowRoute: 'https://www.talkspace.com/public/privacy-policy',
    dataQa: 'accountMorePrivacyPolicy',
  },
  {
    titleText: 'Log out',
    Icon: <ArrowRightFromBracket size="major" colorType="brand" />,
    hideCaret: true,
    dataQa: 'accountMoreLogOut',
    isLogout: true,
  },
];
