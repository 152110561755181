import { useCallback, useMemo, useEffect } from 'react';
import moment from 'moment';
import { useAppReviewCheckStorage } from 'ts-ionic/plugins/appReview';
import {
  View,
  SpacingView,
  getSpacing,
  spacing,
  TextDS,
  Grid,
  Row,
  Col,
  GridMarginView,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { promptPushNotificationAndRegister } from 'ts-ionic/plugins/pushNotifications';
import { useIonicEffect } from 'ts-ionic/hooks';
import { isVideoMessagingWorkshopsTherapy } from 'offer/utils/constants';
import { useIsTeenspaceActive } from 'launchDarkly/hooks';
import { NAV_SHELL_MOBILE_HEADER_HEIGHT } from 'ts-frontend/constants';
import { useHistory } from '@/core/routerLib';
import styled from '@/core/styled';
import AddPsychCard from '../components/AddPsychCard';
import LiveWorkshopsCard from '../components/LiveWorkshopsCard';
import MentalHealthResourcesCard from '../components/MentalHealthResourcesCard';
import PreSessionQuestionsCard from '../components/PreSessionQuestionsCard';
import YourSupportTeamListController from '../controllers/YourSupportTeamListController';
import useNavShellData, { AggregatedRoomData } from '../../hooks/useNavShellData';
import useRedirectOnboarding from '../../hooks/useRedirectOnboarding';
import SwoopBackground from '../../containers/ClientNavShell/SwoopBackground';
import ForYouCardLoader from '../components/ForYouCardLoader';
import { trackNavPageView, trackCTAClick, trackInAppReview } from '../../utils/analytics/events';
import { useCommunityState } from '../../community/hooks/CommunityContext';
import useQueryDailyPrompt from '../../community/hooks/useQueryDailyPrompt';
import DailyPromptView from '../../community/components/DailyPromptView';
import { hasTeenspaceCommunity } from '../../community/utils/helpers';

const { space100, space500 } = spacing;

const PaddedGridMarginView = styled(GridMarginView)(({ theme: { safeAreaInsets } }) => {
  return {
    marginTop: safeAreaInsets.top,
    padding: `${getSpacing('space400', true)} 0`,
  };
});

const HomeScreen = () => {
  const history = useHistory();
  const {
    window: { isMobile },
  } = useEmotionTheme();

  useEffect(() => {
    trackNavPageView('home');
  }, []);

  useIonicEffect(() => {
    promptPushNotificationAndRegister();
  }, []);

  const onPressAddPsych = useCallback(() => {
    trackCTAClick('Find a medication manager', 'home');
    history.push('/add-new-service?serviceType=psychiatry');
  }, [history]);

  useRedirectOnboarding();
  const appReviewCallback = useCallback(() => {
    trackInAppReview();
  }, []);
  useAppReviewCheckStorage(appReviewCallback);

  const {
    queries: { isLoadingHomePage, isLoadingRoomsList },
    aggregatedRoomData: { dataByRoomID, clientInfo, subscriptions, userID },
  } = useNavShellData();
  const { firstName } = clientInfo || {};

  const isTeenspaceActive = useIsTeenspaceActive();
  const { replyCount, isLoading: isCommunityStateLoading } = useCommunityState();
  const shouldShowTeenspaceFeatures =
    isTeenspaceActive && subscriptions && hasTeenspaceCommunity(subscriptions);
  const { data: dailyPromptData } = useQueryDailyPrompt({
    clientUserID: userID,
    disabled: !shouldShowTeenspaceFeatures,
  });
  const dailyPromptSentence = dailyPromptData?.text;
  const showLiveWorkshopsCard = useMemo(
    () =>
      Object.values(dataByRoomID || {})
        .filter(Boolean)
        .some((roomData: AggregatedRoomData) => {
          const planID = roomData.subscription?.subscription?.planID;
          return (
            roomData.room?.accountType === 'dte' ||
            (planID && isVideoMessagingWorkshopsTherapy(planID))
          );
        }),
    [dataByRoomID]
  );

  const showAddPsychCard = useMemo(() => {
    const { dateOfBirth } = clientInfo || {};
    const isOlderThan18 =
      dateOfBirth && moment().subtract(18, 'years').isAfter(clientInfo?.dateOfBirth);

    const hasPsych = Object.values(dataByRoomID || {})
      .filter(Boolean)
      .some((roomData: AggregatedRoomData) => roomData.room?.roomType === 'psychiatry_room');
    return isOlderThan18 && !hasPsych;
  }, [clientInfo, dataByRoomID]);
  const isLoading = isLoadingHomePage || isLoadingRoomsList;
  return (
    <View>
      <SwoopBackground isHeader={isMobile}>
        <PaddedGridMarginView>
          <SpacingView>
            <TextDS variant="heading2xl" tabIndex={0}>
              Welcome{isLoadingHomePage ? '' : `, ${firstName}`}
            </TextDS>
            <TextDS colorRole="textSubtle" variant="body">
              We're so glad you're here
            </TextDS>
          </SpacingView>
        </PaddedGridMarginView>
      </SwoopBackground>
      <Grid fluid rowSpacing="space400">
        <Row
          style={{
            marginBottom: space500,
            ...(isMobile && { marginTop: -NAV_SHELL_MOBILE_HEADER_HEIGHT }),
          }}
        >
          {!isCommunityStateLoading && shouldShowTeenspaceFeatures && (
            <Col sm={8} lg={8}>
              <SpacingView itemSpacing="space150">
                <TextDS variant="headingLg" tabIndex={0}>
                  Teenspace Community
                </TextDS>
                <DailyPromptView sentence={dailyPromptSentence} replyCount={replyCount} />
              </SpacingView>
            </Col>
          )}
          <Col sm={8} lg={8}>
            <SpacingView itemSpacing="space200">
              <TextDS variant="headingLg" tabIndex={0}>
                Your support team
              </TextDS>
              <YourSupportTeamListController />
              {!isLoading && showAddPsychCard && (
                <AddPsychCard onPress={onPressAddPsych} dataQa="homePageCardLiveAddPsych" />
              )}
            </SpacingView>
          </Col>
          <Col sm={8} lg={4}>
            <TextDS style={{ marginBottom: space100 }} variant="headingLg" tabIndex={0}>
              For you
            </TextDS>
            {isLoading ? (
              <ForYouCardLoader />
            ) : (
              <>
                {showLiveWorkshopsCard && (
                  <LiveWorkshopsCard
                    dataQa="homePageCardLiveWorkshops"
                    onPress={() => {
                      trackCTAClick('Live workshops', 'home');
                      window.open('https://www.talkspace.com/live-workshops', '_blank');
                    }}
                  />
                )}
                <MentalHealthResourcesCard
                  dataQa="homePageCardMHResource"
                  onPress={() => {
                    trackCTAClick('Mental health resources', 'home');
                    window.open('https://www.talkspace.com/blog/', '_blank');
                  }}
                />
                <PreSessionQuestionsCard
                  dataQa="homePageCardPreSessionQuestions"
                  onPress={() => {
                    trackCTAClick('Pre-session questions', 'home');
                    window.open(
                      'https://assets.dev.talkspace.com/web-app/how_to_prepare_for_your_session.pdf',
                      '_blank'
                    );
                  }}
                />
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </View>
  );
};
export default HomeScreen;
