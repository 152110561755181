import { RouteComponentProps, withRouter } from '@/core/routerLib';

import OnboardingPage from './OnboardingPage';
import ParentalConsent from '../assets/ParentalConsent';

const OnboardingPageTeenParentalConsent = ({
  match: {
    params: { roomID },
  },
  history,
  totalSteps,
  currentStep,
}: RouteComponentProps<{ roomID: number }> & { totalSteps: number; currentStep: number }) => (
  <OnboardingPage
    totalSteps={totalSteps}
    currentStep={currentStep}
    illustration={<ParentalConsent />}
    title="Let’s complete your profile"
    description="We’ll start by guiding you through the consent process. Then we’ll ask a few more questions so you’ll be ready for therapy."
    ctaText="Start now"
    onPress={() => {
      history.push(`/room/${roomID}/onboarding/parental-consent`);
    }}
  />
);

export default withRouter(OnboardingPageTeenParentalConsent);
