/* eslint-disable react-hooks/exhaustive-deps */
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { DateRangePickerShape, ModifiersShape, SingleDatePickerShape } from 'react-dates';
import { CalendarDayPhrases } from 'react-dates/lib/defaultPhrases';
import moment from 'moment';
import styled, { EmotionStyle } from '../../../core/styled';
import View from '../../../components/View';
import { COLORS } from '../../../constants/commonStyles';
import { ArrowLeft, ArrowRight } from '../../../components/icons';
import CalendarDay, { DayColors } from './CalendarDay';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

type Single = Pick<
  SingleDatePickerShape,
  | 'date'
  | 'phrases'
  | 'numberOfMonths'
  | 'daySize'
  | 'horizontalMonthPadding'
  | 'hideKeyboardShortcutsPanel'
  | 'isOutsideRange'
  | 'isDayHighlighted'
  | 'initialVisibleMonth'
  | 'onNextMonthClick'
  | 'onPrevMonthClick'
  | 'navPrev'
  | 'navNext'
  | 'dayAriaLabelFormat'
  | 'renderCalendarDay'
  | 'onClose'
  | 'onDateChange'
>;

type Multiple = Pick<
  DateRangePickerShape,
  | 'endDate'
  | 'startDate'
  | 'phrases'
  | 'numberOfMonths'
  | 'daySize'
  | 'horizontalMonthPadding'
  | 'hideKeyboardShortcutsPanel'
  | 'isOutsideRange'
  | 'isDayHighlighted'
  | 'initialVisibleMonth'
  | 'onNextMonthClick'
  | 'onPrevMonthClick'
  | 'navPrev'
  | 'navNext'
  | 'dayAriaLabelFormat'
  | 'renderCalendarDay'
  | 'onClose'
  | 'onDatesChange'
>;

export interface BaseDatePickerProps {
  /**
   * If true, non-highlighted days when selected will be grey, and selected highlighted
   * days will be green.
   */
  selectHighlighted?: boolean;
  topOffset?: number;
  weekdaysHeaderHeight?: number;
  monthHeaderHeight?: number;
  nonDaysCalendarHeight?: number;
  calendarDayHeight?: number;
  calendarDayWidth?: number;
  containerStyle?: EmotionStyle;
  isSingle: boolean;
  /**
   * Similar to onDateChange/onDatesChange, but does not perform checks against duplicate calls of the function with the same input
   */
  onEveryChange?: (date: {
    startDate?: moment.Moment | null;
    endDate?: moment.Moment | null;
    date?: moment.Moment | null;
  }) => void;

  minDate?: moment.Moment | null;
  maxDate?: moment.Moment | null;

  isCentered?: boolean;
  shouldRemoveArrow?: boolean;
  inputStyles?: EmotionStyle;
  focusStyle?: EmotionStyle;
  disabledStyle?: EmotionStyle;
  primaryColor?: string;
  secondaryColor?: string;
  inputLabelStyle?: EmotionStyle;
  arrowStyle?: EmotionStyle;
}

interface UseDatePickerReturn<Props> {
  state: {
    topOffset: number;
    currentMonth: moment.Moment;
    containerHeight: number;
  };
  dateProps: Omit<Props, keyof BaseDatePickerProps>;
}

const isKeyboardEvent = (e: SyntheticEvent | UIEvent): e is KeyboardEvent => 'key' in e;

const onNavPress =
  (
    currentMonth: moment.Moment,
    setCurrentMonth: (d: moment.Moment) => void,
    canPerformAction: boolean,
    forward: boolean
  ) =>
  (e: SyntheticEvent | UIEvent) => {
    // If KeyUp and not Enter | Space
    if (isKeyboardEvent(e) && !(e.key === 'Enter' || e.key === ' ')) {
      return;
    }
    // Do nothing but also prevent event from reaching react-date's handlers
    if (!canPerformAction) {
      e.stopPropagation();
      return;
    }
    setCurrentMonth(currentMonth.clone().add(forward ? 1 : -1, 'month'));
  };

const CalendarArrowContainer = styled(View)<{ isPrevious: boolean; topOffset?: number }>(
  ({ isPrevious, topOffset = 0 }) => {
    return {
      position: 'absolute',
      top: topOffset + 5,
      left: isPrevious ? 65 : undefined,
      right: !isPrevious ? 65 : undefined,
    };
  }
);

function calcWeeksInMonth(date: moment.Moment | null) {
  if (!date) return 4; // Return some default value
  const dateFirst = moment(date).date(1);
  const dateLast = moment(date).date(date.daysInMonth());
  const startWeek = dateFirst.week();
  const endWeek = dateLast.week();
  if (endWeek < startWeek) {
    return dateFirst.weeksInYear() - startWeek + 1 + endWeek;
  }
  return endWeek - startWeek + 1;
}

export const defaultAriaLabelFormat = 'dddd, MMMM DD';

function getPropsPerMode(props: Single | Multiple) {
  let single: Partial<Pick<Single, 'date' | 'onDateChange'>> = {};
  let multiple: Partial<Pick<Multiple, 'startDate' | 'endDate' | 'onDatesChange'>> = {};
  if ('date' in props) single = { date: props.date, onDateChange: props.onDateChange };
  else
    multiple = {
      startDate: props.startDate,
      endDate: props.endDate,
      onDatesChange: props.onDatesChange,
    };
  return {
    single,
    multiple,
  };
}

function getDateChangeHandlers(
  props: Single | Multiple
): Partial<Pick<Single, 'onDateChange'> & Pick<Multiple, 'onDatesChange'>> {
  const result: Partial<Pick<Single, 'onDateChange'> & Pick<Multiple, 'onDatesChange'>> = {};
  if ('onDateChange' in props) {
    result.onDateChange = props.onDateChange;
  }
  if ('onDatesChange' in props) {
    result.onDatesChange = props.onDatesChange;
  }
  return result;
}

function useDatePicker<T extends Single | Multiple>(
  props: T & BaseDatePickerProps
): UseDatePickerReturn<T> {
  const {
    minDate,
    maxDate,
    isCentered,
    isSingle,
    containerStyle,
    selectHighlighted,
    topOffset = 0,
    weekdaysHeaderHeight = 16 + 20 + topOffset, // Container height: 16, topMargin: 20
    monthHeaderHeight = 23,
    nonDaysCalendarHeight = weekdaysHeaderHeight + monthHeaderHeight + topOffset,
    calendarDayHeight = 36,
    calendarDayWidth = 51,
    onEveryChange,
    shouldRemoveArrow,
    inputStyles,
    focusStyle,
    disabledStyle,
    primaryColor = COLORS.permaTalkspaceDarkGreen,
    secondaryColor = COLORS.aquaSpring,
    inputLabelStyle,
    arrowStyle,
    ...otherProps
  } = props;
  const {
    onNextMonthClick,
    onPrevMonthClick,
    initialVisibleMonth,
    numberOfMonths = 1,
    // Add a bit of padding, ideally this should be 0 but
    // if there are multiple months showing, the focus outline
    // overlaps if there is no horizontal padding
    horizontalMonthPadding = 2,
    phrases = CalendarDayPhrases,
    hideKeyboardShortcutsPanel = true,
    dayAriaLabelFormat = defaultAriaLabelFormat,
  } = otherProps;
  const { single, multiple } = getPropsPerMode(props);
  const date = single.date || multiple.startDate;
  const [currentMonth, setCurrentMonth] = useState(
    () => initialVisibleMonth?.() || date || moment()
  );

  // Use memo over state because we want this value to be updated in the least re-renders possible
  const containerHeight = useMemo(
    () => calendarDayHeight * calcWeeksInMonth(currentMonth) + nonDaysCalendarHeight,
    [calendarDayHeight, currentMonth, nonDaysCalendarHeight]
  );

  const getDayColors = useCallback(
    (day: moment.Moment, modifiers: ModifiersShape): DayColors => {
      // Colors
      const activeSelectedColor: DayColors = {
        background: primaryColor,
        text: 'standardWhite',
      };
      // Selected but grey color
      const selectedButDisabledColor: DayColors = {
        background: COLORS.darkerPeriwinkleGrey,
        text: 'standardWhite',
      };
      const highlightedColor: DayColors = {
        background: secondaryColor,
        text: 'standardGreen',
      };
      const blockedColor: DayColors = { background: 'transparent', text: 'standardLightGrey' };
      const normalColor: DayColors = { background: 'transparent', text: 'standardBlack' };
      const invalidColor: DayColors = { background: 'transparent', text: 'standardLightGrey' };
      // Definitions
      const isSelected =
        modifiers.has('selected') ||
        modifiers.has('selected-start') ||
        modifiers.has('selected-end');
      const isBlocked = modifiers.has('blocked');
      const isHovered = modifiers.has('hovered') || modifiers.has('hovered-span');
      const isWithinSelectedRange = modifiers.has('selected-span');
      const isHighlighted = modifiers.has('highlighted-calendar');
      const isValid = modifiers.has('valid');
      // Conditions
      if (isHighlighted) {
        if (isSelected) return activeSelectedColor;
        return highlightedColor;
      }
      if (isSelected) return selectHighlighted ? activeSelectedColor : selectedButDisabledColor;
      if (isWithinSelectedRange) return highlightedColor;
      if (isBlocked) return blockedColor;
      if (isHovered) return highlightedColor;
      if (isValid) return normalColor;
      return invalidColor;
    },
    [primaryColor, COLORS.darkerPeriwinkleGrey, secondaryColor, selectHighlighted]
  );

  const isOutsideRange = useCallback(
    (day: moment.Moment) => {
      if (minDate && day.isBefore(minDate)) return true;
      if (maxDate && day.isAfter(maxDate)) return true;
      // Only disable past dates by default on Date Range pickers
      if (!isSingle && !minDate && !maxDate) return day.isBefore(moment().startOf('day'));
      return false;
    },
    [isSingle, maxDate, minDate]
  );
  const canGoBack = useMemo(
    () => !isOutsideRange(currentMonth.clone().subtract(1, 'month').endOf('month')),
    [currentMonth, isOutsideRange]
  );
  const canGoForward = useMemo(
    () => !isOutsideRange(currentMonth.clone().add(1, 'month').startOf('month')),
    [currentMonth, isOutsideRange]
  );
  const onNavBackPress = useCallback(onNavPress(currentMonth, setCurrentMonth, canGoBack, false), [
    currentMonth,
    canGoBack,
  ]);
  const onNavForwardPress = useCallback(
    onNavPress(currentMonth, setCurrentMonth, canGoForward, true),
    [currentMonth, canGoForward]
  );

  const onClose = useCallback(
    (args: unknown) => {
      if ('date' in (args as Record<'date', moment.Moment | null>)) {
        setCurrentMonth((args as Record<'date', moment.Moment | null>).date || date || moment());
      } else {
        setCurrentMonth(
          (args as Record<'startDate' | 'endDate', moment.Moment | null>).endDate ||
            (args as Record<'startDate' | 'endDate', moment.Moment | null>).startDate ||
            date ||
            moment()
        );
      }
    },
    [date]
  );

  const finalOnDateChangeProps: Partial<ReturnType<typeof getDateChangeHandlers>> = useMemo(() => {
    if (isSingle && (single.onDateChange || onEveryChange)) {
      return {
        onDateChange: (newDate) => {
          if (newDate !== single.date) single.onDateChange?.(newDate);
          onEveryChange?.({ date: newDate });
        },
      };
    }
    if (multiple.onDatesChange || onEveryChange) {
      return {
        onDatesChange: (newDates) => {
          const { startDate: newStartDate, endDate: newEndDate } = newDates;
          if (multiple.startDate !== newStartDate || multiple.endDate !== newEndDate)
            multiple.onDatesChange?.(newDates);
          onEveryChange?.(newDates);
        },
      };
    }
    return {};
  }, [
    isSingle,
    multiple.endDate,
    multiple.onDatesChange,
    multiple.startDate,
    onEveryChange,
    single.date,
    single.onDateChange,
  ]);

  return {
    state: {
      topOffset,
      currentMonth,
      containerHeight,
    },
    dateProps: {
      ...otherProps,
      ...finalOnDateChangeProps,
      phrases,
      numberOfMonths,
      isOutsideRange,
      dayAriaLabelFormat,
      horizontalMonthPadding,
      hideKeyboardShortcutsPanel,

      // Doesn't work on SingleDatePickers, had to implement manually
      // https://github.com/airbnb/react-dates/commit/927fb93091f84393301b857bdb8b9d7c4aa00081
      ...(!isSingle && {
        minDate,
        maxDate,
      }),

      onClose,
      initialVisibleMonth: date ? () => date : null,
      daySize: calendarDayWidth,
      navPrev: (
        <CalendarArrowContainer
          // Add clickHander directly to arrow to avoid delay in transition.
          onClick={onNavBackPress}
          onKeyUp={onNavBackPress}
          topOffset={topOffset}
          tabIndex={0}
          isPrevious
        >
          <ArrowLeft
            color={canGoBack ? primaryColor : COLORS.periwinkleGrey}
            width={10}
            height={17}
          />
        </CalendarArrowContainer>
      ),
      navNext: (
        <CalendarArrowContainer
          onClick={onNavForwardPress}
          onKeyUp={onNavForwardPress}
          topOffset={topOffset}
          tabIndex={0}
          isPrevious={false}
        >
          <ArrowRight
            color={canGoForward ? primaryColor : COLORS.periwinkleGrey}
            width={10}
            height={17}
          />
        </CalendarArrowContainer>
      ),
      onNextMonthClick: (d) => {
        // Guarantees `currentMonth` is in sync with the library's internal state
        setCurrentMonth(d);
        onNextMonthClick?.(d);
      },
      onPrevMonthClick: (d) => {
        setCurrentMonth(d);
        onPrevMonthClick?.(d);
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // This field exists, but it's untyped, it's a number from 1 to 7 (weekdays)
      renderCalendarDay: ({ key, day, ...innerProps }) => (
        <CalendarDay
          day={day}
          calendarDayHeight={calendarDayHeight}
          key={(key || day?.toISOString()) ?? 0}
          getDayColors={getDayColors}
          {...innerProps}
        />
      ),
    },
  };
}

export default useDatePicker;
