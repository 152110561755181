import type { EmotionStyle } from '../core/styled';

// TODO: apply naming pattern to all colors
// a11y prefix for colors that toggle to darker/different shade in high contrast mode (A11Y_COLORS)
// perma prefix for colors that stay the same in both contrast modes
// Text suffix for colors where the usual mapping of the color in A11Y_COLORS doesn't provide sufficient contrast with the background and thus requires something different

// green: the standard talkspace green used for buttons, user chat bubbles, green svgs/icons, input caret etc.
// slateGrey: grey text, placeholders
// periwinkleGrey: grey icons, disabled/secondary buttons, input outlines
export const COLORS = {
  mirage: '#131C29',
  permaCatskillWhiteGreen: '#F9FCFC',
  permaRed: '#FF0000',
  bermuda: '#7BD8D0',
  bahamaBlue: '#23527C',
  cyanBlue: '#337AB7',
  lightShadeGray: '#DDDDDD',
  whisperGray: '#E6E6E6',
  permaFauxSilver: '#ADADAD',
  indigo: '#6159D1',
  pacificBlue: '#00AAC9',
  trueBlack: '#000',
  black: '#222F2D',
  gunPowder: '#444960',
  grey: '#65727D',
  greyText: '#65727D',
  bermudaGray: '#6F84A8',
  dustyGrey: '#999999',
  emperor: '#555555',
  a11yConiferGreen: '#ADE953',
  permaConiferGreen: '#ADE953',
  // lightGrey: '#979797',
  a11yLinkWaterGrey: '#EFF3FA',
  permaLinkWaterGrey: '#EFF3FA',
  iconLightGrey: '#D2D8E3',
  a11yExtraLighterGrey: '#E3E8F2',
  extraLightGrey: '#D8D8E0',
  permaSolicitudeGrey: '#EBEEF2',
  permaLightGrey: '#D8D8E0',
  placeholderGrey: '#98A6B2',
  greyBubble: '#EFF3FA',
  greyBubbleTapped: '#E2E8F1',
  whiteSmoke: '#F2F2F2',
  charcoal: '#4F546A',
  white: '#fff',
  green: '#0FC1A7',
  darkGreen: '#09B198',
  greenText: '#09B198',
  permaGreenIndicator: '#54E54C',
  therapistLink: '#0FC1A7',
  clientLink: '#00574F',
  pink: '#E8006B',
  red: '#FF0050',
  permaRibbonRed: '#E10047',
  jellybeanRed: '#DC4F4F',
  heartRed: '#FF0075',
  rose: '#f90062',
  orange: '#EA9436',
  blazeOrange: '#FF6A00',
  lightPurple: '#8EA8FD',
  purple: '#5C84FB',
  permaMalibu: '#8EA8FD',
  darkPurple: '#426FF9',
  darkPurpleBubble: '#426FF9',
  slateGrey: '#68768D',
  periwinkleGrey: '#BECBE0',
  periwinkleGreyText: '#BECBE0',
  darkerPeriwinkleGrey: '#BECBE0', // Darker version of periwinkleGrey on high contrast mode
  cometGrey: '#576074',
  rainforestGreen: '#006A61',
  torchRed: '#FF0050',
  baliHaiGrey: '#8A98AF',
  altoGrey: '#D8D8D8',
  permaFernGreen: '#618340',
  limaGreen: '#59C123',
  a11yVidaLocaGreen: '#54BA1F',
  Limeade: '#47A203',
  ironGrey: '#D1D6D9',
  neptuneGreen: '#73C0AD',
  shadowGreen: '#8DC7B6',
  yellowSea: '#F0AF00',
  yellowSun: '#EB9437',
  razzmatazz: '#DD0561',
  edgewater: '#C1DFDB',
  swansDown: '#D9EEEB',
  osloGrey: '#7D8993',
  athensGrey: '#FCFCFD',
  permaOlympusGrey: '#FCFCFC',
  gossamerGreen: '#099586',
  mintGreen: '#B4F4CE',
  heatherGrey: '#BEC7D7',
  amber: '#FDBF00',
  emerald: '#4CD964',
  a11yChaosEmerald: '#00CB1E',
  bayouxBlue: '#4D5D76',
  loblollyGrey: '#BCC4CE',
  pippinPink: '#FFE6DF',
  cobalt: '#084BA9',
  candleLight: '#FFD418',
  cornflowerBlue: '#579DF4',
  surfieGreen: '#107B81',
  persianGreen: '#009CA3',
  zigguratGreen: '#B8DBD7',
  permaZiggurat: '#C0E3E0',
  permaHedgeGreen: '#00A919',
  blueHaze: '#C7D1DE',
  gold: '#FF9F00',
  fordGrey: '#979797',
  fordGreyText: '#979797',
  lightFordGrey: '#9797A2',
  lightGreen: '#e4f3f1',
  promoGreen: '#6bc73b',
  permaLightBlueStone: '#6BA99F',
  permaBlueStone: '#006259',
  gray: '#7d8993',
  veryLightGray: '#e0e0e0',
  transparentLightGray: '#e0e0e080',
  lightGray: '#bcc7d0',
  darkGray: '#454a5e',
  veryDarkGray: '#404040',
  patensBlue: '#CFEAFF',
  chineseSilver: '#ccc',
  welcomeBlue: '#5C98FB',
  prezi: '#318BFF',
  cobaltGrey: '#96A2B8',
  lightCobaltGrey: '#93A1BA',
  tropicalGreen: '#3CC785',
  earthBlue: '#327494',
  conifer: '#B3EB50',
  laPalma: '#469723',
  pigeonPost: '#B5C4DA',
  goldenTanoi: '#FFC361',
  a11yLavendarBlue: '#BCCCFF',
  permaLavenderBlue: '#BCCCFF',
  mosque: '#00574F',
  flamingo: '#E4584F',
  chambray: '#3B5998',
  waikawaGray: '#5E7091',
  pictonBlue: '#55ACEE',
  regentGray: '#7D89A3',
  apple: '#5CB82C',
  rebeccaPurple: '#3768F9',
  valerianPurple: '#9EB5FF',
  shocking: '#e597b5',
  spindle: '#C6CBF1',
  dropdownOptionBorder: 'transparent',
  permaWhiteSmoke: '#F5F7FA',
  permaGhostWhite: '#F9FAFB',
  permaPhantomWhite: '#FDFDFF',
  permaMintGreen: '#D4FFEB',
  bannerBlue: '#0D2DA0',
  aquaSqueeze: '#E1F0EE',
  blueStone: '#005368',
  bizarre: '#F1DDDD',
  redDamask: '#DC684F',
  catskillWhite: '#E1E5F0',
  balanceBlue: '#B9DDFF',
  optimistOrange: '#F2734A',
  matisse: '#1F4B95',
  a11yRoyalBlue: '#5C84FB',
  permaDarkRoyalBlue: '#2653E8',
  permaBrightRed: '#B2001A',
  aquaSpring: '#E7F8F6',
  permaMercury: '#E5E5E5',
  permaWildBlueYonder: '#758AAE',
  boulder: '#747474',
  violetRed: '#F22988',
  permaMystic: '#E3E9ED',
  permaTalkspaceDarkGreen: '#007E73',
  permaBaliHai: '#8998B0',
  permaLightSlateGray: '#8392AC',
  permaTropicalRainForest: '#006A61',
  permaPeriwinkle: '#CBD7FF',
  permaSmalt: '#012B9E',
  permaErrorRed: '#D30034',
  permaWaikawaGrey: '#596e92',
  permaProvincialPink: '#fde9e9',
  permaBlueBayoux: '#4D6386',
  permaAthensGray: '#F2F2F3',
  permaRiverBed: '#454A5E',
  permaViridianGreen: '#009B8D',
  permaPhilippineSilver: '#B8B8B8', // not accessible (used in therapist-web, OKed by Kris)
  permaMetallicSilver: '#A7ACAB', // not accessible (used in therapist-web, OKed by Kris)
  permaDebianRed: '#D30957',
  permaAntiFlashWhite: '#F3F4F2',
  permaGainsboro: '#D8D8E2',
  permaMysticTwo: '#DFE3EB',
  permaPowderBlue: '#DDE3EA',
  permaSilverSand: '#C4C4C4',
  permaCatSkillWhite: '#EBF3F5',
  permaPanache: '#EAF6F0',
  permaCornflowerBlue: '#4772F2',
  permaHawkesBlue: '#E1ECFD',
  permaDesertStorm: '#F9F9F7',
  permaGrayForContrastOnWhiteBG: '#767676',
  a11yMystic: '#E3E9ED',
  permaGullGray: '#99A6B2',
  permaPrussianBlue: '#002841',
  permaLividBlue: '#7284A0',
  permaMischka: '#DBDBE0',
  jadeGreen: '#0B867A',
  permaBrightGray: '#E4EAED',
  permaLightPeriwinkle: '#BDCADF',
  permaSummerCloud: '#E0E8F3',
  permaSummerSky: '#EAEFFC',
  permaMercuryJunior: '#E1E1E1',
  permaTeal: '#008579',
  permaDarkCyan: '#058076',
  permaDarkForest: '#005E63',
  permaGrayishWhite: '#E8EDF0',
  permaTSWhiteSmoke: '#F5F5F5',
  permaBlueDianne: '#233C51',
  a11yWildBlueYonder: '#BECBE0',
  permaJapaneseLaurel: '#008A09',
  permaFunGreen: '#00A919',
  permaAquaHaze: '#EBF3F2',
  permaFlyAKite: '#D5DEF7',
  permaRainbowFish: '#3B67ED',
  permaEden: '#175C62',
  permaOysterBay: '#D9E9E7',
  permaCadetGrey: '#A4AEBF',
  permaTarawera: '#063855',
  permaMoonStone: '#4C9DB6',
  permaAmericanGreen: '#3BAF3F',
  permaBlueViolet: '#9940F5',
  permaClearDay: '#F5FFFB',
  permaGreyed: '#F4F6FA',
  permaPigeonPost: '#B0C1DE',
  permaMagicMint: '#ACE8D2',
  permaBotanicalGreen: '#507669',
  permaEucalyptus: '#42D791',
  permaPastelPurple: '#A78FD6',
  permaMagnolia: '#F6EAFF',
  permaLavender: '#715A9D',
  permaRhythmPurple: '#806CA6',
  permaGrape: '#5122AD',
  permaDeepViolet: '#220063',
  permaOceanGreen: '#52B68E',
  permaSeaGreen: '#40A27B',
  permaKelpGreen: '#3FA27B',
  permaSolitude: '#ECF1F7',
  permaOuterSpace: '#496470',
  permaVeryDarkCyan: '#005E63',
  permaWhisperBlueGrey: '#F5F7FC',
  permaSubtitleBlue: '#445B7B',
  a11yParentheticalBlue: '#64768F',
  permaCharcoal: '#546074',
  permaSelago: '#EEDCFC',
  permaEastBay: '#405777',
  permaHighlightGreen: '#79D94C',
  permaBotticelli: '#D3DFEA',
  permaBlueStoneNew: '#005C65',
  permaPineGreen: '#007070',
  permaVictoria: '#414A91',
  permaLividBlueNew: '#717F9D',
  permaWaikawaGreyNew: '#5D6D8F',
  permaJapaneseMaple: '#750007',
  permaSoftPeach: '#F9F4F4',
  permaCometGray: '#4D546B',
  permaFlowerBlue: '#D0EBDE',
  permaSchiavaBlue: '#172862',
  permaLinkWater: '#E8EFFA',
  permaWaikawaGreyDark: '#596E92',
  permaSanJuan: '#32486C',
  permaLividLight: '#657492',
  permaPickledBluewood: '#314D5C',
  permaElephant: '#103e42',
  permaLividBlueOpaque: '#717F9D4D',
  permaSolitudeNew: '#E1ECFE',
  permaCasal: '#2E5C60',
  permaZircon: '#FAFCFF',
  permaRhino: '#27325A',
  permaFuchsia: '#CF005F',
  permaSwansDown: '#DDEEEB',
  permaMediumPurple: '#7167E4',
  permaRedOrange: '#F84135',
  permaCeruleanBlue: '#3354BD',
  permaAquaHazeHighlighter: '#E6F2F1',
  pantoneBlue: '#0022BC',
  blueMagneta: '#F3ECF9',
  permaAmethyst: '#A140D4',
  aqua: '#007697',
  accessibilityGreenDark: '#005C65',
  accessibilityDarkGreenBold: '#173F4B',
  permaPersianGreen: '#009789',
  permaFiord: '#434B64',
  permaOuterSpaceNew: '#222F2D',
  permaMischkaNew: '#D1D6E1',
  permaFeta: '#F1FCF1',
  permaCornflowerBlueNew: '#EBF7FF',
  permaTitanWhite: '#EEEDFF',
  darkBlue: '#5F7A9B',
  whiteLilac: '#EEF1F8',
  grey950: '#434B64',
  permaCornflowerBlueSolid: '#D7DEF3',
  permaPeriwinkleGray: '#BECBE0',
  permaContessa: '#C26E5E',
  permaSlateGrayApprox: '#68768D',
  permaFog: '#E6D3FF',
  permaBlueStoneApprox: '#006A6D',
  permaHalfBaked: '#8AC9C4',
  permaJetStream: '#B1D6D1',
  permaValencia: '#CE4129',
  permaCopper: '#C46F31',
  permaCloudBurst: '#263159',
  permaLinkWaterApprox: '#F2F5FC',
  permaWhite: '#FDFDFD',
  permaCornflowerBlueApprox: '#DEDCFF',
  permaMoonRaker: '#D0CDF8',
  permaSelagoApprox: '#E9EDFB',
  permaComet: '#575F7A',
  permaBaliHaiApprox: '#8095AD',
  permaCannonPink: '#864A56',
  permaPersianGreenApprox: '#009B8E',
  permaEbb: '#F0ECEC',
  permaWafer: '#E0D3CC',
  permaBizarre: '#EEDFD9',
  permaEbbApprox: '#E7DADA',
  permaAlabaster: '#F9F9F9',
  permaCadetBlue: '#AEB8C8',
  permaDustyGray: '#979797',
  permaLondonGray: '#D1D6E1',
  permaGrayLike: '#73788C',
  permaWildBlueYonderAprox: '#758AAE',
  permaAtlantis: '#99D234',
  permaJapaneseLaurelApprox: '#2E8B05',
  permaSeaPink: '#ECAFA3',
  permaSkeptic: '#CBECD5',
  permaTusk: '#ECF2C2',
  permaChelseaCucumber: '#75A14A',
  permaCello: '#205266',
  permaOrinoco: '#F2FBCD',
  permaFlamePea: '#E15830',
  permaAtlantisNew: '#9FBC2A',
  permaBaliHaiGrey: '#8A98AF',
  purple600: '#454A5E',
  blueberry: '#8973ff',
  blueLagoon: '#008891',
  grey600: '#E2E5EC',
  TSBlack: '#222F2D',
  darkBlue95: '#E1E5F0',
  darkBlue150: '#BECBE0',
  aliceBlue: '#F6F8FB',
  dimGray: '#7F8DA4',
  aqua500: '#60B0BB',
  crystalFalls: '#E1E8F2',
  shayCandela: '#d6dde6',
  geyser: '#C8D2DE',
  dune: '#4E4B46',
  salmonFresco: '#FCBAAC',
  aquaMarine: '#60b0bb',
  optimumBlue: '#DEE3EC',
  cinnabar: '#3C2FE0',
  ultramarine: '#170BAA',
  gullGray: '#99A6BB',
  slateBlueGray: '#828DA1',
  permaPigeonBlue: '#65778F',
  blueBayoux: '#4C5C78',
  ceruleanBlue: '#3B54C6',
  lightSteelBlue: '#DDE3EA',
  snowDrift: '#F9FAFB',
  independence: '#546074',
  deepTeal: '#007697 ',
  paleSilver: '#DDE3EA',
  babyBlue: '#E5EAFA',
  lavendarBlush: '#FBFBFF',
  aqua800: '#003E4C',
  forestGreen: '#3C8703',
  paleGreen: '#C8E4B4',
  permaGrey: '#454A5F',
  darkPurple500: '#3F3360',
  lightAccessableGreen: '#37720B',
  pastelGray: '#C5DBB4',
  alabaster: '#ECF3E6',
  paleBluGray: '#B8C1D1',
  softPink: '#FDE9E9',
  softGray: '#A1ACBF',
  grayishBlue: '#F0F2F7',
  iceBlue: '#E8F8FC',
  interactiveDefaultGreen: '#007A85',
  interactiveHoveredGreen: '#006B75',
  darkTeal: '#004654',
  lightLavender: '#E9ECF5',
  transparent: 'rgba(0, 0, 0, 0)',
  brandLogo: '#2D5B61',
  successGreen: '#3C7600',
};

export const A11Y_COLORS: typeof COLORS = {
  mirage: '#131C29',
  permaCatskillWhiteGreen: '#F9FCFC',
  permaRed: '#FF0000',
  bermuda: '#7BD8D0',
  bahamaBlue: '#23527C',
  cyanBlue: '#337AB7',
  lightShadeGray: '#DDDDDD',
  whisperGray: '#E6E6E6',
  permaFauxSilver: '#ADADAD',
  indigo: '#6159D1',
  pacificBlue: '#00AAC9',
  trueBlack: '#000',
  permaLightBlueStone: '#6BA99F',
  permaBlueStone: '#006259',
  green: '#007E73',
  darkGreen: '#00574F',
  greenText: '#007E73',
  permaGreenIndicator: '#54E54C',
  bermudaGray: '#6F84A8',
  dustyGrey: '#999999',
  emperor: '#555555',
  slateGrey: '#596E92',
  periwinkleGrey: '#758AAE',
  periwinkleGreyText: '#57626b',
  darkerPeriwinkleGrey: '#596E92', // Darker version of periwinkleGrey on high contrast mode
  black: '#222F2D',
  gunPowder: '#444960', // TODO: @gabikashani verify this color is accessible
  grey: '#65727D',
  greyText: '#596E92',
  a11yExtraLighterGrey: '#596E92',
  extraLightGrey: '#596E92',
  permaSolicitudeGrey: '#EBEEF2',
  placeholderGrey: '#596E92',
  iconLightGrey: '#758AAE',
  a11yConiferGreen: '#007E73',
  permaConiferGreen: '#ADE953',
  charcoal: '#596E92',
  white: '#fff',
  whiteSmoke: '#758AAE',
  pink: '#D30043',
  red: '#D30043',
  permaRibbonRed: '#E10047',
  jellybeanRed: '#D30043',
  heartRed: '#D30043',
  orange: '#EA9436',
  blazeOrange: '#F56600',
  lightPurple: '#8EA8FD',
  purple: '#426FF9',
  permaMalibu: '#8EA8FD',
  darkPurple: '#426FF9',
  darkPurpleBubble: '#7695f9',
  a11yLinkWaterGrey: '#758AAE',
  permaLinkWaterGrey: '#EFF3FA',
  gossamerGreen: '#007E73',
  mintGreen: '#B4F4CE',
  heatherGrey: '#596E92',
  amber: '#EA6F00',
  emerald: '#007E73',
  a11yChaosEmerald: '#007E73',
  bayouxBlue: '#4D5D76',
  cometGrey: '#596E92',
  loblollyGrey: '#596E92',
  pippinPink: '#FFE6DF',
  cobalt: '#084BA9',
  athensGrey: '#FCFCFD',
  permaOlympusGrey: '#FCFCFC',
  candleLight: '#EA6F00',
  cornflowerBlue: '#3170DA',
  surfieGreen: '#107B81',
  persianGreen: '#007E73',
  zigguratGreen: '#007E73',
  permaZiggurat: '#C0E3E0',
  neptuneGreen: '#007E73',
  permaHedgeGreen: '#00A919',
  osloGrey: '#596E92',
  blueHaze: '#596E92',
  // lightGrey: '#979797',
  rainforestGreen: '#00574F',
  torchRed: '#D30043',
  baliHaiGrey: '#596E92',
  altoGrey: '#596E92',
  limaGreen: '#00574F',
  permaFernGreen: '#618340',
  a11yVidaLocaGreen: '#47A203',
  Limeade: '#47A203',
  ironGrey: '#596E92',
  shadowGreen: '#8DC7B6',
  yellowSea: '#976F00',
  yellowSun: '#976F00',
  razzmatazz: '#D30043',
  edgewater: '#C1DFDB',
  swansDown: '#D9EEEB',
  gold: '#EA6F00',
  fordGrey: '#758AAE',
  fordGreyText: '#4f5d72',
  lightFordGrey: '#758AAE',
  lightGreen: '#e4f3f1', // TODO: Currently using same value as COLORS. Confirm value for accessibility
  promoGreen: '#007E73',
  gray: '#596E92',
  veryLightGray: '#e0e0e0', // TODO: @gabikashani verify this color is accessible
  transparentLightGray: '#e0e0e080', // TODO: @gabikashani verify this color is accessible
  lightGray: '#596E92',
  darkGray: '#454a5e',
  veryDarkGray: '#404040', // TODO: @gabikashani verify this color is accessible
  patensBlue: '#edf6fd',
  chineseSilver: '#596E92',
  welcomeBlue: '#3170DA',
  prezi: '#002366',
  cobaltGrey: '#596E92',
  lightCobaltGrey: '#596E92',
  tropicalGreen: '#007E73',
  earthBlue: '#3170DA',
  conifer: '#007E73',
  laPalma: '#00574F',
  pigeonPost: '#596E92',
  goldenTanoi: '#EA6F00',
  a11yLavendarBlue: '#3170DA',
  permaLavenderBlue: '#BCCCFF',
  mosque: '#00574F',
  flamingo: '#D30043',
  chambray: '#3170DA',
  waikawaGray: '#5E7091',
  pictonBlue: '#3170DA',
  regentGray: '#596E92',
  apple: '#007E73',
  rose: '#f90062',
  permaLightGrey: '#D8D8E0',
  greyBubble: '#596E92',
  greyBubbleTapped: '#5E7091',
  rebeccaPurple: '#002366',
  valerianPurple: '#3170DA',
  shocking: '#D30043',
  spindle: '#C6CBF1',
  dropdownOptionBorder: '#596E92',
  permaWhiteSmoke: '#F5F7FA',
  permaGhostWhite: '#F9FAFB',
  permaPhantomWhite: '#FDFDFF',
  permaMintGreen: '#D4FFEB',
  bannerBlue: '#0D2DA0',
  aquaSqueeze: '#E1F0EE',
  blueStone: '#005368',
  bizarre: '#F1DDDD',
  redDamask: '#DC684F',
  catskillWhite: '#E1E5F0',
  balanceBlue: '#B9DDFF',
  optimistOrange: '#F2734A',
  matisse: '#1F4B95',
  therapistLink: '#fff',
  clientLink: '#fff',
  a11yRoyalBlue: '#476DEC',
  permaDarkRoyalBlue: '#2653E8',
  permaBrightRed: '#B2001A',
  aquaSpring: '#E7F8F6',
  permaMercury: '#E5E5E5',
  permaWildBlueYonder: '#758AAE',
  boulder: '#747474',
  violetRed: '#F22988',
  permaMystic: '#E3E9ED',
  permaTalkspaceDarkGreen: '#007E73',
  permaBaliHai: '#8998B0',
  permaLightSlateGray: '#8392AC',
  permaTropicalRainForest: '#006A61',
  permaPeriwinkle: '#CBD7FF',
  permaSmalt: '#012B9E',
  permaErrorRed: '#D30034',
  permaWaikawaGrey: '#596e92',
  permaProvincialPink: '#fde9e9',
  permaBlueBayoux: '#4D6386',
  permaAthensGray: '#F2F2F3',
  permaRiverBed: '#454A5E',
  permaViridianGreen: '#009B8D',
  permaPhilippineSilver: '#B8B8B8', // not accessible (used in therapist-web, OKed by Kris)
  permaMetallicSilver: '#A7ACAB', // not accessible (used in therapist-web, OKed by Kris)
  permaDebianRed: '#D30957',
  permaAntiFlashWhite: '#F3F4F2',
  permaGainsboro: '#D8D8E2',
  permaMysticTwo: '#DFE3EB',
  permaPowderBlue: '#DDE3EA',
  permaSilverSand: '#C4C4C4',
  permaCatSkillWhite: '#EBF3F5',
  permaPanache: '#EAF6F0',
  permaCornflowerBlue: '#4772F2',
  permaHawkesBlue: '#E1ECFD',
  permaDesertStorm: '#F9F9F7',
  permaGrayForContrastOnWhiteBG: '#767676',
  a11yMystic: '#596e92',
  permaGullGray: '#99A6B2',
  permaPrussianBlue: '#002841',
  permaLividBlue: '#7284A0',
  permaMischka: '#DBDBE0',
  jadeGreen: '#0B867A',
  permaBrightGray: '#E4EAED',
  permaLightPeriwinkle: '#BDCADF',
  permaSummerCloud: '#E0E8F3',
  permaSummerSky: '#EAEFFC',
  permaMercuryJunior: '#E1E1E1',
  permaTeal: '#008579',
  permaDarkCyan: '#058076',
  permaDarkForest: '#005E63',
  permaGrayishWhite: '#E8EDF0',
  permaTSWhiteSmoke: '#F5F5F5',
  permaBlueDianne: '#233C51',
  a11yWildBlueYonder: '#758AAE',
  permaJapaneseLaurel: '#008A09',
  permaFunGreen: '#00A919',
  permaAquaHaze: '#EBF3F2',
  permaFlyAKite: '#D5DEF7',
  permaRainbowFish: '#3B67ED',
  permaEden: '#175C62',
  permaOysterBay: '#D9E9E7',
  permaCadetGrey: '#A4AEBF',
  permaTarawera: '#063855',
  permaMoonStone: '#4C9DB6',
  permaAmericanGreen: '#3BAF3F',
  permaBlueViolet: '#9940F5',
  permaClearDay: '#F5FFFB',
  permaGreyed: '#F4F6FA',
  permaPigeonPost: '#B0C1DE',
  permaMagicMint: '#ACE8D2',
  permaBotanicalGreen: '#507669',
  permaEucalyptus: '#42D791',
  permaPastelPurple: '#A78FD6',
  permaMagnolia: '#F6EAFF',
  permaLavender: '#715A9D',
  permaRhythmPurple: '#806CA6',
  permaGrape: '#5122AD',
  permaDeepViolet: '#220063',
  permaOceanGreen: '#52B68E',
  permaSeaGreen: '#40A27B',
  permaKelpGreen: '#3FA27B',
  permaSolitude: '#ECF1F7',
  permaOuterSpace: '#496470',
  permaVeryDarkCyan: '#005E63',
  permaWhisperBlueGrey: '#F5F7FC',
  permaSubtitleBlue: '#445B7B',
  a11yParentheticalBlue: '#56767B',
  permaCharcoal: '#546074',
  permaSelago: '#EEDCFC',
  permaEastBay: '#405777',
  permaHighlightGreen: '#79D94C',
  permaBotticelli: '#D3DFEA',
  permaBlueStoneNew: '#005C65',
  permaPineGreen: '#007070',
  permaVictoria: '#414A91',
  permaLividBlueNew: '#717F9D',
  permaWaikawaGreyNew: '#5D6D8F',
  permaJapaneseMaple: '#750007',
  permaSoftPeach: '#F9F4F4',
  permaCometGray: '#4D546B',
  permaFlowerBlue: '#D0EBDE',
  permaSchiavaBlue: '#172862',
  permaLinkWater: '#E8EFFA',
  permaWaikawaGreyDark: '#596E92',
  permaSanJuan: '#32486C',
  permaLividLight: '#657492',
  permaPickledBluewood: '#314D5C',
  permaElephant: '#103e42',
  permaLividBlueOpaque: '#717F9D4D',
  permaSolitudeNew: '#E1ECFE',
  permaCasal: '#2E5C60',
  permaZircon: '#FAFCFF',
  permaRhino: '#27325A',
  permaFuchsia: '#CF005F',
  permaSwansDown: '#DDEEEB',
  permaMediumPurple: '#7167E4',
  permaRedOrange: '#F84135',
  permaCeruleanBlue: '#3354BD',
  permaAquaHazeHighlighter: '#E6F2F1',
  pantoneBlue: '#0022BC',
  blueMagneta: '#F3ECF9',
  permaAmethyst: '#A140D4',
  aqua: '#007697',
  accessibilityGreenDark: '#005C65',
  accessibilityDarkGreenBold: '#173F4B',
  permaPersianGreen: '#009789',
  permaFiord: '#434B64',
  permaOuterSpaceNew: '#222F2D',
  permaMischkaNew: '#D1D6E1',
  permaFeta: '#F1FCF1',
  permaCornflowerBlueNew: '#EBF7FF',
  permaTitanWhite: '#EEEDFF',
  darkBlue: '#5F7A9B',
  whiteLilac: '#EEF1F8',
  grey950: '#434B64',
  permaCornflowerBlueSolid: '#D7DEF3',
  permaPeriwinkleGray: '#BECBE0',
  permaContessa: '#C26E5E',
  permaSlateGrayApprox: '#68768D',
  permaFog: '#E6D3FF',
  permaBlueStoneApprox: '#006A6D',
  permaHalfBaked: '#8AC9C4',
  permaJetStream: '#B1D6D1',
  permaValencia: '#CE4129',
  permaCopper: '#C46F31',
  permaCloudBurst: '#263159',
  permaLinkWaterApprox: '#F2F5FC',
  permaWhite: '#FDFDFD',
  permaCornflowerBlueApprox: '#DEDCFF',
  permaMoonRaker: '#D0CDF8',
  permaSelagoApprox: '#E9EDFB',
  permaComet: '#575F7A',
  permaBaliHaiApprox: '#8095AD',
  permaCannonPink: '#864A56',
  permaPersianGreenApprox: '#009B8E',
  permaEbb: '#F0ECEC',
  permaWafer: '#E0D3CC',
  permaBizarre: '#EEDFD9',
  permaEbbApprox: '#E7DADA',
  permaAlabaster: '#F9F9F9',
  permaCadetBlue: '#AEB8C8',
  permaDustyGray: '#979797',
  permaLondonGray: '#D1D6E1',
  permaGrayLike: '#73788C',
  permaWildBlueYonderAprox: '#758AAE',
  permaAtlantis: '#99D234',
  permaJapaneseLaurelApprox: '#2E8B05',
  permaSeaPink: '#ECAFA3',
  permaSkeptic: '#CBECD5',
  permaTusk: '#ECF2C2',
  permaChelseaCucumber: '#75A14A',
  permaCello: '#205266',
  permaOrinoco: '#F2FBCD',
  permaFlamePea: '#E15830',
  permaAtlantisNew: '#9FBC2A',
  permaBaliHaiGrey: '#8A98AF',
  purple600: '#454A5E',
  blueberry: '#8973ff',
  blueLagoon: '#008891',
  grey600: '#E2E5EC',
  TSBlack: '#222F2D',
  darkBlue95: '#E1E5F0',
  darkBlue150: '#BECBE0',
  aliceBlue: '#F6F8FB',
  dimGray: '#7F8DA4',
  aqua500: '#60B0BB',
  crystalFalls: '#E1E8F2',
  shayCandela: '#d6dde6',
  geyser: '#C8D2DE',
  dune: '#4E4B46',
  salmonFresco: '#FCBAAC',
  aquaMarine: '#60b0bb',
  optimumBlue: '#DEE3EC',
  cinnabar: '#3C2FE0',
  ultramarine: '#170BAA',
  gullGray: '#99A6BB',
  slateBlueGray: '#828DA1',
  permaPigeonBlue: '#65778F',
  blueBayoux: '#4C5C78',
  ceruleanBlue: '#3B54C6',
  lightSteelBlue: '#DDE3EA',
  snowDrift: '#F9FAFB',
  independence: '#546074',
  deepTeal: '#007697 ',
  paleSilver: '#DDE3EA',
  babyBlue: '#E5EAFA',
  lavendarBlush: '#FBFBFF',
  aqua800: '#003E4C',
  forestGreen: '#3C8703',
  paleGreen: '#C8E4B4',
  permaGrey: '#454A5F',
  darkPurple500: '#3F3360',
  lightAccessableGreen: '#37720B',
  pastelGray: '#C5DBB4',
  alabaster: '#ECF3E6',
  paleBluGray: '#B8C1D1',
  softPink: '#FDE9E9',
  softGray: '#A1ACBF',
  grayishBlue: '#F0F2F7',
  iceBlue: '#E8F8FC',
  interactiveDefaultGreen: '#007A85',
  interactiveHoveredGreen: '#006B75',
  darkTeal: '#004654',
  lightLavender: '#E9ECF5',
  transparent: 'rgba(0, 0, 0, 0)',
  brandLogo: '#2D5B61',
  successGreen: '#3C7600',
};

const fontFamily = 'Roboto, sans-serif';
const commonTextStyles: EmotionStyle = {
  fontFamily,
  letterSpacing: 0.2,
  fontSize: 15,
  color: COLORS.black,
};

const globalColorConfig = {
  QMResponsiveWidth: { maxWidth: 335, width: '100%' } as EmotionStyle,
  visiblyHidden: {
    position: 'fixed',
    top: 9999,
    left: 9999,
    overflow: 'hidden',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
    clip: 'rect(0 0 0 0)',
  } as EmotionStyle,
  tinyDefaultStyle: {
    fontSize: 13,
    lineHeight: '18px',
    letterSpacing: 0.2,
    color: COLORS.slateGrey,
  } as EmotionStyle,
  dropdowns: {
    text: {
      placeholderUp: {
        ...commonTextStyles,
        color: COLORS.placeholderGrey,
        fontSize: 12,
      } as EmotionStyle,
      placeholderDown: {
        ...commonTextStyles,
        color: COLORS.placeholderGrey,
      } as EmotionStyle,
      listItem: {
        ...commonTextStyles,
        fontSize: 14,
      } as EmotionStyle,
      listItemSubHeader: {
        ...commonTextStyles,
        fontSize: 12,
        color: COLORS.extraLightGrey,
      } as EmotionStyle,
      displayValue: {
        ...commonTextStyles,
        color: COLORS.placeholderGrey,
      } as EmotionStyle,
      listItemValue: {
        ...commonTextStyles,
      } as EmotionStyle,
    },
  },
  userActionCard: {
    text: {
      header: {
        ...commonTextStyles,
        fontSize: 16,
        fontWeight: 600,
      } as EmotionStyle,
    },
  },
  navigation: {
    text: {
      actionCardItem: {
        ...commonTextStyles,
        fontSize: 16,
      } as EmotionStyle,
      cancelButton: {
        ...commonTextStyles,
        letterSpacing: 0.42,
        color: COLORS.extraLightGrey,
      } as EmotionStyle,
      greenButton: {
        ...commonTextStyles,
        fontWeight: 600,
        letterSpacing: 0.42,
        color: COLORS.green,
      } as EmotionStyle,
      redButton: {
        ...commonTextStyles,
        fontWeight: 600,
        letterSpacing: 0.42,
        color: COLORS.red,
      } as EmotionStyle,
      orangeButton: {
        ...commonTextStyles,
        fontWeight: 500,
        letterSpacing: 0.42,
        color: COLORS.orange,
      } as EmotionStyle,
    },
  },
  table: {
    text: {
      header: {
        ...commonTextStyles,
        fontSize: 12,
        color: COLORS.placeholderGrey,
      } as EmotionStyle,
    },
  },
  tabs: {
    text: {
      inactive: {
        ...commonTextStyles,
        fontSize: 16,
        fontWeight: 600,
        color: COLORS.extraLightGrey,
      } as EmotionStyle,
      active: {
        ...commonTextStyles,
        fontSize: 16,
        fontWeight: 600,
      } as EmotionStyle,
    },
  },
  chip: {
    text: {
      label: {
        ...commonTextStyles,
        color: COLORS.white,
        fontSize: 16,
        paddingVertical: 5,
        maxWidth: 270,
      } as EmotionStyle,
    },
    icon: {
      textAlign: 'center',
      color: COLORS.white,
      fontSize: 20,
      fontWeight: 900,
      padding: 5,
    } as EmotionStyle,
  },
  submenu: {
    text: {
      title: {
        fontFamily: 'SanFranciscoText-Bold',
        fontSize: 15,
      } as EmotionStyle,
    },
  },
  general: {
    text: {
      regular: {
        ...commonTextStyles,
      },
      accordionHeader: {
        ...commonTextStyles,
        fontSize: 17,
        fontWeight: 600,
      } as EmotionStyle,
      header: {
        ...commonTextStyles,
      },
      paragraph: {
        ...commonTextStyles,
        fontSize: 14,
        color: COLORS.grey,
      } as EmotionStyle,
      alert: {
        ...commonTextStyles,
        color: COLORS.red,
      } as EmotionStyle,
      input: {
        ...commonTextStyles,
        color: COLORS.placeholderGrey,
      } as EmotionStyle,
    },
    layout: {
      centered: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    shadows: {
      deep: {
        shadowColor: 'rgba(0,0,0,1)',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.35,
        shadowRadius: 2.84,
        elevation: 4,
      } as EmotionStyle,
      initial: {
        shadowColor: 'rgba(255,255,255,0)',
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.1,
        shadowRadius: 2.84,
        elevation: 0,
      } as EmotionStyle,
    },
  },
  treatmentPlanner: {
    text: {} as EmotionStyle,
  },
  // temporary dark blue focus outline. will need to be replaced with styling that meets minimum contrast requirements
  crossBrowserFocusRing: {
    outline: '1px auto rgb(0, 107, 223)',
    outlineOffset: 2,
    zIndex: 1, // Fixes outline overlapping colliding components (example: SimpleSurveyQuestion)
  },
  // red focus outline for invalid input components
  // TODO: come up with solution that colors safari outline red
  crossBrowserErrorFocusRing: {
    outline: '1px auto #D30043',
    outlineOffset: 2,
    zIndex: 1, // Fixes outline overlapping colliding components (example: SimpleSurveyQuestion)
  },
  // This styling assumes that whatever component it's being applied to already has borderRadius: {someNumber}
  // If the component has no border (think BackButton) add borderRadius: 5 (or use your best judgement) to achieve a nice rounded effect
  // it also assumes that whatever component it's being applied to has in inner color other than white. if that is not the case (as with text inputs)
  // a trivial wrapper is necessary to which the focus styling should applied. See DateOfBirthInput in Direct2ConsumerForm.tsx for example
  roundedFocusRing: (primaryColor: string, isKeyboardFocus: boolean, isError?: boolean) => {
    const outerRingColor = isError ? '#D30043' : primaryColor;
    return {
      boxShadow: isKeyboardFocus ? `0 0 0 1.5pt ${outerRingColor}` : 'none',
      border: isKeyboardFocus ? `1px solid ${A11Y_COLORS.white}` : 'none',
      // overrides native focus styling
      ':focus': {
        outline: 'none',
      },
    };
  },
};

export default globalColorConfig;
