import { RefObject } from 'react';
import { A11Y_COLORS, Checkbox, RHFInput, RHFSelect, View } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';

const textFieldContainerStyle = { width: '98%', marginLeft: 2 };
const textFieldLabelStyle = { color: A11Y_COLORS.permaWaikawaGreyNew };
const textFieldInputStyle = { borderColor: A11Y_COLORS.permaLividBlueNew };

const languageOptions = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'es',
    label: 'Spanish',
  },
];

function capitalizeFirstLetter(str) {
  // Check if the input string is empty
  if (str.length === 0) {
    return str; // Return the original string if it's empty
  }

  // Capitalize the first letter and concatenate the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const handleInputFocus = (e) => {
  const scrollY = e.target.getBoundingClientRect().top + window.scrollY - 160;

  window.scrollTo({
    top: scrollY,
    behavior: 'smooth',
  });
};

const ConsenterDetailsForm = ({
  consenterAlsoEmergencyContact,
  setConsenterAlsoEmergencyContact,
  showConsenterAlsoEmergencyContactCheckbox,
  relation,
  detailsRef,
}: {
  consenterAlsoEmergencyContact: any;
  setConsenterAlsoEmergencyContact: any;
  showConsenterAlsoEmergencyContactCheckbox: boolean;
  relation: string;
  detailsRef?: RefObject<HTMLInputElement>;
}) => {
  const cappedRelation = capitalizeFirstLetter(relation);
  const { localizationParentalConsent } = useFlags();

  return (
    <View ref={detailsRef}>
      <RHFInput
        fieldName="firstName"
        label={`${cappedRelation} first name`}
        containerStyle={textFieldContainerStyle}
        labelStyle={textFieldLabelStyle}
        inputStyle={textFieldInputStyle}
        handleInputFocus={handleInputFocus}
      />
      <RHFInput
        fieldName="lastName"
        label={`${cappedRelation} last name`}
        containerStyle={textFieldContainerStyle}
        labelStyle={textFieldLabelStyle}
        inputStyle={textFieldInputStyle}
        handleInputFocus={handleInputFocus}
      />
      <RHFInput
        fieldName="email"
        label={`${cappedRelation} email`}
        containerStyle={textFieldContainerStyle}
        labelStyle={textFieldLabelStyle}
        inputStyle={textFieldInputStyle}
        handleInputFocus={handleInputFocus}
      />
      {localizationParentalConsent && (
        <RHFSelect
          fieldName="language"
          label="Their preferred language"
          containerStyle={textFieldContainerStyle}
          labelStyle={textFieldLabelStyle}
          inputStyle={textFieldInputStyle}
          handleInputFocus={handleInputFocus}
          options={languageOptions}
          menuPosition="fixed"
          registerOptionValue
        />
      )}
      <View
        style={{
          justifyContent: 'left',
          flexDirection: 'row',
        }}
      >
        {showConsenterAlsoEmergencyContactCheckbox && (
          <Checkbox
            checkedColor={A11Y_COLORS.green}
            checkboxStyle={{ width: 24, height: 24 }}
            label={`My ${relation} is also my emergency contact`}
            isLabelOnRight
            isChecked={consenterAlsoEmergencyContact}
            setIsChecked={(value: boolean) => setConsenterAlsoEmergencyContact(value)}
            dataQa="consenterAlsoEmergencyContactCheckbox"
            labelStyle={{
              marginTop: 3,
              color: A11Y_COLORS.darkGray,
              fontSize: 13,
              fontWeight: '400',
            }}
          />
        )}
      </View>
    </View>
  );
};

export default ConsenterDetailsForm;
