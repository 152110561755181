import { useRef, useEffect } from 'react';
import TouchableView from '../../../components/TouchableView';
import styled from '../../../core/styled';
import { spacing } from '../../tokens';
import { getSurfaceDefaultStyles } from '../../styles/interactiveStateStyles';
import TextDS from '../typography/TextDS';

const { space200 } = spacing;

const FloatingMenuOption = styled(TouchableView)(({ theme: { colorRoles } }) => {
  return {
    padding: space200,
    ...getSurfaceDefaultStyles({ colorRoles }),
  };
});

interface OverflowMenuPopupOptionProps {
  dataQa?: string;
  label: string;
  optionCallback: (event: React.MouseEvent) => void;
  keydownCallback?: (event?: KeyboardEvent) => void;
}
const OverflowMenuPopupOption = (props: OverflowMenuPopupOptionProps) => {
  const { dataQa, label, optionCallback, keydownCallback } = props;
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const buttonRefCopy = buttonRef.current;

    if (buttonRefCopy && keydownCallback) {
      buttonRefCopy.addEventListener('keydown', keydownCallback);
    }
    return () => {
      if (buttonRefCopy && keydownCallback) {
        buttonRefCopy.removeEventListener('keydown', keydownCallback);
      }
    };
  }, [keydownCallback]);

  return (
    <FloatingMenuOption ref={buttonRef} onPress={optionCallback} dataQa={dataQa || label}>
      <TextDS variant="bodySm">{label}</TextDS>
    </FloatingMenuOption>
  );
};
export default OverflowMenuPopupOption;
