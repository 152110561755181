import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const SvgComponent = (props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'stop recording';
  return (
    <Svg
      width="28px"
      height="28px"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 28 28"
      {...props}
    >
      <Path
        d="M14 28C6.268 28 0 21.732 0 14S6.268 0 14 0s14 6.268 14 14-6.268 14-14 14zm0-2c6.627 0 12-5.373 12-12S20.627 2 14 2 2 7.373 2 14s5.373 12 12 12zM10 8h8a2 2 0 012 2v8a2 2 0 01-2 2h-8a2 2 0 01-2-2v-8a2 2 0 012-2z"
        transform="translate(-779 -19) translate(779 19)"
        fill={colors.heartRed}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
};

export default SvgComponent;
