import { styled, TextDS, TouchableView, View } from '@talkspace/react-toolkit';
import { useState } from 'react';
import { useIonicEffect } from '../hooks';
import { AppInfo, getAppInfo } from '../plugins/capacitor';
import { dialogAlert } from '../plugins/dialog';
import { getLiveUpdateStatus } from '../plugins/liveUpdates';

const TouchableContainer = styled(TouchableView)({
  justifyContent: 'center',
  alignItems: 'center',
  padding: 15,
  alignSelf: 'center',
  minWidth: 230,
  flexShrink: 1,
});

const ItemRow = ({ label, value }) => (
  <View row style={{ gap: 6 }}>
    <TextDS variant="headingXs">{label}</TextDS>
    <TextDS variant="bodyXs">{value}</TextDS>
  </View>
);

const AppDetails = () => {
  const [liveUpdateInfo, setLiveUpdateInfo] =
    useState<ReturnType<typeof getLiveUpdateStatus>>(null);
  const [appInfo, setAppInfo] = useState<AppInfo | null>(null);

  useIonicEffect(() => {
    setLiveUpdateInfo(getLiveUpdateStatus());
  }, []);

  useIonicEffect(() => {
    getAppInfo().then((info) => {
      setAppInfo(info);
    });
  }, []);

  const onDebugPress = () => {
    dialogAlert({
      title: 'App info',
      message: JSON.stringify({ liveUpdateInfo, appInfo }, null, 2),
    });
  };

  if (!liveUpdateInfo) return null;
  return (
    <TouchableContainer onPress={onDebugPress}>
      {liveUpdateInfo.snapshot ? (
        <>
          <ItemRow label="Snapshot ID" value={liveUpdateInfo.snapshot.id} />
          <ItemRow label="Snapshot Build ID" value={liveUpdateInfo.snapshot.buildId} />
        </>
      ) : (
        <TextDS variant="headingXs">No live update found</TextDS>
      )}
      <ItemRow label="Channel" value={liveUpdateInfo.liveUpdate.channel || 'Not set'} />
      <ItemRow label="AppID" value={liveUpdateInfo.liveUpdate.appId} />
      <ItemRow label="Live Update Source" value={liveUpdateInfo.source} />
      <ItemRow label="App version" value={appInfo?.version ?? 'Error'} />
      <ItemRow label="Build" value={appInfo?.build} />
    </TouchableContainer>
  );
};

export default AppDetails;
