import { FunctionComponent } from 'react';
import * as React from 'react';
import {
  useUniqueID,
  Image,
  ImageProps,
  TouchableView,
  TouchableViewProps,
  HiddenText,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import { useNewMemberNav } from '../../../launchDarkly/hooks';

interface InputIconProps {
  hide?: boolean;
  marginRight?: number;
  height?: number;
  componentProps?: {};
  Component?: React.ComponentType<{ fillColor?: string }>;
  ariaDescribedByText?: string;
  ariaControls?: string;
  ariaExpanded?: boolean;
  disabled?: boolean;
}

const Wrapper = styled(TouchableView)<Partial<InputIconProps>>(({ marginRight }) => {
  return {
    width: 30,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: marginRight || 0,
    padding: 0,
    backgroundColor: 'transparent',
  };
});

const StyledImage = styled(Image)<Partial<InputIconProps>>(({ height }) => {
  return {
    width: '100%',
    height: height || 40,
    ...webOnlyStyle({
      objectFit: 'contain',
    }),
  };
});

const InputIcon: FunctionComponent<InputIconProps & ImageProps & TouchableViewProps> = ({
  onPress,
  Component,
  ariaDescribedByText,
  marginRight,
  componentProps,
  hide = false,
  ariaControls,
  ariaExpanded,
  disabled,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const ariaDescribedByID = useUniqueID('ariaDescribedByID');
  const useNewNav = useNewMemberNav();
  const dynamicGreen = useNewNav ? colors.accessibilityGreenDark : colors.green;
  return (
    (!hide && (
      <Wrapper
        dataQa="uploadFilePress"
        marginRight={marginRight}
        onPress={(e) => {
          if (!disabled && onPress) {
            onPress(e);
          }
        }}
        aria-describedby={ariaDescribedByText ? ariaDescribedByID : undefined}
        aria-controls={ariaControls}
        aria-expanded={ariaExpanded}
        aria-haspopup
      >
        {Component ? (
          <Component
            aria-hidden="true"
            {...componentProps}
            fillColor={disabled ? colors.lightGray : dynamicGreen}
          />
        ) : (
          <StyledImage aria-hidden="true" {...otherProps} />
        )}
        <HiddenText id={ariaDescribedByID}>{ariaDescribedByText}</HiddenText>
      </Wrapper>
    )) ||
    null
  );
};
export default InputIcon;
