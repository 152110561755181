import { SessionModality } from 'ts-frontend/types';
import { CANCELLATION_WINDOW_HOURS, CONFIRMATION_WINDOW_HOURS } from '../../utils/constants';

// eslint-disable-next-line import/prefer-default-export
export const getText = ({
  isTherapist,
  isWithin24Hours,
  modality,
  plural,
}: {
  isTherapist: boolean;
  isWithin24Hours: boolean;
  modality: SessionModality;
  plural?: boolean;
}) => {
  if (isTherapist) {
    if (plural) {
      return isWithin24Hours
        ? `We’ve notified your client and have asked them to confirm. Because the first session starts in the next ${CANCELLATION_WINDOW_HOURS} hours, the no show policy will not apply until the client confirms.`
        : `Your client has been notified and must confirm these sessions within ${CONFIRMATION_WINDOW_HOURS} hours`;
    }
    return isWithin24Hours
      ? `We’ve notified your client and have asked them to confirm. Because this session starts in the next ${CANCELLATION_WINDOW_HOURS} hours, the no show policy will not apply until the client confirms.`
      : `Your client has been notified and must confirm this session within ${CONFIRMATION_WINDOW_HOURS} hours`;
  }
  return modality === 'messaging'
    ? `You can start messaging your provider right away. They'll respond soon.`
    : 'Please be ready a few minutes before the scheduled time';
};
