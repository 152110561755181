import { useCallback } from 'react';
import { useNewMemberNav } from 'launchDarkly/hooks';

import { useParams } from '@/core/routerLib';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import OnboardingPage from './OnboardingPage';
import AllDone from '../assets/AllDone';
import AllDoneTeens from '../assets/AllDoneTeens';

const OnboardingPageAllDone = ({
  totalSteps,
  isTeensIntake,
  isRedirectToRoom,
}: {
  totalSteps: number;
  isTeensIntake?: boolean;
  isRedirectToRoom?: boolean;
}) => {
  const useNewNav = useNewMemberNav();
  const { roomID } = useParams<{ roomID: string }>();
  const closeModal = useCloseModal();

  const onClose = useCallback(() => {
    closeModal(
      useNewNav && !isRedirectToRoom
        ? {
            navigateTo: 'home',
            metadata: { path: '/' },
          }
        : {
            navigateTo: 'room',
            metadata: { roomID: Number(roomID) },
          }
    );
  }, [closeModal, roomID, isRedirectToRoom, useNewNav]);
  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={totalSteps}
      illustration={isTeensIntake ? <AllDoneTeens /> : <AllDone />}
      title="You’re all done!"
      description={
        isTeensIntake
          ? 'You’ve completed all the steps needed to begin therapy'
          : 'You’ve completed all the steps needed to begin treatment'
      }
      ctaText="Close"
      onPress={onClose}
    />
  );
};

export default OnboardingPageAllDone;
