import { FunctionComponent, useEffect } from 'react';
import * as React from 'react';
import {
  View,
  Text,
  Heading1,
  Caption,
  useEmotionTheme,
  BaseButton,
  useUniqueID,
} from '@talkspace/react-toolkit';
import { ClientMatchPresentingProblem } from 'ts-frontend/types';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import FullscreenWrapper from '../../../components/FullscreenWrapper';
import styled from '../../../core/styled';
import MatchResultCard from '../MatchResultCard/MatchResultCard';
import useMatchResults from '../../hooks/useMatchResults';
import TalkToMatchingAgentOrSupport from '../TalkToMatchingAgent';
import { MatchResult, MatchPayload } from '../../matchingTypes';
import useScreenSize, { screenSizes, isResponsive } from '../../../core/hooks/useScreenSize';
import { trackEvent } from '../../../utils/analytics/eventTracker';
import { trackWizardEvent } from '../../../utils/analytics/events';

const H1 = styled(Heading1)<{ screenSize: screenSizes }>(({ screenSize }) =>
  isResponsive(screenSize)
    ? {
        fontSize: 32,
        fontWeight: 500,
        letterSpacing: -0.4,
        lineHeight: '30px',
        width: 'auto',
      }
    : {}
);

const ResultsList = styled(View)({
  width: '100%',
});

const MatchAgain = styled(View)(({ theme: { colors } }) => {
  return { fontSize: 13, color: colors.grey, textAlign: 'center' };
});

const GetMatchedAgainButton: FunctionComponent<{
  remainingMatchResults: MatchResult[];
  ariaDescribedBy?: string;
}> = ({ remainingMatchResults, ariaDescribedBy }) => {
  const { setMatchResultsState } = useMatchResults();
  const { colors } = useEmotionTheme();
  return (
    <BaseButton
      aria-describedby={ariaDescribedBy}
      onPress={() =>
        setMatchResultsState({
          isLoading: true,
          matchResults: remainingMatchResults,
        })
      }
    >
      <Text
        style={{
          color: colors.green,
          fontWeight: 700,
          fontSize: 12,
        }}
      >
        Get matched again
      </Text>
    </BaseButton>
  );
};

const OtherMatchingOptionsButton: FunctionComponent<
  {
    remainingMatchResults: MatchResult[];
    roomID: number;
    eventCategory?: string;
    clientUserID?: number;
    formerTherapistID?: number;
    ariaDescribedBy?: string;
    isB2B?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  remainingMatchResults,
  roomID,
  eventCategory,
  clientUserID,
  formerTherapistID,
  ariaDescribedBy,
  isB2B,
  ...otherProps
}) => (
  <View {...otherProps} justify="center">
    {remainingMatchResults.length ? (
      <GetMatchedAgainButton
        ariaDescribedBy={ariaDescribedBy}
        remainingMatchResults={remainingMatchResults}
      />
    ) : (
      <TalkToMatchingAgentOrSupport
        ariaDescribedBy={ariaDescribedBy}
        roomID={roomID}
        eventCategory={eventCategory}
        clientUserID={clientUserID}
        formerTherapistID={formerTherapistID}
        isB2B={isB2B}
      />
    )}
  </View>
);

interface MatchResultsProps extends React.HTMLAttributes<HTMLDivElement> {
  roomID: number;
  matchPayload: MatchPayload;
  selectedPresentingProblemAsString: string;
  selectedPresentingProblemsAsExpertise: ClientMatchPresentingProblem[];
  skipPayment: boolean;
  handleSwitchWizardSelectedTherapist?: (
    therapistID: number,
    therapistFirstName: string,
    therapistImage: string,
    therapistType: TherapistType
  ) => void;
  eventCategory?: string;
  clientUserID?: number;
  formerTherapistID?: number;
  isB2B?: boolean;
}

const MatchResults: FunctionComponent<MatchResultsProps> = ({
  roomID,
  matchPayload,
  selectedPresentingProblemAsString,
  selectedPresentingProblemsAsExpertise,
  skipPayment,
  handleSwitchWizardSelectedTherapist,
  eventCategory,
  clientUserID,
  formerTherapistID,
  isB2B,
  ...otherProps
}) => {
  const { matchResultsState } = useMatchResults();

  const screenSize = useScreenSize();

  const getAnalyticsEventProperties = (matchResults?: MatchResult[]) => {
    if (matchResults && matchResults.length > 0) {
      return {
        matchesFound: 'true',
        therapistIDs: matchResults.map((result) => `${result.details.id}`).join(','),
      };
    }
    return {
      matchesFound: 'false',
      therapistIDs: '',
    };
  };

  useEffect(() => {
    trackEvent('Meet Matched Therapists', {
      eventCategory: 'Reactivation',
      eventCategoryKey: 'Funnel Name',
    });
  }, []);

  useEffect(() => {
    if (eventCategory === 'Self Serve Switch') {
      const eventProps = getAnalyticsEventProperties(matchResultsState.matchResults);
      trackWizardEvent('Meet Matched Therapists', eventCategory, {
        'Match Found': eventProps.matchesFound,
        'Therapist ID': eventProps.therapistIDs,
        'User Id': clientUserID ? clientUserID.toString() : '',
        label: 'Matches Shown',
        Application: eventCategory,
        eventProperty: eventProps.therapistIDs,
        eventPropertyValue: formerTherapistID || 0.0,
      });
    }
  }, [clientUserID, formerTherapistID, matchResultsState.matchResults, eventCategory]);
  const notHappyTextId = useUniqueID('notHappyTextId');
  const cantChooseAgainTextId = useUniqueID('cantChooseAgainTextId');
  return (
    <FullscreenWrapper
      style={{
        maxWidth: 875,
        marginTop: 13,
        marginBottom: 60,
        ...(!isResponsive(screenSize) && {
          width: '100%',
        }),
      }}
      {...otherProps}
    >
      <H1 screenSize={screenSize}>Meet your matches</H1>
      <Caption
        style={{
          textAlign: 'center',
          maxWidth: 600,
          marginTop: 15,
        }}
      >
        We’ve prioritized {matchPayload ? matchPayload.therapistGender : ''} providers who
        specialize in {selectedPresentingProblemAsString || ''}.
      </Caption>
      <ResultsList
        style={{
          marginTop: 40,
          marginBottom: 40,
        }}
      >
        {matchResultsState.matchResults &&
          matchResultsState.matchResults.slice(0, 3).map((result) => (
            <MatchResultCard
              key={result.userId}
              {...result.details}
              style={{
                marginTop: 20,
                marginBottom: 20,
              }}
              numberOfMatches={matchResultsState.matchResults?.length}
              roomID={roomID}
              roomType={matchPayload.roomType}
              skipPayment={skipPayment}
              handleSwitchWizardSelectedTherapist={handleSwitchWizardSelectedTherapist}
              selectedPresentingProblemsAsExpertise={selectedPresentingProblemsAsExpertise}
            />
          ))}
      </ResultsList>

      <MatchAgain>
        <View
          row
          justify="center"
          style={{
            marginBottom: 5,
            flexDirection: isResponsive(screenSize) ? 'column' : 'row',
          }}
        >
          <Text id={notHappyTextId}>Not happy with these options?</Text>
          {matchResultsState.matchResults && (
            <OtherMatchingOptionsButton
              ariaDescribedBy={`${notHappyTextId} ${cantChooseAgainTextId}`}
              style={{
                marginLeft: 5,
                marginTop: isResponsive(screenSize) ? 5 : 0,
              }}
              remainingMatchResults={matchResultsState.matchResults.slice(3)}
              roomID={roomID}
              clientUserID={clientUserID}
              formerTherapistID={formerTherapistID}
              isB2B={isB2B}
            />
          )}
        </View>
        <Text id={cantChooseAgainTextId}>
          You can't choose these providers again if you ask for new matches.
        </Text>
      </MatchAgain>
    </FullscreenWrapper>
  );
};

export default MatchResults;
