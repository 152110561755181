import { FunctionComponent, useState } from 'react';
import moment from 'moment';
import { useWizardActions, useWizardState } from 'stepWizard';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import { trackWizardEvent } from '@/utils/analytics/events';
import SharingPreferencesActionView from '../../clientChat/components/SharingPreferencesAction/SharingPreferencesActionView';

const SharingPreferencesContainer: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const [confirmDate, setConfirmDate] = useState('');
  const [isSharing, setIsSharing] = useState<boolean | null>(true);
  const { setState } = useWizardActions();
  const { roomID, clientUserID, eventCategory, responses } = useWizardState();
  const DATE_FORMAT_STRING = 'MMM DD, YYYY';

  const handleOnAgreePress = () => {
    trackWizardEvent('Share Message History', eventCategory, {
      'User ID': clientUserID,
      Private: !!isSharing,
      label: 'Share Message History',
      Application: eventCategory,
      eventProperty: isSharing ? 'true' : 'false',
      eventPropertyValue: 0.0,
    });
    setState({ isMessageHistoryShared: isSharing });
    const currentStepResponses = responses;
    currentStepResponses.lastStepName = 'Sharing preferences';
    setState({
      isMessageHistoryShared: isSharing,
      responses: currentStepResponses,
    });
    setConfirmDate(moment().format(DATE_FORMAT_STRING));
    history.push('/switch-provider/therapist-confirmation', {
      roomID,
    });
  };

  return (
    <>
      <SharingPreferencesActionView
        isSharing={isSharing}
        setIsSharing={setIsSharing}
        confirmDate={confirmDate}
        onAgreePress={handleOnAgreePress}
        error=""
      />
    </>
  );
};

export default withRouter(SharingPreferencesContainer);
