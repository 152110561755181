import { FunctionComponent } from 'react';
import Svg, { Defs, Path, LinearGradient, Stop, G, Use } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface AppIconSquareProps {
  color?: string;
  width?: number;
  height?: number;
  style?: object;
}

const AppIconSquare: FunctionComponent<AppIconSquareProps> = ({
  color,
  width = 58,
  height = 58,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'app';
  return (
    <Svg
      width={height}
      height={width}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <Defs>
        <LinearGradient x1="50%" y1="100%" x2="50%" y2="50%" id="AppIconSquare_b">
          <Stop stopColor={colors.green} offset="0%" />
          <Stop stopColor={colors.green} offset="100%" />
        </LinearGradient>
        <Path
          d="M44.18 0C52.563 0 58 5.438 58 13.82v30.36C58 52.563 52.562 58 44.18 58H13.82C5.437 58 0 52.562 0 44.18V13.82C0 5.437 5.438 0 13.82 0h30.36z"
          id="AppIconSquare_a"
        />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-18 -428) translate(18 428)">
          <Use fill={color || colors.green} href="#AppIconSquare_a" />
          <Use fill="url(#AppIconSquare_b)" href="#AppIconSquare_a" />
        </G>
        <Path
          d="M15.416 12.083l4.55 4.666v5.013c0 .446.359.805.806.805h18.067a.8.8 0 00.805-.806V2.406a.803.803 0 00-.805-.806H20.772a.802.802 0 00-.805.805v5.013l-4.551 4.665z"
          stroke={colors.white}
          strokeWidth={3.19999981}
          transform="translate(-18 -428) translate(18 428) translate(7.733 16.756)"
        />
        <Path
          d="M6.122 10.311H9.99v-2.9H6.122V1.933H2.576v5.478H0v2.9h2.576v6.134c0 3.253 1.846 5.055 5.054 4.97 1 0 1.895-.191 2.895-.614l.31-.132-1.086-3.02-.36.172c-.549.262-1.132.416-1.605.416-1.102 0-1.662-.575-1.662-1.792v-6.134z"
          fill={colors.white}
          fillRule="nonzero"
          transform="translate(-18 -428) translate(18 428) translate(7.733 16.756)"
        />
      </G>
    </Svg>
  );
};

export default AppIconSquare;
