import { FunctionComponent } from 'react';
import { View, Text } from '@talkspace/react-toolkit';
import styled from '@/core/styled/styled';

interface RecorderTimerViewProps {
  time: number;
}

const parseDuration = (durationSeconds?: number): string => {
  const dur = Math.round(durationSeconds || 0);
  if (!dur || dur < 0) return '0:00';
  const minutes = Math.floor(dur / 60);
  const seconds = dur - minutes * 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const Wrapper = styled(View)({
  padding: 0,
  marginRight: 10,
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  backgroundColor: 'transparent',
});

const RecordingText = styled(Text)(({ theme: { colors } }) => {
  return { color: colors.red };
});

const RecorderTimerView: FunctionComponent<RecorderTimerViewProps> = ({ time }) => (
  <Wrapper>
    <RecordingText>{parseDuration(time)}</RecordingText>
  </Wrapper>
);

export default RecorderTimerView;
