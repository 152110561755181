import { useEmotionTheme, View, Screen } from '@talkspace/react-toolkit';
import { WizardScreen, useWizardActions } from 'stepWizard';
import { Route, Switch, useHistory } from '@/core/routerLib';
import useWindowWidth from '../../clientChat/hooks/useWindowWidth';
import mentalHealthScheme from './teenMentalHealthScheme';
import SubmitMentalHealth from './containers/SubmitTeenMentalHealth';

const Routes = () => {
  const { onExitClick } = useWizardActions();
  const { isMobile } = useWindowWidth();
  const { colors } = useEmotionTheme();

  const {
    location: { pathname },
  } = useHistory();

  const isOnboarding = pathname.includes('/onboarding');

  const showCloseButton = !isOnboarding;
  const wizardScheme = {
    ...mentalHealthScheme,
    ...(isOnboarding ? { hideProgressIndicator: true } : {}),
  };
  return (
    <Switch>
      <View style={{ background: colors.white }}>
        <Screen
          safeAreaVariant="setHeightAndAddTop"
          onCloseButtonClick={onExitClick}
          title={isMobile ? 'Your mental health' : ''}
          showCloseButton={showCloseButton}
          scrollViewStyles={{ padding: !isMobile ? '0 22px 22px 22px' : 0 }}
        >
          <Route
            exact
            path={[
              '/teen-mental-health-wizard/submit',
              '/room/:roomID/onboarding/teen-mental-health/source/:source/submit',
            ]}
            component={SubmitMentalHealth}
          />
          <Route
            exact
            path={[
              '/teen-mental-health-wizard/room/:roomID/source/:source',
              '/room/:roomID/onboarding/teen-mental-health/source/:source',
            ]}
            render={() => <WizardScreen wizardScheme={wizardScheme} />}
          />
        </Screen>
      </View>
    </Switch>
  );
};

export default Routes;
