import { useEmotionTheme } from '@talkspace/react-toolkit';
import { Circle, Path, Svg } from 'svgs';

const SmileHeart = () => {
  const { colorRoles } = useEmotionTheme();
  const title = 'Smile with Heart';
  return (
    <Svg title={title} aria-label={title} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M17 13.0001C17.2761 13.0002 17.5048 13.2257 17.4632 13.4986C17.2164 15.1158 15.7599 16 14 16C12.2401 16 10.7837 15.1156 10.5369 13.4984C10.4952 13.2255 10.7239 13 11.0001 13C12.4398 13 12.2473 13 14 13C15.7527 13 15.5602 12.9998 17 13.0001Z"
        fill={colorRoles.icons.iconDefaultDefault}
      />
      <Circle cx="11.5" cy="10" r="1" fill={colorRoles.icons.iconDefaultDefault} />
      <Circle cx="16.5" cy="10" r="1" fill={colorRoles.icons.iconDefaultDefault} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9729 5.29803C12.6145 5.1042 13.2951 5 14 5C17.866 5 21 8.13401 21 12C21 15.866 17.866 19 14 19C10.276 19 7.23114 16.0919 7.0126 12.4227L5.66317 13.6656C6.4374 17.5626 9.87568 20.5 14 20.5C18.6945 20.5 22.5 16.6944 22.5 12C22.5 7.30558 18.6945 3.5 14 3.5C13.1469 3.5 12.3231 3.62569 11.5462 3.85955C11.7748 4.31495 11.917 4.80258 11.9729 5.29803ZM5.55273 11.0482C5.85645 8.32238 7.44923 5.98576 9.7076 4.66187C9.98961 5.1629 10.0674 5.74817 9.94204 6.29559C8.94282 7.0077 8.13865 7.97578 7.62525 9.10414L7.17999 9.54941L5.55273 11.0482Z"
        fill={colorRoles.icons.iconDefaultDefault}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.17994 9.54941L5.5 11.0968C5.5 11.0968 4.28237 10.0117 3.82006 9.54941L1.66778 7.39713C0.778916 6.50826 0.775956 5.05965 1.66761 4.168C2.55744 3.27817 4.00498 3.27643 4.89673 4.16818L5.5 4.77145L6.10327 4.16818C6.99502 3.27643 8.44256 3.27817 9.33239 4.168C10.224 5.05965 10.2211 6.50826 9.33222 7.39713L7.17994 9.54941Z"
        fill={colorRoles.icons.iconDefaultDefault}
      />
    </Svg>
  );
};

export default SmileHeart;
