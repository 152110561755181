import { Capacitor } from '@capacitor/core';

/** Only true for native platform */
export const getIsIonic = () =>
  !!('Capacitor' in globalThis || 'Ionic' in globalThis) && Capacitor.getPlatform() !== 'web';

export const safeIonicWrapper = <TFun extends (...args: any) => any>(
  inputFn: TFun,
  defaultReturn: ReturnType<TFun>
) => {
  if (!getIsIonic() || Capacitor.getPlatform() === 'web') return (() => defaultReturn) as TFun;
  return ((...params) => inputFn(...params)) as TFun;
};

export const getDeviceSubType = (model: string) =>
  model.toLowerCase().includes('ipad') ? 'ipad' : 'iphone';

/** List of supported plugins (from the /plugins folder) */
export enum NativeFeaturesEnum {
  APP_REVIEW = 'appReview',
  BIOMETRICS = 'biometrics',
  CAPACITOR = 'capacitor',
  DIALOG = 'dialog',
  LIVE_UPDATES = 'liveUpdates',
  MEDIA = 'media',
  PUSH_NOTIFICATIONS = 'pushNotifications',
  SECURE_STORAGE = 'secureStorage',
  SETTINGS = 'settings',
  PASSCODE = 'passcode',
  MIXPANEL = 'mixpanel',
  NEW_RELIC = 'newRelic',
}

/**
 * Can be used as a middleware in the future for determining whether to show X feature
 */
export const shouldShowNativeFeature = (feature?: NativeFeaturesEnum) => {
  if (!getIsIonic()) return false;
  const isNativeDevice = Capacitor.getPlatform() !== 'web';
  switch (feature) {
    case NativeFeaturesEnum.APP_REVIEW:
      return isNativeDevice;
    case NativeFeaturesEnum.BIOMETRICS:
      return isNativeDevice;
    case NativeFeaturesEnum.CAPACITOR:
      return isNativeDevice;
    case NativeFeaturesEnum.DIALOG:
      return isNativeDevice;
    case NativeFeaturesEnum.LIVE_UPDATES:
      return isNativeDevice;
    case NativeFeaturesEnum.MEDIA:
      return isNativeDevice;
    case NativeFeaturesEnum.PUSH_NOTIFICATIONS:
      return isNativeDevice;
    case NativeFeaturesEnum.SECURE_STORAGE:
      return isNativeDevice;
    case NativeFeaturesEnum.PASSCODE:
      return isNativeDevice;
    case NativeFeaturesEnum.SETTINGS:
      return isNativeDevice;
    case NativeFeaturesEnum.MIXPANEL:
      return isNativeDevice;
    case NativeFeaturesEnum.NEW_RELIC:
      return isNativeDevice;
    default:
      return true;
  }
};
