import { EmotionStyle, View, useWindowWidth } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const Span = styled(View)(() => {
  return {
    display: 'inline',
    fontSize: '32px',
    lineHeight: '28px',
    letterSpacing: '0.36px',
  };
});

const weightConfig = {
  0: 900,
  1: 900,
  2: 800,
  3: 700,
  4: 600,
  5: 600,
  6: 500,
  7: 400,
  8: 400,
};

/**
 * Adds a specific font weight to a word depending on the it's position in a sentence
 * @param sentence sentence to be updated
 * @returns JSX.Element
 */
const WeightedSentence = ({
  sentence,
  style,
}: {
  sentence: string;
  style?: EmotionStyle;
}): JSX.Element => {
  const { isMobile } = useWindowWidth();
  const formattedSentence = sentence.split(' ');
  return (
    <View
      style={{
        display: 'inline',
        minWidth: 280,
        maxWidth: isMobile ? 280 : '100%',
        ...style,
      }}
    >
      {formattedSentence.map((word, index) => {
        const weight = weightConfig[index] ?? 200;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Span key={`word-${index}`} as="span" style={{ fontWeight: weight }}>
            {word}{' '}
          </Span>
        );
      })}
    </View>
  );
};

export default WeightedSentence;
