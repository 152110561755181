import { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { useClientSideGlobal } from './ssrHooks';
import { getIsSSR } from '../utils/ssr';
import { Breakpoint, BreakpointValues } from '../types';
import { parseVersionKey, SemanticVersion } from '../utils/semanticVersioning';
import { getBreakpoint, standardBreakpoints } from '../designSystems/components/Grid/gridUtils';
import { getIsIonic } from '../utils/ionicUtils';

export interface WidthBreakpoints {
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
}

export interface WindowWidthValues extends WidthBreakpoints {
  width: number;
  height: number;
}

export const breakpointsValuesV1: BreakpointValues = {
  xs: 0,
  sm: 375,
  md: 750,
  lg: 1125,
  xl: 1280,
};
export const breakpointsValuesV2: BreakpointValues = standardBreakpoints;

export function getWidthBreakpoints(
  width: number,
  version?: SemanticVersion | string
): WidthBreakpoints {
  const breakpoints =
    version && parseVersionKey(version).major >= 2 ? breakpointsValuesV2 : breakpointsValuesV1;

  if (version && parseVersionKey(version).major >= 2) {
    const breakpoint = getBreakpoint(width, undefined, breakpoints);
    const breakpointMap = {
      isSmall: breakpoint === Breakpoint.xs,
      isMedium: breakpoint === Breakpoint.sm,
      isLarge: breakpoint === Breakpoint.md,
      isDesktop: breakpoint === Breakpoint.lg,
      isMobile: breakpoint === Breakpoint.xs || breakpoint === Breakpoint.sm,
      isTablet: [Breakpoint.sm, Breakpoint.md].includes(breakpoint),
    };
    return breakpointMap;
  }

  // Previous way of doing breakpoints
  const isSmall = width <= breakpoints.sm;
  const isMedium = width > breakpoints.sm && width <= breakpoints.md;
  const isLarge = width > breakpoints.md && width <= breakpoints.lg;
  const isDesktop = width > breakpoints.lg;

  const breakpointMap = {
    isSmall,
    isMedium,
    isLarge,
    isDesktop,
    isMobile: isSmall || isMedium,
    isTablet: isMedium || isLarge,
  };
  return breakpointMap;
}

export default function useWindowWidth(version?: SemanticVersion | string): WindowWidthValues {
  const innerWidth = useClientSideGlobal((w) => w.innerWidth, 0);
  const clientHeight = useClientSideGlobal((w) => w.document.documentElement.clientHeight, 0);

  const [windowWidth, setWindowWidth] = useState(innerWidth);
  const [windowHeight, setWindowHeight] = useState(clientHeight);

  const windowHeightDebounce = useRef(
    debounce(
      () => {
        setWindowHeight(document.documentElement.clientHeight);
      },
      300,
      { trailing: true }
    )
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // Mobile devices open and close the keyboard very often.
      // Update height faster for smoother experience
      if (getIsIonic()) {
        setWindowHeight(document.documentElement.clientHeight);
      } else {
        windowHeightDebounce.current();
      }
    };

    if (getIsSSR()) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ...getWidthBreakpoints(windowWidth, version), width: windowWidth, height: windowHeight };
}
