import Svg, { Defs, Path, G, Circle, Rect, Mask, Use } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const CircledX = ({ width = 28, height = 28, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'X';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <Defs>
        <Path id="a" d="M-3.90798505e-14 0L825 0 825 105 -3.90798505e-14 105z" />
      </Defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <G transform="translate(-24 -804) translate(0 745)">
          <Mask id="a" fill={colors.white}>
            <Use href="#a" xlinkHref="#a" />
          </Mask>
          <Use fill={colors.white} href="#a" xlinkHref="#a" />
        </G>
        <G transform="translate(-24 -804) translate(0 745) rotate(45 -46.926 73.178)">
          <Circle cx="13" cy="13" r="13" stroke={colors.periwinkleGrey} strokeWidth="2" />
          <Rect width="14" height="2" x="6" y="12" fill={colors.heatherGrey} rx="1" />
          <Path
            fill={colors.heatherGrey}
            d="M6 13c0-.552.45-1 1.007-1h11.986c.556 0 1.007.444 1.007 1 0 .552-.45 1-1.007 1H7.007A1.001 1.001 0 016 13z"
            transform="rotate(90 13 13)"
          />
        </G>
      </G>
    </Svg>
  );
};

export default CircledX;
