import { TOptions } from 'i18next';
import { TranslationFunction, TranslationKey } from './types';

export type Language = 'en' | 'es' | 'es_US';
export const SUPPORTED_LANGUAGES: Record<string, Language> = {
  en: 'en',
  es: 'es',
  es_US: 'es_US',
};
export const STATIC_PATH = '';
export const translateIfNeeded = (
  translateFunction: TranslationFunction | undefined,
  fallbackText: string,
  translateKey: TranslationKey,
  translateOptions?: TOptions
): string => {
  if (!translateFunction) return fallbackText;
  return translateFunction(translateKey, fallbackText, translateOptions);
};
