import { useEffect } from 'react';

import { View, useWindowWidthState } from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import styled from '@/core/styled';

import PersonalInformationDataSectionMyInformation from '../PersonalInformationDataSectionMyInformation';
import PersonalInformationDataSectionEmergencyContact from '../PersonalInformationDataSectionEmergencyContact';
import IncompleteInfoTooltip from '../IncompleteInfoTooltip';
import {
  usePersonalInformationState,
  usePersonalInformationActions,
} from '../../hooks/usePersonalInformation';

interface Props {
  onEditMyInformation: () => void;
  onEditEmergencyContact: () => void;
}
const Container = styled(View)<{ useNewNav: boolean }>(({ useNewNav }) => {
  return {
    maxWidth: 510,
    ...(useNewNav ? { marginTop: -20 } : {}),
  };
});
const PersonalInformation = ({ onEditMyInformation, onEditEmergencyContact }: Props) => {
  const { isMobile } = useWindowWidthState();
  const { myInformation, emergencyContact, isLoading } = usePersonalInformationState();
  const { firstName, lastName, dateOfBirth, country, state, city, zipcode, address } =
    myInformation;
  const { contactName, contactPhone, relationship } = emergencyContact;
  const isIncompleteInfo =
    !isLoading &&
    (!firstName ||
      !lastName ||
      !dateOfBirth ||
      !country ||
      !city ||
      !zipcode ||
      !address ||
      !contactName ||
      !contactPhone ||
      !relationship ||
      (country === 'US' && !state));

  const { getMyInformation, getEmergencyContact } = usePersonalInformationActions();
  const useNewNav = useNewMemberNav();
  useEffect(() => {
    getMyInformation();
    getEmergencyContact();
  }, [getEmergencyContact, getMyInformation]);

  return (
    <Container useNewNav={useNewNav}>
      {isIncompleteInfo && (
        <IncompleteInfoTooltip
          text="Please fill in all your personal information."
          style={{ margin: `0 ${isMobile ? '10px' : 0} 15px` }}
        />
      )}
      <PersonalInformationDataSectionMyInformation
        data={myInformation}
        onEdit={onEditMyInformation}
      />
      <PersonalInformationDataSectionEmergencyContact
        data={emergencyContact}
        onEdit={onEditEmergencyContact}
        wrapperStyle={{ marginTop: 16 }}
      />
    </Container>
  );
};
export default PersonalInformation;
