import { WizardScheme } from 'stepWizard';
import EmergencyResourcesBodyText from '../components/EmergencyResourcesBodyText';

const mentalHealthScheme: WizardScheme = {
  wizardModalTitle: 'mental intake',
  wizardVersion: 1,
  wizardType: 'mentalIntake',
  disablePersist: true,
  steps: [
    {
      title: 'Have you ever been hospitalized for psychiatric care?',
      inputType: 'select',
      inputState: 'hospitalizationHistory',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'hospitalizationHistoryOptions',
      name: 'hospitalizationHistory',
      backOption: 'back',
    },
    {
      title:
        'Which (if any) of the following mental health conditions have you been diagnosed with?',
      inputTypePlaceholder: 'Select',
      inputType: 'searchableMultiSelect',
      inputState: 'previousMentalHealthIssues',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'Skip',
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      inputOptions: 'previousMentalHealthIssuesOptions',
      name: 'previousMentalHealthIssues',
      backOption: 'back',
    },
    {
      title: 'Have you ever had thoughts of killing yourself?',
      inputType: 'select',
      inputState: 'suicideIdeation',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'suicideIdeationOptions',
      name: 'suicideIdeation',
      backOption: 'back',
      postAction: 'setSuicideIdeation',
    },
    {
      title: 'Have you ever thought about how you might do this?',
      inputType: 'select',
      inputState: 'suicidePlanning',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'suicidePlanningOptions',
      name: 'suicidePlanning',
      backOption: 'back',
      displayCondition: 'selectedSuicideIdeation',
      postAction: 'setSuicidePlanning',
    },
    {
      title: 'If you’re in immediate danger, don’t use this app. Instead, use these resources:',
      bodyComponent: () => <EmergencyResourcesBodyText />,
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'never',
      },
      secondaryButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      name: 'emergencyResources',
      backOption: 'back',
      displayCondition: 'selectedSuicidePlanning',
    },
    {
      title: 'Have you ever had thoughts about harming or killing others?',
      inputType: 'select',
      inputState: 'homicidalIdeation',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'homicidalIdeationOrPlanningOptions',
      name: 'homicidalIdeation',
      backOption: 'back',
      postAction: 'setHomicidalIdeation',
    },
    {
      title: 'Have you ever thought about how you might do this?',
      inputType: 'select',
      inputState: 'homicidalPlanning',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'homicidalIdeationOrPlanningOptions',
      name: 'homicidalPlanningPlanning',
      backOption: 'back',
      displayCondition: 'selectedHomicidalIdeation',
      postAction: 'setHomicidalPlanning',
    },

    {
      title: 'Do you have excessive anger, aggression, and/or violent behaviors?',
      inputType: 'select',
      inputState: 'angerAggression',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'angerAggressionOptions',
      name: 'angerAggression',
      backOption: 'back',
      postAction: 'setAngerAggression',
    },

    {
      title: 'Have you experienced an especially frightening, horrible, or traumatic event?',
      inputType: 'select',
      inputState: 'traumaticExperience',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'traumaticExperienceOptions',
      name: 'traumaticExperience',
      backOption: 'back',
      postAction: 'setTraumaticExperience',
    },
    {
      title: 'In the past 30 days, have you struggled with thoughts or dreams of the event?',
      inputType: 'select',
      inputState: 'traumaticFlashbacks',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'traumaticFlashbacksOptions',
      name: 'traumaticFlashbacks',
      backOption: 'back',
      postAction: 'setTraumaticFlashbacks',
      displayCondition: 'selectedTraumaticExperience',
    },
    {
      title: 'In the past 30 days, have you felt guilty or blamed yourself for what happened?',
      inputType: 'select',
      inputState: 'guilt',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'guiltOptions',
      name: 'guilt',
      backOption: 'back',
      postAction: 'setGuilt',
      displayCondition: 'selectedTraumaticExperience',
    },
    {
      title:
        'In the past 30 days, have you felt distant from others or stopped enjoying your usual activities?',
      inputType: 'select',
      inputState: 'isolation',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'isolationOptions',
      name: 'isolation',
      backOption: 'back',
      postAction: 'setIsolation',
      displayCondition: 'selectedTraumaticExperience',
    },

    {
      title: 'Do you currently use or have you used any of the following substances?',
      inputType: 'searchableMultiSelect',
      inputState: 'controlledSubstances',
      inputTypePlaceholder: 'Select',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: `Skip`,
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      inputOptions: 'controlledSubstancesOptions',
      name: 'controlledSubstances',
      backOption: 'back',
    },
    {
      title: 'How would you describe your sleep quality?',
      inputType: 'select',
      inputState: 'sleepQuality',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'sleepQualityOptions',
      name: 'sleepQuality',
      backOption: 'back',
    },
    {
      title: 'Which of the following are you experiencing at school or in your community?',
      inputTypePlaceholder: 'Select',
      inputType: 'searchableMultiSelect',
      inputState: 'experiencingSchoolCommunity',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'experiencingSchoolCommunityOptions',
      name: 'experiencingSchoolCommunity',
      backOption: 'back',
    },
    {
      title: 'Which of the following have you experienced through social media?',
      inputTypePlaceholder: 'Select',
      inputType: 'searchableMultiSelect',
      inputState: 'experiencingSocialMedia',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: (_, wizardContext) => {
          if (wizardContext.source === 'onboarding') {
            return `/room/${wizardContext.roomID}/onboarding/teen-mental-health/source/onboarding/submit`;
          }
          return '/teen-mental-health-wizard/submit';
        },
        whenVisible: 'onSelect',
      },
      inputOptions: 'experiencingSocialMediaOptions',
      name: 'experiencingSocialMedia',
      backOption: 'back',
    },
  ],
};

export default mentalHealthScheme;
