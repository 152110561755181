import { OptionType } from '@talkspace/react-toolkit';
import {
  sleepQuality,
  hospitalizationHistory,
  suicideIdeationOrPlanning,
  homicidalIdeationOrPlanning,
  teenMentalHealthIssues,
  traumaticExperienceOptions,
  traumaticFlashbacksOptions,
  guiltOptions,
  isolationOptions,
  controlledSubstances,
  experiencingSchoolCommunity,
  experiencingSocialMedia,
  angerAggression,
} from '@talkspace/configs';

import { ValueType } from 'react-select';
import {
  BaseWizardState,
  baseWizardInitialState,
  keyValueToValueType,
  WizardPostAction,
} from 'stepWizard';

const controlledSubstancesKeyOrder = ['1', '8', '2', '3', '4', '5', '6', '9', '7', '10'];
const teenMentalHealthIssuesKeyOrder = [
  '10',
  '1',
  '2',
  '3',
  '11',
  '4',
  '12',
  '13',
  '5',
  '6',
  '7',
  '8',
  '9',
  '14',
  '15',
  '16',
];

export interface TeenMentalHealthWizardState extends BaseWizardState {
  sleepQuality: string;
  previousMentalHealthIssues: number[];
  hospitalizationHistory: null | string;
  traumaticExperience: boolean;
  traumaticFlashbacks: null | boolean;
  guilt: null | boolean;
  isolation: null | boolean;
  suicideIdeation: string;
  suicidePlanning: null | string;
  homicidalIdeation: null | string;
  homicidalPlanning: null | string;
  angerAggression: null | boolean;
  experiencingSchoolCommunity: number[];
  experiencingSocialMedia: number[];
  controlledSubstances: number[];

  selectedSuicideIdeation: boolean | null;
  selectedSuicidePlanning: boolean | null;
  selectedTraumaticExperience: boolean | null;
  selectedTraumaticFlashbacks: boolean | null;
  selectedGuilt: boolean | null;
  selectedIsolation: boolean | null;
  selectedAngerAggression: boolean | null;

  sleepQualityOptions: ValueType<OptionType, true>;
  previousMentalHealthIssuesOptions: ValueType<OptionType, true>;
  hospitalizationHistoryOptions: ValueType<OptionType, true>;
  traumaticExperienceOptions: ValueType<OptionType, true>;
  traumaticFlashbacksOptions: ValueType<OptionType, true>;
  guiltOptions: ValueType<OptionType, true>;
  isolationOptions: ValueType<OptionType, true>;
  suicideIdeationOptions: ValueType<OptionType, true>;
  suicidePlanningOptions: ValueType<OptionType, true>;
  homicidalIdeationOrPlanningOptions: ValueType<OptionType, true>;
  angerAggressionOptions: ValueType<OptionType, true>;
  experiencingSchoolCommunityOptions: ValueType<OptionType, true>;
  experiencingSocialMediaOptions: ValueType<OptionType, true>;
  controlledSubstancesOptions: ValueType<OptionType, true>;

  setTraumaticExperience?: WizardPostAction<TeenMentalHealthWizardState, void>;
  setTraumaticFlashbacks?: WizardPostAction<TeenMentalHealthWizardState, void>;
  setGuilt?: WizardPostAction<TeenMentalHealthWizardState, void>;
  setIsolation?: WizardPostAction<TeenMentalHealthWizardState, void>;
  setSuicideIdeation?: WizardPostAction<TeenMentalHealthWizardState, void>;
  setSuicidePlanning?: WizardPostAction<TeenMentalHealthWizardState, void>;
  setHomicidalIdeation?: WizardPostAction<TeenMentalHealthWizardState, void>;
  setHomicidalPlanning?: WizardPostAction<TeenMentalHealthWizardState, void>;
  setAngerAggression?: WizardPostAction<TeenMentalHealthWizardState, void>;
  setExperiencingSchoolCommunity?: WizardPostAction<TeenMentalHealthWizardState, void>;
  setExperiencingSocialMedia?: WizardPostAction<TeenMentalHealthWizardState, void>;
}

interface YesNoOptionList extends Record<string, string> {
  no: string;
  yes: string;
}

const reverseYesNoOptions = (options: YesNoOptionList) => {
  const { yes, no, ...cleaned } = options;
  return {
    yes,
    no,
    ...cleaned,
  };
};

export const getInitialState = (
  clientUserID: number,
  roomID: number,
  source: string,
  contextID: string
): TeenMentalHealthWizardState => {
  return {
    ...baseWizardInitialState(source, contextID),
    roomID,
    clientUserID,
    sleepQuality: '',
    previousMentalHealthIssues: [],
    hospitalizationHistory: null,
    traumaticExperience: false,
    traumaticFlashbacks: null,
    guilt: null,
    isolation: null,
    suicideIdeation: '',
    suicidePlanning: null,
    homicidalIdeation: null,
    homicidalPlanning: null,
    experiencingSchoolCommunity: [],
    experiencingSocialMedia: [],
    angerAggression: null,
    controlledSubstances: [],

    selectedSuicideIdeation: null,
    selectedSuicidePlanning: null,
    selectedTraumaticExperience: null,
    selectedTraumaticFlashbacks: null,
    selectedGuilt: null,
    selectedIsolation: null,
    selectedAngerAggression: null,

    sleepQualityOptions: keyValueToValueType(sleepQuality),
    previousMentalHealthIssuesOptions: keyValueToValueType(
      teenMentalHealthIssues,
      teenMentalHealthIssuesKeyOrder
    ),
    hospitalizationHistoryOptions: keyValueToValueType(hospitalizationHistory),
    traumaticExperienceOptions: keyValueToValueType(
      reverseYesNoOptions(traumaticExperienceOptions)
    ),
    traumaticFlashbacksOptions: keyValueToValueType(
      reverseYesNoOptions(traumaticFlashbacksOptions)
    ),
    guiltOptions: keyValueToValueType(reverseYesNoOptions(guiltOptions)),
    isolationOptions: keyValueToValueType(reverseYesNoOptions(isolationOptions)),
    suicideIdeationOptions: keyValueToValueType(suicideIdeationOrPlanning),
    suicidePlanningOptions: keyValueToValueType(suicideIdeationOrPlanning),
    homicidalIdeationOrPlanningOptions: keyValueToValueType(homicidalIdeationOrPlanning),
    angerAggressionOptions: keyValueToValueType(reverseYesNoOptions(angerAggression)),
    experiencingSchoolCommunityOptions: keyValueToValueType(experiencingSchoolCommunity),
    experiencingSocialMediaOptions: keyValueToValueType(experiencingSocialMedia),
    controlledSubstancesOptions: keyValueToValueType(
      controlledSubstances,
      controlledSubstancesKeyOrder
    ),
  };
};
