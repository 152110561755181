import { FunctionComponent } from 'react';
import { View, Text, ImplicitBusinessHoursChart, Tooltip } from '@talkspace/react-toolkit';
import moment from 'moment';
import styled from '../../../core/styled';
import {
  ImplicitBusinessHoursByDay,
  BusinessDays,
  TimeOff,
} from '../../reducers/inPlatformMatchingReducer';
import TimezoneIcon from '../../assets/Timezone';
import TimeOffIcon from '../../assets/TimeOff';
import useWindowWidth from '../../hooks/useWindowWidth';

interface TherapistAvailabilityProps {
  timeOff?: TimeOff;
  implicitBusinessHoursByDay?: ImplicitBusinessHoursByDay;
  businessDays: BusinessDays;
  therapistTimezone: string;
}

const IconText = styled(Text)({
  width: 100,
  fontWeight: 500,
  textAlign: 'left',
  marginLeft: 4,
});

const TherapistAvailability: FunctionComponent<TherapistAvailabilityProps> = ({
  implicitBusinessHoursByDay,
  businessDays,
  therapistTimezone,
  timeOff = {},
}) => {
  const { isMobile } = useWindowWidth();
  const { dateStart, dateEnd } = timeOff;
  const timeOffText =
    dateStart && dateEnd
      ? `${moment(dateStart).format('ll')} - ${moment(dateEnd).format('ll')}`
      : 'No time off scheduled';

  return (
    <View>
      <View row align="center" style={{ marginBottom: 40 }}>
        <Text>Typically active</Text>
        <Tooltip
          label="availability tooltip"
          tip="The bars in this graph represent the level of typical activity for this provider on a given day. The data is sourced from the past several weeks of platform activity. It is intended to help you find a provider whose activity patterns best fit your schedule."
          questionMarkSize={16}
          buttonStyle={{ display: 'flex', marginLeft: 6 }}
        />
      </View>
      <View align="center">
        <View style={{ maxWidth: 500, width: '100%' }}>
          <ImplicitBusinessHoursChart
            implicitBusinessHoursByDay={implicitBusinessHoursByDay}
            businessDays={businessDays}
            showArrows={!isMobile}
          />
        </View>
      </View>
      <View row align="center" style={{ marginTop: 50, marginBottom: 10 }}>
        <View style={{ width: 20 }}>
          <TimezoneIcon />
        </View>
        <IconText>Timezone</IconText>
        <Text>{therapistTimezone}</Text>
      </View>
      <View row align="center">
        <View style={{ width: 20 }}>
          <TimeOffIcon />
        </View>
        <IconText>On time off</IconText>
        <Text>{timeOffText}</Text>
      </View>
    </View>
  );
};

export default TherapistAvailability;
