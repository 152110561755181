import { FunctionComponent, useEffect } from 'react';
import {
  View,
  TouchableView,
  Big,
  Small,
  ArrowRight,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import styled from '../../../core/styled';
import { withRouter, RouteComponentProps } from '../../../core/routerLib';
import { ClinicalProgressDashboardIcon } from '../icons';
import { useSurveysState } from '../../hooks/surveysContext';
import { UpcomingSurvey } from '../../reducers/surveys';
import { useMainState, useMainActions } from '../../../hooks/mainContext';
import { useOpenModal } from '../../../utils/ModalsContextProvider';
import { useTreatmentPlannerState } from '../../hooks/treatmentPlannerContext';
import { ID_CLINICAL_PROGRESS_PANEL } from '../../../utils/IDConstants';

interface ClinicalProgressDashboardToolProps extends RouteComponentProps<{ roomID: string }> {
  isActive?: boolean;
}

const Wrapper = styled(TouchableView)<{ isActive?: boolean }>(({ isActive, theme: { colors } }) => {
  return {
    height: 90,
    flexDirection: 'row',
    backgroundColor: isActive ? colors.permaLinkWaterGrey : colors.white,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    alignItems: 'center',
    paddingLeft: 17,
    paddingRight: 16,
    '&:hover': {
      backgroundColor: isActive ? undefined : colors.athensGrey,
    },
    '&:focus': {
      outlineOffset: -1,
    },
  };
});

export const getProgressMessageText = (upcomingSurveys: UpcomingSurvey[], roomID: number) => {
  const now = moment();
  let progressMessageLine1 = 'Track symptoms, set goals and see how you progress';
  let progressMessageLine2 = '';
  const surveys = upcomingSurveys.filter((s) => s.roomID === roomID);
  if (surveys.length) {
    // sort to get earliest -- its the one that might be due
    const [upcomingSurvey] = surveys.sort((a, b) => a.userRoomSurveyID - b.userRoomSurveyID);
    if (upcomingSurvey.isDue) {
      progressMessageLine1 = 'Your assessment is due';
      progressMessageLine2 = moment(upcomingSurvey.dueAt).format('dddd, MMMM D');
    } else {
      const days = Math.abs(now.diff(upcomingSurvey.deliverAt, 'day'));
      const daysText = `${days} day${days > 1 ? 's' : ''}`;
      const dueDateText = days === 0 ? 'today' : `in ${daysText}`;
      progressMessageLine1 = `Your next assessment is ${dueDateText}`;
    }
  }
  return { progressMessageLine1, progressMessageLine2 };
};

const ClinicalProgressDashboardTool: FunctionComponent<ClinicalProgressDashboardToolProps> = ({
  isActive,
  history,
  match,
}) => {
  const { upcomingSurveys, surveyResultsByRoomID } = useSurveysState();
  const { treatmentPlansByRoomID } = useTreatmentPlannerState();
  const {
    adminConfigs: { isClinicalProgressEnabled },
  } = useMainState();
  const { getAdminConfigOption } = useMainActions();

  useEffect(() => {
    getAdminConfigOption('clinical_progress_web');
  }, [getAdminConfigOption]);

  const openModal = useOpenModal();

  const { progressMessageLine1, progressMessageLine2 } = getProgressMessageText(
    upcomingSurveys || [],
    Number(match.params.roomID)
  );

  const upcomingSurveysByRoomID: {
    [roomID: string]: UpcomingSurvey[];
  } = groupBy(upcomingSurveys, 'roomID');

  function handleOnPress() {
    const roomIDSet = new Set([
      ...Object.keys(surveyResultsByRoomID),
      ...Object.keys(upcomingSurveysByRoomID),
      ...Object.keys(treatmentPlansByRoomID),
    ]);
    if (roomIDSet.size > 1) {
      openModal(
        'clinical-progress-rooms',
        {
          upcomingSurveysByRoomID,
          currentRoomID: Number(match.params.roomID),
        },
        false,
        true
      );
    } else {
      history.push(`/room/${match.params.roomID}/clinical-progress`);
    }
  }
  const { colors } = useEmotionTheme();
  const isClinicalProgressPanelRendered = history.location.pathname.includes('clinical-progress');
  return isClinicalProgressEnabled ? (
    <>
      <Wrapper
        aria-label="clinical progress"
        isActive={isActive}
        onPress={() => handleOnPress()}
        aria-controls={isClinicalProgressPanelRendered ? ID_CLINICAL_PROGRESS_PANEL : undefined}
        aria-expanded={isClinicalProgressPanelRendered}
      >
        <ClinicalProgressDashboardIcon />
        <View flex={1} style={{ marginLeft: 14 }}>
          <View row justify="space-between">
            <View row flex={1} justify="space-between" align="center">
              <Big variant="bigMedium" style={{ marginBottom: 5 }}>
                Clinical progress
              </Big>
              <View row align="center">
                <ArrowRight color={colors.periwinkleGrey} />
              </View>
            </View>
          </View>
          <View style={{ height: 36, paddingRight: 26 }}>
            <Small>{progressMessageLine1}</Small>
            {progressMessageLine2 && <Small>{progressMessageLine2}</Small>}
          </View>
        </View>
      </Wrapper>
    </>
  ) : null;
};

export default withRouter(ClinicalProgressDashboardTool);
