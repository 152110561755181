import { useCallback, useState, useEffect } from 'react';
import * as React from 'react';
import {
  View,
  Large,
  Button,
  TabRadioGroup,
  ScrollView,
  useModalActions,
  useEmotionTheme,
  spacing,
  SpacingView,
} from '@talkspace/react-toolkit';
import { withRouter, RouteComponentProps } from '../../../core/routerLib';
import ClinicalProgressDashboardIcon from '../icons/ClinicalProgressDashboardIcon';
import { useMainState } from '../../../hooks/mainContext';
import { UpcomingSurvey } from '../../reducers/surveys';
import { getProgressMessageText } from '../ClinicalProgressDashboardTool';
import { useCloseModal } from '../../../utils/ModalsContextProvider';
import RoomSelectionCard from '../../../2storybook/components/RoomSelectionCard';
import { sortRoomsByLastMessageDate } from '../../../utils/extractRoomEntitiesFromState';
import { trackNavPageView } from '../../../utils/analytics/events';

const { space200, space400, space800 } = spacing;

const getMessageText = (
  upcomingSurveysByRoomID: { [roomID: string]: UpcomingSurvey[] } = {},
  roomID: number
) => {
  const { progressMessageLine1, progressMessageLine2 } = getProgressMessageText(
    upcomingSurveysByRoomID[roomID] || [],
    roomID
  );
  return `${progressMessageLine1} \n${progressMessageLine2}`;
};

const ID_THERAPIST_NAME = 'ID_THERAPIST_NAME';
const ID_CLINICAL_PROGRESS = 'ID_CLINICAL_PROGRESS';

const ClinicalProgressRoomList = ({
  location,
  isV2,
}: { isV2?: boolean } & RouteComponentProps<
  {},
  {},
  {
    currentRoomID: number;
    upcomingSurveysByRoomID?: { [roomID: string]: UpcomingSurvey[] };
  }
>) => {
  const closeModal = useCloseModal();
  const { colorRoles } = useEmotionTheme();
  const routeState = location.state || {};
  const { upcomingSurveysByRoomID, currentRoomID } = routeState;
  const [checkedRoom, setCheckedRoom] = useState<number>(currentRoomID);
  const { roomsByID, therapistsByID } = useMainState();
  const rooms = Object.values(roomsByID).sort(sortRoomsByLastMessageDate);

  useEffect(() => {
    trackNavPageView('clinical-progress-select');
  }, []);

  const handleSubmit = useCallback(() => {
    closeModal({
      navigateTo: 'clinicalProgress',
      metadata: { roomID: checkedRoom },
    });
  }, [checkedRoom, closeModal]);

  const { setModalTitle } = useModalActions();
  const titleText =
    'You are tracking your progress with multiple therapists. Select which progress area you’d like to view.';
  setModalTitle(titleText);
  return (
    <>
      <View
        row
        style={{
          justifyContent: 'center',
          marginTop: 9,
          marginBottom: 14,
        }}
      >
        <ClinicalProgressDashboardIcon width={85} height={85} iconWidth={40} iconHeight={31} />
      </View>
      <Large
        variant="largeDarkGrey"
        style={{ width: 326, marginBottom: space200, textAlign: 'center' }}
      >
        {titleText}
      </Large>
      <ScrollView style={{ maxHeight: '40vh', minWidth: 345, paddingBottom: space800 + space400 }}>
        <TabRadioGroup
          legendText="Select a room to view clinical progress"
          initialSelection={!!checkedRoom}
        >
          <SpacingView>
            {rooms.map((room, i) => (
              <RoomSelectionCard
                room={room}
                therapist={therapistsByID[room.therapistID]}
                setSelectedRoom={setCheckedRoom}
                selectedRoom={checkedRoom}
                tinyText={getMessageText(upcomingSurveysByRoomID, room.roomID)}
                key={room.roomID}
                ariaLabelledBy={`${ID_THERAPIST_NAME}-${i}`}
                ariaDescribedBy={`${ID_CLINICAL_PROGRESS}-${i}`}
                isTabFirstOption={i === 0 && !checkedRoom}
              />
            ))}
          </SpacingView>
        </TabRadioGroup>
      </ScrollView>
      {isV2 ? (
        <View
          style={{
            paddingTop: space200,
            position: 'absolute',
            bottom: 0,
            backgroundColor: colorRoles.surfaces.surfaceInteractiveDefault,
            width: '100%',
          }}
          align="center"
        >
          <Button
            text="Continue"
            onPress={handleSubmit}
            disabled={!checkedRoom}
            style={{ width: '100%' }}
          />
        </View>
      ) : (
        <Button text="Continue" onPress={handleSubmit} style={{ marginTop: 20, width: 335 }} />
      )}
    </>
  );
};

export default withRouter(ClinicalProgressRoomList);
