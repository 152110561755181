import { forwardRef } from 'react';

import { TextDS, View, spacing } from '@talkspace/react-toolkit';

import CommentBubble from './CommentBubble';
import UserTag from './UserTag';
import CommunityReferenceThreeDotMenu from './CommunityReferenceThreeDotMenu';

import { getTimestampDisplayString } from '../utils/helpers';
import { CommunityReferenceTypeEnum } from '../types';

const { space100 } = spacing;

interface Props {
  comment: Amity.Comment;
}
const CommunityPostComment = forwardRef<HTMLDivElement, Props>(({ comment }, ref) => {
  const { creator } = comment;
  const commentText = (comment as Amity.Comment<'text'>)?.data?.text;
  return (
    <View ref={ref}>
      <View row align="center" justify="space-between" style={{ padding: `${space100}px 0` }}>
        <UserTag user={creator} />
        <View row align="center">
          <TextDS>{getTimestampDisplayString(comment.createdAt)}</TextDS>
          <CommunityReferenceThreeDotMenu
            reference={comment}
            referenceType={CommunityReferenceTypeEnum.COMMENT}
          />
        </View>
      </View>

      <CommentBubble>
        <TextDS>{commentText}</TextDS>
      </CommentBubble>
    </View>
  );
});

export default CommunityPostComment;
