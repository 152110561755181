import { RefObject } from 'react';

import { EmotionStyle, StickyDrawer, View, useThemeVersion } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const BottomButtonContainerV0 = styled(View)({
  width: 335,
  position: 'absolute',
  paddingTop: 15,
  paddingBottom: 30,
  height: 'auto',
  bottom: 0,
  alignItems: 'center',
  justifyContent: 'left',
  backgroundColor: 'white',
});

interface Props {
  children: React.ReactNode;
  customV0?: JSX.Element;
  propsV0?: {
    style?: EmotionStyle;
    ref?: RefObject<HTMLDivElement>;
  };
}
const BottomButtonContainer = ({ children, customV0, propsV0 }: Props): JSX.Element => {
  const { major } = useThemeVersion();
  switch (major) {
    case 1:
      return <StickyDrawer>{children}</StickyDrawer>;
    default:
      if (customV0) {
        return customV0;
      }
      return <BottomButtonContainerV0 {...propsV0}>{children}</BottomButtonContainerV0>;
  }
};

export default BottomButtonContainer;
