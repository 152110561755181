import { useEffect, useMemo, useState, useRef } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { FormProvider, useForm } from 'react-hook-form';
import validateEmail from 'ts-frontend/utils/validateEmail';
import * as yup from 'yup';
import {
  View,
  Button,
  Large,
  Huge,
  A11Y_COLORS,
  Link,
  PanelHeader,
  Big,
  HeaderBackWrapper,
  useWindowWidthState,
  TSLogo,
} from '@talkspace/react-toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import localStorage from '@/core/storage/localStorage';
import BackButton from '../components/BackButton';
import useScreenSize, { isResponsive } from '../../core/hooks/useScreenSize';
import styled from '../../core/styled';
import useMutationParentalConsentDetails from '../hooks/useMutationSubmitParentalConsentDetails';
import RadioAccordionOption from '@/components/RadioAccordionOption';
import RadioAccordion from '@/components/RadioAccordion';
import { getUserData } from '../../auth/helpers/token';
import ConsenterDetailsForm from '../components/ConsenterDetailsForm';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

const TEEN_CONSENT_EXCEPTIONS_ARTICLE_LINK =
  'https://help.talkspace.com/hc/en-us/articles/18167691776027';

interface ParentalConsentFields {
  consenterRelationship?: string;
  consenterFirstName?: string;
  consenterLastName?: string;
  consenterEmail?: string;
  consenterLanguage?: string;
  consentDetailsFormSkipped?: boolean;
  consenterAlsoEmergencyContact?: boolean;
  shouldSendEmailImmediately?: boolean;
}

interface ConsenterDetailsFields {
  firstName: string;
  lastName: string;
  email: string;
}

type ConsenterOptionType = 'parent' | 'guardian' | 'exemption';

const StyledExceptionsLink = styled(Link)({
  marginTop: 24,
  display: 'flex',
  padding: '14px 24px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  alignSelf: 'stretch',
  color: A11Y_COLORS.accessibilityGreenDark,
  fontSize: 17,
  fontWeight: 700,
  letterSpacing: 0.5,
});

const StyledSubmitButtonContainer = styled(View)({
  display: 'inline-flex',
  width: '100%',
  paddingTop: 24,
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
});

const StyledWrapper = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundColor: 'white',
});

const StyledStickyFooter = styled('footer')({
  borderTop: `1px solid ${A11Y_COLORS.permaLondonGray}`,
  backgroundColor: A11Y_COLORS.white,
  position: 'sticky',
  bottom: 0,
  overflow: 'hidden',
  transition: 'all 0.25s ease',
});

const StyledStickyHeader = styled('header')({
  borderBottom: `1px solid ${A11Y_COLORS.permaLondonGray}`,
  backgroundColor: A11Y_COLORS.white,
  position: 'sticky',
  top: 0,
  height: 67,
  display: 'flex',
  alignItems: 'center',
});

const StyledContent = styled(View)({
  flex: '1',
});

const StyledBottomBar = styled(View)({
  width: 134,
  height: 5,
  flexShrink: 0,
  borderRadius: 100,
  backgroundColor: A11Y_COLORS.black,
});

const StyledBottomBarWrapper = styled(View)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 25,
});

const StyledButton = styled(Button)(({ theme: { colors } }) => {
  return {
    position: 'sticky',
    top: 100,
    fontFamily: 'Roboto',
    backgroundColor: colors.green,
    fontSize: 19,
    fontWeight: 'bold',
    width: 335,
    minHeight: 55,
    borderRadius: 10,
    display: 'flex',
    marginTop: 0,
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white,
    letterSpacing: 0.74,
    textAlign: 'center',
  };
});

const getDetailsSchema = (
  relation: string,
  userEmail: string | undefined
): yup.SchemaOf<ConsenterDetailsFields> =>
  yup.object().shape({
    firstName: yup
      .string()
      .test(
        'has-entered-first-name',
        `Please enter ${relation} first name.`,
        (userInput: ConsenterDetailsFields['firstName']) => !!userInput
      )
      .required(`Please enter ${relation} first name.`),
    lastName: yup
      .string()
      .test(
        'has-entered-last-name',
        `Please enter ${relation} last name.`,
        (userInput: ConsenterDetailsFields['lastName']) => !!userInput
      )
      .required(`Please enter ${relation} last name.`),
    email: yup
      .string()
      .test(
        'has-entered-email',
        `Please enter ${relation} email.`,
        (userInput: ConsenterDetailsFields['email']) => !!userInput
      )
      .test(
        'has-entered-valid-email',
        'this is not a valid email.',
        (userInput: ConsenterDetailsFields['email']) => !userInput || validateEmail(userInput)
      )
      .test(
        'has-set-different-email-than-own',
        `Different email required for ${relation}.`,
        (userInput: ConsenterDetailsFields['email']) => !userInput || userInput !== userEmail
      )
      .required(`Please enter ${relation} email.`),
    language: yup.string().optional(),
  });

interface ParentalConsentDetailsInnerContainerProps {
  onComplete: () => void;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  title;
  onCloseButtonClick?: () => void;
  userEmail: string | undefined;
  isResubmit: boolean;
}

const ParentalConsentDetailsContainerInner = ({
  onComplete,
  showBackButton = true,
  showCloseButton = false,
  title,
  onCloseButtonClick,
  userEmail,
  isResubmit,
}: ParentalConsentDetailsInnerContainerProps) => {
  const {
    mutate: postDetails,
    isLoading: isPostDetailsLoading,
    isSuccess: isPostDetailsSuccess,
    isError: isPostDetailsError,
    error: postDetailsError,
  } = useMutationParentalConsentDetails();
  const [consenterAlsoEmergencyContact, setConsenterAlsoEmergencyContact] = useState(true);
  const [consenterOption, setConsenterOption] = useState<ConsenterOptionType | undefined>(
    undefined
  );
  const screenSize = useScreenSize();
  const { isMobile } = useWindowWidthState();
  const { id: userID } = getUserData();
  const { teenUserCanSkipParentalConsent, localizationParentalConsent, brandRefreshChanges } =
    useFlags();
  const guardianDetailsRef = useRef<HTMLInputElement>(null);
  const parentDetailsRef = useRef<HTMLInputElement>(null);

  const parentDetailsSchema = getDetailsSchema('parent', userEmail);
  const guardianDetailsSchema = getDetailsSchema('guardian', userEmail);

  const allowSkip = useMemo(
    () => teenUserCanSkipParentalConsent && !isResubmit,
    [isResubmit, teenUserCanSkipParentalConsent]
  );

  const parentMethods = useForm<ConsenterDetailsFields>({
    resolver: yupResolver(parentDetailsSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      language: localizationParentalConsent ? 'en' : undefined,
    } as ConsenterDetailsFields,
  });
  const { handleSubmit: handleParentSubmit, formState: parentFormState } = parentMethods;

  const handleCloseButtonClick = () => {
    if (onCloseButtonClick) {
      onCloseButtonClick();
    }
  };

  const guardianMethods = useForm<ConsenterDetailsFields>({
    resolver: yupResolver(guardianDetailsSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      language: localizationParentalConsent ? 'en' : undefined,
    } as ConsenterDetailsFields,
  });
  const { handleSubmit: handleGuardianSubmit, formState: guardianFormState } = guardianMethods;

  useEffect(() => {
    if (isPostDetailsSuccess) {
      onComplete();
    }
  }, [isPostDetailsSuccess, onComplete]);

  const additionalHeightForParentFormErrors = useMemo(
    () => Object.keys(parentFormState.errors).length * 18,
    [parentFormState]
  );

  const resubmitExtraHeight = 34;
  const initialOptionsHeight = localizationParentalConsent ? 400 : 264;
  const baseOptionsHeight = useMemo(
    () => (isResubmit ? initialOptionsHeight : initialOptionsHeight + resubmitExtraHeight),
    [isResubmit, initialOptionsHeight]
  );

  const additionalHeightForGuardianFormErrors = useMemo(
    () => Object.keys(guardianFormState.errors).length * 18,
    [guardianFormState]
  );

  const sendFormNoticeCopy = useMemo(
    () =>
      allowSkip
        ? 'We’ll send a form to your parent or guardian, but we won’t share why you’re seeking therapy. There are a few exceptions where consent isn’t needed. If you fall under one, your therapist will reach out to discuss.'
        : 'We’ll send a form to your parent or guardian, but we won’t share why you’re seeking therapy.',
    [allowSkip]
  );

  const stickyFooterHeight = useMemo(() => {
    if (!consenterOption) {
      return 0;
    }
    return isMobile ? 120 : 104;
  }, [consenterOption, isMobile]);

  const submitFormData = (data: ParentalConsentFields) => {
    postDetails({
      userID,
      data,
    });
  };

  const onSubmit = (data) => {
    localStorage.setItem(
      'parentalConsentLanguage',
      JSON.stringify({ consenterLanguage: data.language })
    );
    submitFormData({
      consenterRelationship: consenterOption,
      consenterFirstName: data.firstName,
      consenterLastName: data.lastName,
      consenterEmail: data.email,
      consenterLanguage: data.language,
      consentDetailsFormSkipped: false,
      consenterAlsoEmergencyContact,
      shouldSendEmailImmediately: isResubmit,
    });
  };

  const onSubmitExemption = () => {
    submitFormData({
      consentDetailsFormSkipped: true,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    switch (consenterOption) {
      case 'parent':
        handleParentSubmit(onSubmit)(e);
        break;
      case 'guardian':
        handleGuardianSubmit(onSubmit)(e);
        break;
      case 'exemption':
        onSubmitExemption();
        break;
    }
  };

  const handleRadioClick = (inputRef) => {
    if (inputRef?.current) {
      const element = inputRef.current.querySelector('[name="firstName"]');
      if (element) {
        setTimeout(() => {
          element.focus();
        }, 300);
      }
    }
  };

  return (
    <StyledWrapper>
      <StyledStickyHeader>
        <PanelHeader
          renderLeft={() => (
            <>
              {isMobile ? (
                <>
                  <View style={{ width: 13 }}>
                    {showBackButton && (
                      <HeaderBackWrapper>
                        <BackButton />
                      </HeaderBackWrapper>
                    )}
                  </View>
                  <View flex={1} align={isMobile ? 'center' : 'start'}>
                    <Big>{!isResubmit && title}</Big>
                  </View>
                </>
              ) : (
                <TSLogo variant={brandRefreshChanges ? '2024' : 'old'} />
              )}
            </>
          )}
          onRightPress={showCloseButton ? () => handleCloseButtonClick() : undefined}
          dataQa="parentalConsentDetailsCloseButtonDataQa"
        />
      </StyledStickyHeader>
      <StyledContent>
        {!isMobile && (
          <View style={{ paddingTop: 52, paddingLeft: 32 }}>
            {showBackButton && <BackButton />}
          </View>
        )}
        <View align="center">
          <View
            align="center"
            style={{
              marginTop: isResponsive(screenSize) ? 30 : 60,
              marginBottom: 29,
              maxWidth: 335,
            }}
          >
            <Huge style={{ marginBottom: 10, textAlign: 'center' }}>
              Who should we send a consent form to?
            </Huge>
            <View
              style={{
                textAlign: 'center',
                width: 335,
              }}
            >
              <Large style={{ color: A11Y_COLORS.permaGrey }}>{sendFormNoticeCopy}</Large>
            </View>
          </View>
          {isPostDetailsError && <View style={{ color: 'red' }}>{postDetailsError?.message} </View>}
          <RadioAccordion
            onChange={(value) => {
              setConsenterOption(value as ConsenterOptionType);
            }}
          >
            <RadioAccordionOption
              title="Parent"
              value="parent"
              contentHeight={baseOptionsHeight + additionalHeightForParentFormErrors}
              color={A11Y_COLORS.accessibilityGreenDark}
              dataQa="conesnterRadioOptionParent"
              onRadioChange={() => handleRadioClick(parentDetailsRef)}
            >
              <FormProvider {...parentMethods}>
                <ConsenterDetailsForm
                  consenterAlsoEmergencyContact={consenterAlsoEmergencyContact}
                  setConsenterAlsoEmergencyContact={setConsenterAlsoEmergencyContact}
                  showConsenterAlsoEmergencyContactCheckbox={!isResubmit}
                  relation="parent"
                  detailsRef={parentDetailsRef}
                />
              </FormProvider>
            </RadioAccordionOption>
            <RadioAccordionOption
              title="Guardian"
              value="guardian"
              contentHeight={baseOptionsHeight + additionalHeightForGuardianFormErrors}
              color={A11Y_COLORS.accessibilityGreenDark}
              dataQa="conesnterRadioOptionGuardian"
              onRadioChange={() => handleRadioClick(guardianDetailsRef)}
            >
              <FormProvider {...guardianMethods}>
                <ConsenterDetailsForm
                  consenterAlsoEmergencyContact={consenterAlsoEmergencyContact}
                  setConsenterAlsoEmergencyContact={setConsenterAlsoEmergencyContact}
                  showConsenterAlsoEmergencyContactCheckbox={!isResubmit}
                  relation="guardian"
                  detailsRef={guardianDetailsRef}
                />
              </FormProvider>
            </RadioAccordionOption>
            <RadioAccordionOption
              title="I'm exempt from consent"
              description="I'll discuss this with my new therapist."
              value="exemption"
              noContent
              hidden={!allowSkip}
              contentHeight={0}
              color={A11Y_COLORS.accessibilityGreenDark}
              dataQa="conesnterRadioOptionExemption"
            />
          </RadioAccordion>
          {allowSkip && (
            <StyledExceptionsLink
              dataQa="teenConsentExceptionArticleLink"
              href={TEEN_CONSENT_EXCEPTIONS_ARTICLE_LINK}
              target="_blank"
            >
              See consent exceptions
            </StyledExceptionsLink>
          )}
        </View>
      </StyledContent>
      <StyledStickyFooter style={{ height: stickyFooterHeight }}>
        <StyledSubmitButtonContainer>
          <form onSubmit={handleSubmit}>
            <StyledButton
              type="submit"
              style={{ width: 335, backgroundColor: A11Y_COLORS.green, padding: '14px 24px' }}
              text={consenterOption === 'exemption' ? 'Continue' : 'Send consent form'}
              isLoading={isPostDetailsLoading}
              disabled={!consenterOption}
              dataQa="submitParentalConsentFormButton"
            />
          </form>
        </StyledSubmitButtonContainer>
        {isMobile ? (
          <StyledBottomBarWrapper>
            <StyledBottomBar
              style={{
                width: 134,
                height: 5,
                flexShrink: 0,
                borderRadius: 100,
                backgroundColor: A11Y_COLORS.black,
              }}
            />
          </StyledBottomBarWrapper>
        ) : (
          <View style={{ height: 50 }} />
        )}
      </StyledStickyFooter>
    </StyledWrapper>
  );
};

interface ParentalConsentDetailsContainerProps {
  onComplete: () => void;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  title;
  onCloseButtonClick?: () => void;
  isResubmit?: boolean;
}

const ParentalConsentDetailsContainer = ({
  onComplete,
  showBackButton = true,
  showCloseButton = false,
  title,
  onCloseButtonClick,
  isResubmit = false,
}: ParentalConsentDetailsContainerProps) => {
  const { id: userID } = getUserData();
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getUserEmail = async () => {
      const userEmailRes = await apiWrapper.get(
        `${apiHelper().apiEndpoint}/v2/users/${userID}/email`
      );
      setUserEmail(userEmailRes?.data?.data?.email || 'email_not_found');
    };

    getUserEmail();
  }, [isResubmit, userID]);

  return userEmail ? (
    <ParentalConsentDetailsContainerInner
      onComplete={onComplete}
      title={title}
      userEmail={userEmail}
      showBackButton={showBackButton}
      showCloseButton={showCloseButton}
      onCloseButtonClick={onCloseButtonClick}
      isResubmit={isResubmit}
    />
  ) : (
    <></>
  );
};

export default ParentalConsentDetailsContainer;
