import { useEffect, VoidFunctionComponent } from 'react';
import { View, Button, Tiny, Large, useEmotionTheme } from '@talkspace/react-toolkit';
import { trackCancellationPolicyPrompt } from 'ts-analytics/mixpanel/events';
import styled from '@/core/styled';

import { getUserData } from '@/auth/helpers/token';
import { TherapistInfo } from '../../types';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';
import BookingTimeZone from '../BookingTimeZone';
import BottomButtonContainer from '../BottomButtonContainer';

const Paragraph = styled(Large)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      textAlign: 'center',
      maxWidth: isMobile ? 335 : 340,
    };
  }
);

interface Props {
  therapist?: TherapistInfo;
  timezone: string;
  handleReserveClick: () => void;
  onClickCancellationPolicy: () => void;
}

const ConfirmBookingWithPolicy: VoidFunctionComponent<Props> = ({
  therapist,
  timezone,
  handleReserveClick,
  onClickCancellationPolicy,
}) => {
  const {
    isLoading,
    selectedCreditOption,
    modality,
    room,
    selectedConfirmBooking,
    selectedBookingToReschedule,
    schedulerMode,
  } = useInRoomSchedulingState();
  const { id: userID } = getUserData();

  useEffect(() => {
    if (
      schedulerMode === 'clientScheduled' ||
      selectedConfirmBooking?.modality ||
      selectedBookingToReschedule?.modality
    ) {
      trackCancellationPolicyPrompt({
        userID,
        roomID: room?.roomID!,
        providerID: therapist?.id!,
        planID: selectedCreditOption?.planID!,
        sessionModality:
          selectedConfirmBooking?.modality || selectedBookingToReschedule?.modality || modality,
        sessionLength: selectedCreditOption?.creditMinutes || null,
      });
    }
  }, [
    modality,
    room?.roomID,
    schedulerMode,
    selectedBookingToReschedule?.modality,
    selectedConfirmBooking,
    selectedCreditOption?.creditMinutes,
    selectedCreditOption?.planID,
    therapist?.id,
    userID,
  ]);

  const { colorRoles } = useEmotionTheme();

  return (
    <>
      <View flex={1} align="center">
        <BookingTimeZone timezone={timezone} />
        <Paragraph variant="largeDarkGrey" style={{ marginTop: 20, marginBottom: 20 }}>
          If you need to reschedule or cancel session, you may do so for free until 24 hours before
          your appointment’s start time.
        </Paragraph>
        <Paragraph variant="largeDarkGrey">
          Late cancellations or no-shows will result in a fee for the cost of service, which can be
          <Large variant="largeDarkGrey" style={{ fontWeight: 700 }} inline>
            <br />
            up to $175.
          </Large>
        </Paragraph>
      </View>

      <BottomButtonContainer
        propsV0={{
          style: {
            width: 335,
            position: 'absolute',
            height: 65,
            bottom: 69,
            alignItems: 'center',
            justifyContent: 'left',
            backgroundColor: 'white',
          },
        }}
      >
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onPress={handleReserveClick}
          text="I agree"
          dataQa="inRoomSchedulingConfirmBookingIAgree"
          stretch
        />
        <Tiny
          style={{ textAlign: 'center', marginTop: 16, color: colorRoles.typography.textSubtlest }}
        >
          By clicking “I agree” you are agreeing to
          <Tiny
            inline
            variant="tinyBoldGreen"
            style={{ cursor: 'pointer', color: colorRoles.typography.textBrandDefault }}
            onPress={onClickCancellationPolicy}
          >
            {' '}
            Talkspace Cancellation Policy
          </Tiny>
        </Tiny>
      </BottomButtonContainer>
    </>
  );
};

export default ConfirmBookingWithPolicy;
