import { useMemo, useCallback, useEffect } from 'react';
import useDashboardSocket from './useDashboardSocket';
import useRoomSubscriptionSocket from './useRoomSubscriptionSocket';
import { useMainActions } from './mainContext';
import { useNavShellDeepLinks } from '../utils/deeplinksHelper';
import useChatContainerSocket from './useChatContainerSocket';
import useNavShellData from './useNavShellData';

const useNavShellEffects = () => {
  // sets up websockets, redirects, deeplinks, and possibly also the getLastMessagesPromiseWrapper call
  // This hook will require the aggregatedRoomData as a parameter, so this hook will depend on useNavShellData
  // This hook may require options to enable and disable certain effects if we need to use this hook in multiple places
  const { getLVSSubscriptions } = useMainActions();
  const {
    aggregatedRoomData: { userID, rooms },
    queries: {
      refetchSubscriptions,
      refetchLastMessages,
      refetchGetClientInfo,
      refetchBookingsAllRooms,
      refetchAllRooms,
      refetchActiveSessionAllRooms,
      refetchOnboarding,
    },
  } = useNavShellData();

  const getAllRoomsPromiseWrapper = useCallback(
    (userIDParam: number) =>
      new Promise<null>((resolve) => {
        refetchAllRooms();
        resolve(null);
      }),
    [refetchAllRooms]
  );

  const getLastMessagesPromiseWrapper = useCallback(
    (userIDParam: number) =>
      new Promise<null>((resolve) => {
        refetchBookingsAllRooms();
        refetchAllRooms();
        refetchLastMessages();
        refetchActiveSessionAllRooms();
        resolve(null);
      }),
    [refetchLastMessages, refetchBookingsAllRooms, refetchAllRooms, refetchActiveSessionAllRooms]
  );

  const refreshOnBookingUpdate = useCallback(
    async (bookingID: string) => {
      refetchBookingsAllRooms();
      refetchActiveSessionAllRooms();
      return null;
    },
    [refetchBookingsAllRooms, refetchActiveSessionAllRooms]
  );

  const refreshSubscriptions = useCallback(async () => {
    getLVSSubscriptions();
    refetchSubscriptions();
    return null;
  }, [getLVSSubscriptions, refetchSubscriptions]);

  const refreshGetClientInfo = useCallback(async () => {
    refetchGetClientInfo();
    return null;
  }, [refetchGetClientInfo]);

  const getOnboardingWrapper = useCallback(
    () =>
      new Promise<null>((resolve) => {
        refetchOnboarding();
        resolve(null);
      }),
    [refetchOnboarding]
  );

  useDashboardSocket({
    getAllRooms: getAllRoomsPromiseWrapper,
    getLastMessages: getLastMessagesPromiseWrapper,
    getBooking: refreshOnBookingUpdate,
    getOnboarding: getOnboardingWrapper,
    watchRoomIDs: rooms?.map((room) => room.roomID.toString()),
    getClientInfo: refreshGetClientInfo,
  });
  useRoomSubscriptionSocket({ getLVSSubscriptions: refreshSubscriptions });
  useChatContainerSocket({ refetchRoomDetails: getAllRoomsPromiseWrapper });

  // If user has only one room, use that planID for deeplink actions
  const planID = useMemo(() => {
    if (rooms?.length === 1) {
      const room = rooms[0];
      const { planID: roomPlanID } = room;
      return roomPlanID;
    }
    return null;
  }, [rooms]);

  useNavShellDeepLinks({ planID });

  useEffect(() => {
    getLastMessagesPromiseWrapper(userID);
  }, [getLastMessagesPromiseWrapper, userID]);
};

export default useNavShellEffects;
