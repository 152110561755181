import { Notepad } from '../../components/icons';
import ForYouCard, { ForYouCardProps } from './ForYouCard';

interface PreSessionQuestionsCardProps
  extends Pick<ForYouCardProps, 'onPress' | 'disabled' | 'dataQa'> {}

const PreSessionQuestionsCard = ({ onPress, disabled, dataQa }: PreSessionQuestionsCardProps) => (
  <ForYouCard
    dataQa={dataQa}
    onPress={onPress}
    disabled={disabled}
    Icon={<Notepad />}
    titleText="Pre-session questions"
    bodyText="A few prompts to help you prepare for your next session"
  />
);

export default PreSessionQuestionsCard;
