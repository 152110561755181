import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import { View, Spinner } from '@talkspace/react-toolkit';
import API from './LaunchDarklyApiHelper';
import { getUserData } from '@/auth/helpers/token';
import appConfig from '@/utils/configs';

const LDIdentifierForTherapist: FunctionComponent<{}> = ({
  children,
  stateAuth,
}: {
  children: React.ReactNode;
  stateAuth: { isAuthenticated: boolean; user: { id: number } };
}) => {
  const [user, setUser] = useState<any | null | undefined>(undefined);
  const { isAuthenticated, user: stateUser } = stateAuth || {};
  const getUserAndIdentify = useCallback(async () => {
    const userData = getUserData();
    if (!isAuthenticated && !stateUser.id && (!userData || !userData.id)) {
      setUser(null);
      return;
    }
    try {
      const ldUser = await API.getTherapistUserForLD(userData.id || stateUser.id);
      setUser(ldUser);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setUser(null);
    }
  }, [isAuthenticated, stateUser]);

  useEffect(() => {
    getUserAndIdentify();
  }, [getUserAndIdentify]);

  if (user === undefined) {
    return (
      <View style={{ height: '100vh' }}>
        <View flex={1}>
          <Spinner isLoading />
        </View>
      </View>
    );
  }

  return (
    <LDProvider
      key={`ld-user-${user?.key}`}
      context={{
        ...user?.custom,
        kind: 'user',
        key: user?.key || 'anonymous-provider-user',
      }}
      clientSideID={appConfig.launchDarkly.sdkKey}
      options={{
        bootstrap: 'localStorage',
      }}
    >
      {children}
    </LDProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    stateAuth: state.auth,
  };
};

const LDTherapistIdentifier = connect(mapStateToProps)(LDIdentifierForTherapist);

export default LDTherapistIdentifier;
