import * as React from 'react';
import Svg, { G, Path, Circle } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface OffersIconProps {
  hide?: boolean;
  fillColor?: string;
}

const OffersIcon = ({ hide, fillColor, ...otherProps }: OffersIconProps) => {
  const { colors } = useEmotionTheme();
  const color = fillColor || colors.green;
  const secondaryColor = colors.white;
  const titleText = 'offer';
  if (hide) return null;
  return (
    <Svg
      width="45px"
      height="45px"
      viewBox="0 0 42 42"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <G
        transform="translate(-24 -14)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path fill={secondaryColor} d="M0 0L825 0 825 67 0 67z" />
        <G
          transform="translate(30 21) rotate(-45 14.647 4.186)"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <Path
            d="M0 6.042L7.25 0l7.25 6.042v13.291H0V6.042z"
            strokeWidth={2}
            fill={secondaryColor}
          />
          <Circle fill={color} cx={7.25} cy={7.25} r={1.20833333} />
        </G>
      </G>
    </Svg>
  );
};

export default OffersIcon;
