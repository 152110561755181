import React, { forwardRef } from 'react';
import styled, { useEmotionTheme } from '../../../core/styled';
import View from '../../../components/View';
import SpacingView from '../SpacingView';
import { useWindowWidthState } from '../../../hooks/windowWidthContext';
import { spacing } from '../../tokens';
import TextDS, { TextDSVariant } from '../typography/TextDS';
import { CardContentProps, CardContentVariant } from './types';
import { ChevronRight } from '../../icons';

const { space200, space300 } = spacing;

const LeftColumn = styled(View)<{ variant: CardContentVariant }>(({ variant }) => {
  return {
    width: variant === 'regular' ? 64 : 'fit-content',
    marginRight: space200,
  };
});

const CardText = ({ copy, variant }: { copy: string | React.ReactNode; variant: TextDSVariant }) =>
  typeof copy === 'string' ? (
    <TextDS variant={variant} colorRole="textDefault">
      {copy}
    </TextDS>
  ) : (
    <>{copy}</>
  );

const CardContentView = forwardRef<HTMLDivElement, CardContentProps>(
  (props: CardContentProps, ref) => {
    const {
      centerContent,
      centerIcon,
      variant = 'regular',
      enableBottomRowHover,
      enableRightColHover,
      hideCaret,
      forceColumn = false,
      Icon,
      titleText,
      bodyText,
      TopRow,
      BottomRow,
      RightCol,
      Menu,
      isAccountCard,
      itemSpacing,
      bottomRowFullWidth = true,
      bottomRowLeftMargin,
      onChildMouseEnter,
      onChildMouseLeave,
    } = props;
    const { isSmall, isMobile } = useWindowWidthState();
    const { colorRoles } = useEmotionTheme();
    const newNavVariant = isAccountCard ? 'body' : 'headingMd';
    const newNavBodyText = isAccountCard ? (
      <View
        style={{ color: colorRoles.typography.textSubtlest, display: isMobile ? 'contents' : '' }}
      >
        {bodyText}
      </View>
    ) : (
      <CardText copy={bodyText} variant="bodySm" />
    );

    return (
      <>
        <View row flex={1} ref={ref}>
          {!!Menu && (
            <View
              onMouseEnter={enableRightColHover ? onChildMouseEnter : undefined}
              onMouseLeave={enableRightColHover ? onChildMouseLeave : undefined}
              style={{ top: 16, right: 14, position: 'absolute' }}
            >
              {Menu}
            </View>
          )}
          {!!Icon && (
            <LeftColumn align="center" justify={centerIcon ? 'center' : 'start'} variant={variant}>
              {Icon}
            </LeftColumn>
          )}
          <View row align={centerContent ? 'center' : 'start'} justify="space-between" flex={1}>
            <SpacingView
              itemSpacing={itemSpacing || (variant === 'slim' ? 'space0' : undefined)}
              centerContent={centerContent}
            >
              {TopRow && (
                <View
                  style={Menu ? { marginRight: space300 } : undefined}
                  wrap="wrap"
                  row
                  flex={1}
                  rowGap={6}
                >
                  {TopRow}
                </View>
              )}
              {titleText && <CardText copy={titleText} variant={newNavVariant} />}
              {bodyText && newNavBodyText}
            </SpacingView>
            {!hideCaret && !Menu && !RightCol && (
              <View justify={isSmall ? 'start' : 'center'}>
                <ChevronRight colorType="brand" />
              </View>
            )}
            {!!RightCol && (!isMobile || forceColumn) && (
              <View
                justify="center"
                onMouseEnter={enableRightColHover ? onChildMouseEnter : undefined}
                onMouseLeave={enableRightColHover ? onChildMouseLeave : undefined}
              >
                {RightCol}
              </View>
            )}
          </View>
        </View>
        {!!RightCol && isMobile && !forceColumn && (
          <View
            justify="center"
            onMouseEnter={enableRightColHover ? onChildMouseEnter : undefined}
            onMouseLeave={enableRightColHover ? onChildMouseLeave : undefined}
          >
            {RightCol}
          </View>
        )}
        {BottomRow && bottomRowFullWidth && (
          <View
            wrap="wrap"
            row
            flex={1}
            style={{ marginTop: space200 }}
            onMouseEnter={enableBottomRowHover ? onChildMouseEnter : undefined}
            onMouseLeave={enableBottomRowHover ? onChildMouseLeave : undefined}
          >
            {BottomRow}
          </View>
        )}
        {BottomRow && !bottomRowFullWidth && (
          <View
            wrap="wrap"
            row
            flex={1}
            style={{ marginTop: space200, width: isMobile ? '100%' : 'fit-content' }}
          >
            {!isMobile && <View style={{ width: bottomRowLeftMargin }} />}
            <View
              onMouseEnter={enableBottomRowHover ? onChildMouseEnter : undefined}
              onMouseLeave={enableBottomRowHover ? onChildMouseLeave : undefined}
              flex={1}
            >
              {BottomRow}
            </View>
          </View>
        )}
      </>
    );
  }
);

export default CardContentView;
