import {
  SquarePlus,
  Phone,
  CircleQuestion,
  PenToSquare,
  Files,
} from '@talkspace/react-toolkit/src/designSystems/icons';
import {
  immediateCrisisSupportLink,
  helpNowLink,
  helpCenterLink,
  reportAnIssueLink,
  viewMyTicketsLink,
} from 'ts-frontend/constants';
import ssoHelper, { ZendeskPath } from '@/utils/sso';
import { AccountCard } from '../../types';

export const crisisSupportCards: AccountCard[] = [
  {
    titleText: 'Call +1(833) 929-1721',
    bodyText: '24/7 immediate crisis support',
    Icon: <Phone size="major" colorType="brand" />,
    hideCaret: false,
    route: immediateCrisisSupportLink,
    dataQa: 'accountHelpCallCrisisSupport',
  },
  {
    titleText: 'Emergency resources',
    bodyText: 'More hotlines and helpful websites',
    Icon: <SquarePlus size="major" colorType="brand" />,
    route: helpNowLink,
    hideCaret: false,
    dataQa: 'accountHelpEmergencyResources',
  },
];

export const technicalSupportCards: AccountCard[] = [
  {
    titleText: 'Visit our help center',
    Icon: <CircleQuestion size="major" colorType="brand" />,
    hideCaret: false,
    route: helpCenterLink,
    dataQa: 'accountHelpVisitHelpCenter',
    zendeskPath: ssoHelper.ZendeskPath.KNOWLEDGE_BASE as ZendeskPath,
  },
  {
    titleText: 'Report an issue',
    Icon: <PenToSquare size="major" colorType="brand" />,
    hideCaret: false,
    route: reportAnIssueLink,
    dataQa: 'accountHelpReportAnIssue',
    zendeskPath: ssoHelper.ZendeskPath.CONTACT_US as ZendeskPath,
  },
  {
    titleText: 'View my tickets',
    Icon: <Files size="major" colorType="brand" />,
    hideCaret: false,
    route: viewMyTicketsLink,
    dataQa: 'accountHelpViewTickets',
    zendeskPath: ssoHelper.ZendeskPath.MY_TICKETS as ZendeskPath,
  },
];
