/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: @Eric remove above line
import { useEffect, useMemo, useState } from 'react';
import { states, countries } from '@talkspace/configs';
import { Styles } from 'react-select';
import { useFormContext } from 'react-hook-form';
import { OptionType } from '../Select';
import styled, { EmotionTheme, useEmotionTheme } from '../../core/styled';
import View from '../View';
import RHFSelect from '../RHFSelect';

const SpaceBetween = styled(View)({
  width: 10,
});

const CountryStateDropDownContainer = styled(View)<{ maxWidth: number }>(({ maxWidth }) => {
  return {
    flexDirection: 'row',
    alignItems: 'baseline',
    marginBottom: 18,
    maxWidth,
    width: '100%',
  };
});

// Temporary fix until https://github.com/react-hook-form/react-hook-form/discussions/6665
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Path<T> = any;

export interface RHFCountryStateDropDownProps<
  TFieldValues extends Record<string, string>,
  FieldName extends Path<TFieldValues> = Path<TFieldValues>
> {
  maxWidth: number;
  isDisabled?: boolean;
  countryFieldName: FieldName;
  stateFieldName: FieldName;
}

const topCountries: OptionType[] = [
  { label: 'United States', value: 'US' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Canada', value: 'CA' },
  { label: 'Australia', value: 'AU' },
  { label: '', value: '', isDisabled: true },
];

const getCountryDropdownStyles = (
  colors: EmotionTheme['colors'],
  maxWidth: number
): Styles<OptionType, boolean> => {
  return {
    option: (provided, { data }) => {
      return {
        ...provided,
        ...(data.isDisabled && {
          cursor: 'default',
          backgroundColor: colors.permaLinkWaterGrey,
          boxShadow: 'none',
          marginTop: 2,
          padding: 0,
          '&:hover': {
            backgroundColor: colors.permaLinkWaterGrey,
          },
        }),
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        width: maxWidth,
      };
    },
  };
};

const RHFCountryStateDropDown = <TFieldValues extends Record<string, string>>(
  props: RHFCountryStateDropDownProps<TFieldValues>
) => {
  const { maxWidth, countryFieldName, stateFieldName, isDisabled = false } = props;
  const { watch } = useFormContext<TFieldValues>();
  const countryValue = watch(countryFieldName);
  const countryOptions = useMemo(() => {
    const memoCountryOptions = Object.entries(countries)
      .map(([value, label]) => {
        return { value, label };
      })
      .filter(({ value }) => !topCountries.map((option) => option.value).includes(value));
    return topCountries.concat(memoCountryOptions);
  }, []);

  const stateOptions = useMemo(
    () =>
      Object.entries(states).map(([value, label]) => {
        return { value, label };
      }),
    []
  );

  const [showStates, setShowStates] = useState(true);
  const { colors } = useEmotionTheme();
  const statesWidth = Math.round(maxWidth / 2) - 5;

  useEffect(() => {
    if (countryValue === 'US') {
      setShowStates(true);
    } else {
      setShowStates(false);
    }
  }, [countryValue]);

  return (
    <CountryStateDropDownContainer maxWidth={maxWidth}>
      <RHFSelect
        fieldName={countryFieldName}
        label="Country"
        placeholder="Country"
        options={countryOptions}
        containerStyle={{ maxWidth: showStates ? statesWidth : maxWidth }}
        isDisabled={isDisabled}
        selectStyle={getCountryDropdownStyles(colors, maxWidth)}
        registerOptionValue
      />
      {showStates && (
        <>
          <SpaceBetween />
          <RHFSelect
            fieldName={stateFieldName}
            label="State"
            placeholder="State"
            options={stateOptions}
            registerOptionValue
            containerStyle={{ maxWidth: statesWidth }}
            selectStyle={{
              menu: (provided) => {
                return {
                  ...provided,
                  width: maxWidth,
                  position: 'absolute',
                  right: 1,
                };
              },
            }}
          />
        </>
      )}
    </CountryStateDropDownContainer>
  );
};

export default RHFCountryStateDropDown;
