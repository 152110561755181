import { VoidFunctionComponent } from 'react';
import { View, BaseButton, Button, Large, Big, useEmotionTheme } from '@talkspace/react-toolkit';

import BottomButtonContainer from '../BottomButtonContainer';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';
import BookingTimeZone from '../BookingTimeZone';
import { useFlags } from '../../../launchDarkly/FlagsProvider';

interface Props {
  onClosePress: () => void;
  bookWithIntroSession?: boolean;
  isReschedule: boolean;
  timezone: string;
  handleReserveClick: () => void;
  onClickCancellationPolicy: () => void;
}

const ConfirmBookingWithoutPolicy: VoidFunctionComponent<Props> = ({
  onClosePress,
  timezone,
  bookWithIntroSession = false,
  isReschedule,
  handleReserveClick,
  onClickCancellationPolicy,
}) => {
  const { isLoading, room } = useInRoomSchedulingState();
  const { postLvsCheckInNoBookings } = useFlags();
  const { colorRoles } = useEmotionTheme();
  return (
    <>
      <View flex={1} align="center">
        <BookingTimeZone timezone={timezone} />
        {!room?.isEAP && (
          <BaseButton style={{ marginTop: 40 }} onPress={onClickCancellationPolicy}>
            <Large
              variant="largeBoldWideGreen"
              style={{
                color: postLvsCheckInNoBookings
                  ? colorRoles.typography.textBrandDefault
                  : undefined,
              }}
            >
              Review cancellation policy
            </Large>
          </BaseButton>
        )}
      </View>

      <BottomButtonContainer>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onPress={handleReserveClick}
          text={isReschedule ? 'Confirm rescheduling' : 'Book session'}
          dataQa={`inRoomSchedulingConfirmBooking${isReschedule ? 'Reschedule' : 'Schedule'}`}
          style={{ backgroundColor: colorRoles.system.actionPrimaryDefault }}
          stretch
        />
        {bookWithIntroSession && (
          <BaseButton
            style={{ marginTop: 20 }}
            onPress={onClosePress}
            data-qa="inRoomSchedulingConfirmBookingReserveSessionLater"
          >
            <Big variant="bigMedium">I’ll reserve this session later</Big>
          </BaseButton>
        )}
      </BottomButtonContainer>
    </>
  );
};

export default ConfirmBookingWithoutPolicy;
