import { useEmotionTheme, View } from '@talkspace/react-toolkit';
import DailyPrompt from './DailyPrompt';
import TeenspaceLeftWing from '../../assets/icons/TeenspaceLeftWing';
import TeenspaceRightWing from '../../assets/icons/TeenspaceRightWing';

const DailyPromptView = ({ sentence, replyCount }) => {
  const { colorRoles } = useEmotionTheme();

  return (
    <View row style={{ width: '100%' }}>
      <View style={{ flexShrink: 0 }}>
        <TeenspaceLeftWing />
      </View>
      <View
        flex={1}
        justify="center"
        align="center"
        style={{
          backgroundColor: colorRoles.surfaces.decorativeLiftedLemon,
          width: '100%',
        }}
      >
        <DailyPrompt sentence={sentence} replyCount={replyCount} />
      </View>
      <View style={{ flexShrink: 0 }}>
        <TeenspaceRightWing />
      </View>
    </View>
  );
};

export default DailyPromptView;
