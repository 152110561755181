import {
  Grid,
  Col,
  View,
  SpacingView,
  Card,
  TextDS,
  useWindowWidth,
  Tag,
  spacing,
} from '@talkspace/react-toolkit';
import { crisisSupportCards, technicalSupportCards } from './helpCards';
import ssoHelper from '@/utils/sso';
import HelpCard from './HelpCard';

const { space150 } = spacing;

export interface UserSettings {
  sendMessagesReceipts: boolean;
}

const HelpScreen = () => {
  const { isMobile } = useWindowWidth();

  return (
    <Grid fluid rowSpacing={isMobile ? 'space0' : 'space400'}>
      <Col sm={8} lg={8}>
        <SpacingView itemSpacing="space200" row={false}>
          <TextDS variant="heading2xl">Help</TextDS>

          <View>
            <SpacingView itemSpacing="space0" row={false}>
              <View as="span" style={{ display: 'inline' }}>
                <TextDS variant="headingLg" as="span">
                  Crisis support
                </TextDS>
                <Tag
                  color="criticalBoldDefault"
                  text="SOS"
                  style={{ padding: '1px 8px', marginLeft: space150 }}
                  inline
                />
              </View>

              {crisisSupportCards.map(({ dataQa, route, ...props }) => (
                <Card
                  variant="noBorder"
                  padding="slim"
                  onPress={() => {
                    window.open(route, '_blank');
                  }}
                  dataQa={dataQa}
                >
                  <HelpCard {...props} />
                </Card>
              ))}
            </SpacingView>
          </View>

          <View>
            <SpacingView itemSpacing="space0" row={false}>
              <TextDS variant="headingLg">Technical support</TextDS>
              {technicalSupportCards.map(({ dataQa, zendeskPath, route, ...props }) => (
                <Card
                  variant="noBorder"
                  padding="slim"
                  onPress={() => {
                    if (zendeskPath) {
                      ssoHelper.openZendesk(zendeskPath);
                    } else {
                      window.open(route, '_blank');
                    }
                  }}
                  dataQa={dataQa}
                >
                  <HelpCard {...props} />
                </Card>
              ))}
            </SpacingView>
          </View>
        </SpacingView>
      </Col>
    </Grid>
  );
};

export default HelpScreen;
