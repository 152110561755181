import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M10 2.39648C12.1217 2.39648 14.1566 3.23934 15.6569 4.73963C17.1571 6.23992 18 8.27475 18 10.3965C18 12.5182 17.1571 14.553 15.6569 16.0533C14.1566 17.5536 12.1217 18.3965 10 18.3965C7.87827 18.3965 5.84344 17.5536 4.34315 16.0533C2.84285 14.553 2 12.5182 2 10.3965C2 8.27475 2.84285 6.23992 4.34315 4.73963C5.84344 3.23934 7.87827 2.39648 10 2.39648ZM9.25 6.14648V10.3965C9.25 10.6465 9.375 10.8809 9.58437 11.0215L12.5844 13.0215C12.9281 13.2527 13.3938 13.159 13.625 12.8121C13.8562 12.4652 13.7625 12.0027 13.4156 11.7715L10.75 9.99648V6.14648C10.75 5.73086 10.4156 5.39648 10 5.39648C9.58437 5.39648 9.25 5.73086 9.25 6.14648Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
};

const Clock = withDSIconMaker(pathConfig, 'clock');

export default Clock;
