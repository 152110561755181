import { FunctionComponent, useState, useEffect, useRef } from 'react';
import * as React from 'react';
import { setFunnelName } from 'ts-frontend/hooks/funnelName';
import { Route, useHistory, useParams } from '@/core/routerLib/routerLib';
import ChatScreen from './screens/ChatScreen';
import VideoCallScreen from './screens/VideoCallScreen';
import sessionStorage from '@/core/storage/sessionStorage';

const Routes: FunctionComponent<{
  isAcking?: boolean;
  shouldPlaySoundNotifications?: boolean;
  style?: {};
  banners?: JSX.Element[];
  priorityBanner?: JSX.Element;
  isTherapistChat?: boolean;
  stopInputAutofocus?: boolean;
  isFocusedAfterModal: boolean;
  isOffline?: boolean;
  outsideHeaderHeight: number;
  outsideFooterHeight: number;
}> = ({
  isAcking,
  shouldPlaySoundNotifications,
  style,
  banners,
  priorityBanner,
  isTherapistChat,
  stopInputAutofocus,
  isFocusedAfterModal,
  isOffline,
  outsideHeaderHeight,
  outsideFooterHeight,
}) => {
  const [isLVSModalOpen, setIsLVSModalOpen] = useState(false);
  const history = useHistory();
  const params = useParams<{ roomID: string }>();
  const replacePath = useRef(history.location.pathname + history.location.search);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const videoSessionInfo = JSON.parse(sessionStorage.getItem('videoSessionInfo') || '{}');
      const isInModalPath = location.pathname.includes('/modal/video-call');

      if (location.pathname.includes('/progress-notes') && !isInModalPath) {
        replacePath.current = location.pathname + location.search;
      }

      if (videoSessionInfo?.isInSession || isInModalPath) {
        setIsLVSModalOpen(true);
      } else {
        if (!isTherapistChat) {
          // this is here so that we can call closeModal in inRoomScheduling after a post-lvs checkin as a client and not get sent back to JoinVideoCallContainer
          setIsLVSModalOpen(false);
        }
        replacePath.current = location.pathname + location.search;
      }
    });
    return () => unlisten();
  }, [history, isLVSModalOpen, isTherapistChat]);

  useEffect(() => {
    const videoSessionInfo = JSON.parse(sessionStorage.getItem('videoSessionInfo') || '{}');
    const isInModalPath = history.location.pathname.includes('/modal/video-call');
    if (videoSessionInfo?.isInSession || isInModalPath) {
      setIsLVSModalOpen(true);
    }
    setFunnelName('In Room');
  }, [history]);

  const closeLVSModal = () => {
    setIsLVSModalOpen(false);
    sessionStorage.removeItem('videoSessionInfo');
    if (replacePath.current.includes('modal/video-call') && params.roomID) {
      history.replace(`/room/${params.roomID}`);
    } else {
      history.replace(replacePath.current);
    }
  };

  return (
    <Route
      path={[
        '/room/:roomID',
        '/room/:roomID/modal/video-call',
        '/room/:roomID/*/modal/video-call',
        '/room/:roomID/modal/video-call/:videoCallID',
      ]}
      render={(routerProps: any) => (
        <>
          {isLVSModalOpen ? (
            <VideoCallScreen
              {...routerProps}
              closeLVSModal={closeLVSModal}
              isTherapistChat={isTherapistChat}
              outsideHeaderHeight={outsideHeaderHeight}
              outsideFooterHeight={outsideFooterHeight}
            />
          ) : null}
          <ChatScreen
            {...routerProps}
            isAcking={isAcking}
            shouldPlaySoundNotifications={shouldPlaySoundNotifications}
            style={style}
            banners={banners}
            priorityBanner={priorityBanner}
            stopInputAutofocus={stopInputAutofocus}
            roomID={Number(routerProps.match.params.roomID)}
            isFocusedAfterModal={isFocusedAfterModal}
            isOffline={isOffline}
          />
        </>
      )}
    />
  );
};
export default Routes;
