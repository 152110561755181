import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { History } from 'history';
import { StepWizardProvider } from 'stepWizard';
import Routes from './Routes';
import { getInitialState, State } from './reducers/cancellationWizardState';
import cancellationWizardActions from './hooks/cancellationWizardActions';
import { MainContextProvider } from '../hooks/mainContext';
import { getUserData } from '../auth/helpers/token';
import { RouteComponentProps } from '../core/routerLib';
import { useGenericWizardActions } from '../utils/genericWizardActions';
import useNavShellData from '@/hooks/useNavShellData';

interface CancellationRouteParams {
  roomID: number;
  source: string;
  contextID?: string;
  history: History;
}

const CancellationWizard: FunctionComponent<RouteComponentProps<CancellationRouteParams>> = ({
  history,
  match: {
    params: { roomID, source, contextID = '' },
  },
}) => {
  const { id } = getUserData();
  const getFirstState = useCallback(getInitialState, [roomID, source, contextID]);
  const [initialState, setInitialState] = useState<State | undefined>();
  const genericCancellationWizardActions = useGenericWizardActions(cancellationWizardActions);
  const {
    aggregatedRoomData: { dataByRoomID },
  } = useNavShellData();

  useEffect(() => {
    setInitialState({
      ...getFirstState(id, roomID, source, contextID),
      ...(dataByRoomID ? dataByRoomID[roomID] : {}),
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {initialState ? (
        <MainContextProvider>
          <StepWizardProvider
            initialState={initialState}
            genericActions={genericCancellationWizardActions}
          >
            <Routes history={history} />
          </StepWizardProvider>
        </MainContextProvider>
      ) : null}
    </>
  );
};

export default CancellationWizard;
