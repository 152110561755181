import {
  EmotionTheme,
  useEmotionTheme,
  View,
  Input,
  Button,
  TextDS,
  spacing,
  Spinner,
  BaseButton,
} from '@talkspace/react-toolkit';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';
import { generateUniqueName } from '../../utils/helpers';
import useMutationPutCommunityUserData from '../../hooks/useMutationPutCommunityUserData';
import { DISPLAY_NAME_ERRORS, GENERIC_ERROR } from '../../constants';

const { space150, space200, space300, space400, space500, space800 } = spacing;
const displayNameValue = 'displayName';

const baseInputStyle = (colorRoles: EmotionTheme['colorRoles'], isError: boolean) => {
  return {
    inputStyle: {
      borderColor: isError
        ? colorRoles.typography.textCriticalDefault
        : colorRoles.borders.borderDefault,
      color: 'black',
      '::placeholder': { color: colorRoles.borders.borderDefault },
    },
  };
};

const TeenspaceProfileSetup = () => {
  const {
    register,
    formState: { errors },
    watch,
    trigger,
    setValue,
    setError,
    clearErrors,
  } = useFormContext();
  const { colorRoles } = useEmotionTheme();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const {
    mutate: putCommunityUserData,
    reset: resetMutation,
    isError,
  } = useMutationPutCommunityUserData();

  const displayName = watch(displayNameValue);
  const displayNameField = register(displayNameValue);
  const canContinue = !isError && !errors.displayName && displayName;
  const isFormError = displayName.length > 20 || displayName.length < 5;

  const onContinuePress = async () => {
    setIsLoading(true);
    if (isFormError) {
      trigger(displayNameValue);
      setIsLoading(false);
      return;
    }
    putCommunityUserData(
      { displayName },
      {
        onSuccess: (data) => {
          if (data) {
            history.push('/community/teenspace/onboarding/daily-question');
          }
        },
        onError: (err) => {
          const putCommunityUserDataStatusCode = err.data?.status;
          if (putCommunityUserDataStatusCode !== 200) {
            const putCommunityUserDataErrorMessage =
              (putCommunityUserDataStatusCode &&
                DISPLAY_NAME_ERRORS[putCommunityUserDataStatusCode]) ||
              GENERIC_ERROR;
            setError(displayNameValue, { message: putCommunityUserDataErrorMessage });
          }
          setIsLoading(false);
        },
      }
    );
  };
  return isLoading ? (
    <View justify="center" align="center" style={{ marginTop: 140 }}>
      <Spinner />
    </View>
  ) : (
    <View align="center" justify="space-between" style={{ textAlign: 'center', height: '100%' }}>
      <View align="center" style={{ textAlign: 'center' }}>
        <View align="stretch">
          <TextDS
            style={{
              marginBottom: space200,
              marginTop: space500,
            }}
            variant="heading2xl"
          >
            Choose your anonymous display name
          </TextDS>
        </View>
        <View align="stretch" flex={1} style={{ marginTop: space150 }}>
          <TextDS style={{ color: colorRoles.typography.textSubtle }} variant="body">
            You can edit this in your profile later
          </TextDS>
        </View>

        <View style={{ marginTop: space400, position: 'relative' }}>
          <Input
            maxLength={20}
            style={{ width: '100%' }}
            dataQa="displayNameInput"
            wrappedInputProps={{
              ...baseInputStyle(colorRoles, !!errors.displayName),
              label: 'Display name',
              isError: !!errors.displayName,
              errorMessage: errors.displayName?.message,
            }}
            {...register(displayNameValue)}
            onChange={(e) => {
              displayNameField.onChange(e);
              resetMutation();
              clearErrors();
            }}
          />
          <View style={{ position: 'absolute', left: 259, top: 45 }}>
            {displayName && (
              <BaseButton
                onPress={(e) => {
                  e.preventDefault();
                  setValue(displayNameValue, generateUniqueName());
                  clearErrors();
                }}
                dataQa="teenspaceProfileDisplayNameRefreshButton"
              >
                <TextDS
                  style={{ marginBottom: -space800 }}
                  variant="headingMd"
                  colorRole="textBrandDefault"
                >
                  Refresh
                </TextDS>
              </BaseButton>
            )}
          </View>
        </View>
      </View>
      <Button
        onPress={onContinuePress}
        text="Continue"
        style={{
          width: '100%',
          marginBottom: space400,
          padding: `${space200}px ${space300}px`,
          maxWidth: undefined,
        }}
        dataQa="teenspaceProfileSetupContinueButton"
        disabled={!canContinue}
      />
    </View>
  );
};

export default TeenspaceProfileSetup;
