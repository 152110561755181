import { useReducer, useCallback, useRef } from 'react';
import { InformedConsentAPIResponse } from 'ts-frontend/types';
import { wizardActionReducer, wizardActionInitialState, WizardActionState } from 'stepWizard';
import ApiHelper from '../../clientChat/utils/ApiHelper';
import switchWizardApiHelper from '../utils/switchWizardApiHelper';
import switchTherapistApiHelper from '../../therapistMatches/utils/switchTherapistApiHelper';
import {
  popIsOnboardingDismissed,
  popIsOnboardingDismissedTestAccount,
} from '@/onboarding/util/onboardingStorage';

interface switchState extends WizardActionState {
  informedConsent: InformedConsentAPIResponse | undefined;
  canBookIntroSession: boolean;
  confirmedTherapist: boolean;
}

export default function useSwitchWizard(): [
  switchState,
  {
    patchClientInfo: (userID: number, info: object) => void;
    getInformedConsent: (roomID: number, therapistID: number) => void;
    getTherapistTimeslots: (therapistID: number, roomID: number) => void;
    switchTherapist: (
      roomID: number,
      therapistID: number,
      informedConsentGiven: boolean,
      isSharing: boolean | null
    ) => void;
    exitAction: () => void;
  }
] {
  const additionalStateProps = {
    informedConsent: undefined,
    canBookIntroSession: false,
    confirmedTherapist: false,
  };

  const [state, dispatch] = useReducer(
    wizardActionReducer,
    wizardActionInitialState(additionalStateProps)
  );
  const apiRef = useRef(new ApiHelper());
  const apiRefSwitchWizard = useRef(switchWizardApiHelper);

  function patchClientInfo(userID: number, info: object) {
    dispatch({ type: 'requestAction' });
    apiRefSwitchWizard.current
      .patchClientInfo(userID, info)
      .then(() => {
        dispatch({
          type: 'receiveAction',
        });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  function getInformedConsent(roomID: number, therapistID: number) {
    dispatch({ type: 'requestAction' });
    apiRef.current
      .getInformedConsent(roomID, therapistID, 0, false)
      .then((informedConsent) => {
        dispatch({
          type: 'receiveAction',
          payload: { informedConsent },
        });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  function getTherapistTimeslots(therapistID: number, roomID: number) {
    dispatch({ type: 'requestTherapistTimeslots' });
    apiRef.current.getTherapistTimeslots(therapistID, 30, roomID).then((therapistTimeslots) => {
      dispatch({
        type: 'receiveTherapistTimeslots',
        payload: { canBookIntroSession: therapistTimeslots.timeslots.length > 0 },
      });
    });
  }

  function switchTherapist(
    roomID: number,
    therapistID: number,
    informedConsentGiven: boolean,
    isSharing: boolean | null
  ) {
    dispatch({ type: 'requestAction' });
    switchTherapistApiHelper
      .switchTherapist({
        roomID,
        newTherapistID: therapistID,
        consentSigned: informedConsentGiven,
        shareHistory: isSharing,
      })
      .then(() => {
        popIsOnboardingDismissed(roomID);
        popIsOnboardingDismissedTestAccount(roomID);
        dispatch({ type: 'receiveAction', payload: { confirmedTherapist: true } });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }
  function exitAction() {
    dispatch({ type: 'exitAction' });
  }

  return [
    state as switchState,
    {
      getTherapistTimeslots: useCallback(getTherapistTimeslots, []),
      patchClientInfo: useCallback(patchClientInfo, []),
      getInformedConsent: useCallback(getInformedConsent, []),
      switchTherapist: useCallback(switchTherapist, []),
      exitAction: useCallback(exitAction, []),
    },
  ];
}
