import { useEffect, useCallback, useState } from 'react';
import { History } from 'history';
import { getUserData } from 'auth/helpers/token';
import { useGenericWizardActions } from 'utils/genericWizardActions';
import { StepWizardProvider } from 'stepWizard';
import Routes from './Routes';
import { getInitialState, State } from './reducers/switchWizardState';
import switchWizardActions from './hooks/switchWizardActions';
import ApiHelper from '../utils/ApiHelper';

interface Props {
  roomID: number;
  source: string;
  contextID?: string;
  history: History;
}

const SwitchWizard = ({ roomID, source, contextID = '', history }: Props) => {
  const { id } = getUserData();
  const getFirstState = useCallback(getInitialState, [roomID, source, contextID]);
  const [initialState, setInitialState] = useState<State | undefined>();
  const genericSwitchWizardActions = useGenericWizardActions(switchWizardActions);
  useEffect(() => {
    if (roomID) {
      const firstState = getFirstState(id, roomID, source, contextID);
      setInitialState(firstState);
    }
  }, [contextID, getFirstState, id, roomID, source]);
  return (
    <>
      {initialState ? (
        <StepWizardProvider
          ApiHelperOverride={ApiHelper}
          initialState={initialState}
          genericActions={genericSwitchWizardActions}
        >
          <Routes history={history} />
        </StepWizardProvider>
      ) : null}
    </>
  );
};

export default SwitchWizard;
