import { WizardScheme } from 'stepWizard';

const emergencyContactScheme: WizardScheme = {
  wizardModalTitle: 'emergency contact',
  wizardVersion: 1,
  wizardType: 'emergencyContact',
  disablePersist: true,
  steps: [
    {
      title: 'What is your name?',
      inputType: 'form',
      inputState: 'name',
      inputFields: [
        {
          inputType: 'text',
          inputState: 'firstName',
          inputTypePlaceholder: 'First Name',
          validationRequired: true,
        },
        {
          inputType: 'text',
          inputState: 'middleName',
          inputTypePlaceholder: 'Middle Name',
          validationRequired: false,
        },
        {
          inputType: 'text',
          inputState: 'lastName',
          inputTypePlaceholder: 'Last Name',
          validationRequired: true,
        },
      ],
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'name',
    },
    {
      title: 'What is your home address?',
      inputType: 'address',
      inputState: 'address',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'address',
      backOption: 'back',
    },
    {
      title: 'What is your phone number?',
      inputType: 'phone',
      inputTypePlaceholder: '(123) 123-1234',
      inputState: 'phone',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'Skip',
        actionDispatch: 'next',
        defaultValue: null,
      },
      name: 'phone',
      backOption: 'back',
    },
    {
      title: 'Who would you like to designate as your emergency contact?',
      inputType: 'text',
      inputTypePlaceholder: 'Full name',
      inputState: 'contactName',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'contactName',
      backOption: 'back',
    },
    {
      title: 'What is their relationship to you?',
      inputType: 'select',
      inputState: 'relationship',
      inputOptions: 'relationshipOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      displayCondition: 'contactName',
      name: 'relationship',
      backOption: 'back',
    },
    {
      title: 'How can we reach your emergency contact?',
      inputType: 'phone',
      inputState: 'contactPhone',
      nextButton: {
        displayText: 'Next',
        actionDispatch: (_, wizardContext) => {
          if (wizardContext.source === 'onboarding') {
            return `/room/${wizardContext.roomID}/onboarding/emergency-contact/source/onboarding/submit`;
          }
          return '/emergency-contact-wizard/submit';
        },
        whenVisible: 'onSelect',
      },
      displayCondition: 'contactName',
      name: 'contactPhone',
      backOption: 'back',
      validationRequired: true,
    },
  ],
};

export default emergencyContactScheme;
