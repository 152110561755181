import { useEffect } from 'react';
import { StripeProvider } from 'stripe/stripeContext';
import { debug } from 'ts-frontend/utils/dev-console';
import { EmotionThemeProvider, ToastContainer, HtmlMetaManager } from '@talkspace/react-toolkit';
import { PromiseMessageContextProvider } from 'ts-promise-message';
import ChatMessageContextProvider from 'chat/hooks/chatMessageContext';
import { FlagsProvider, useFlags } from 'launchDarkly/FlagsProvider';
import { ThemedFlagsProvider } from 'launchDarkly';
import { RoomOffer } from 'offer';
import LDClientIdentifier from 'launchDarkly/LDClientIdentifier';
import { InRoomSchedulingProvider } from 'inRoomScheduling';
import { NativeFeaturesEnum, shouldShowNativeFeature, useIonicEffect } from 'ts-ionic';
import { IonicPasscodeContainer, CreatePasscode } from 'ts-ionic/modules/passcode';
import { updateWebVersion } from 'ts-ionic/plugins/liveUpdates';
import { PushNotificationsContainer } from 'ts-ionic/modules/pushNotifications';
import {
  TreatmentIntakeLauncher,
  EmergencyContactWizard,
  MentalHealthWizard,
  MedicalHealthWizard,
  TeenMentalHealthWizard,
} from '@/treatmentIntake';
import MatchingIntakeWizard from '@/matchingIntakeWizard';
import { getUseStripeLinkFlagValue } from '@/utils/adminFlagValues';
import { trackEvent } from './utils/analytics/eventTracker';
import ReviewTherapistContainer from './therapistReview';
import SwitchWizard from './switchWizard';
import Superbills from './superbills';
import MainScreen from './screens/MainScreen';
import Home from './home';
import ClientNavShell from './containers/ClientNavShell/ClientNavShell';
import FullScreenModal from './screens/FullScreenWizard';
import RoomReactivation from './roomReactivation';
import { RoomInviteScreen, InvitationTokenError } from './roomInvites';
import RefundWizard from './refundWizard';
import MyAccount from './myAccount';
import MeetYourProvider from './meetYourProvider';
import Onboarding from './onboarding';
import OnboardingProvider from './onboarding/hooks/onboardingContext';
import Login from './login';
import InsuranceEligibility from './insuranceEligibility';
import { DeepLinkContextProvider } from './hooks/useDeepLink';
import { MainContextProvider } from './hooks/mainContext';
import GoogleMapsContextProvider from './hooks/googleMapsContext';
import { ClientAuthContextProvider } from './hooks/clientAuthContext';
import FinancialAidWizard from './financialAidWizard';
import EmailVerification from './emailVerification';
import EligibilityWidget from './eligibilityWidget';
import { Router, Route, Switch, RouteComponentProps, Redirect } from './core/routerLib';
import CrossPlatformDeepLink from './containers/CrossPlatformDeepLink';
import CreateSessionReportModalContainer from './containers/CreateSessionReportModalContainer';
import InRoomSchedulingWrapper from './components/InRoomSchedulingWrapper';
import PaymentWizardWrapper from './components/PaymentWizardWrapper';
import { SurveysContextProvider } from './clinicalProgress/hooks/surveysContext';
import B2BCancellationWizard from './b2BCancellationWizard';
import UnauthorizedScreen from './auth/screens/UnauthorizedScreen';
import ParentalConsentScreen from './parentalConsent/screens/ParentalConsentScreen';
import SSOLogout from './auth/containers/SSOLogout';
import AuthContainer from './auth/containers/AuthContainer';
import NPSWizard from './NPSWizard';
import PostAsyncPrompt from './PostAsyncPrompt';
import DTERenewal from './DTERenewal';
import CheckInWizard from './CheckInWizard/index';
import CancellationWizard from './CancellationWizard';
import ManageSubscriptionSessionsToCancel from './myAccount/containers/ManageSubscriptionSessionsToCancel';
import ManageSubscriptionThanksForStaying from './myAccount/containers/ManageSubscriptionThanksForStaying';
import TwoFactorAuthentication from './TwoFactorAuthentication';
import { useAttribution } from './hooks/useAttribution';
import InformedConsentAction from './components/InformedConsentAction';
import SessionDetails from './sessionDetails';
import { UpdateCoverageContainer, ChangeProviderContainer } from './updatePlans';
import WhatToExpect from './whatToExpect';
import FindingProvider from './components/FindingProvider/FindingProvider';
import EligibilityWarning from './EligibilityWarning';
import EligibilityWarningSuccess from './EligibilityWarning/containers/EligibilityWarningSuccessContainer';
import ParentalConsentResubmit from './ParentalConsentResubmit';
import IsraelSupportScreen from '@/israelSupport/screens/IsraelSupportScreen';
import TherapistDetails from './containers/TherapistDetails';
import LoginSuccess from './auth/screens/LoginSuccess';
import ScheduleChooseProviderContainer from './containers/ScheduleChooseProvider';
import ClinicalProgressRoomContainer from './clinicalProgress/containers/ClinicalProgressRoomContainer';
import ModalsProvider from './utils/ModalsContextProvider';
import ModalRoutes from './ModalRoutes';
import useNavHelpers from './hooks/useNavHelpers';
import { getTokens } from './auth/helpers/token';
import CommunityProvider from './community/hooks/CommunityContext';
import CommunityRouter from './community/Router';

const FlagLogger = () => {
  const flags = useFlags();
  useEffect(() => {
    debug('ClientWeb: LaunchDarkly flags have changed:', JSON.parse(JSON.stringify(flags)));
  }, [flags]);
  return null;
};

// note: when you pass an array of path strings into the path prop of a <Route>, they must be in order from most to least specific
const CatchAll = ({ history, location }: RouteComponentProps) => {
  const { navigateToDefault } = useNavHelpers({ history });
  navigateToDefault();
  trackEvent('Invalid Page', {
    eventCategory: 'Debug',
    eventProperty: 'pathname',
    eventPropertyValue: location.pathname,
  });
  return null;
};

const UpdateIonicWebVersion = () => {
  useIonicEffect(() => {
    updateWebVersion();
  }, []);

  return null;
};

const ClientRoutes = () => {
  useAttribution();
  return (
    <ClientAuthContextProvider>
      <GoogleMapsContextProvider>
        <EmotionThemeProvider>
          <StripeProvider getUseStripeLinkFlag={getUseStripeLinkFlagValue}>
            <PromiseMessageContextProvider>
              <Router>
                <AuthContainer successRoute="/login-success">
                  <DeepLinkContextProvider>
                    <ChatMessageContextProvider>
                      <LDClientIdentifier>
                        <FlagsProvider>
                          <HtmlMetaManager />
                          <FlagLogger />
                          <PushNotificationsContainer>
                            <MainContextProvider>
                              <InRoomSchedulingProvider>
                                <SurveysContextProvider>
                                  <CommunityProvider>
                                    <ModalsProvider ModalRoutes={ModalRoutes}>
                                      <ThemedFlagsProvider versionKey="homePage">
                                        <ClientNavShell>
                                          <ThemedFlagsProvider versionKey="default">
                                            {shouldShowNativeFeature(
                                              NativeFeaturesEnum.LIVE_UPDATES
                                            ) && <UpdateIonicWebVersion />}
                                            {shouldShowNativeFeature(
                                              NativeFeaturesEnum.PASSCODE
                                            ) && (
                                              <IonicPasscodeContainer
                                                getTokens={getTokens}
                                                isClient
                                              />
                                            )}
                                            <Switch>
                                              <Route
                                                path={['/passcode', '/passcode/edit']}
                                                render={() => (
                                                  <CreatePasscode isClient successRoute="/home" />
                                                )}
                                              />
                                              <Route
                                                path="/login-success"
                                                component={LoginSuccess}
                                              />
                                              <Route
                                                path="/create-session-report/room/:roomID"
                                                component={CreateSessionReportModalContainer}
                                              />
                                              <Route
                                                path="/treatment-intake-launcher/room/:roomID/source/:source"
                                                component={TreatmentIntakeLauncher}
                                              />
                                              <Route
                                                path={[
                                                  '/emergency-contact-wizard/room/:roomID/source/:source',
                                                  '/emergency-contact-wizard/submit',
                                                ]}
                                                component={EmergencyContactWizard}
                                              />
                                              <Route
                                                path={[
                                                  '/mental-health-wizard/room/:roomID/source/:source',
                                                  '/mental-health-wizard',
                                                ]}
                                                component={MentalHealthWizard}
                                              />
                                              <Route
                                                path={[
                                                  '/teen-mental-health-wizard/room/:roomID/source/:source',
                                                  '/teen-mental-health-wizard',
                                                ]}
                                                component={TeenMentalHealthWizard}
                                              />
                                              <Route
                                                path={[
                                                  '/medical-health-wizard/room/:roomID/source/:source',
                                                  '/medical-health-wizard',
                                                ]}
                                                component={MedicalHealthWizard}
                                              />
                                              <Route
                                                path="/support-israel"
                                                exact
                                                component={IsraelSupportScreen}
                                              />
                                              <Route
                                                path={[
                                                  '/',
                                                  '/login',
                                                  '/login/sso',
                                                  '/login/sso/callback',
                                                  '/login/sso/callback/:provider?',
                                                  '/login/sso/register',
                                                  '/login/sso/close',
                                                  '/signup/:slugName?',
                                                  '/forgot-password',
                                                  '/reset-password',
                                                  '/change-password',
                                                  '/oauth',
                                                ]}
                                                exact
                                                component={Login}
                                              />
                                              <Route
                                                path="/parental-consent-form"
                                                exact
                                                component={ParentalConsentScreen}
                                              />
                                              <Route
                                                path="/sso/logout/:provider"
                                                exact
                                                component={SSOLogout}
                                              />
                                              <Route
                                                path="/unauthorized"
                                                component={UnauthorizedScreen}
                                              />
                                              <Route path="/dl" component={CrossPlatformDeepLink} />
                                              <Route path="/my-account" component={MyAccount} />
                                              <Route
                                                path="/room/:roomID/session-details/:bookingID"
                                                component={SessionDetails}
                                              />
                                              <Route
                                                path="/room-reactivation"
                                                component={RoomReactivation}
                                              />
                                              <Route
                                                path={[
                                                  '/2fa/reminder',
                                                  '/2fa/verify',
                                                  '/2fa/close',
                                                ]}
                                                render={() => (
                                                  <MainContextProvider>
                                                    <TwoFactorAuthentication />
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path="/review-therapist/:roomID"
                                                render={(props) => (
                                                  <MainContextProvider>
                                                    <ReviewTherapistContainer {...props} />
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path="/meet-your-provider"
                                                component={MeetYourProvider}
                                              />
                                              <Route
                                                path="/room/:roomID/onboarding"
                                                render={() => (
                                                  <MainContextProvider>
                                                    <OnboardingProvider>
                                                      <Onboarding />
                                                    </OnboardingProvider>
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path="/invite-partner/:roomID"
                                                component={RoomInviteScreen}
                                              />
                                              <Route
                                                path="/superbills/room/:roomID"
                                                component={Superbills}
                                              />
                                              <Route
                                                path={['/room-offer', '/offer']}
                                                component={RoomOffer}
                                              />
                                              <Route
                                                path={['/room', '/rooms']}
                                                component={MainScreen}
                                              />
                                              <Route path={['/home']} component={Home} />
                                              <Route
                                                path="/community"
                                                component={CommunityRouter}
                                              />
                                              <Route
                                                path="/email-verification"
                                                component={EmailVerification}
                                              />
                                              <Route
                                                path="/invite-error/:reason"
                                                component={InvitationTokenError}
                                              />
                                              <Route
                                                path="/cancel-subscription/room/:roomID/source/:source/sessions-to-cancel"
                                                component={ManageSubscriptionSessionsToCancel}
                                              />
                                              <Route
                                                path="/cancel-subscription/room/:roomID/source/:source/thanks-for-staying"
                                                component={ManageSubscriptionThanksForStaying}
                                              />
                                              <Route
                                                path={[
                                                  '/cancel-subscription/room/:roomID/source/:source/context-id/:contextID',
                                                  '/cancel-subscription/room/:roomID/source/:source',
                                                  '/cancel-subscription',
                                                ]}
                                                component={CancellationWizard}
                                              />
                                              <Route
                                                path={[
                                                  '/update-coverage/room/:roomID/source/:source/context-id/:contextID',
                                                  '/update-coverage/room/:roomID/source/:source',
                                                  '/update-coverage',
                                                ]}
                                                render={() => (
                                                  <MainContextProvider>
                                                    <UpdateCoverageContainer />
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path={[
                                                  '/change-provider/room/:roomID/source/:source/context-id/:contextID',
                                                  '/change-provider/room/:roomID/source/:source',
                                                  '/change-provider',
                                                ]}
                                                render={() => (
                                                  <MainContextProvider>
                                                    <ChangeProviderContainer />
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path="/what-to-expect"
                                                render={() => (
                                                  <MainContextProvider>
                                                    <WhatToExpect />
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path="/finding-provider"
                                                render={() => (
                                                  <MainContextProvider>
                                                    <FindingProvider />
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path={[
                                                  '/cancel-non-subscription/room/:roomID/source/:source/context-id/:contextID',
                                                  '/cancel-non-subscription/room/:roomID/source/:source',
                                                  '/cancel-non-subscription',
                                                ]}
                                                component={B2BCancellationWizard}
                                              />
                                              <Route
                                                path="/switch-provider/room/:roomID"
                                                render={(props) => {
                                                  const { match, location } =
                                                    props as RouteComponentProps<
                                                      { roomID: string },
                                                      {},
                                                      { source: string }
                                                    >;
                                                  const { roomID } = match.params;
                                                  const { source } = location.state || {};
                                                  return (
                                                    <Redirect
                                                      to={{
                                                        pathname: '/switch-provider',
                                                        state: {
                                                          roomID,
                                                          source: source || '',
                                                          contextID: '',
                                                        },
                                                      }}
                                                    />
                                                  );
                                                }}
                                              />
                                              <Route
                                                path="/switch-provider"
                                                render={(props) => {
                                                  const { location, history } =
                                                    props as RouteComponentProps<
                                                      {},
                                                      {},
                                                      {
                                                        roomID: number;
                                                        source: string;
                                                        contextID?: string;
                                                      }
                                                    >;
                                                  const { roomID, source, contextID } =
                                                    location.state;
                                                  return (
                                                    <SwitchWizard
                                                      roomID={roomID}
                                                      source={source}
                                                      contextID={contextID}
                                                      history={history}
                                                    />
                                                  );
                                                }}
                                              />
                                              <Route
                                                path={[
                                                  '/financial-aid/room/:roomID/source/:source/context-id/:contextID',
                                                  '/financial-aid/room/:roomID/source/:source',
                                                  '/financial-aid',
                                                ]}
                                                component={FinancialAidWizard}
                                              />
                                              <Route
                                                path={[
                                                  '/refund-purchase/source/:source/context-id/:contextID',
                                                  '/refund-purchase/source/:source',
                                                  '/refund-purchase',
                                                ]}
                                                component={RefundWizard}
                                              />
                                              <Route
                                                path={[
                                                  '/check-in/room/:roomID/source/:source/check-in-source/:checkInSource/video-call/:videoCallID',
                                                  '/check-in/room/:roomID/source/:source/check-in-source/:checkInSource/user-prompt/:userPromptID',
                                                  '/check-in/done/:source',
                                                  '/v3/check-in/room/:roomID',
                                                  '/v3/check-in/done/:source',
                                                  '/v3/check-in/change-provider',
                                                ]}
                                                render={(props) => (
                                                  <MainContextProvider>
                                                    <CheckInWizard {...props} />
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path={[
                                                  '/nps/room/:roomID/source/:source/user-prompt/:userPromptID',
                                                  '/nps/done/:source',
                                                ]}
                                                component={NPSWizard}
                                              />
                                              <Route
                                                path="/post-async-prompt/room/:roomID/source/:source/user-prompt/:userPromptID"
                                                render={() => (
                                                  <MainContextProvider>
                                                    <PostAsyncPrompt />
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path={[
                                                  '/matching-intake/room/:roomID/source/:source',
                                                  '/matching-intake/submit',
                                                  '/matching-intake/under13',
                                                  '/matching-intake/under18',
                                                ]}
                                                render={(props) => (
                                                  <MainContextProvider>
                                                    <MatchingIntakeWizard {...props} />
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path={[
                                                  '/informed-consent',
                                                  '/informed-consent/room/:roomID',
                                                ]}
                                                component={InformedConsentAction}
                                              />
                                              <Route
                                                path={[
                                                  '/eligibility-widget/room/:roomID',
                                                  '/eligibility-widget',
                                                ]}
                                                component={EligibilityWidget}
                                              />
                                              <Route
                                                exact
                                                path="/eligibility-warning/:roomID"
                                                component={EligibilityWarning}
                                              />
                                              <Route
                                                path="/eligibility-warning/:roomID/success"
                                                component={EligibilityWarningSuccess}
                                              />
                                              <Route
                                                path={[
                                                  '/add-new-service/room/:roomID',
                                                  '/add-new-service',
                                                ]}
                                                render={() => <EligibilityWidget qmFlow={90} />}
                                              />
                                              <Route
                                                path="/insurance-eligibility"
                                                component={InsuranceEligibility}
                                              />
                                              <Route
                                                path="/dte-renewal/room/:roomID/org-name/:orgName"
                                                component={DTERenewal}
                                              />
                                              <Route
                                                path="/parental-consent-resubmit"
                                                component={ParentalConsentResubmit}
                                              />
                                              <Route
                                                path="/in-room-scheduling/choose-provider"
                                                render={() => (
                                                  <ThemedFlagsProvider versionKey="homePage">
                                                    <ScheduleChooseProviderContainer />
                                                  </ThemedFlagsProvider>
                                                )}
                                              />
                                              <Route
                                                path="/in-room-scheduling/room/:roomID"
                                                render={(props) => {
                                                  const { location } = props as RouteComponentProps<
                                                    {},
                                                    {},
                                                    { bookWithIntroSession: boolean }
                                                  >;
                                                  const { bookWithIntroSession = false } =
                                                    location.state || {};
                                                  return (
                                                    <MainContextProvider>
                                                      <InRoomSchedulingWrapper
                                                        bookWithIntroSession={bookWithIntroSession}
                                                      />
                                                    </MainContextProvider>
                                                  );
                                                }}
                                              />
                                              <Route
                                                path="/payment-wizard/room/:roomID"
                                                render={() => (
                                                  <MainContextProvider>
                                                    <PaymentWizardWrapper />
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path="/surveys/room/:roomID/survey/:userRoomSurveyID"
                                                render={(props) => (
                                                  <MainContextProvider>
                                                    <FullScreenModal
                                                      initialModalStateParams={props.match.params}
                                                      initialModalRoute="/survey"
                                                    />
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path="/provider-profile/room/:roomID"
                                                render={(props) => (
                                                  <MainContextProvider>
                                                    <TherapistDetails
                                                      isChatHidden
                                                      isMobile={false}
                                                    />
                                                  </MainContextProvider>
                                                )}
                                              />
                                              <Route
                                                path="/clinical-progress-rooms"
                                                component={ClinicalProgressRoomContainer}
                                              />
                                              <Route component={CatchAll} />
                                            </Switch>
                                          </ThemedFlagsProvider>
                                        </ClientNavShell>
                                      </ThemedFlagsProvider>
                                    </ModalsProvider>
                                  </CommunityProvider>
                                </SurveysContextProvider>
                              </InRoomSchedulingProvider>
                            </MainContextProvider>
                          </PushNotificationsContainer>
                        </FlagsProvider>
                      </LDClientIdentifier>
                    </ChatMessageContextProvider>
                  </DeepLinkContextProvider>
                </AuthContainer>
              </Router>
            </PromiseMessageContextProvider>
          </StripeProvider>
        </EmotionThemeProvider>

        <ToastContainer />
      </GoogleMapsContextProvider>
    </ClientAuthContextProvider>
  );
};

export default ClientRoutes;
