import {
  createContext,
  MouseEventHandler,
  MutableRefObject,
  PropsWithChildren,
  useContext,
  useLayoutEffect,
  useState,
  useEffect,
} from 'react';

import * as React from 'react';
import AriaModal from 'react-aria-modal';
import Lottie from 'react-lottie';
import HiddenText from '../HiddenText';
import { useUniqueID } from '../../hooks/a11yHelper';
import loadingSpinner from '../../utils/loadingSpinner.json';
import View from '../View';
import CloseButton from '../CloseButton';
import styled, { EmotionStyle } from '../../core/styled';
import ModalCloseButton from './ModalCloseButton';
import useWindowWidth from '../../hooks/useWindowWidth';
import Screen from '../Screen';
import { ScreenProps } from '../Screen/Screen';
import { getScreenSafeAreaInsets } from '../../utils/safeAreaInsets';

// TODO: add returnId prop and expose setting action to allow for focus to return to original button that opened the modal (not happening when closeModal action is used)
export interface ModalProps {
  isMobileFullscreen?: boolean;
  underlayClick?: boolean;
  titleText?: string;
  titleID?: string;
  isVisible: boolean;
  focusTrapPaused?: boolean;
  onBackdropPress?: MouseEventHandler<HTMLDivElement>;
  underlayStyle?: EmotionStyle;
  dialogStyle?: EmotionStyle;
  isSecondModal?: boolean;
  modalRef?: MutableRefObject<HTMLDivElement | null>;
  dataQa?: string;
  onEnter?: () => void;
}

export interface ModalPanelProps {
  isLoading?: boolean;
  height?: number | string;
  onBackdropPress?: () => void;
  wrapperStyle?: EmotionStyle;
  contentViewStyle?: EmotionStyle;
  showCloseButton?: boolean;
  isMobileFullscreen?: boolean;
}

const fullScreenStyles = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 'initial',
  height: 'initial',
  borderRadius: 0,
};

const Wrapper = styled(View)<{
  height?: number | string;
  isMobile?: boolean;
  isMobileFullscreen?: boolean;
}>(({ height, isMobile, isMobileFullscreen, theme: { colors, safeAreaInsets } }) => {
  return {
    alignItems: 'flex-end',
    backgroundColor: colors.white,
    borderRadius: 10,
    position: isMobile && isMobileFullscreen ? 'absolute' : 'relative',
    width: isMobile && !isMobileFullscreen ? '100%' : 500,
    maxWidth: isMobile && !isMobileFullscreen ? 335 : 750,
    paddingTop: safeAreaInsets.top,
    paddingBottom: safeAreaInsets.bottom,
    minHeight: 390,
    boxShadow: '0 4px 10px -4px rgba(0,40,65,0.40)',
    transition: '.2s',
    height,
    ...(isMobile && isMobileFullscreen ? fullScreenStyles : {}),
  };
});

const FullScreenWrapper = styled(View)<{
  height?: number | string;
  isMobile?: boolean;
  isMobileFullscreen?: boolean;
}>(({ theme: { colors } }) => {
  return {
    alignItems: 'stretch',
    backgroundColor: colors.white,
    position: 'absolute',
    minHeight: 390,
    transition: '.2s',
    ...fullScreenStyles,
  };
});

const ContentView = styled(View)<{ isFullScreen?: boolean }>(({ isFullScreen }) => {
  return {
    paddingTop: 17,
    paddingRight: isFullScreen ? 20 : 78,
    paddingLeft: isFullScreen ? 20 : 78,
    paddingBottom: 60,
    alignSelf: 'center',
  };
});

const Loading = () => (
  <View flex={1} align="center" justify="center" style={{ paddingBottom: 67 }}>
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: loadingSpinner,
      }}
      height={160}
      width={160}
    />
  </View>
);

interface ModalActions {
  setModalTitle: (title: string) => void;
  setModalTitleId: (id: string) => void;
}

interface ModalState {
  modalTitle?: string;
  modalTitleId?: string;
}

const ModalStateContext = createContext<ModalState | undefined>(undefined);
const ModalActionsContext = createContext<ModalActions>({
  // If not under a modal, nothing happens
  setModalTitle: () => undefined,
  setModalTitleId: () => undefined,
});

const ModalContextProvider: React.FC<{ defaultTitle?: string; defaultTitleId?: string }> = ({
  children,
  defaultTitle,
  defaultTitleId,
}) => {
  const [modalTitle, setModalTitle] = useState<string | undefined>(defaultTitle);
  const [modalTitleId, setModalTitleId] = useState<string | undefined>(defaultTitleId);

  useEffect(() => {
    if (defaultTitle) {
      setModalTitle(defaultTitle);
    }
    if (defaultTitleId) {
      setModalTitleId(defaultTitleId);
    }
  }, [defaultTitle, defaultTitleId]);

  return (
    <ModalActionsContext.Provider value={{ setModalTitle, setModalTitleId }}>
      <ModalStateContext.Provider value={{ modalTitle, modalTitleId }}>
        {children}
      </ModalStateContext.Provider>
    </ModalActionsContext.Provider>
  );
};

const withModalContext =
  <P extends { titleText?: string; titleID?: string }>(
    Component: React.ComponentType<P>
  ): React.ComponentType<P> =>
  (props: P) => {
    const { titleText, titleID } = props;
    return (
      <ModalContextProvider defaultTitle={titleText} defaultTitleId={titleID}>
        <Component {...props} />
      </ModalContextProvider>
    );
  };

const useModalState = (): ModalState => {
  const context = useContext(ModalStateContext);
  if (!context) throw new Error('Cannot use Modal State outside of Modal');
  return context;
};

export const useModalActions = (): ModalActions => {
  const context = useContext(ModalActionsContext);
  if (!context) throw new Error('Cannot use Modal Actions outside of Modal');
  return context;
};

const ModalPanel = ({
  children,
  isLoading,
  wrapperStyle,
  contentViewStyle,
  showCloseButton = true,
  height,
  isMobileFullscreen = true,
  onBackdropPress = () => {
    /** do nothing */
  },
  ...otherProps
}: PropsWithChildren<ModalPanelProps>) => {
  const { isMobile } = useWindowWidth();
  return (
    <Wrapper
      height={height}
      style={wrapperStyle}
      isMobile={isMobile}
      isMobileFullscreen={isMobileFullscreen}
      data-qa="modal-panel-wrapper-view"
      {...otherProps}
    >
      {showCloseButton && (
        <CloseButton
          onPress={onBackdropPress}
          style={{ display: 'flex', marginLeft: 'auto', marginTop: 16, marginRight: 16 }}
        />
      )}
      <ContentView
        data-qa="modal-panel-content-view"
        style={contentViewStyle}
        isFullScreen={isMobile && isMobileFullscreen}
      >
        {isLoading ? <Loading /> : children}
      </ContentView>
    </Wrapper>
  );
};

const ModalFullScreenPanel = ({
  children,
  isLoading,
  wrapperStyle,
  contentViewStyle,
  showCloseButton = true,
  onBackdropPress = () => {
    /** do nothing */
  },
  renderHeader,
  ...otherProps
}: PropsWithChildren<
  Omit<ModalPanelProps, 'height' | 'isMobileFullScreen'> & Pick<ScreenProps, 'renderHeader'>
>) => (
  <FullScreenWrapper
    style={wrapperStyle}
    data-qa="modal-panel-full-screen-wrapper-view"
    {...otherProps}
  >
    <Screen
      onCloseButtonClick={onBackdropPress}
      showCloseButton={renderHeader ? false : showCloseButton}
      renderHeader={renderHeader}
    >
      {children}
    </Screen>
  </FullScreenWrapper>
);

const ModalWithContext = withModalContext(
  ({
    isMobileFullscreen = false,
    underlayClick = true,
    children,
    isVisible,
    focusTrapPaused,
    underlayStyle,
    dialogStyle,
    isSecondModal,
    modalRef,
    onBackdropPress = () => undefined,
    dataQa,
    ...otherProps
  }: PropsWithChildren<ModalProps>) => {
    const { isMobile } = useWindowWidth();
    const getApplicationNode = () => document.getElementById('root');
    const { modalTitle: titleText, modalTitleId: titleID } = useModalState();
    const hiddenTitleID = useUniqueID('hiddenTitleID');
    const dialogID = useUniqueID('dialogID');
    const safeAreaInsets = getScreenSafeAreaInsets();
    const mobileHeight = '100%';
    const dialogHeight = isMobileFullscreen && isMobile ? mobileHeight : undefined;
    const dialogWidth = isMobileFullscreen && isMobile ? '100%' : undefined;
    const underlayBackgroundColor = isMobileFullscreen && isMobile ? 'white' : undefined;

    useLayoutEffect(() => {
      if (
        // AriaModal does not take a `ref` property, but it adds this view with this ID
        // The parentElement is the one that has the full size available. The modal itself
        // changes size depending on the children.
        document.getElementById('react-aria-modal-dialog')?.parentElement &&
        modalRef &&
        'current' in modalRef
      ) {
        // eslint-disable-next-line no-param-reassign
        modalRef.current = document.getElementById('react-aria-modal-dialog')
          ?.parentElement as HTMLDivElement;
      }
    }, [isVisible, dialogID, modalRef]);
    return (
      <AriaModal
        underlayClickExits={underlayClick}
        mounted={isVisible}
        getApplicationNode={getApplicationNode}
        focusDialog
        dialogId={isSecondModal ? dialogID : undefined}
        onExit={onBackdropPress}
        titleId={titleID || hiddenTitleID}
        focusTrapPaused={focusTrapPaused}
        underlayStyle={{
          cursor: '',
          display: 'flex',
          justifyContent: 'center',
          alignItems: isMobile ? 'start' : 'center',
          ...(underlayBackgroundColor ? { backgroundColor: underlayBackgroundColor } : {}),
          ...underlayStyle,
        }}
        dialogStyle={{
          height: dialogHeight,
          width: dialogWidth,
          display: 'flex',
          justifyContent: 'center',
          paddingTop: safeAreaInsets.top,
          paddingBottom: safeAreaInsets.bottom,
          alignItems: isMobile ? 'start' : 'center',
          ...dialogStyle,
        }}
        data-qa={dataQa}
        {...otherProps}
      >
        {!titleID && (
          <HiddenText
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            as="h2"
            id={hiddenTitleID}
          >
            {titleText || ''}
          </HiddenText>
        )}
        {isSecondModal ? (
          <View tabIndex={-1} role="dialog" id={dialogID}>
            {children}
          </View>
        ) : (
          children
        )}
      </AriaModal>
    );
  }
);

const Modal = Object.assign(ModalWithContext, {
  Panel: ModalPanel,
  CloseButton: ModalCloseButton,
  FullScreenPanel: ModalFullScreenPanel,
});

export default Modal;
