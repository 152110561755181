import { useReducer, useCallback, createContext, useContext } from 'react';
import moment from 'moment';
import API from '../utils/clinicalProgressApiHelper';
import { surveysReducer, State, initialState, UpcomingSurvey } from '../reducers/surveys';

export interface SurveyActions {
  dispatchGetSurveys: () => Promise<void>;
}

export const StateContext = createContext<State | undefined>(undefined);
export const ActionsContext = createContext<SurveyActions | undefined>(undefined);

function processSurveyStatus(upcomingSurvey: UpcomingSurvey): UpcomingSurvey {
  const now = moment();
  const dueAt = moment(upcomingSurvey.dueAt);
  const deliverAt = moment(upcomingSurvey.deliverAt);
  // the cron runs on the 10, so we round up a bit to be sure its delivered
  const isDelivered = deliverAt
    .add(10, 'minutes')
    .startOf('minute')
    .add(10, 'seconds')
    .isBefore(now);
  const isDue = dueAt.isAfter(now) && isDelivered;
  return {
    ...upcomingSurvey,
    isDue,
  };
}

function SurveysContextProvider({ children }) {
  const [state, dispatch] = useReducer(surveysReducer, initialState);

  function dispatchGetSurveys() {
    dispatch({ type: 'requestGetSurveys' });
    return API.getClientSurveys()
      .then(({ data }) => {
        if (data) {
          return dispatch({
            type: 'receiveGetSurveys',
            payload: {
              upcomingSurveys: data.data.upcomingSurveys.map(processSurveyStatus),
              surveyResultsByRoomID: data.data.results.reduce((acc, result) => {
                const prevRoomResults = acc[result.roomID] || [];
                return {
                  ...acc,
                  [result.roomID]: [...prevRoomResults, result],
                };
              }, {}),
            },
          });
        }
        throw new Error('No surveys');
      })
      .catch((error) =>
        dispatch({
          type: 'setError',
          error: error.message,
        })
      );
  }

  const actions: SurveyActions = {
    dispatchGetSurveys: useCallback(dispatchGetSurveys, []),
  };

  return (
    <StateContext.Provider value={state}>
      <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>
    </StateContext.Provider>
  );
}

function useSurveysState(): State {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('surveys state context must be used within SurveysContextProvider');
  }
  return context;
}

function useSurveysActions() {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error('surveys state context must be used within SurveysContextProvider');
  }
  return context;
}

export { SurveysContextProvider, useSurveysState, useSurveysActions };
