import { FunctionComponent, useMemo } from 'react';
import { View, TouchableView, Lightbox } from '@talkspace/react-toolkit';

import { CircleXMark } from '@talkspace/react-toolkit/src/designSystems/icons';
import styled from '@/core/styled';
import XIcon from '../Icons/CircledX';
import FileBubble from '../FileBubble';
import { MediaTypeField } from '../../types/inputTypes';
import VideoPlayer from '../VideoPlayer';

const MAX_PREVIEW_WIDTH = 172;
const MAX_PREVIEW_HEIGHT = 80;

const Wrapper = styled(View)(({ theme: { window } }) => {
  return {
    flexDirection: 'row',
    position: 'relative',
    flex: 1,
    paddingTop: 8,
    paddingBottom: 16,
    paddingLeft: window.isSmall || window.isMedium ? 0 : 8,
  };
});

const IconWrapper = styled(TouchableView)({
  alignSelf: 'flex-end',
  marginRight: 14,
});

const ImageIconWrapper = styled(TouchableView)({
  position: 'absolute',
  top: -12,
  right: -12,
  zIndex: 2,
  backgroundColor: 'white',
  borderRadius: 12,
});

interface Props {
  blob: Blob;
  fileType: MediaTypeField;
  fileName: string;
  /** localSrc can be used if we want to display preview of Image / Video */
  localSrc?: string;
  onClosePress: () => void;
}

const InputFile: FunctionComponent<Props> = ({
  blob,
  fileType,
  localSrc,
  fileName,
  onClosePress,
}) => {
  const mediaUrl = useMemo(() => {
    if (fileType === 'photo' || fileType === 'video') {
      return localSrc || URL.createObjectURL(blob);
    }
    return '';
  }, [localSrc, blob, fileType]);
  if (fileType === 'photo' || fileType === 'video') {
    if (fileType === 'video') {
      return (
        <Wrapper>
          <View style={{ position: 'relative' }}>
            <ImageIconWrapper onPress={onClosePress}>
              <CircleXMark variant="filled" size="major" colorType="brand" />
            </ImageIconWrapper>
            <VideoPlayer
              url={mediaUrl}
              style={{ maxWidth: MAX_PREVIEW_WIDTH + 50, maxHeight: MAX_PREVIEW_HEIGHT + 50 }}
              previewFrame={0.1}
            />
          </View>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <View style={{ position: 'relative' }}>
          <ImageIconWrapper onPress={onClosePress}>
            <CircleXMark variant="filled" size="major" colorType="brand" />
          </ImageIconWrapper>
          <Lightbox
            ariaLabel="picture message"
            a11yText="enlarged image view"
            source={mediaUrl}
            thumbnailURL={mediaUrl}
            thumbnailStyle={{ maxWidth: MAX_PREVIEW_WIDTH, maxHeight: MAX_PREVIEW_HEIGHT }}
          />
        </View>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <IconWrapper onPress={onClosePress}>
        <XIcon />
      </IconWrapper>
      <FileBubble
        dataQa="fileBubble"
        fileName={fileName}
        fileSize={blob.size}
        fileType={blob.type}
      />
    </Wrapper>
  );
};

export default InputFile;
