import { Subscription } from 'ts-frontend/types';
import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';
import { getActionAuth } from './tokenHelper';
import { MyInformationData, EmergencyContactData } from '../types';

export interface ApiResponse<T = any> {
  data?: T;
  status?: number;
}

function getMyInformation(clientUserID: number): Promise<{ data: { data: MyInformationData } }> {
  return apiWrapper.get(`${apiHelper().apiEndpoint}/v3/users/${clientUserID}/my-information`);
}
function patchMyInformation(clientUserID: number, data: MyInformationData): Promise<ApiResponse> {
  return apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v3/users/${clientUserID}/my-information`,
    data
  );
}

function getEmergencyContact(
  clientUserID: number
): Promise<{ data: { data: EmergencyContactData } }> {
  return apiWrapper.get(`${apiHelper().apiEndpoint}/v3/users/${clientUserID}/emergency-contact`);
}
function patchEmergencyContact(
  clientUserID: number,
  data: EmergencyContactData
): Promise<ApiResponse> {
  const { contactName, contactPhone, relationship } = data;
  return apiWrapper.patch(`${apiHelper().apiEndpoint}/v3/users/${clientUserID}/emergency-contact`, {
    contactName,
    contactPhone,
    relationship,
  });
}

interface FreezeSubscriptionResponse {
  data: { success: boolean };
}
function getNotificationPreferences(clientUserID: number): Promise<ApiResponse> {
  return apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/communication-preferences`
  );
}

function patchNotificationPreferences(
  clientUserID: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
): Promise<ApiResponse> {
  return apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/communication-preferences`,
    payload
  );
}

function getUserSettings(clientUserID: number): Promise<ApiResponse> {
  return apiWrapper.get(`${apiHelper().apiEndpoint}/v3/users/${clientUserID}/user-settings`);
}

function patchUserSettings(
  clientUserID: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
): Promise<ApiResponse> {
  return apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v3/users/${clientUserID}/user-settings`,
    payload
  );
}

function patchUserNickname(userID: number, nickname: string): Promise<ApiResponse> {
  return apiWrapper.patch(`${apiHelper().apiEndpoint}/api/v1/clients/${userID}`, {
    pseudonym: nickname,
  });
}

function update2FA(otpToken: string, otpKey: number): Promise<ApiResponse> {
  return apiWrapper.post(
    `${apiHelper().authAPIEndpoint}/v3/2fa/verify`,
    {
      otpToken,
      otpKey,
    },
    { shouldIgnore401: true }
  );
}

interface CreateJWTAuthTokenResponse {
  data: {
    data?: {
      token: string;
      ttlMinutes: number;
    };
  };
}

function createJWTAuthToken(payload: {
  action: string;
  password: string;
}): Promise<CreateJWTAuthTokenResponse> {
  return apiWrapper.post(`${apiHelper().authAPIEndpoint}/v2/tokens/jwt`, payload);
}

function createJWTAuthTokenCognito({
  action,
  idToken,
}: {
  action: string;
  idToken: string;
}): Promise<CreateJWTAuthTokenResponse> {
  return apiWrapper.post(
    `${apiHelper().authAPIEndpoint}/v2/tokens/jwt-cognito`,
    {
      action,
    },
    {
      headers: {
        Authorization: `Cognito ${idToken}`,
      },
    }
  );
}

function patchUserEmail(userID: number, email: string): Promise<ApiResponse> {
  return apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/users/${userID}/basic-details`,
    { email },
    {
      headers: {
        Authorization: `Bearer ${getActionAuth().token}`,
      },
      shouldIgnore401: true,
    }
  );
}

/**
 * update phone number for client.
 * @param userID
 * @param phoneNumber
 * @param phoneNumberCountryCode
 */
function patchPhoneNumber(
  userID: number,
  phoneNumber: string,
  phoneNumberCountryCode: string
): Promise<ApiResponse> {
  return apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/users/${userID}/basic-details`,
    { phoneNumber, phoneNumberCountryCode },
    {
      headers: {
        Authorization: `Bearer ${getActionAuth().token}`,
      },
    }
  );
}

/**
 * update if 2fa should be enabled or disabled
 * @param userID
 */
const patch2FA = (userID: number, disable2FA: boolean): Promise<ApiResponse> =>
  apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/users/${userID}/basic-details`,
    { disable2FA },
    {
      headers: {
        Authorization: `Bearer ${getActionAuth().token}`,
      },
    }
  );

/**
 * get phone number from client
 * @param userID
 */
function getClientPhoneNumber(userID: number): Promise<ApiResponse> {
  return apiWrapper.get(`${apiHelper().apiEndpoint}/v2/users/${userID}/2fa`);
}

function patchUserPassword(
  userID: number,
  password: string,
  confirmedPassword: string
): Promise<ApiResponse> {
  return apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/users/${userID}/basic-details`,
    {
      password,
      confirmedPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${getActionAuth().token}`,
      },
      shouldIgnore401: true,
    }
  );
}

interface GetSubscriptionsParams {
  clientUserID: number;
  roomID?: number;
  includePaymentDetails?: boolean;
  includeFirstPurchase?: boolean;
  includePlanDetails?: boolean;
  includeRedeemedVideoCredit?: boolean;
}

/**
 * Gets subscriptions for a client.
 * @param clientUserID User ID
 * @param roomID If specified, only returns the specified room
 * @param includePaymentDetails
 * @param includeFirstPurchase
 * @param includePlanDetails
 * @param includeRedeemedVideoCredit It returns video credit if user did a live session
 */
function getSubscriptions({
  clientUserID,
  roomID,
  includePaymentDetails,
  includeFirstPurchase,
  includePlanDetails,
  includeRedeemedVideoCredit,
}: GetSubscriptionsParams): Promise<ApiResponse<{ data: Subscription[] }>> {
  const queryString = new URLSearchParams();
  if (roomID) {
    queryString.set('roomID', roomID.toString());
  }
  const includes: string[] = [];
  if (includeFirstPurchase) includes.push('firstPurchase');
  if (includePlanDetails) includes.push('planDetails');
  if (includeRedeemedVideoCredit) includes.push('redeemedVideoCredit');
  if (includePaymentDetails) includes.push('paymentDetails');
  if (includes.length) queryString.set('include', includes.join(','));

  return apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/subscriptions?${queryString.toString()}`
  );
}

async function pauseSubscription(
  roomID: number,
  period?: number
): Promise<FreezeSubscriptionResponse> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/payments/subscriptions/${roomID}/freeze`, {
      freezePeriod: period,
    })
    .then((res) => res.data);
}

async function resumeSubscription(roomID: number): Promise<FreezeSubscriptionResponse> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/payments/subscriptions/${roomID}/unfreeze`)
    .then((res) => res.data);
}

async function undoCancelSubscription(roomID: number): Promise<ApiResponse> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/renew-subscription`)
    .then((res) => res.data);
}

function undoCancelNonSubscription(roomID: number): Promise<{}> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/renew-non-subscription`)
    .then((res) => res.data);
}

function sendEmailVerification(payload: { email: string }): Promise<ApiResponse> {
  return apiWrapper.post(`${apiHelper().authAPIEndpoint}/v2/auth/email-verification`, payload);
}

const myAccountApiHelper = {
  getEmergencyContact,
  patchEmergencyContact,
  getMyInformation,
  patchMyInformation,
  getNotificationPreferences,
  patchNotificationPreferences,
  getUserSettings,
  patchUserSettings,
  getSubscriptions,
  pauseSubscription,
  resumeSubscription,
  undoCancelSubscription,
  patchUserNickname,
  createJWTAuthToken,
  createJWTAuthTokenCognito,
  patchUserEmail,
  patchUserPassword,
  patchPhoneNumber,
  sendEmailVerification,
  undoCancelNonSubscription,
  getClientPhoneNumber,
  patch2FA,
  update2FA,
};

export default myAccountApiHelper;
