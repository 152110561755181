import { FormProvider, useForm } from 'react-hook-form';

import {
  Button,
  Big,
  RHFSelect,
  SparklingCalendar,
  Spinner,
  TextAreaRounded,
  View,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { ClientCancellationReasonEnum, ClientCancellationReasonOption } from 'ts-frontend/types';
import styled from '@/core/styled';

import BaseWrapper from './BaseWrapper';

const CANCELLATION_REASON_FIELD_NAME = 'cancellationReason';
const CANCELLATION_REASON_OTHER_TEXT_FIELD_NAME = 'cancellationReasonOtherText';

const StyledForm = styled.form({
  width: 335,
});
const StyledButton = styled(Button)({
  width: 335,
});

type CancellationReasonFormFields = {
  cancellationReason: ClientCancellationReasonEnum;
  cancellationReasonOtherText: string;
};
interface Props {
  options: ClientCancellationReasonOption[];
  isLoading: boolean;
  isEAP?: boolean;
  formattedStartTime?: string;
  isBookingTooSoon?: boolean;
  isBookingConfirmed?: boolean;
  onPressCancelSession: (reason: ClientCancellationReasonEnum, otherText: string) => () => void;
}
const CancelSessionSelectReason = ({
  options,
  isLoading,
  isEAP,
  formattedStartTime,
  isBookingTooSoon,
  isBookingConfirmed,
  onPressCancelSession,
}: Props) => {
  const { colors } = useEmotionTheme();
  const methods = useForm<CancellationReasonFormFields>();
  const { watch, setValue } = methods;
  const cancellationReasonValue = watch(CANCELLATION_REASON_FIELD_NAME);
  const cancellationReasonTextInputValue = watch(CANCELLATION_REASON_OTHER_TEXT_FIELD_NAME);

  const showTextInput = cancellationReasonValue === 'other';
  const canContinue =
    cancellationReasonValue === 'other'
      ? !!cancellationReasonTextInputValue
      : !!cancellationReasonValue;

  let ctaColor = colors.gray;
  if (canContinue) {
    ctaColor =
      isBookingTooSoon && isBookingConfirmed && !isEAP
        ? colors.torchRed
        : colors.permaTalkspaceDarkGreen;
  }
  return (
    <BaseWrapper>
      <>
        <SparklingCalendar />
        <Big style={{ marginTop: 24 }}>
          {isLoading ? (
            <Spinner style={{ margin: '24px 0' }} />
          ) : (
            `To cancel your live session scheduled for ${formattedStartTime}, please select a reason for cancelling.`
          )}
        </Big>
        <View style={{ marginTop: 16 }}>
          <FormProvider {...methods}>
            <StyledForm>
              <RHFSelect
                label=""
                fieldName={CANCELLATION_REASON_FIELD_NAME}
                placeholder="Select"
                options={options}
                registerOptionValue
              />
              {showTextInput && (
                <TextAreaRounded
                  multiline
                  placeholder="Please tell us more about your reason for cancelling"
                  dataQa="textInputCancellationReason"
                  value={cancellationReasonTextInputValue}
                  onChangeText={(value) =>
                    setValue(CANCELLATION_REASON_OTHER_TEXT_FIELD_NAME, value)
                  }
                  textAreaStyle={{ padding: '16px 0 0', margin: 0 }}
                />
              )}
            </StyledForm>
          </FormProvider>
        </View>
        <StyledButton
          text="Cancel session"
          dataQa="cancelSession"
          onPress={onPressCancelSession(cancellationReasonValue, cancellationReasonTextInputValue)}
          style={{
            marginTop: 40,
            backgroundColor: ctaColor,
          }}
          disabled={!canContinue}
        />
      </>
    </BaseWrapper>
  );
};

export default CancelSessionSelectReason;
