import { useQuery } from 'react-query';

import { ClientUsageStats } from 'ts-frontend/types';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { clientUsageStatsQueryKey } from '../utils/queryKeys';

interface QueryProps {
  clientID: number;
  therapistID?: number;
}
type QueryData = ClientUsageStats;

const fetchClientUsageStats =
  ({ clientID, therapistID }: QueryProps) =>
  async () => {
    let url = `${apiHelper().apiEndpoint}/v2/clients/${clientID}/usage-stats`;
    if (therapistID) {
      url += `?therapistID=${therapistID}`;
    }
    const {
      data: { data },
    } = await apiWrapper.get(url);
    return data;
  };

const useQueryClientUsageStats = ({ clientID, therapistID }: QueryProps) =>
  useQuery<QueryData, Error>({
    queryKey: clientUsageStatsQueryKey({ clientID, therapistID }),
    queryFn: fetchClientUsageStats({ clientID, therapistID }),
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(clientID),
  });

export default useQueryClientUsageStats;
