import { useCallback, useEffect } from 'react';
import CommunityCreatePostSuccess from '../components/CommunityCreatePostSuccess';
import { TEENSPACE_DAY_END_SHIFT_HOURS } from '../constants';
import { useCommunityState } from '../hooks/CommunityContext';
import useQueryCommunityFilteredPostCount from '../hooks/useQueryCommunityFilteredPostCount';
import { getCommunityFeedFilterTags } from '../utils/helpers';
import { CommunityFeedTypeEnum } from '../types';
import useSharePostWithTherapist from '../hooks/useSharePostWithTherapist';
import { useHistory } from '@/core/routerLib';
import { useCloseModal } from '../../utils/ModalsContextProvider';

const CommunityCreatePostSuccessContainer = () => {
  const history = useHistory<{ responseText: string }>();
  const { responseText } = history.location.state;
  const closeModal = useCloseModal();
  const { userID, clientTimezone, communityID, accessToken, dailyPrompt } = useCommunityState();
  const { data: userPostCount, isLoading } = useQueryCommunityFilteredPostCount({
    communityID,
    accessToken,
    tags: getCommunityFeedFilterTags({
      userID,
      timezone: clientTimezone,
      feedType: CommunityFeedTypeEnum.USER,
      shiftHours: TEENSPACE_DAY_END_SHIFT_HOURS,
    }),
  });
  const { sharePostWithTherapist } = useSharePostWithTherapist({
    userID,
    dailyPrompt,
    responseText,
  });

  const onPressShareWithTherapist = () => {
    sharePostWithTherapist().then(() => {
      closeModal({ navigateTo: 'teenspace' });
    });
  };
  const onClose = useCallback(() => {
    closeModal({ navigateTo: 'teenspace' });
  }, [closeModal]);

  const onPressSkip = () => {
    closeModal({ navigateTo: 'teenspace' });
  };

  useEffect(() => {
    if (!responseText) {
      closeModal({ navigateTo: 'teenspace' });
    }
  }, [closeModal, responseText]);

  return (
    <CommunityCreatePostSuccess
      isLoading={isLoading}
      userPostCount={userPostCount}
      communityDisplayString="Teenspace"
      onPressShareWithTherapist={onPressShareWithTherapist}
      onPressSkip={onPressSkip}
      onClose={onClose}
    />
  );
};

export default CommunityCreatePostSuccessContainer;
