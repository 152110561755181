import * as React from 'react';
import { Button, useEmotionTheme, BaseButton, Big } from '@talkspace/react-toolkit';

import { FlowVariant, TherapistTimeslot } from 'ts-frontend/types';

import { ERoom } from '../../../ts-frontend/entities/Room';

interface SelectTimeslotActionButtonsProps {
  room?: ERoom;
  selectedTimeslot?: TherapistTimeslot | null;
  flowVariant?: FlowVariant;
  bookWithIntroSession: boolean;
  isTherapist: boolean;
  isNoAvailabilityFFOn?: boolean;
  isClosingModal: boolean;
  noTimesTopPadding: number;
  handleBookLater: (optionType: string) => void;
  handleContinuePress: () => void;
  onPressTimesNotCompatible: () => void;
  onClosePress: () => void;
}

const SelectTimeslotActionButtons = (props: SelectTimeslotActionButtonsProps) => {
  const {
    room,
    selectedTimeslot,
    flowVariant,
    bookWithIntroSession,
    isTherapist,
    isNoAvailabilityFFOn,
    isClosingModal,
    noTimesTopPadding,
    handleBookLater,
    handleContinuePress,
    onPressTimesNotCompatible,
    onClosePress,
  } = props;
  const { colors, colorRoles } = useEmotionTheme();

  if (flowVariant === 'bookAndActivate') {
    const isB2B = ['dte', 'eap', 'bh'].includes(room?.accountType || '');
    const optionText = isB2B ? `None of these times work for me` : `I’ll book later`;
    const dataQa = isB2B ? 'noneOfTheseTimeWorksPress' : 'bookLaterPress';

    return (
      <>
        <Button
          style={{
            marginTop: 0,
            width: 335,
            backgroundColor: colorRoles.system.actionPrimaryDefault,
          }}
          disabled={!selectedTimeslot}
          onPress={handleContinuePress}
          text="Continue"
          size="medium"
          dataQa="inRoomSchedulingSelectTimeslotContinuePress"
        />
        <BaseButton
          style={{ marginTop: 14, width: 335 }}
          disabled={!selectedTimeslot}
          onPress={() => {
            handleBookLater(optionText);
          }}
          dataQa={dataQa}
        >
          <Big variant="bigBlueStoneNew">{optionText}</Big>
        </BaseButton>
      </>
    );
  }

  return (
    <>
      <Button
        style={{
          marginTop: 0,
          width: 335,
          backgroundColor: colorRoles.system.actionPrimaryDefault,
        }}
        disabled={!selectedTimeslot}
        onPress={handleContinuePress}
        text="Continue"
        size="medium"
        dataQa="inRoomSchedulingSelectTimeslotContinuePress"
      />
      {bookWithIntroSession && (
        <BaseButton
          style={{ marginTop: isTherapist ? 20 : 10 }}
          onPress={onClosePress}
          dataQa="inRoomSchedulingSelectTimeslotReserveSessionLater"
        >
          <Big variant="bigMedium">I’ll reserve this session later</Big>
        </BaseButton>
      )}
      {!isTherapist && isNoAvailabilityFFOn && (
        <Button
          disabled={isClosingModal}
          style={{
            backgroundColor: colorRoles.system.actionSecondaryDefault,
            color: colorRoles.system.actionSecondaryText,
            marginTop: bookWithIntroSession ? 0 : noTimesTopPadding,
            width: 335,
          }}
          text="I can't find a time that works for me"
          onPress={onPressTimesNotCompatible}
          dataQa="inRoomSchedulingIncompatibleTimes"
          primaryColor={colors.green}
          roundedFocusStyle
        />
      )}
    </>
  );
};

export default SelectTimeslotActionButtons;
