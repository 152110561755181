import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from '@/core/routerLib/routerLib';
import {
  getIsOnboardingDismissed,
  getIsOnboardingDismissedTestAccount,
} from '../onboarding/util/onboardingStorage';
import useNavShellData, { AggregatedRoomDataByID } from './useNavShellData';

const ONBOARDING_SOURCE_PARAMS = [
  'eligibility',
  'reactivation',
  'updateCoverage',
  'mbhIneligibility',
];

type IsDismissedPerRoom = Record<
  number,
  { isOnboardingDismissedTestAccount: boolean; isOnboardingDismissed: boolean }
>;

const getOnboardingRoomID = (
  dataByRoomID: AggregatedRoomDataByID,
  isDismissedPerRoom: IsDismissedPerRoom,
  roomIDParam?: number
) =>
  Object.keys(dataByRoomID).find((roomID) => {
    if (roomIDParam && roomIDParam !== +roomID) {
      return false;
    }
    const { onboarding: onboardingForRoom } = dataByRoomID[roomID];
    if (!onboardingForRoom?.length) {
      return false;
    }
    const { isOnboardingDismissed, isOnboardingDismissedTestAccount } = isDismissedPerRoom[roomID];
    // onboarding will show up if both true:
    // 1. QA account did not dismiss it
    // 2. User did not dismiss it OR user dismissed it and there are required onboarding steps
    const showOnboarding =
      !isOnboardingDismissedTestAccount &&
      (!isOnboardingDismissed || onboardingForRoom.some(({ isRequired }) => isRequired));
    return showOnboarding;
  });

interface UseRedirectOnboardingOptions {
  roomID?: number;
  disabled?: boolean;
}

const useRedirectOnboarding = (props: UseRedirectOnboardingOptions = {}) => {
  const { roomID, disabled } = props;
  const history = useHistory();
  const location = useLocation();
  const {
    aggregatedRoomData: { dataByRoomID },
    queries: { isLoadingOnboarding },
  } = useNavShellData();

  const isDismissedPerRoom = useMemo(
    () =>
      Object.keys(dataByRoomID || {}).reduce<
        Record<
          number,
          { isOnboardingDismissedTestAccount: boolean; isOnboardingDismissed: boolean }
        >
      >((prev, onboardingRoomID) => {
        return {
          ...prev,
          [onboardingRoomID]: {
            isOnboardingDismissedTestAccount: getIsOnboardingDismissedTestAccount(onboardingRoomID),
            isOnboardingDismissed: getIsOnboardingDismissed(onboardingRoomID),
          },
        };
      }, {}),
    [dataByRoomID]
  );

  useEffect(() => {
    if (isLoadingOnboarding || !dataByRoomID || disabled) {
      return;
    }
    const roomIDWithOnboarding = getOnboardingRoomID(dataByRoomID, isDismissedPerRoom, roomID);
    if (!roomIDWithOnboarding) {
      return;
    }
    const { onboarding: onboardingForRoom } = dataByRoomID[roomIDWithOnboarding];
    if (!onboardingForRoom) {
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const sourceParam = queryParams.get('source');
    const redirectToRoom = sourceParam && ONBOARDING_SOURCE_PARAMS.includes(sourceParam);

    history.push(
      `/room/${roomIDWithOnboarding}/onboarding${redirectToRoom ? '?redirectToRoom=true' : ''}`
    );
  }, [dataByRoomID, history, isLoadingOnboarding, disabled, roomID, location, isDismissedPerRoom]);
};
export default useRedirectOnboarding;
