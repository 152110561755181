import {
  spacing,
  StickyDrawer,
  Button,
  TextDS,
  View,
  Link,
  useEmotionTheme,
  StickyDrawerParentContainer,
} from '@talkspace/react-toolkit';
import { useState } from 'react';
import {
  ArrowRotateRight,
  Ban,
  MessageHeart,
  UserXMark,
  FlagPennant,
  PenToSquare,
} from '@talkspace/react-toolkit/src/designSystems/icons';
import GuidelineItem from './GuideLineItem';
import styled from '../../../core/styled';
import useMutationPutCommunityUserData from '../../hooks/useMutationPutCommunityUserData';
import { useCloseModal } from '@/utils/ModalsContextProvider';

const { space100, space150, space200, space500, space800 } = spacing;

const GUIDELINES = [
  {
    title: 'This is not therapy',
    description:
      'By continuing you agree that Teenspace is not a therapeutic or healthcare platform.',
    icon: UserXMark,
  },
  {
    title: 'Be a contributor',
    description:
      'Join your peers in creating a safe space for honesty and connection. Use your voice to encourage others and show support.',
    icon: MessageHeart,
  },
  {
    title: 'No discrimination',
    description:
      'Posts containing hate or discrimination based on race, ethnicity, gender, sexual orientation, or disability are not allowed.',
    icon: Ban,
  },
  {
    title: 'Post appropriately',
    description:
      "Ensure that your posts are relevant to the daily topic and don't contain identifying, illegal, or promotional content are prohibited.",
    icon: PenToSquare,
  },
  {
    title: 'Report inappropriate content',
    description: 'Please flag any content or behavior that violates our community guidelines.',
    icon: FlagPennant,
  },
];
const GUIDELINE_AGREEMENT =
  'By continuing you agree that the Teenspace community is not therapy and consent to these community guidelines';

const StyledLink = styled(Link)(({ theme: { colorRoles } }) => {
  return {
    textDecorationLine: 'underline',
    color: colorRoles.typography.textBrandDefault,
    marginBottom: space500,
  };
});
const StyledBody = styled(TextDS)(() => {
  return {
    textAlign: 'center',
    marginTop: space100,
    marginBottom: space500,
  };
});
const StyledHeader = styled(TextDS)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    textAlign: 'center',
    marginTop: isMobile ? space200 : space500,
  };
});

const Guidelines = () => {
  const { colorRoles } = useEmotionTheme();
  const { mutate: putCommunityUserData } = useMutationPutCommunityUserData();
  const closeModal = useCloseModal();

  const [isOnboardingError, setIsOnboardingError] = useState(false);

  const onContinuePress = async () => {
    putCommunityUserData(
      { onboardedTeenspaceAt: String(new Date()) },
      {
        onSuccess: (data) => {
          if (data) {
            closeModal();
          }
        },
        onError: (err) => {
          if (err.data?.status !== 200) {
            setIsOnboardingError(true);
          }
        },
      }
    );
  };
  return (
    <View align="center" style={{ height: '100%', marginTop: space200 }}>
      <StickyDrawerParentContainer>
        <View>
          <StyledHeader variant="heading2xl">Please read our community guidelines</StyledHeader>
          <StyledBody variant="body" style={{ color: colorRoles.typography.textSubtle }}>
            Here's how you can help us create a truly supportive space for teens to share stories
          </StyledBody>
        </View>
        <View align="center">
          {GUIDELINES.map((guideline) => (
            <GuidelineItem guideline={guideline} colorRoles={colorRoles} />
          ))}
          <View align="inherit" justify="inherit" style={{ marginRight: space800 }}>
            <StyledLink>
              <TextDS variant="bodySm" colorRole="textBrandDefault">
                Read the full community guidelines
              </TextDS>
            </StyledLink>
          </View>
        </View>
        <StickyDrawer>
          <Button
            stretch
            sizeDS="medium"
            variant="primary"
            onPress={onContinuePress}
            Icon={isOnboardingError ? () => <ArrowRotateRight colorType="inverse" /> : () => <></>}
            text={isOnboardingError ? 'Try again' : 'Continue'}
            style={{ width: '100%' }}
            dataQa="teenspaceCommunityGuidelineContinueButton"
          />
          <View align="center" style={{ marginTop: space150 }}>
            <TextDS
              variant="bodyXs"
              style={{
                marginBottom: space200,
                color: colorRoles.typography.textSubtlest,
                textAlign: 'center',
              }}
            >
              {GUIDELINE_AGREEMENT}
            </TextDS>
          </View>
        </StickyDrawer>
      </StickyDrawerParentContainer>
    </View>
  );
};

export default Guidelines;
