import { useCallback, useMemo } from 'react';
import {
  Tray,
  View,
  Small,
  ExtraHuge,
  Huge,
  Large,
  Button,
  BaseButton,
} from '@talkspace/react-toolkit';
import { ETherapistInfo, isTherapistCT } from 'ts-frontend/entities/Therapist';
import { ERoom } from 'ts-frontend/entities/Room';
import useIsClientDischargeV3OnForPlan from 'ts-frontend/hooks/useIsClientDischargeV3OnForPlan';
import styled from '@/core/styled';
import { trackEvent } from '@/utils/analytics/eventTracker';
import TherapistAvatarV2 from '../TherapistAvatarV2';

interface Props {
  therapist: ETherapistInfo;
  room: ERoom;
  isProviderAvailable: boolean;
  handleMatchProvider: () => void;
  handleReactivation: () => void;
  dismissReactivationTray: () => void;
  roomID: number;
}

const Styled = {
  ProviderContainer: styled(View)(({ theme: { colors } }) => {
    return {
      width: 320,
      height: 184,
      paddingTop: 16,
      paddingBottom: 16,
      borderRadius: 15,
      background: colors.permaGreyed,
      justifyContent: 'center',
    };
  }),
  AvatarContainer: styled(View)({
    paddingBottom: 14,
  }),
  HeaderWrapper: styled(View)({
    marginTop: 33,
  }),
  SubTitle: styled(Large)({
    marginTop: 16,
  }),
  ButtonContainer: styled(View)({
    gap: 24,
    marginTop: 42,
    paddingLeft: 10,
    paddingRight: 10,
  }),
};

const ReactivationTray = ({
  therapist,
  room,
  isProviderAvailable,
  handleMatchProvider,
  handleReactivation,
  dismissReactivationTray,
  roomID,
}: Props) => {
  const isClientDischargeV3OnForPlan = useIsClientDischargeV3OnForPlan(roomID);
  const handleMatchProviderAndClose = useCallback(() => {
    trackEvent(
      'Inactivity Options Selected',
      {
        actionName: 'inactivityPromptInteraction',
        optionSelected: 'Switch provider',
        isProviderAvailable,
        roomID,
      },
      ['tsAnalytics']
    );
    handleMatchProvider();
    dismissReactivationTray();
  }, [handleMatchProvider, dismissReactivationTray, roomID, isProviderAvailable]);
  const handleReactivationAndClose = useCallback(() => {
    trackEvent(
      'Inactivity Options Selected',
      {
        actionName: 'inactivityPromptInteraction',
        optionSelected: 'Message provider',
        isProviderAvailable,
        roomID,
      },
      ['tsAnalytics']
    );
    handleReactivation();
    dismissReactivationTray();
  }, [handleReactivation, dismissReactivationTray, roomID, isProviderAvailable]);

  const handleClose = useCallback(() => {
    trackEvent(
      'Inactivity Prompt Dismissed',
      {
        actionName: 'inactivityPromptInteraction',
        isProviderAvailable,
        roomID,
      },
      ['tsAnalytics']
    );
    dismissReactivationTray();
  }, [isProviderAvailable, roomID, dismissReactivationTray]);

  const providerAvailabilityText = useMemo(() => {
    const { availability, therapistType } = therapist || {};

    if (therapistType && isTherapistCT(therapistType)) {
      return '';
    }

    if (!isProviderAvailable) {
      return 'Your provider is unavailable';
    }

    if (availability === 'time-off') {
      return 'Your provider is on time-off';
    }

    if (availability === 'unavailable') {
      return 'Your provider is off today';
    }

    return '';
  }, [isProviderAvailable, therapist]);

  const planText = useMemo(() => {
    const { roomType } = room || {};
    if (roomType === 'couples_room') {
      return 'Couples therapy plan';
    }
    return 'Therapy plan';
  }, [room]);

  return (
    <Tray handleClose={handleClose}>
      <Styled.ProviderContainer align="center">
        <Styled.AvatarContainer>
          <TherapistAvatarV2
            therapist={therapist}
            room={room}
            isProviderAvailable={isProviderAvailable}
          />
        </Styled.AvatarContainer>
        <Huge style={{ marginBottom: 4 }}>
          {therapist.firstName} {therapist.lastName}
        </Huge>
        <Small>{isProviderAvailable ? planText : providerAvailabilityText}</Small>
      </Styled.ProviderContainer>
      <Styled.HeaderWrapper>
        <ExtraHuge>Welcome back to Talkspace</ExtraHuge>
        <Styled.SubTitle>
          {isProviderAvailable
            ? `If you want to continue therapy, your provider is ready to hear from you`
            : `It looks like your provider is unavailable. If you're ready to continue therapy, we'll match you to a new provider to keep making progress.
            `}
        </Styled.SubTitle>
      </Styled.HeaderWrapper>
      <Styled.ButtonContainer>
        {isProviderAvailable ? (
          <>
            <Button onPress={handleReactivationAndClose} dataQa="messageProviderPress">
              {isClientDischargeV3OnForPlan ? (
                <Large variant="largeBoldWhite">Restart therapy</Large>
              ) : (
                <Large variant="largeBoldWhite">Message {therapist.firstName}</Large>
              )}
            </Button>
            <BaseButton onPress={handleMatchProviderAndClose} dataQa="matchWithNewProviderPress">
              <Large variant="largeBoldWideGreen">Match me with a new provider</Large>
            </BaseButton>
          </>
        ) : (
          <>
            <Button onPress={handleMatchProviderAndClose} dataQa="continueWithNewProviderPress">
              <Large variant="largeBoldWhite">Continue with a new provider</Large>
            </Button>
          </>
        )}
      </Styled.ButtonContainer>
    </Tray>
  );
};

export default ReactivationTray;
