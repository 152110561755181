import { useEffect } from 'react';

import { useCommunityState } from './CommunityContext';
import { useOpenModal } from '../../utils/ModalsContextProvider';

const useTeenspaceOnboarding = () => {
  const openModal = useOpenModal();
  const { isTeenspaceOnboarded, isLoading } = useCommunityState();

  useEffect(() => {
    if (!isTeenspaceOnboarded && !isLoading) {
      openModal('/community/teenspace/onboarding/welcome');
    }
  }, [isTeenspaceOnboarded, isLoading, openModal]);
};

export default useTeenspaceOnboarding;
