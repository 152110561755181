import React, { VFC, useMemo } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { TherapistTimeslot, Booking } from 'ts-frontend/types';
import styled, { EmotionStyle, useEmotionTheme } from '../../../core/styled';
import { webOnlyStyle } from '../../../core/styleHelpers';
import View from '../../../components/View';
import TouchableView from '../../../components/TouchableView';
import ScrollView from '../../../components/ScrollView';
import Large from '../../../components/Typography/Large';
import Small from '../../../components/Typography/Small';
import HiddenText from '../../../components/HiddenText';
import { useUniqueID } from '../../../hooks/a11yHelper';
import { useA11y, TIMESLOTS_ARROW_DIR } from './TimeslotCarousel.a11y';

interface BaseTimeslotCarouselProps {
  selectedTimeslot?: TherapistTimeslot | null;
  setSelectedTimeslot: (timeslot: TherapistTimeslot) => void;
  isTherapist: boolean;
  carouselContainerStyle?: EmotionStyle;
  isMobile: boolean;
  bookings?: Booking[];
  containerStyle?: EmotionStyle;
  roundedFocusStyle?: boolean;
}

interface TimeslotsByDayCarouselProps extends BaseTimeslotCarouselProps {
  timeslotsByDay: { day: number; timeslots: TherapistTimeslot[] }[];
  timeslots?: never;
  day?: never;
}
interface TimeslotsSingleDayCarouselProps extends BaseTimeslotCarouselProps {
  timeslotsByDay?: never;
  timeslots: TherapistTimeslot[];
  day: moment.Moment;
}

type TimeslotCarouselProps = TimeslotsByDayCarouselProps | TimeslotsSingleDayCarouselProps;

const getTimeslotID = (column: number, row: number, prefix = 'TSTimeSlotCarousel_'): string =>
  `${prefix}${column}-${row}`;

const getIsSelected = (timeslot: string, selectedTimeslot: string | undefined) =>
  moment(timeslot).isSame(moment(selectedTimeslot));

const TIMESLOT_MARGIN = 5;
const TIMESLOT_WIDTH = 106;
const TIMESLOT_HEIGHT = 34;
const TIMESLOT_AREA_WIDTH = 344;

const TimeslotWrapper = styled(ScrollView)({
  marginRight: 4,
  paddingTop: 14,
  width: TIMESLOT_AREA_WIDTH,
});

const BlankTimeslot = styled(View)(({ theme: { colors } }) => {
  return {
    width: TIMESLOT_WIDTH,
    height: TIMESLOT_HEIGHT,
    borderRadius: 5,
    backgroundColor: colors.permaLinkWaterGrey,
  };
});

const AvailableTimeslot = styled(TouchableView)<{
  isSelected: boolean;
}>(({ isSelected, theme: { colors, colorRoles } }) => {
  return {
    width: TIMESLOT_WIDTH,
    height: TIMESLOT_HEIGHT,
    borderRadius: 5,
    outline: 'none',
    justifyContent: 'center',
    backgroundColor: isSelected ? colorRoles.system.interactivePrimaryDefault : colors.white,
    ...webOnlyStyle({
      border: `1px solid ${isSelected ? colors.green : colors.periwinkleGrey}`,
    }),
  };
});

const TimeslotColumn = ({
  index,
  columnHeader,
  timeslotIDPrefix,
  timeslots,
  maxLength,
  isTherapist,
  selectedTimeslot,
  columnHasFirstSelectableElement,
  arrowHandlers,
  setSelectedTimeslot,
  bookings,
  roundedFocusStyle,
}: {
  isTherapist: TimeslotsSingleDayCarouselProps['isTherapist'];
  timeslots: TimeslotsSingleDayCarouselProps['timeslots'];
  selectedTimeslot: TimeslotsSingleDayCarouselProps['selectedTimeslot'];
  bookings: TimeslotsSingleDayCarouselProps['bookings'];
  roundedFocusStyle: TimeslotsSingleDayCarouselProps['roundedFocusStyle'];
  index: number;
  maxLength: number;
  columnHeader: string;
  setSelectedTimeslot: (timeslot: TherapistTimeslot) => void;
  arrowHandlers: (dir: TIMESLOTS_ARROW_DIR, columnIndex: number, rowIndex: number) => void;
  columnHasFirstSelectableElement: boolean;
  timeslotIDPrefix: string;
}) => {
  const activeBookings = bookings?.filter((item) => item?.status === 'active');
  const bookingsStartTimes = activeBookings?.map((item) => moment(item.startTime));
  const timeslotArray: React.ReactElement[] = [];
  const { colors } = useEmotionTheme();

  const descriptionID = useUniqueID('timeslotDescription');

  const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>, indexParam: number) => {
    e.stopPropagation();
    if (!e.key.includes('Arrow')) return;
    const dir = e.key.replace('Arrow', '').toUpperCase() as TIMESLOTS_ARROW_DIR;
    arrowHandlers(dir, index, indexParam);
  };

  const hasSelected = !!selectedTimeslot;

  const pushAvailableTimeSlot = (indexParam: number, isSelected: boolean) =>
    timeslotArray.push(
      <AvailableTimeslot
        align="center"
        tabIndex={
          isSelected || (!hasSelected && columnHasFirstSelectableElement && indexParam === 0)
            ? 0
            : -1
        }
        id={getTimeslotID(index, indexParam, timeslotIDPrefix)}
        key={indexParam}
        isSelected={isSelected}
        aria-describedby={descriptionID}
        onKeyUp={(e) => onKeyUp(e, indexParam)}
        onPress={() => setSelectedTimeslot(timeslots[indexParam])}
        style={{
          marginLeft: TIMESLOT_MARGIN,
          marginTop: indexParam === 0 ? 0 : TIMESLOT_MARGIN,
        }}
        roundedFocusStyle={roundedFocusStyle}
        primaryColor={colors.green}
        data-qa={moment(timeslots[indexParam].start).format('h:mm-a')}
      >
        <HiddenText id={descriptionID}>
          {`${columnHeader}.`} {isSelected ? ' Selected.' : ''} Use arrow keys to navigate
        </HiddenText>
        <Large
          style={{
            color: isSelected ? colors.white : colors.black,
          }}
        >
          {moment(timeslots[indexParam].start).format('h:mm a')}
        </Large>
      </AvailableTimeslot>
    );
  const pushBlankTimeSlot = (indexParam: number) =>
    timeslotArray.push(
      <BlankTimeslot
        key={indexParam}
        style={{
          marginTop: indexParam === 0 ? 0 : TIMESLOT_MARGIN,
          marginLeft: TIMESLOT_MARGIN,
        }}
      />
    );

  let hasAvailableSlots = false;
  for (let i = 0; i < maxLength; i += 1) {
    if (isTherapist) {
      const alreadyBookedCheck =
        timeslots[i] &&
        bookingsStartTimes?.map((time) => time.isBetween(timeslots[i].start, timeslots[i].end));
      if (timeslots[i] && !alreadyBookedCheck?.includes(true)) {
        const isSelected = getIsSelected(timeslots[i].start, selectedTimeslot?.start);
        pushAvailableTimeSlot(i, isSelected);
        hasAvailableSlots = true;
      } else {
        pushBlankTimeSlot(i);
      }
    } else if (timeslots[i]) {
      const isSelected = getIsSelected(timeslots[i].start, selectedTimeslot?.start);
      pushAvailableTimeSlot(i, isSelected);
      hasAvailableSlots = true;
    }
  }
  if (!hasAvailableSlots) {
    return (
      <View>
        <BlankTimeslot
          style={{
            marginTop: 0,
            marginLeft: TIMESLOT_MARGIN,
          }}
        />
      </View>
    );
  }
  return <View>{timeslotArray.map((el) => el)}</View>;
};

const CarouselDateHeader = ({
  text,
  isByDay,
  index,
}: {
  text: string;
  isByDay: boolean;
  index: number;
}) => {
  const { colors } = useEmotionTheme();
  return (
    <View
      align="center"
      style={{
        width: TIMESLOT_WIDTH,
        marginLeft: index === 0 ? 0 : TIMESLOT_MARGIN,
      }}
    >
      {isByDay ? (
        <View>
          {/* day i.e. "Mon" */}
          <Small style={{ fontWeight: 900, color: colors.black }}>{text.slice(0, 3)}</Small>
          {/* month and date i.e. "jan 3" */}
          <Small style={{ fontWeight: 500, color: colors.slateGrey }}>{text.slice(4)}</Small>
        </View>
      ) : (
        <Small style={{ fontWeight: 500, color: colors.slateGrey }}>{text}</Small>
      )}
    </View>
  );
};

const TimeslotCarousel: VFC<TimeslotCarouselProps> = ({
  timeslotsByDay,
  timeslots,
  day,
  isMobile,
  isTherapist,
  selectedTimeslot,
  setSelectedTimeslot,
  carouselContainerStyle,
  bookings,
  containerStyle,
  roundedFocusStyle,
}) => {
  const { colors } = useEmotionTheme();
  const noon = 12;
  const sixPM = 18;
  const timeslotIDPrefix = `${useUniqueID('timeslotCarousel')}_`;
  const timeslotColumns = useMemo(() => {
    const columns: { timeslots: TherapistTimeslot[]; title: string }[] = [
      { timeslots: [], title: '' },
      { timeslots: [], title: '' },
      { timeslots: [], title: '' },
    ];
    // separate the timeslots data by day, presenting 3 days worth
    if (timeslotsByDay) {
      const localTimezone = momentTz.tz.guess();
      for (let i = 0; i < timeslotsByDay.length; i += 1) {
        const singleDayTimeslotData = timeslotsByDay[i].timeslots as TherapistTimeslot[];
        const columnDayOfWeek = momentTz(new Date(singleDayTimeslotData[0].start))
          .tz(localTimezone)
          .format('ddd');
        const columnMonthAndDay = momentTz(new Date(singleDayTimeslotData[0].start))
          .tz(localTimezone)
          .format('MMM D');
        columns[i].timeslots = singleDayTimeslotData;
        // i.e. mon jan 3
        columns[i].title = `${columnDayOfWeek} ${columnMonthAndDay}`;
      }
      // separate a given days timeslot data by time of day
    } else if (timeslots && day) {
      timeslots?.forEach((timeslot) => {
        columns[0].title = 'Morning';
        columns[1].title = 'Afternoon';
        columns[2].title = 'Evening';

        const startTime = moment(timeslot.start);
        if (startTime.isBefore(day.clone().hour(noon))) {
          // 12am - 11:59am
          columns[0].timeslots.push(timeslot);
        } else if (startTime.isBefore(day.clone().hour(sixPM))) {
          // 12pm - 5:59pm
          columns[1].timeslots.push(timeslot);
        } else {
          columns[2].timeslots.push(timeslot);
        }
      });
    }
    return columns;
  }, [timeslotsByDay, timeslots, day]);
  const arrayOfTimeslotColumnsLengths = timeslotColumns.map(
    ({ timeslots: columnTimeslots }) => columnTimeslots.length
  );
  const maxLength = Math.max(...arrayOfTimeslotColumnsLengths);

  const { firstSelectableElementColumnIndex, arrowHandlers } = useA11y(
    timeslotColumns,
    arrayOfTimeslotColumnsLengths,
    timeslotIDPrefix,
    getTimeslotID
  );
  return (
    <View style={containerStyle}>
      <View
        row
        style={{
          position: 'sticky',
          top: '4em',
          backgroundColor: colors.white,
          zIndex: 1,
          paddingTop: '1em',
        }}
      >
        {timeslotColumns.map(({ title }, index) => (
          <CarouselDateHeader text={title} isByDay={!!timeslotsByDay} index={index} key={title} />
        ))}
      </View>
      <View flex={1}>
        <TimeslotWrapper
          row
          align="start"
          style={{
            width: '100%',
            height: '100%',
            maxHeight: isMobile ? undefined : 437,
            overflowY: isMobile ? 'visible' : 'auto',
            ...carouselContainerStyle,
          }}
        >
          {timeslotColumns.map((timeslotColumn, index) => (
            <TimeslotColumn
              columnHasFirstSelectableElement={firstSelectableElementColumnIndex === index}
              timeslotIDPrefix={timeslotIDPrefix}
              arrowHandlers={arrowHandlers}
              columnHeader={timeslotColumn.title}
              timeslots={timeslotColumn.timeslots}
              selectedTimeslot={selectedTimeslot}
              setSelectedTimeslot={setSelectedTimeslot}
              maxLength={maxLength}
              key={timeslotColumn.title}
              index={index}
              isTherapist={isTherapist}
              bookings={bookings}
              roundedFocusStyle={roundedFocusStyle}
            />
          ))}
        </TimeslotWrapper>
      </View>
    </View>
  );
};

export default TimeslotCarousel;
