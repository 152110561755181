import { FunctionComponent, useEffect } from 'react';
import { keyframes } from '@emotion/core';

import TouchableView from '../TouchableView';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import styled, { EmotionStyle } from '../../core/styled';

export type AnimationSize = 'small' | 'medium' | 'large';
export interface ModalProps {
  handleClose: () => void;
  backgroundStyle?: EmotionStyle;
  style?: EmotionStyle;
  escapeCloses?: boolean;
  dataQa?: string;
  mobileContainerStyle?: EmotionStyle;
  animationSize?: AnimationSize;
}

const slideInSm = keyframes`
0% {
  bottom: -30%;
 }
 100% {
  bottom: 0;
 }
`;

const slideInMd = keyframes`
0% {
  bottom: -50%;
 }
 100% {
  bottom: 0;
 }
`;

const slideInLg = keyframes`
0% {
  bottom: -100%;
 }
 100% {
  bottom: 0;
 }
`;

const getKeyframes = (animationSize?: AnimationSize) => {
  if (animationSize === 'small') return slideInSm;
  if (animationSize === 'medium') return slideInMd;
  if (animationSize === 'large') return slideInLg;
  return null;
};

const BackgroundWrapper = styled(TouchableView)(() => {
  return {
    backgroundColor: `rgba(0, 0, 0, 0.5)`,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    textAlign: 'center',
    cursor: 'default',
  };
});

const ContentView = styled(TouchableView)<{
  isMobile?: boolean;
  mobileContainerStyle?: EmotionStyle;
  animationSize?: AnimationSize;
}>(({ isMobile, mobileContainerStyle, animationSize, theme: { colors } }) => {
  const keyFrame = getKeyframes(animationSize);
  const mobileStyle = {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 20,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    paddingTop: 72,
    paddingBottom: 81,
    paddingLeft: 23,
    paddingRight: 23,
    animation: keyFrame && `${keyFrame} 300ms ease-out both`,
    zIndex: 1000,
    ...mobileContainerStyle,
  };
  return {
    backgroundColor: colors.white,
    cursor: 'default',
    ...(isMobile
      ? mobileStyle
      : { borderRadius: 10, padding: 80, width: 500, alignSelf: 'center', margin: 'auto' }),
  };
});

const Tray: FunctionComponent<ModalProps> = ({
  children,
  handleClose,
  style,
  backgroundStyle,
  escapeCloses = false,
  mobileContainerStyle,
  dataQa,
  animationSize,
}) => {
  const { isMobile } = useWindowWidthState();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (escapeCloses) {
      const close = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          handleClose();
        }
      };
      window.addEventListener('keydown', close);
      return () => window.removeEventListener('keydown', close);
    }
  }, [handleClose, escapeCloses]);

  return (
    <BackgroundWrapper onPress={handleClose} style={backgroundStyle}>
      <ContentView
        onPress={(e) => e.stopPropagation()}
        isMobile={isMobile}
        align="center"
        style={style}
        data-qa={dataQa}
        mobileContainerStyle={mobileContainerStyle}
        animationSize={animationSize}
      >
        {children}
      </ContentView>
    </BackgroundWrapper>
  );
};

export default Tray;
