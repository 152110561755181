import { FunctionComponent } from 'react';
import { useEmotionTheme } from '@talkspace/react-toolkit';
import { GenericWizardWelcomeScreen } from 'stepWizard';
import peopleImage from '../../assets/peopleImage.png';

const WelcomeScreen: FunctionComponent<{
  handleStartButton: () => void;
}> = ({ handleStartButton }) => {
  const { colors } = useEmotionTheme();
  return (
    <GenericWizardWelcomeScreen
      firstHeadlineRowText="Find a new"
      secondHeadlineRowText="provider"
      subHeadlineText="5 questions - 2min"
      description="Finding the right fit with a provider is essential to making progress. Let's go over your information and identify what you're looking for in your new provider."
      onButtonPress={handleStartButton}
      buttonText="Begin"
      imageURL={peopleImage}
      imageHeight={206}
      imageWidth={226}
      imageXPos={161}
      imageYPos={56}
      imageMobileXPos={34}
      imageMobileYPos={120}
      backgroundColor={colors.welcomeBlue}
    />
  );
};

export default WelcomeScreen;
