/* eslint-disable arrow-body-style */
import { useHistory } from 'react-router';
import { ColorRolesVersion } from '../../types';
import ChevronRight from '../../icons/ChevronRight';
import View from '../../../components/View';
import BaseButton from '../../../components/BaseButton';
import TextDS from '../typography/TextDS';
import styled from '../../../core/styled';

const getInteractiveStyles = (colorRoles: ColorRolesVersion) => {
  return {
    color: colorRoles.typography.textSubtlest,
    '&:hover': {
      color: `${colorRoles.button.brandSecondaryTextHovered}`,
    },
    '&:active': {
      color: colorRoles.button.brandSecondaryTextPressed,
    },
  };
};

const StyledLink = styled(BaseButton)(({ theme: { colorRoles } }) =>
  getInteractiveStyles(colorRoles)
);

interface BreadcrumbRoute {
  label: string;
  path: string;
}

export interface BreadcrumbProps {
  routes: BreadcrumbRoute[];
}

const Breadcrumb = ({ routes }: BreadcrumbProps) => {
  const history = useHistory();
  const lastIdx = routes.length - 1;

  return (
    <View
      as="nav"
      aria-label="Breadcrumb"
      row
      columnGap={8}
      align="center"
      style={{ marginBottom: 8 }}
    >
      {routes.map((route, i) =>
        i < lastIdx ? (
          <>
            <StyledLink
              onPress={() => {
                history.push(route.path);
              }}
            >
              <TextDS variant="headingSm" key={`${route}`} inheritColor>
                {route.label}
              </TextDS>
            </StyledLink>
            <ChevronRight size="small" colorType="subtlest" />
          </>
        ) : (
          <>
            <TextDS
              variant="headingSm"
              colorRole="textSubtlest"
              aria-current={i === lastIdx && 'page'}
              key={`${route}`}
            >
              {route.label}
            </TextDS>
          </>
        )
      )}
    </View>
  );
};

export default Breadcrumb;
