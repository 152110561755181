import { FunctionComponent, useEffect } from 'react';
import {
  View,
  Big,
  ResponsiveLayoutWithHeader,
  PanelHeader,
  TextDS,
} from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useFlags } from 'launchDarkly/FlagsProvider';
import UpcomingSurveysContainer from '../containers/UpcomingSurveysContainer';
import SymptomTrackerContainer from '../containers/SymptomTrackerContainer';
import GoalsContainer from '../containers/GoalsContainer';
import ObjectivesContainer from '../containers/ObjectivesContainer';
import { RouteComponentProps } from '../../core/routerLib/routerLib';
import { trackNavPageView } from '../../utils/analytics/events';
import useNavShellWindowHeight from '../../hooks/useNavShellWindowHeight';

const ClinicalProgress: FunctionComponent<
  RouteComponentProps<{
    roomID: number;
  }>
> = ({ history, match }) => {
  const useNewNav = useNewMemberNav();
  const { memberNewNav } = useFlags();
  const { outsideHeaderHeight, outsideFooterHeight } = useNavShellWindowHeight();

  useEffect(() => {
    if (memberNewNav && memberNewNav !== 'disabled') {
      trackNavPageView('clinical-progress', { roomID: match.params.roomID });
    }
  }, [memberNewNav, match.params.roomID]);

  return (
    <ResponsiveLayoutWithHeader
      outsideHeaderHeight={outsideHeaderHeight}
      outsideFooterHeight={outsideFooterHeight}
      safeAreaVariant="none"
      noHeaderLine={useNewNav}
      renderHeader={() =>
        useNewNav ? (
          <PanelHeader
            renderLeft={() => (
              <View row align="center">
                <TextDS variant="heading2xl">Journey</TextDS>
              </View>
            )}
          />
        ) : (
          <PanelHeader
            renderLeft={() => (
              <View row align="center">
                <Big as="h1">Clinical Progress</Big>
              </View>
            )}
            onRightPress={history.goBack}
          />
        )
      }
    >
      <UpcomingSurveysContainer />
      <SymptomTrackerContainer />
      <GoalsContainer />
      <ObjectivesContainer />
    </ResponsiveLayoutWithHeader>
  );
};

export default ClinicalProgress;
