import { FunctionComponent } from 'react';

import { useEmotionTheme } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useNewMemberNav } from 'launchDarkly/hooks';
import BaseAccordion from './Base';
import NotificationTitle from '../NotificationTitle';
import NotificationPanelRow from '../NotificationPanelRow';
import { DailyReminder, EmailEnvelope, CellPhone } from '../../../components/icons';
import constructDescription from './helpers';
import styled from '@/core/styled';

const arrowContainerStyles = {
  marginTop: 21,
};

const StyledCellPhone = styled(CellPhone)(() => {
  return {
    width: '22px',
  };
});

interface Props {
  isOpen: boolean;
  handlePress: (value: boolean) => void;
  isMobile: boolean;
  sessionReminder: { email: boolean; push: boolean; sms: boolean };
  handlePushToggle: (value: number) => void;
  handleEmailToggle: (value: number) => void;
  handleSMSToggle: (value: number) => void;
}

const SessionRemindersAccordion: FunctionComponent<Props> = ({
  isOpen,
  handlePress,
  isMobile,
  sessionReminder,
  handlePushToggle,
  handleEmailToggle,
  handleSMSToggle,
}) => {
  const { colors, colorRoles } = useEmotionTheme();
  const { sessionSmsReminders } = useFlags<{ sessionSmsReminders: boolean }>();
  const useNewNav = useNewMemberNav();

  return (
    <BaseAccordion
      open={isOpen}
      onPress={handlePress}
      disabled={false}
      titleComponent={() => (
        <NotificationTitle
          title="Session reminders"
          isOpen={isOpen}
          isMobile={isMobile}
          subTitle={
            isOpen
              ? 'Reminders about your upcoming live sessions.'
              : constructDescription(sessionReminder)
          }
        />
      )}
      arrowStyles={{
        fill: useNewNav ? colorRoles.icons.iconDefaultDefault : colors.periwinkleGrey,
      }}
      arrowContainerStyles={arrowContainerStyles}
    >
      <NotificationPanelRow
        label="Push"
        iconComponent={() => <DailyReminder />}
        isOn={sessionReminder.push}
        onToggle={handlePushToggle}
        name="sessionRemindersPush"
      />
      <NotificationPanelRow
        label="Email"
        iconComponent={() => <EmailEnvelope />}
        isOn={sessionReminder.email}
        onToggle={handleEmailToggle}
        name="sessionRemindersEmail"
      />
      {sessionSmsReminders && (
        <NotificationPanelRow
          label="SMS"
          iconComponent={() => <StyledCellPhone />}
          isOn={sessionReminder.sms}
          onToggle={handleSMSToggle}
          name="sessionRemindersSMS"
        />
      )}
    </BaseAccordion>
  );
};

export default SessionRemindersAccordion;
