import { FunctionComponent } from 'react';
import { View, Huge, Standard } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import GoalCard from '../components/GoalCard';
import { useTreatmentPlannerState } from '../hooks/treatmentPlannerContext';
import NoItemsCard from '../components/NoItemsCard';
import { GoalsIcon } from '../components/icons';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';

const Wrapper = styled(View)({
  marginTop: 20,
  marginBottom: 20,
  maxHeight: 550,
  overflowY: 'hidden',
});

const GoalCardRow = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    flexDirection: 'row',
    flexWrap: isMobile ? 'nowrap' : 'wrap',
    overflowX: isMobile ? 'auto' : 'hidden',
  };
});

const ResponsiveIconWrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return isMobile
    ? {
        marginBottom: 14,
        marginTop: -14,
      }
    : {
        marginRight: 24,
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
      };
});

const GoalsContainer: FunctionComponent<RouteComponentProps<{ roomID: number }>> = ({ match }) => {
  const { treatmentPlansByRoomID } = useTreatmentPlannerState();

  const { goals = [] } = treatmentPlansByRoomID[match.params.roomID] || {};

  if (!goals.length)
    return (
      <Wrapper>
        <Huge as="h2" style={{ marginBottom: 20, marginLeft: 24 }}>
          Long-term goals
        </Huge>
        <NoItemsCard disabled>
          <ResponsiveIconWrapper>
            <GoalsIcon />
          </ResponsiveIconWrapper>
          <Standard variant="standardDarkGrey">
            Talk to your therapist about setting long-term goals.
          </Standard>
        </NoItemsCard>
      </Wrapper>
    );

  return (
    <Wrapper>
      <Huge style={{ marginBottom: 20, marginLeft: 24 }}>Long-term goals</Huge>
      <GoalCardRow style={{ paddingLeft: 19, paddingRight: 14 }}>
        {(goals || []).map((goal, i, arr) => (
          <GoalCard
            onPress={() => {}}
            index={i}
            key={goal.id}
            shouldRenderAsSquare={arr.length > 2}
            text={goal.description}
          />
        ))}
      </GoalCardRow>
    </Wrapper>
  );
};
export default withRouter(GoalsContainer);
