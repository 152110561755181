import { FunctionComponent, useEffect, useRef } from 'react';
import { useEmotionTheme, View } from '@talkspace/react-toolkit';
import { useWizardActions, useWizardState } from 'stepWizard';
import { useTSAdminConfig } from 'ts-frontend/hooks/useTSAdminConfig';
import { AdminConfigName } from 'ts-frontend/types';
import ActionStatus from '@/components/ActionStatus';
import styled from '@/core/styled';
import { RouteComponentProps } from '@/core/routerLib/routerLib';
import useEmergencyContact from '../hooks/useEmergencyContact';

const Wrapper = styled(View)({
  alignSelf: 'center',
  width: 340,
});

const SubmitEmergencyContact: FunctionComponent<RouteComponentProps<{}>> = ({ history }) => {
  const isOnboarding = history.location.pathname.includes('/onboarding');
  const [{ error, isSuccess, isLoading }, { submitIntakeResults }] = useEmergencyContact();
  const requestSent = useRef(false);
  const { colors } = useEmotionTheme();

  const { roomID, clientUserID, name, address, phone, contactName, relationship, contactPhone } =
    useWizardState();
  const { onExitClick } = useWizardActions();

  const { data: treatmentIntakeInOnboardingActive } = useTSAdminConfig(
    AdminConfigName.TREATMENT_INTAKE_IN_ONBOARDING
  );

  const {
    country = '',
    city = '',
    state = '',
    zipcode = '',
    address: addressSpecific = '',
  } = address && address.addressFromApi;

  const { firstName = '', middleName = '', lastName = '' } = name;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const payload = {
    firstName,
    middleName: middleName || null,
    lastName,
    address: addressSpecific,
    address2: address.addressString,
    country: country || null,
    city: city || null,
    state: state || null,
    zipcode: zipcode || null,
    phone: phone || null,
    contactName: contactName || null,
    relationship: relationship || null,
    contactPhone: contactPhone || null,
    isOnboarding,
  };

  useEffect(() => {
    if (!requestSent.current) {
      requestSent.current = true;
      submitIntakeResults(roomID, clientUserID, payload);
    }
  }, [roomID, clientUserID, payload, submitIntakeResults]);

  useEffect(() => {
    if (isSuccess) {
      if (treatmentIntakeInOnboardingActive && isOnboarding) {
        history.push(`/room/${roomID}/onboarding/next`);
      } else {
        history.push(`/treatment-intake-launcher/room/${roomID}/source/chat`, { initStep: 0 });
      }
    }
  }, [treatmentIntakeInOnboardingActive, history, isSuccess, roomID, isOnboarding]);

  return (
    <Wrapper>
      {error || isLoading ? (
        <ActionStatus
          isLoading={isLoading}
          isError={!!error}
          errorTitle="Something Went Wrong"
          errorSubTitle="Please check your internet connection and try again."
          errorButtonText="Close"
          errorButtonAction={onExitClick}
          titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25 }}
          subTitleStyles={{
            fontSize: 16,
            color: colors.darkGray,
            marginTop: 13,
            marginBottom: 42,
            width: 200,
            textAlign: 'center',
          }}
        />
      ) : null}
    </Wrapper>
  );
};
export default SubmitEmergencyContact;
