import { useState, useEffect, useCallback, VFC, useMemo } from 'react';
import { useHistory } from 'react-router';
import {
  useA11yState,
  useA11yActions,
  useWindowWidth,
  ResponsiveLayoutWithHeader,
  PanelHeader,
} from '@talkspace/react-toolkit';
import { NAV_SHELL_HEADER_HEIGHT } from 'ts-frontend/constants';

import AccountScreen from './AccountScreen';
import { CloseModal } from '../../../utils/ModalsContextProvider';
import useNavShellData from '../../../hooks/useNavShellData';
import useQueryUserSettings from '../../../hooks/useQueryUserSettings';
import { useClientAuthActions } from '../../../hooks/clientAuthContext';
import useOpenModalSafe from '@/hooks/useOpenModalSafe';
import myAccountApiHelper from '../../../myAccount/utils/myAccountApiHelper';
import { trackUserSettingsEvent } from '../../../utils/analytics/events';
import { UserAuthProvider } from '../../../entities/Me';
import { AccountCard, TrackUserSettingsEvent, UserSettings } from '../../types';

export interface AccountScreenContainerProps {
  closeModal: CloseModal;
}

export const AccountScreenContainer: VFC<AccountScreenContainerProps> = ({ closeModal }) => {
  const {
    aggregatedRoomData: { userID, clientInfo, dataByRoomID, hasPendingMatchRoom },
  } = useNavShellData();
  const { logoutAction } = useClientAuthActions();
  const { isHighContrast } = useA11yState();
  const { setColorContrast } = useA11yActions();
  const { isMobile } = useWindowWidth();
  const history = useHistory();
  const openModal = useOpenModalSafe();

  const [userSettings, setUserSettings] = useState<UserSettings>();
  const { data, isLoading } = useQueryUserSettings({ userID });

  const authProvider = clientInfo?.authProvider;
  const roomIDs = useMemo(
    () => (dataByRoomID ? Object.keys(dataByRoomID as {}) : []),
    [dataByRoomID]
  );

  useEffect(() => {
    if (!isLoading) setUserSettings(data);
  }, [isLoading, data]);

  useEffect(() => {
    if (closeModal && !isMobile) {
      closeModal();
    }
  }, [isMobile, closeModal]);

  const updateUserData = (newSetting) =>
    setUserSettings({
      ...userSettings,
      ...newSetting,
    });

  const onPressReadReceipts = async () => {
    const newSetting = { sendMessagesReceipts: !userSettings?.sendMessagesReceipts };

    try {
      const res = await myAccountApiHelper.patchUserSettings(userID, newSetting);
      if (res.status === 204) {
        updateUserData(newSetting);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Could not update send_messages_receipts', error);
    }
  };

  const onPressColorContrast = async () => {
    const newSetting = { highContrast: !isHighContrast };

    try {
      const res = await myAccountApiHelper.patchUserSettings(userID, newSetting);
      if (res.status === 204) {
        setColorContrast(newSetting.highContrast);
        updateUserData(newSetting);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Could not update high_contrast setting', error);
    }
  };

  const handleTracking = useCallback(
    (trackEvent: TrackUserSettingsEvent) =>
      trackUserSettingsEvent(userID, trackEvent.CTA, trackEvent.source),
    [userID]
  );

  const handleRouting = useCallback((route: string) => history.push(`${route}`), [history]);

  const handleWindowRouting = useCallback((route: string) => window.open(route, '_blank'), []);

  // roomID added to match old routing in DropdownMenu.tsx
  const handleAddService = useCallback(() => {
    if (isMobile) {
      closeModal({ navigateTo: 'addNewService', metadata: { roomID: +roomIDs[0] } });
    } else {
      history.push(`/home/account/add-new-service/room/${roomIDs[0]}`);
    }
  }, [history, roomIDs, closeModal, isMobile]);

  const handleLogout = useCallback(() => {
    if (authProvider === UserAuthProvider.AETNA) {
      openModal('/sso/logout-popups/aetna', undefined, false, true);
    } else {
      logoutAction();
    }
  }, [openModal, logoutAction, authProvider]);

  const handleOnPress = useCallback(
    ({
      trackUserSettingsEvent: event,
      route,
      windowRoute,
      isLogout,
      isAddService,
    }: AccountCard) => {
      if (event) handleTracking(event);
      if (route) handleRouting(route);
      if (windowRoute) handleWindowRouting(windowRoute);
      if (isLogout) handleLogout();
      if (isAddService) handleAddService();
    },
    [handleTracking, handleRouting, handleWindowRouting, handleLogout, handleAddService]
  );

  const outsideHeaderHeight = isMobile ? 0 : NAV_SHELL_HEADER_HEIGHT;

  return isMobile ? (
    <ResponsiveLayoutWithHeader
      outsideHeaderHeight={outsideHeaderHeight}
      renderHeader={() => <PanelHeader onRightPress={closeModal} />}
      noHeaderLine
    >
      <AccountScreen
        hasPendingMatchRoom={hasPendingMatchRoom}
        isHighContrast={isHighContrast}
        isMobile={isMobile}
        isLoadingUserSettings={isLoading}
        userSettings={userSettings}
        onPressReadReceipts={onPressReadReceipts}
        onPressColorContrast={onPressColorContrast}
        onPress={handleOnPress}
      />
    </ResponsiveLayoutWithHeader>
  ) : (
    <>
      <AccountScreen
        hasPendingMatchRoom={hasPendingMatchRoom}
        isHighContrast={isHighContrast}
        isMobile={isMobile}
        isLoadingUserSettings={isLoading}
        userSettings={userSettings}
        onPressReadReceipts={onPressReadReceipts}
        onPressColorContrast={onPressColorContrast}
        onPress={handleOnPress}
      />
    </>
  );
};

export default AccountScreenContainer;
