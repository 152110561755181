import {
  PostRepository,
  createReport as createReportSDK,
  isReportedByMe,
  CommentRepository,
  ReactionRepository,
  UserRepository,
} from '@amityco/ts-sdk';

import {
  CommunityReferenceTypeEnum,
  CommunityTargetTypeEnum,
  ReactableReference,
  ReportableReference,
  ReactionsEnum,
} from '../types';

export const createTextPost = async ({
  communityID,
  text,
  metadata,
  tags,
}: {
  communityID: string;
  text: string;
  metadata?: {
    dailyPromptText: string;
  };
  tags?: string[];
}) => {
  const newPost = {
    tags,
    data: {
      text,
    },
    targetType: CommunityTargetTypeEnum.COMMUNITY,
    targetId: communityID,
    metadata,
  };

  const { data: post } = await PostRepository.createPost(newPost);

  return post;
};

export const deletePost = async ({
  postID,
  hardDelete,
}: {
  postID: string;
  hardDelete?: boolean;
}) => {
  const result = await PostRepository.deletePost(postID, hardDelete);
  return result;
};

export const createComment = async ({ text, postID }: { text: string; postID: string }) => {
  const newComment = {
    data: {
      text,
    },
    referenceId: postID,
    referenceType: CommunityReferenceTypeEnum.POST as Amity.CommentReferenceType,
  };

  const { data: comment } = await CommentRepository.createComment(newComment);
  return comment;
};

export const deleteComment = async ({
  commentID,
  hardDelete,
}: {
  commentID: string;
  hardDelete?: boolean;
}) => {
  const result = await CommentRepository.deleteComment(commentID, hardDelete);
  return result;
};

export const createReport = async ({
  referenceType,
  referenceID,
}: {
  referenceType: ReportableReference;
  referenceID: string;
}) => {
  const success = await createReportSDK(referenceType, referenceID);
  return success;
};

export const getIsReportedByMe = async ({
  referenceType,
  referenceID,
}: {
  referenceType: ReportableReference;
  referenceID: string;
}) => {
  const isReported = await isReportedByMe(referenceType, referenceID);
  return isReported;
};

export const addReaction = async ({
  referenceType,
  referenceID,
  reactionType,
}: {
  referenceType: ReactableReference;
  referenceID: string;
  reactionType: ReactionsEnum;
}) => {
  const isReactionAdded = await ReactionRepository.addReaction(
    referenceType,
    referenceID,
    reactionType
  );
  return isReactionAdded;
};

export const removeReaction = async ({
  referenceType,
  referenceID,
  reactionType,
}: {
  referenceType: ReactableReference;
  referenceID: string;
  reactionType: ReactionsEnum;
}) => {
  const isReactionRemoved = await ReactionRepository.removeReaction(
    referenceType,
    referenceID,
    reactionType
  );
  return isReactionRemoved;
};

export const updateUser = async ({
  userID,
  params,
}: {
  userID: string;
  params: {
    displayName?: string;
    metadata?: { state?: string; dateOfBirth?: string };
  };
}) => {
  const { data: updatedUser } = await UserRepository.updateUser(userID, params);

  return updatedUser;
};
