import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useFlags } from 'launchDarkly/FlagsProvider';

import { Big, Button, Huge, Large, Small, View, useEmotionTheme } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import appConfigs from '@/utils/configs';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';

import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';
import useRepeatingTimeslotsCopy from '../../hooks/useRepeatingTimeslotsCopy';
import useRepeatingBooking from '../../hooks/useRepeatingBooking';
import AddToCalendar from '../AddToCalendar';
import BookingAvatars from '../BookingAvatars';
import BookingSummaryList from '../BookingSummaryList';
import BookingSummaryDropdown from '../BookingSummaryDropdown';
import BookingSummaryDatetimes from '../BookingSummaryDatetimes';
import BookingTimeZone from '../BookingTimeZone';
import { TherapistInfo } from '../../types';
import { getText } from './bookingConfirmationUtils';

const SuccessTitleText = styled(Huge)({
  marginTop: 22,
  textAlign: 'center',
});

const FooterWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    width: 'auto',
    position: 'absolute',
    marginLeft: 0,
    marginRight: 0,
    bottom: 0,
    borderTop: `solid 1px ${colors.permaCornflowerBlueSolid}`,
    padding: '16px 0 32px 20px',
    backgroundColor: 'white',
  };
});

const FooterTextContainer = styled(View)(({ theme: { colors } }) => {
  return {
    borderRadius: 8,
    backgroundColor: colors.aquaSqueeze,
    padding: 16,
    marginRight: 14,
  };
});

const Wrapper = styled(View)({
  alignItems: 'center',
  paddingBottom: 160,
});

const Row = styled(View)({
  marginBottom: 16,
});

const Subtitle = styled(Big)(({ theme: { colors } }) => {
  return {
    textAlign: 'center',
    maxWidth: 280,
    fontWeight: 500,
    color: colors.permaLividBlueNew,
  };
});

interface Props {
  isTherapist: boolean;
  handleOnClosePress: () => void;
  isWithin24Hours: boolean;
  hasBreakAfterSession?: boolean;
  hideAddToCalendar?: boolean;
  therapist?: TherapistInfo;
}

const BookingConfirmationRecurring: FunctionComponent<Props> = ({
  isTherapist,
  handleOnClosePress,
  isWithin24Hours,
  hasBreakAfterSession,
  therapist,
  hideAddToCalendar,
}) => {
  const isiOS = ReactFrameService.isiOS();
  const isAndroid = ReactFrameService.isAndroid();
  const {
    room,
    modality,
    selectedCreditOption,
    selectedTimeslot,
    recurringAvailableTimeslots,
    recurringConflictingTimeslots,
    recurringRequestedTimeslots,
    selectedConfirmBooking,
  } = useInRoomSchedulingState();
  const { repeatingSessionsFull2 } = useFlags();
  const { colorRoles } = useEmotionTheme();
  const timezone = moment.tz.guess();

  const { repeatingTimeslots, conflictingTimeslots } = useRepeatingBooking({
    selectedBooking: selectedConfirmBooking,
    onlyConfirmedOrTentative: true,
  });

  const timeslots = isTherapist ? recurringAvailableTimeslots : repeatingTimeslots;

  const allTimeslots = [...(repeatingTimeslots || []), ...(conflictingTimeslots || [])].sort(
    (a, b) => new Date(a.start).valueOf() - new Date(b.start).valueOf()
  );

  const { dayName, startTimeString, startDateString, endDateString, everyWeek } =
    useRepeatingTimeslotsCopy(timeslots);

  const v1Content = useCallback(
    () => (
      <>
        {isTherapist ? (
          <SuccessTitleText style={{ width: 284 }}>
            Your recurring sessions are scheduled!
          </SuccessTitleText>
        ) : (
          <SuccessTitleText>Your sessions are confirmed!</SuccessTitleText>
        )}
        {isTherapist && (
          <View row>
            <BookingTimeZone timezone={timezone} style={{ margin: 11 }} />
          </View>
        )}
        <BookingSummaryList
          timeslots={isTherapist ? recurringAvailableTimeslots : repeatingTimeslots}
        />
      </>
    ),
    [isTherapist, recurringAvailableTimeslots, repeatingTimeslots, timezone]
  );

  const v2Content = useCallback(
    () =>
      isTherapist ? (
        <>
          <SuccessTitleText style={{ width: 335 }}>Your sessions are scheduled!</SuccessTitleText>
          <View align="center" style={{ paddingTop: 10, paddingBottom: 17 }}>
            {everyWeek ? (
              <Subtitle>{`${dayName}s at ${startTimeString}`}</Subtitle>
            ) : (
              <Subtitle>{`Every other ${dayName} at ${startTimeString}`}</Subtitle>
            )}
            <Subtitle>{`from ${startDateString} – ${endDateString}`}</Subtitle>
            <BookingTimeZone timezone={moment.tz.guess()} style={{ marginTop: 11 }} />
          </View>
          <BookingSummaryDropdown
            timeslots={recurringRequestedTimeslots}
            recurringAvailable={recurringAvailableTimeslots}
            recurringConflicting={recurringConflictingTimeslots}
          />
        </>
      ) : (
        <>
          <SuccessTitleText style={{ marginBottom: 16 }}>
            Your sessions are confirmed!
          </SuccessTitleText>
          {repeatingTimeslots && (
            <Row>
              <BookingSummaryDatetimes timeslots={repeatingTimeslots} />
            </Row>
          )}
          <Row>
            <BookingTimeZone timezone={timezone} />
          </Row>
          {repeatingTimeslots && (
            <BookingSummaryDropdown
              timeslots={allTimeslots}
              recurringConflicting={conflictingTimeslots}
              recurringAvailable={repeatingTimeslots}
            />
          )}
        </>
      ),
    [
      isTherapist,
      allTimeslots,
      conflictingTimeslots,
      repeatingTimeslots,
      recurringRequestedTimeslots,
      recurringAvailableTimeslots,
      recurringConflictingTimeslots,
      timezone,
      dayName,
      startTimeString,
      startDateString,
      endDateString,
      everyWeek,
    ]
  );

  const footerText = useCallback(
    () =>
      repeatingSessionsFull2 ? (
        <Small
          style={{
            width: 335,
            marginTop: 0,
            marginBottom: hasBreakAfterSession ? 0 : 25,
            textAlign: 'center',
          }}
          variant="default"
        >
          {getText({ isTherapist, isWithin24Hours, modality, plural: true })}
        </Small>
      ) : (
        <Large
          style={{
            width: 284,
            marginTop: 19,
            marginBottom: hasBreakAfterSession ? 0 : 25,
            textAlign: 'center',
          }}
          variant="largeDarkGrey"
        >
          {getText({ isTherapist, isWithin24Hours, modality, plural: true })}
        </Large>
      ),
    [repeatingSessionsFull2, isTherapist, isWithin24Hours, modality, hasBreakAfterSession]
  );

  const [shouldShowBookingSuccess, setShouldShowBookingSuccess] = useState(false);
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setShouldShowBookingSuccess(true);
    }, 100);
    return () => {
      clearTimeout(timeoutID);
    };
  }, []);

  return (
    <>
      <Wrapper>
        {therapist && (
          <BookingAvatars
            therapistUserID={therapist.id}
            selectedTimeslot={selectedTimeslot}
            modality={modality}
            shouldShowBookingSuccess={shouldShowBookingSuccess}
            bookingSuccessSmall
            isCouples={room?.roomType === 'couples_room'}
          />
        )}

        {repeatingSessionsFull2 ? v2Content() : v1Content()}

        {!isTherapist && footerText()}
        {hasBreakAfterSession && (
          <Large
            style={{
              width: 335,
              marginTop: 19,
              marginBottom: 25,
              textAlign: 'center',
            }}
            variant="largeDarkGrey"
          >
            The session will be followed by a 15 minute break.
          </Large>
        )}
        {modality !== 'messaging' &&
        appConfigs.featureFlags.addToCalendar &&
        !hideAddToCalendar &&
        !isTherapist &&
        !repeatingTimeslots &&
        !isiOS &&
        !isAndroid ? (
          <View flex={1} align="center" justify="space-between" style={{ marginBottom: 30 }}>
            <AddToCalendar
              selectedBooking={
                selectedTimeslot?.start && selectedCreditOption?.creditMinutes
                  ? {
                      startTime: selectedTimeslot?.start,
                      creditMinutes: selectedCreditOption?.creditMinutes,
                    }
                  : undefined
              }
              videoCreditType={selectedCreditOption?.type}
              therapistName={`${therapist?.firstName} ${therapist?.lastName}`}
              roomId={room?.roomID}
            />
          </View>
        ) : null}
      </Wrapper>
      <FooterWrapper>
        {isTherapist && (
          <FooterTextContainer>
            <Small variant="smallTSBlack">
              {getText({ isTherapist, isWithin24Hours, modality, plural: true })}
            </Small>
          </FooterTextContainer>
        )}
        <Button
          style={{
            marginTop: 16,
            width: 335,
            backgroundColor: colorRoles.system.actionPrimaryDefault,
          }}
          onPress={handleOnClosePress}
          text="Done"
          size="medium"
          dataQa="inRoomSchedulingConfirmationRecurringContinuePress"
        />
      </FooterWrapper>
    </>
  );
};

export default BookingConfirmationRecurring;
