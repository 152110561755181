import { FunctionComponent } from 'react';
import { View, Large, Tiny } from '@talkspace/react-toolkit';
import { SubscriptionInfo } from 'ts-frontend/types';
import styled from '../../../core/styled';

const Container = styled(View)({
  marginTop: 10,
  marginBottom: 10,
  maxWidth: 360,
});

function getBillingCycleString(
  renewalDate?: string,
  billingCycleUnit?: string,
  billingCycleValue?: number
) {
  if (billingCycleValue && billingCycleUnit) {
    // The split between month and day is intended for readabilty and maintainabilty.
    if (billingCycleUnit === 'month') {
      switch (billingCycleValue) {
        case 12:
          return 'Yearly';
        case 6:
          return 'Biannual';
        case 3:
          return 'Quarterly';
        case 1:
          return 'Monthly';
        default:
          return `${billingCycleValue} Months`;
      }
    } else if (billingCycleUnit === 'day') {
      switch (billingCycleValue) {
        case 28:
        case 30:
          return 'Monthly';
        case 84:
        case 90:
          return 'Quarterly';
        case 7:
          return 'Weekly';
        default:
          return `${billingCycleValue} days`;
      }
    }
  }
  return '-';
}

interface BillingCycleProps {
  subscriptionInfo: SubscriptionInfo;
}

const BillingCycle: FunctionComponent<BillingCycleProps> = ({ subscriptionInfo }) => (
  <Container>
    <Tiny>Billing cycle</Tiny>
    <Large>
      {getBillingCycleString(
        subscriptionInfo.renewalDate,
        subscriptionInfo.billingCycleUnit,
        subscriptionInfo.billingCycleValue
      )}
    </Large>
  </Container>
);

export default BillingCycle;
