import { Lightbulb } from '../../components/icons';
import ForYouCard, { ForYouCardProps } from './ForYouCard';

interface LiveWorkshopsCardProps extends Pick<ForYouCardProps, 'onPress' | 'disabled' | 'dataQa'> {}

const LiveWorkshopsCard = ({ onPress, disabled, dataQa }: LiveWorkshopsCardProps) => (
  <ForYouCard
    dataQa={dataQa}
    onPress={onPress}
    disabled={disabled}
    Icon={<Lightbulb />}
    titleText="Live workshops"
    bodyText="Explore group classes led by expert Talkspace therapists"
  />
);

export default LiveWorkshopsCard;
