import { View, useWindowWidthState } from '@talkspace/react-toolkit';
import { PaymentDetails } from 'ts-frontend/types';
import { MessagingInformationFlow, ClientHeader } from 'inRoomScheduling';
import { useHistory } from 'react-router';

interface Props {
  paymentDetails: PaymentDetails | undefined;
}

const BookAsyncSessionMessagingSession = ({ paymentDetails }: Props) => {
  const history = useHistory();
  const { isMobile } = useWindowWidthState();

  return (
    <ClientHeader
      isMobile={isMobile}
      titleText="Book a session"
      handleBackClick={() => history.goBack()}
      shouldHideBackArrow
    >
      <View flex={1} style={{ padding: 20, paddingBottom: 69 }} align="center">
        <View flex={1} style={{ maxWidth: 375 }}>
          <MessagingInformationFlow isOnboarding paymentDetails={paymentDetails} />
        </View>
      </View>
    </ClientHeader>
  );
};

export default BookAsyncSessionMessagingSession;
