import { useState } from 'react';

import { Spinner } from '@talkspace/react-toolkit';
import { useHistory } from '@/core/routerLib';

import CommunityCreatePost from '../components/CommunityCreatePost';
import { createTextPost } from '../utils/amity';
import { getCreatePostTags, getCreatePostMetadata } from '../utils/helpers';
import { useCommunityState } from '../hooks/CommunityContext';
import useCommunityUser from '../hooks/useCommunityUser';
import { TEENSPACE_DAY_END_SHIFT_HOURS } from '../constants';
import { useOpenModal } from '../../utils/ModalsContextProvider';

const CommunityCreatePostContainer = () => {
  const { communityID, userID, clientTimezone, dailyPrompt } = useCommunityState();
  const history = useHistory();
  const [responseText, setResponseText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const openModal = useOpenModal();

  const metadata = dailyPrompt
    ? getCreatePostMetadata({ dailyPromptText: dailyPrompt.text })
    : undefined;
  const tags = getCreatePostTags({
    userID,
    timezone: clientTimezone,
    shiftHours: TEENSPACE_DAY_END_SHIFT_HOURS,
  });

  const { user: communityUser } = useCommunityUser({ userID });

  if (!dailyPrompt || !communityID) {
    return <Spinner />;
  }
  return (
    <CommunityCreatePost
      communityUser={communityUser}
      dailyPrompt={dailyPrompt}
      responseText={responseText}
      onChangeResponseText={(value) => {
        setResponseText(value);
      }}
      onPressPost={() => {
        setIsLoading(true);
        createTextPost({ communityID, text: responseText, metadata, tags })
          .then(() => {
            openModal('/community/teenspace/create-post/success', { responseText });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
      onPressBack={() => history.goBack()}
      isLoading={isLoading}
    />
  );
};

export default CommunityCreatePostContainer;
