import { FunctionComponent, useEffect } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { isApple, isAndroid } from 'ts-frontend/utils/device';
import getParamByName from '../../utils/queryString';
import useEmailVerification from '../hooks/useEmailVerification';
import { withRouter, RouteComponentProps } from '../../core/routerLib';
import ValidateEmail from '../components/ValidateEmail';
import { getFirebaseDeepLink } from '../../utils/deeplinksHelper';
import { trackEmailVerifiedEvent } from '../../utils/analytics/events';
import { setTrackerUserID } from '../../utils/analytics/eventTracker';

const ValidateEmailContainer: FunctionComponent<RouteComponentProps<{}>> = ({
  history,
  location,
}) => {
  const { emailVerificationCopyChange } = useFlags();
  const tlt = getParamByName('tlt');
  const token = getParamByName('verificationCode');

  const [state, { checkEmailVerificationTokenAction, sendEmailVerificationAction }] =
    useEmailVerification();

  useEffect(() => {
    if (token) {
      checkEmailVerificationTokenAction(token, tlt);
    }
  }, [checkEmailVerificationTokenAction, tlt, token]);

  useEffect(() => {
    if (state.expiredTokenEmail) {
      sendEmailVerificationAction(state.expiredTokenEmail);
    }
  }, [sendEmailVerificationAction, state.expiredTokenEmail]);

  useEffect(() => {
    let timeout;
    if (state.emailVerifiedRedirect) {
      if (emailVerificationCopyChange && emailVerificationCopyChange !== 'disabled') {
        if (state.userID) {
          setTrackerUserID(state.userID).then(() =>
            trackEmailVerifiedEvent({ variant: emailVerificationCopyChange })
          );
        }
      }

      timeout = setTimeout(() => {
        if ((isAndroid || isApple) && !state.skipDeeplink) {
          // we need to construct the deeplink manually and redirect the browser in order to trigger the app
          // this is because the tlt token returned by the api cannot be configured in the firebase dashboard
          window.location.href = getFirebaseDeepLink(state.emailVerifiedRedirect);
        } else {
          window.location.href = state.emailVerifiedRedirect;
        }
      }, 3000);
    }

    if (state.emailSent && state.expiredTokenEmail) {
      history.push(`/email-verification/expired#email=${state.expiredTokenEmail}`);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [
    history,
    location.hash,
    state.emailSent,
    state.emailVerifiedRedirect,
    state.expiredTokenEmail,
    state.skipDeeplink,
    state.userID,
    emailVerificationCopyChange,
  ]);

  return (
    <ValidateEmail
      token={token}
      emailVerifiedRedirect={state.emailVerifiedRedirect}
      isLoading={state.isLoading}
      error={state.error}
    />
  );
};

export default withRouter(ValidateEmailContainer);
