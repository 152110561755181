import { FunctionComponent, useMemo } from 'react';
import { View, Big, Small, Spinner } from '@talkspace/react-toolkit';

import styled from '@/core/styled';
import useWindowWidth from '../../hooks/useWindowWidth';

import FileErrorIcon from '../Icons/FileError';
import PDFIcon from '../Icons/PDF';

import './style.css'; // hack to solve bootstrap global * { box-sizing: border-box }

const Wrapper = styled(View)(({ theme: { window } }) => {
  return {
    flexDirection: 'row',
    width: window.isSmall || window.isMedium ? 203 : 253,
    height: 69,
    alignItems: 'center',
    paddingRight: 16,
    paddingLeft: 16,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#BEC7D7',
    borderRadius: 20,
  };
});

interface Props {
  isLoading?: boolean;
  isError?: boolean;
  fileName: string;
  fileSize: number;
  fileType: string;
  dataQa?: string;
}

const FileBubble: FunctionComponent<Props> = ({
  isLoading,
  isError,
  fileName,
  fileSize,
  fileType,
  dataQa,
}) => {
  const window = useWindowWidth();
  const charLimit = window.isSmall || window.isMedium ? 16 : 22;

  const displayedFileName = useMemo(
    () => (fileName.length > charLimit ? `${fileName.slice(0, charLimit)}...` : fileName),
    [fileName, charLimit]
  );

  const displayedFileSizeMB = useMemo(() => Number((fileSize / 1000000).toFixed(2)), [fileSize]);

  const displayedFileSizeKB = useMemo(() => Number((fileSize / 1000).toFixed(2)), [fileSize]);

  const displayedFileType = useMemo(
    () => fileType.split('/')[1]?.toUpperCase() || fileType,
    [fileType]
  );

  const fileDetails = `${
    displayedFileSizeMB > 1 ? `${displayedFileSizeMB}MB` : `${displayedFileSizeKB}KB`
  }, ${displayedFileType}`;

  return (
    <View className="file-bubble-container" data-qa={dataQa}>
      <Wrapper>
        <View style={{ marginRight: 12 }}>
          {(() => {
            if (isLoading) {
              return (
                <Spinner
                  style={{ width: 30, height: 30 }}
                  containerStyle={{ paddingTop: 2, width: 35, height: 35 }}
                />
              );
            }
            if (isError) {
              return <FileErrorIcon />;
            }
            return <PDFIcon />;
          })()}
        </View>
        <View>
          <Big>{displayedFileName}</Big>
          <Small>{fileDetails}</Small>
        </View>
      </Wrapper>
    </View>
  );
};

export default FileBubble;
