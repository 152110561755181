import * as React from 'react';
import { useReducer, useCallback, createContext, useContext } from 'react';

import { OnboardingStep, OnBoardingMetadata } from '../types';

interface OnboardingState {
  steps: null | Array<{
    step: OnboardingStep;
    metadata?: OnBoardingMetadata;
  }>;
  currentStepIndex: number;
  hasStarted: boolean;
  isLoading: boolean;
  isTeensIntake: boolean;
  isRedirectToRoom: boolean;
}

type OnboardingAction =
  | { type: 'setOnboardingState'; payload: OnboardingState }
  | { type: 'setIsRedirectToRoom'; payload: boolean }
  | { type: 'advanceToNextStep' }
  | { type: 'advanceToStep'; payload: { stepIndex: number } }
  | { type: 'reset' }
  | { type: 'setIsLoading' };

interface OnboardingActions {
  setOnboardingState: (newState: Partial<OnboardingState>) => void;
  setIsRedirectToRoom: (newIsUpdatedRoom: boolean) => void;
  advanceToNextStep: () => void;
  advanceToStep: (stepIndex: number) => void;
  reset: () => void;
  setIsLoading: () => void;
}

export const onboardingReducer = (
  state: OnboardingState,
  action: OnboardingAction
): OnboardingState => {
  switch (action.type) {
    case 'setIsLoading':
      return {
        ...state,
        isLoading: true,
      };
    case 'setOnboardingState':
      return {
        ...state,
        ...action.payload,
        hasStarted: false,
        isLoading: false,
      };
    case 'setIsRedirectToRoom':
      return {
        ...state,
        isRedirectToRoom: action.payload,
      };
    case 'advanceToNextStep':
      return {
        ...state,
        currentStepIndex: state.currentStepIndex + 1,
        hasStarted: true,
      };
    case 'advanceToStep':
      return {
        ...state,
        currentStepIndex: action.payload.stepIndex,
        hasStarted: true,
      };
    case 'reset':
      return {
        ...state,
        hasStarted: false,
        isLoading: false,
      };
    default:
      return state;
  }
};

const OnboardingStateContext = createContext<OnboardingState | undefined>(undefined);
const OnboardingActionsContext = createContext<OnboardingActions | undefined>(undefined);

const OnboardingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(onboardingReducer, {
    steps: null,
    currentStepIndex: 0,
    hasStarted: false,
    isLoading: false,
    isTeensIntake: false,
    isRedirectToRoom: false,
  });

  const actions = {
    setOnboardingState: useCallback((newState: OnboardingState) => {
      dispatch({ type: 'setOnboardingState', payload: newState });
    }, []),
    advanceToNextStep: useCallback(() => {
      dispatch({ type: 'advanceToNextStep' });
    }, []),
    setIsRedirectToRoom: useCallback((newIsUpdatedRoom: boolean) => {
      dispatch({ type: 'setIsRedirectToRoom', payload: newIsUpdatedRoom });
    }, []),
    advanceToStep: useCallback((stepIndex: number) => {
      dispatch({ type: 'advanceToStep', payload: { stepIndex } });
    }, []),
    reset: useCallback(() => {
      dispatch({ type: 'reset' });
    }, []),
    setIsLoading: useCallback(() => {
      dispatch({ type: 'setIsLoading' });
    }, []),
  };
  return (
    <OnboardingStateContext.Provider value={state}>
      <OnboardingActionsContext.Provider value={actions}>
        {children}
      </OnboardingActionsContext.Provider>
    </OnboardingStateContext.Provider>
  );
};

export function useOnboardingState() {
  const context = useContext(OnboardingStateContext);
  if (context === undefined)
    throw new Error('useOnboardingState must be used within a OnboardingProvider');
  return context;
}

export function useOnboardingActions() {
  const context = useContext(OnboardingActionsContext);
  if (context === undefined)
    throw new Error('useOnboardingActions must be used within a OnboardingProvider');
  return context;
}

export default OnboardingProvider;
