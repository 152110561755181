export type EmailVerificationStatus = 'unverified_existing' | 'unverified' | 'verified';

export enum UserAuthProvider {
  AETNA = 'aetna',
}

export interface ClientAPI {
  id: number;
  nickname: string;
  firstName: string;
  lastName: string;
  email: string;
  pendingEmail: string;
  createdAt: string;
  emailVerificationStatus: EmailVerificationStatus;
  authProvider?: UserAuthProvider;
  cognitoUsername?: string;
  show2FAFeatures: boolean;
  is2FAToggleOn: boolean;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  dateOfBirth: string;
  state: string;
}

export interface Me {
  id: number;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  pendingEmail: string;
  createdAt: Date;
  emailVerificationStatus: EmailVerificationStatus;
  authProvider?: UserAuthProvider;
  phoneNumber: string;
  is2FAToggleOn: boolean;
  show2FAFeatures: boolean;
  phoneNumberCountryCode: string;
  dateOfBirth: Date;
}

// eslint-disable-next-line import/prefer-default-export
export class EMe implements Me {
  id: number;

  displayName: string;

  firstName: string;

  lastName: string;

  email: string;

  pendingEmail: string;

  createdAt: Date;

  emailVerificationStatus: EmailVerificationStatus;

  authProvider?: UserAuthProvider;

  cognitoUsername?: string;

  phoneNumber: string;

  is2FAToggleOn: boolean;

  show2FAFeatures: boolean;

  phoneNumberCountryCode: string;

  dateOfBirth: Date;

  state: string;

  constructor(client: ClientAPI) {
    this.id = client.id;
    this.displayName = client.nickname;
    this.firstName = client.firstName;
    this.lastName = client.lastName;
    this.email = client.email;
    this.pendingEmail = client.pendingEmail;
    this.createdAt = new Date(client.createdAt);
    this.emailVerificationStatus = client.emailVerificationStatus;
    this.authProvider = client.authProvider;
    this.cognitoUsername = client.cognitoUsername;
    this.phoneNumber = client.phoneNumber;
    this.is2FAToggleOn = client.is2FAToggleOn;
    this.show2FAFeatures = client.show2FAFeatures;
    this.phoneNumberCountryCode = client.phoneNumberCountryCode;
    this.dateOfBirth = new Date(client.dateOfBirth);
    this.state = client.state;
  }
}
