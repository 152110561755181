import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M16.425 18.4339C17.4493 17.783 18.1264 16.6562 18.1264 15.3751C18.1264 13.3601 16.451 11.7266 14.3843 11.7266C12.3175 11.7266 10.6421 13.3601 10.6421 15.3751C10.6421 16.7255 11.3945 17.9045 12.5128 18.5354V18.9178C10.6175 19.708 9.28159 21.6116 9.28159 23.8344H19.6575C19.6575 21.6111 18.321 19.7071 16.425 18.9173V18.4339ZM16.5967 15.3749C16.5967 16.5656 15.6067 17.5308 14.3854 17.5308C13.1642 17.5308 12.1742 16.5656 12.1742 15.3749C12.1742 14.9822 12.2818 14.614 12.47 14.2969C12.91 14.8773 14.3514 15.9056 16.5967 15.3749Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const Teenspace = withDSIconMaker(pathConfig, 'teenspace');

export default Teenspace;
