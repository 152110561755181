export interface NotificationPreference {
  name?: Preferences;
  email: boolean;
  push: boolean;
  sms: boolean;
}

export interface NotificationPreferencesByName {
  soundNotification: NotificationPreference;
  sendMessagesReceipts: NotificationPreference;
  accountUpdate: NotificationPreference;
  roomActivity: NotificationPreference;
  sessionReminder: NotificationPreference;
  marketingBlog: NotificationPreference;
  marketingEducation: NotificationPreference;
  marketingNews: NotificationPreference;
  marketingPromotions: NotificationPreference;
  superbills: NotificationPreference;
}

export type Preferences =
  | 'soundNotification'
  | 'sendMessagesReceipts'
  | 'accountUpdate'
  | 'roomActivity'
  | 'sessionReminder'
  | 'productUpdate'
  | 'marketingBlog'
  | 'marketingEducation'
  | 'marketingNews'
  | 'marketingPromotions'
  | 'marketingPush'
  | 'superbills';

export type NotificationType = 'push' | 'email' | 'sms' | 'all';

export interface APINotificationPreference extends NotificationPreference {
  name: Preferences;
}

export interface APIResponse {
  data: { categories: APINotificationPreference[] };
}

export interface State {
  notificationPreferencesByName: NotificationPreferencesByName | null;
  isLoading: boolean;
  isEditing: boolean;
  isError: boolean;
  superbillsFlag: boolean;
}

export type Action =
  | { type: 'getNotificationPreferences' }
  | { type: 'receiveGetNotificationPreferences'; payload: Partial<State> }
  | { type: 'updateNotificationPreferences' }
  | { type: 'receiveUpdateNotificationPreferences'; payload: Partial<State> }
  | { type: 'requestAdminOptionSuperbills' }
  | { type: 'receiveAdminOptionSuperbills'; payload: Partial<State> }
  | { type: 'setIsEditing'; payload: Partial<State> }
  | { type: 'setIsError' };

export const initialState: State = {
  notificationPreferencesByName: null,
  isLoading: false,
  isEditing: false,
  isError: false,
  superbillsFlag: false,
};

export const notificationPreferencesReducer = (
  state: State = initialState,
  action: Action
): State => {
  switch (action.type) {
    case 'getNotificationPreferences':
    case 'updateNotificationPreferences':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'receiveGetNotificationPreferences':
    case 'receiveUpdateNotificationPreferences':
    case 'receiveAdminOptionSuperbills':
    case 'setIsEditing':
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case 'setIsError':
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
