export interface MatchingPreferences {
  therapistGender?: string;
  therapistEthnicity?: string;
  ageGroups?: string[];
  yearsOfExperience?: number;
  languages?: number[];
  fieldsOfExpertise?: number[];
  planGroup?: number;
}

export class EMatchingPreferences implements MatchingPreferences {
  therapistGender?: string;

  therapistEthnicity?: string;

  ageGroups?: string[];

  yearsOfExperience?: number;

  languages?: number[];

  fieldsOfExpertise?: number[];

  planGroup?: number;

  constructor(MatchingPreferences: MatchingPreferences) {
    this.therapistGender = MatchingPreferences.therapistGender;
    this.therapistEthnicity = MatchingPreferences.therapistEthnicity;
    this.ageGroups = MatchingPreferences.ageGroups || [];
    this.yearsOfExperience = MatchingPreferences.yearsOfExperience;
    this.languages = MatchingPreferences.languages || [];
    this.fieldsOfExpertise = MatchingPreferences.fieldsOfExpertise || [];
    this.planGroup = MatchingPreferences.planGroup;

    this.therapistGender =
      MatchingPreferences.therapistGender && MatchingPreferences.therapistGender !== 'any'
        ? MatchingPreferences.therapistGender
        : undefined;
  }
}
