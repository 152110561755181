import { useHistory, useParams } from 'core/routerLib';
import { VFC, useEffect } from 'react';
import { setFunnelName } from 'ts-frontend/hooks/funnelName';
import OnboardingPage from './OnboardingPage';
import BookFirstSession from '../assets/BookFirstSession';
import CameraSparkles from '../assets/CameraSparkles';
import { useOnboardingState } from '../hooks/onboardingContext';
import { trackBookFirstSessionPrompt } from '../../utils/analytics/events';

interface OnboardingPageBookAndActivateProps {
  totalSteps: number;
  currentStep: number;
}

const OnboardingPageBookAndActivate: VFC<OnboardingPageBookAndActivateProps> = ({
  totalSteps,
  currentStep,
}) => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string | undefined }>();

  const { steps } = useOnboardingState();
  const { metadata: { isMessagingFirstVideoRequired = true } = {} } = steps
    ? steps[currentStep]
    : {};

  useEffect(() => {
    setFunnelName('Onboarding');
  }, []);

  useEffect(() => {
    if (roomID) {
      trackBookFirstSessionPrompt({
        roomID: Number(roomID),
        modalityNudge: 'Match Based on Availability',
      });
    }
  }, [roomID]);

  let illustration = <BookFirstSession />;
  let title = 'Book your first session';
  let description =
    "Choose a day and time that works for you and we'll match you with an available provider";
  // for messaging only plans in states that require video show different intro information
  if (isMessagingFirstVideoRequired) {
    illustration = <CameraSparkles />;
    title = 'Book your intro video call';
    description =
      "Your state requires you to meet your provider over video before messaging therapy begins. Choose a day and time that works for you and we'll match you with an available provider.";
  }
  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={currentStep}
      illustration={illustration}
      title={title}
      description={description}
      ctaText="Continue"
      onPress={() => history.push(`/room/${roomID}/onboarding/book-and-activate`)}
    />
  );
};

export default OnboardingPageBookAndActivate;
