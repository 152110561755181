import { OptionType, AddressInputValue } from '@talkspace/react-toolkit';
import { contactRelationship } from '@talkspace/configs';
import { ValueType } from 'react-select';
import { BaseWizardState, baseWizardInitialState, keyValueToValueType } from 'stepWizard';

export interface State extends BaseWizardState {
  roomID: number;
  clientUserID: number;
  name: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
  address: AddressInputValue;
  phone: string;
  contactName?: string;
  relationship?: string;
  contactPhone?: string;

  relationshipOptions: ValueType<OptionType, true>;
}

export const getInitialState = (
  clientUserID: number,
  roomID: number,
  source: string,
  contextID: string
): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    roomID,
    clientUserID,
    name: {
      firstName: '',
      middleName: '',
      lastName: '',
    },
    address: {
      addressFromApi: {
        address: '',
        city: '',
        state: '',
        fullAddress: '',
        zipcode: '',
      },
      addressString: '',
    },
    phone: '',
    contactName: '',
    relationship: '',
    contactPhone: '',

    relationshipOptions: keyValueToValueType(contactRelationship).filter((option) => {
      const castOption = option as OptionType;
      return castOption && castOption.label !== 'No preference';
    }),
  };
};
