/* eslint-disable camelcase */
import {
  AppSource,
  BillingFrequency,
  PlanData,
  PaymentDetails,
  Currency,
  CouponState,
  ChangePlanCheckoutInfo,
  InsuranceEligibility,
} from 'ts-frontend/types';
import { PromoFromBanner } from 'ts-promise-message';
import { RoomType } from 'ts-frontend/entities/Room';

export enum BoughtFrom {
  reactivation = 31,
}

export type OfferSource = Extract<AppSource, AppSource.qm | AppSource.client | AppSource.therapist>;

export type TherapistType =
  | 'consultation'
  | 'primary'
  | 'consultation_and_primary'
  | 'psychiatrist';
export type Availability = 'time-off' | 'available' | 'unavailable';

export interface TherapistInfo {
  firstName: string;
  lastName: string;
  therapistID: number;
  therapistLicenses: string[];
  therapistImage: string;
  therapistType?: TherapistType;
}

export enum CompletePurchaseFunnelName {
  CT = 'CT',
  REACTIVATION = 'Reactivation',
  QM_B2B = 'B2B QuickMatch',
  QM_B2C = 'QuickMatch',
  ACCOUNT_SETTINGS = 'Account Settings',
  ELIGIBILITY = 'Eligibility',
  ADMIN = 'Admin',
}

export interface SubscribeDetails {
  plan_id: number;
  offer_id: number;
  gid: number; // roomId
  therapistId?: number;
  switchRoom: boolean;
  externalProcessorToken?: string;
  tokenId?: string;
  couponCode?: string;
  boughtFrom?: BoughtFrom;
  funnelName?: CompletePurchaseFunnelName;
}

export interface SubscribePayload {
  params: SubscribeDetails;
}

export interface SubscribeAnalyticsData {
  billingPrice?: {
    amount?: number;
    cycleValue?: number;
    unit?: string;
  };
  discount?: number;
  discountPercent: number;
  displayName?: string;
  requestOfferID: number;
  currency: Currency;
}

type Loadable<T> = { loading: true } | ({ loading: false } & T);

export enum CouponInputMode {
  default = 'default',
  hidden = 'hidden',
  disabled = 'disabled',
}

export interface RoomOfferState {
  email?: string;
  offerID?: number;
  flowID: number | null;
  offer?: OfferData;
  planBuilderMainPlan?: PlanData;
  plansMatrix?: PlanData[][];
  plansMatrixDisplayReady: boolean;
  currentPlan?: PlanData;
  selectedSubscription?: PlanData;
  selectedPlanSavings?: number;
  changePlanCheckoutInfo?: Loadable<ChangePlanCheckoutInfo>;
  trialOfferPrice?: number;
  trialOfferPriceDisplay?: string;
  therapistInfo?: TherapistInfo;
  isEmailRequired?: boolean;
  showCloseButton?: boolean;
  isChangePlan?: boolean;
  isNoMatches?: boolean;
  boughtFrom?: number;
  paymentDetails?: PaymentDetails;
  paymentToken?: string;
  subscriptionErrorMessage?: string;
  isErrorSubscription: boolean;
  isLoadingSubscription: boolean;
  isErrorRoomOffer: boolean;
  isLoadingRoomOffer: boolean;
  hasFetchedFrameData: boolean;
  coupon: CouponState;
  experimentRouteReady: boolean;
  couponInputMode: CouponInputMode;
  insuranceEligibility?: InsuranceEligibility;
  experimentError?: string;
  goToUrl?: string;
  bookWithIntroSession?: boolean;
  hideProviderInfo?: boolean;
  promoFromBanner?: PromoFromBanner;
  promoToDisplay?: PromoToDisplay;
  isLoggedInUser: boolean;
}

export interface DiscountGroup {
  frequency: BillingFrequency | null;
  plans: PlanData[];
  discountPercent?: string;
}

export interface OfferData {
  id: number;
  discountGroups: DiscountGroup[];
  trialable: boolean;
  location: string;
  currency: Currency;
  trialValue?: number;
  roomType: RoomType;
}

export enum OfferType {
  PSYCHIATRY = 61,
}

export type SubscriptionType = 'plus' | 'premium' | 'ultimate';

export interface RoomOfferRouteParams {
  roomID: string;
  offerID: string;
  planID?: string;
}

export interface PromoToDisplay {
  promoAmount: number;
  promoWeeks: number;
}

export interface MyInformationData {
  firstName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  country: string | null;
  state: string | null | undefined;
  city: string | null;
  zipcode: string | null;
  address: string | null;
  address2: string | null | undefined;
}

export interface EmergencyContactData {
  contactName: string | null;
  contactPhone: string | null;
  relationship: string | null;
}

export interface HandleQuickmatchSubscribeData {
  email: string;
  paymentToken: string | undefined;
  planId: number;
  promoCode: string | undefined;
  offerID: number;
  attribution: {
    discountPercent: number;
    promoValue: number;
    planName: string;
    billingFrequency: number;
    price: number;
    requestedOfferID: number;
    currency: Currency;
  };
}
