import { OpenBook } from '../../components/icons';
import ForYouCard, { ForYouCardProps } from './ForYouCard';

interface MentalHealthResourcesCardProps
  extends Pick<ForYouCardProps, 'onPress' | 'disabled' | 'dataQa'> {}

const MentalHealthResourcesCard = ({
  onPress,
  disabled,
  dataQa,
}: MentalHealthResourcesCardProps) => (
  <ForYouCard
    dataQa={dataQa}
    onPress={onPress}
    disabled={disabled}
    Icon={<OpenBook />}
    titleText="Mental health resources"
    bodyText="Your source for tips on becoming your best self"
  />
);

export default MentalHealthResourcesCard;
