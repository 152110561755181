import DTERenewalScreen from './screens/DTERenewalScreen';
import { Route, Switch } from '../core/routerLib';

const Routes = () => (
  <Switch>
    <Route exact path="/dte-renewal/room/:roomID/org-name/:orgName" component={DTERenewalScreen} />
  </Switch>
);

export default Routes;
