import { useCallback } from 'react';

import { spacing, useEmotionTheme, View } from '@talkspace/react-toolkit';

import ReactionsToolbar from './ReactionsToolbar';
import CommentsLink from './CommentsLink';

import { useCommunityState } from '../hooks/CommunityContext';
import { addReaction, removeReaction } from '../utils/amity';
import { getPostCommentingEndedAt } from '../utils/helpers';
import { ReactionsEnum, CommunityReferenceTypeEnum } from '../types';
import { TEENSPACE_DAY_END_SHIFT_HOURS } from '../constants';

const { space100, space150 } = spacing;

interface Props {
  post: Amity.Post;
  commentInputRef?: React.RefObject<HTMLInputElement>;
}
const PostToolbar = ({ post, commentInputRef }: Props) => {
  const {
    postId: postID,
    reactions,
    myReactions,
    creator: { userId: creatorUserID },
  } = post;
  const { userID, clientTimezone } = useCommunityState();
  const isOwnPost = creatorUserID === userID;
  const { colorRoles } = useEmotionTheme();
  const onAddReaction = useCallback(
    (reactionType: ReactionsEnum) => () => {
      if (myReactions.includes(reactionType)) {
        removeReaction({
          referenceType: CommunityReferenceTypeEnum.POST,
          referenceID: postID,
          reactionType,
        });
      } else {
        if (myReactions.length !== 0) {
          removeReaction({
            referenceType: CommunityReferenceTypeEnum.POST,
            referenceID: postID,
            reactionType: myReactions[0],
          });
        }
        addReaction({
          referenceType: CommunityReferenceTypeEnum.POST,
          referenceID: postID,
          reactionType,
        });
      }
    },
    [myReactions, postID]
  );
  const commentingEndedAt = getPostCommentingEndedAt({
    post,
    timezone: clientTimezone,
    shiftHours: TEENSPACE_DAY_END_SHIFT_HOURS,
  });
  const showCommentsLink = !commentingEndedAt || isOwnPost;
  return (
    <View
      row
      align="center"
      style={{
        borderTop: `1px solid ${colorRoles.borders.borderSubtleDefault}`,
        padding: `${space100}px 0 ${space150}px`,
      }}
    >
      <ReactionsToolbar
        reactions={reactions}
        myReactions={myReactions}
        onAddReaction={onAddReaction}
      />
      {showCommentsLink && (
        <CommentsLink
          post={post}
          commentInputRef={commentInputRef}
          style={{ marginLeft: space150 }}
        />
      )}
    </View>
  );
};

export default PostToolbar;
