import moment, { Moment } from 'moment';
import { useQuery } from 'react-query';

import { SurveyResult } from 'ts-frontend/types';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { UpcomingSurvey } from '../clinicalProgress/reducers/surveys';
import { getClientSurveysQueryKey } from '../utils/queryKeys';

const getIsDue = (survey: UpcomingSurvey, now: Moment) => {
  const isDelivered = moment(survey.deliverAt)
    .add(10, 'minutes')
    .startOf('minute')
    .add(10, 'seconds')
    .isBefore(now);
  return moment(survey.dueAt).isAfter(now) && isDelivered;
};

interface SurveysResponse {
  results: SurveyResult[];
  upcomingSurveys: UpcomingSurvey[];
}

const fetchClientSurveys = () => async (): Promise<SurveysResponse> => {
  const {
    data: { data },
  } = await apiWrapper.get(`${apiHelper().surveyAPIEndpoint}/v2/client-surveys`);
  const now = moment();
  const response: SurveysResponse = {
    results: data.results,
    upcomingSurveys: data.upcomingSurveys.map((survey) => {
      return {
        ...survey,
        isDue: getIsDue(survey, now),
      };
    }),
  };
  return response;
};

const useQueryClientSurveys = ({ disabled }: { disabled?: boolean }) =>
  useQuery<SurveysResponse, Error>({
    queryKey: getClientSurveysQueryKey(),
    queryFn: fetchClientSurveys(),
    cacheTime: 5 * 60 * 1000,
    staleTime: 0,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: true,
    enabled: !disabled,
  });

export default useQueryClientSurveys;
