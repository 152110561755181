import View from '../../../components/View';
import { useEmotionTheme } from '../../../core/styled';
import TextDS from '../typography/TextDS';
import { ColorRolesVersion } from '../../types';
import { spacing, getSpacing } from '../../tokens';

const { space075 } = spacing;

export type StatusTagVariant =
  | 'psychiatry'
  | 'psychotherapy'
  | 'therapyCouples'
  | 'coverageExpired'
  | 'roomClosed'
  | 'liveSessionStarting'
  | 'messagingSessionActive';

const getText = (variant: StatusTagVariant) => {
  switch (variant) {
    case 'psychiatry':
      return 'Psychiatry';
    case 'psychotherapy':
      return 'Therapy';
    case 'therapyCouples':
      return 'Couples Therapy';
    case 'roomClosed':
      return 'Room closed';
    case 'coverageExpired':
      return 'Insurance coverage expired';
    case 'liveSessionStarting':
      return 'Live session starting';
    case 'messagingSessionActive':
      return 'Messaging session active';
    default:
      return 'Therapy';
  }
};

const getColor = (variant: StatusTagVariant, colorRoles: ColorRolesVersion) => {
  switch (variant) {
    case 'psychiatry':
      return colorRoles.surfaces.decorativePsych;
    case 'psychotherapy':
      return colorRoles.surfaces.brandDefault;
    case 'therapyCouples':
      return colorRoles.surfaces.decorativeCouples;
    case 'roomClosed':
      return colorRoles.snowflake.roomClosed;
    case 'coverageExpired':
      return colorRoles.surfaces.criticalBoldDefault;
    case 'liveSessionStarting':
      return colorRoles.surfaces.successDefault;
    case 'messagingSessionActive':
      return colorRoles.surfaces.successDefault;
    default:
      return colorRoles.surfaces.brandDefault;
  }
};

interface Props {
  variant: StatusTagVariant;
}

const StatusTag = ({ variant }: Props) => {
  const { colorRoles } = useEmotionTheme();
  return (
    <View
      align="center"
      style={{
        width: 'fit-content',
        padding: `1px ${getSpacing('space100', true)} 2px`,
        marginRight: space075,
        borderRadius: 100,
        backgroundColor: getColor(variant, colorRoles),
      }}
    >
      <TextDS
        variant="bodyXs"
        colorRole={variant === 'coverageExpired' ? 'textInverse' : 'textDefault'}
      >
        {getText(variant)}
      </TextDS>
    </View>
  );
};

export default StatusTag;
