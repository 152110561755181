// IMPORTANT: this is referencing from https://github.com/mixpanel/mixpanel-js/blob/master/src/utils.js#L1464-L1594
/* eslint-disable camelcase */
import { OwnProperties, Browser, Device, OS, CampaignParams } from './types';

const campaignParams = (): CampaignParams => {
  const campaignKeywords = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term'];
  const allParams = new URLSearchParams(document.location.search);
  const params = campaignKeywords.reduce((obj: CampaignParams, key: string) => {
    const value = allParams.get(key);
    if (value) {
      return {
        ...obj,
        [key]: value,
      };
    }
    return obj;
  }, {});

  return params;
};

const browser = (userAgent: string, vendor?: string, opera?: Record<string, unknown>): Browser => {
  const vendorText = vendor || ''; // vendor is undefined for at least IE9
  if (opera || userAgent.includes(' OPR/')) {
    if (userAgent.includes('Mini')) {
      return 'Opera Mini';
    }
    return 'Opera';
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry';
  }
  if (userAgent.includes('IEMobile') || userAgent.includes('WPDesktop')) {
    return 'Internet Explorer Mobile';
  }
  if (userAgent.includes('SamsungBrowser/')) {
    // https://developer.samsung.com/internet/user-agent-string-format
    return 'Samsung Internet';
  }
  if (userAgent.includes('Edge') || userAgent.includes('Edg/')) {
    return 'Microsoft Edge';
  }
  if (userAgent.includes('FBIOS')) {
    return 'Facebook Mobile';
  }
  if (userAgent.includes('Chrome')) {
    return 'Chrome';
  }
  if (userAgent.includes('CriOS')) {
    return 'Chrome iOS';
  }
  if (userAgent.includes('UCWEB') || userAgent.includes('UCBrowser')) {
    return 'UC Browser';
  }
  if (userAgent.includes('FxiOS')) {
    return 'Firefox iOS';
  }
  if (vendorText.includes('Apple')) {
    if (userAgent.includes('Mobile')) {
      return 'Mobile Safari';
    }
    return 'Safari';
  }
  if (userAgent.includes('Android')) {
    return 'Android Mobile';
  }
  if (userAgent.includes('Konqueror')) {
    return 'Konqueror';
  }
  if (userAgent.includes('Firefox')) {
    return 'Firefox';
  }
  if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    return 'Internet Explorer';
  }
  if (userAgent.includes('Gecko')) {
    return 'Mozilla';
  }
  return '';
};

const browserVersion = (
  userAgent: string,
  vendor?: string,
  opera?: Record<string, unknown>
): number | null => {
  const browserInfo = browser(userAgent, vendor, opera);
  const versionRegexs = {
    'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
    'Microsoft Edge': /Edge?\/(\d+(\.\d+)?)/,
    Chrome: /Chrome\/(\d+(\.\d+)?)/,
    'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
    'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
    Safari: /Version\/(\d+(\.\d+)?)/,
    'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
    Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
    Firefox: /Firefox\/(\d+(\.\d+)?)/,
    'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
    Konqueror: /Konqueror:(\d+(\.\d+)?)/,
    BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
    'Android Mobile': /android\s(\d+(\.\d+)?)/,
    'Samsung Internet': /SamsungBrowser\/(\d+(\.\d+)?)/,
    'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
    Mozilla: /rv:(\d+(\.\d+)?)/,
  };
  const regex = versionRegexs[browserInfo];
  if (regex === undefined) {
    return null;
  }
  const matches = userAgent.match(regex);
  if (!matches) {
    return null;
  }
  return parseFloat(matches[matches.length - 2]);
};

const getDeviceInfo = (userAgent: string): Device => {
  if (/Windows Phone/i.test(userAgent) || /WPDesktop/.test(userAgent)) {
    return 'Windows Phone';
  }
  if (/iPad/.test(userAgent)) {
    return 'iPad';
  }
  if (/iPod/.test(userAgent)) {
    return 'iPod Touch';
  }
  if (/iPhone/.test(userAgent)) {
    return 'iPhone';
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry';
  }
  if (/Android/.test(userAgent)) {
    return 'Android';
  }
  return '';
};

const getOS = (userAgent: string): OS => {
  const a = userAgent;
  if (/Windows/i.test(a)) {
    if (/Phone/.test(a) || /WPDesktop/.test(a)) {
      return 'Windows Phone';
    }
    return 'Windows';
  }
  if (/(iPhone|iPad|iPod)/.test(a)) {
    return 'iOS';
  }
  if (/Android/.test(a)) {
    return 'Android';
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
    return 'BlackBerry';
  }
  if (/Mac/i.test(a)) {
    return 'Mac OS X';
  }
  if (/Linux/.test(a)) {
    return 'Linux';
  }
  if (/CrOS/.test(a)) {
    return 'Chrome OS';
  }
  return '';
};

const getBrowserProperties = () => {
  const { width: $screen_width, height: $screen_height } = window.screen;
  // @ts-ignore
  const opera = window?.opera;

  return {
    $screen_height,
    $screen_width,
    $os: getOS(navigator.userAgent),
    $browser: browser(navigator.userAgent, navigator.vendor, opera),
    $browser_version: browserVersion(navigator.userAgent, navigator.vendor, opera),
    $current_url: window.location.href,
  };
};

const getAttributes = (): OwnProperties => {
  const params = new URLSearchParams(window.location.search);

  const search = params.get('search') || '';

  const { utm_source = '', utm_campaign = '', utm_medium = '', utm_term = '' } = campaignParams();

  const $device: Device = getDeviceInfo(navigator.userAgent);
  const { $browser, $browser_version, $current_url, $os, $screen_height, $screen_width } =
    getBrowserProperties();

  return {
    $current_url,
    $browser,
    $browser_version,
    $screen_height,
    $screen_width,
    $os,
    $device,
    utm_source,
    utm_campaign,
    utm_medium,
    utm_term,
    search,
    Platform: $os,
  };
};

const utils = {
  campaignParams,
  getDeviceInfo,
  getOS,
  getBrowserProperties,
  getAttributes,
  browserVersion,
  browser,
};

export default utils;

export {
  campaignParams,
  getDeviceInfo,
  getOS,
  getBrowserProperties,
  getAttributes,
  browserVersion,
  browser,
};
