import { createContext, useState, useEffect } from 'react';
import { View, Spinner } from '@talkspace/react-toolkit';
import googleMapSetup from '../utils/analytics/tools/googleMapSetup';

interface GoogleMapsState {
  status: boolean;
}

const GoogleMapsContext = createContext<GoogleMapsState | undefined>(undefined);

const GoogleMapsContextProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    googleMapSetup.initSDK((success: boolean) => {
      if (!success) {
        // eslint-disable-next-line no-console
        console.warn('Could not setup Google Map library');
        setIsError(true);
        return;
      }
      setIsReady(true);
    });
  }, []);

  if (!isReady) {
    return (
      <View style={{ height: '100vh' }}>
        <View flex={1}>
          <Spinner isLoading />
        </View>
      </View>
    );
  }

  if (isError) {
    return (
      <View style={{ height: '100vh' }}>
        <View flex={1}> Error: Could not setup Google Map library </View>
      </View>
    );
  }

  return (
    <GoogleMapsContext.Provider value={{ status: isReady }}>{children}</GoogleMapsContext.Provider>
  );
};

export default GoogleMapsContextProvider;
