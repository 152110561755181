import * as React from 'react';

import { VideoCallProvider } from 'chat/hooks/videoCallContext';
import { RoomInviteProvider } from '../roomInvites/hooks/useRoomInvites';
import { RouteComponentProps, Route } from '../core/routerLib';
import MainContainer from '../containers/MainContainer';
import { TreatmentPlannerContextProvider } from '../clinicalProgress/hooks/treatmentPlannerContext';

const MainScreen: React.FunctionComponent<RouteComponentProps> = () => (
  <VideoCallProvider>
    <TreatmentPlannerContextProvider>
      <RoomInviteProvider>
        <Route path={['/rooms', '/room/:roomID', '/room']} component={MainContainer} />
      </RoomInviteProvider>
    </TreatmentPlannerContextProvider>
  </VideoCallProvider>
);
export default MainScreen;
