import { OptionType } from '@talkspace/react-toolkit';
import {
  sleepQuality,
  sleepAidUse,
  socialSupportSystem,
  previousMentalHealthTreatment,
  hospitalizationHistory,
  suicideIdeationOrPlanning,
  homicidalIdeationOrPlanning,
  mentalHealthIssues,
  traumaticExperienceOptions,
  traumaticFlashbacksOptions,
  guiltOptions,
  isolationOptions,
  angerAggression,
} from '@talkspace/configs';

import { ValueType } from 'react-select';
import {
  BaseWizardState,
  baseWizardInitialState,
  keyValueToValueType,
  WizardPostAction,
} from 'stepWizard';

export interface State extends BaseWizardState {
  sleepQuality: string;
  sleepAidUse: string;
  socialSupportSystem: string;
  previousMentalHealthTreatment: null | string;
  previousMentalHealthIssues: number[];
  hospitalizationHistory: null | string;
  previousRelativesMentalHealthIssues: number[];
  traumaticExperience: boolean;
  traumaticFlashbacks: null | boolean;
  guilt: null | boolean;
  isolation: null | boolean;
  suicideIdeation: string;
  suicidePlanning: null | string;
  homicidalIdeation: null | string;
  homicidalPlanning: null | string;
  angerAggression: null | boolean;

  selectedPreviousMentalHealthTreatment: boolean | null;
  selectedSuicideIdeation: boolean | null;
  selectedSuicidePlanning: boolean | null;
  selectedTraumaticExperience: boolean | null;
  selectedTraumaticFlashbacks: boolean | null;
  selectedGuilt: boolean | null;
  selectedIsolation: boolean | null;
  selectedAngerAggression: boolean | null;

  sleepQualityOptions: ValueType<OptionType, true>;
  sleepAidUseOptions: ValueType<OptionType, true>;
  socialSupportSystemOptions: ValueType<OptionType, true>;
  previousMentalHealthTreatmentOptions: ValueType<OptionType, true>;
  previousMentalHealthIssuesOptions: ValueType<OptionType, true>;
  hospitalizationHistoryOptions: ValueType<OptionType, true>;
  previousRelativesMentalHealthIssuesOptions: ValueType<OptionType, true>;
  traumaticExperienceOptions: ValueType<OptionType, true>;
  traumaticFlashbacksOptions: ValueType<OptionType, true>;
  guiltOptions: ValueType<OptionType, true>;
  isolationOptions: ValueType<OptionType, true>;
  suicideIdeationOptions: ValueType<OptionType, true>;
  suicidePlanningOptions: ValueType<OptionType, true>;
  homicidalIdeationOrPlanningOptions: ValueType<OptionType, true>;
  angerAggressionOptions: ValueType<OptionType, true>;

  setPreviousMentalHealthTreatment?: WizardPostAction<State, void>;
  setTraumaticExperience?: WizardPostAction<State, void>;
  setTraumaticFlashbacks?: WizardPostAction<State, void>;
  setGuilt?: WizardPostAction<State, void>;
  setIsolation?: WizardPostAction<State, void>;
  setSuicideIdeation?: WizardPostAction<State, void>;
  setSuicidePlanning?: WizardPostAction<State, void>;
  setHomicidalIdeation?: WizardPostAction<State, void>;
  setHomicidalPlanning?: WizardPostAction<State, void>;
  setAngerAggression?: WizardPostAction<State, void>;
}

export const getInitialState = (
  clientUserID: number,
  roomID: number,
  source: string,
  contextID: string
): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    roomID,
    clientUserID,
    sleepQuality: '',
    sleepAidUse: '',
    socialSupportSystem: '',
    previousMentalHealthTreatment: null,
    previousMentalHealthIssues: [],
    hospitalizationHistory: null,
    previousRelativesMentalHealthIssues: [],
    traumaticExperience: false,
    traumaticFlashbacks: null,
    guilt: null,
    isolation: null,
    suicideIdeation: '',
    suicidePlanning: null,
    homicidalIdeation: null,
    homicidalPlanning: null,
    angerAggression: null,

    selectedPreviousMentalHealthTreatment: null,
    selectedSuicideIdeation: null,
    selectedSuicidePlanning: null,
    selectedTraumaticExperience: null,
    selectedTraumaticFlashbacks: null,
    selectedGuilt: null,
    selectedIsolation: null,
    selectedAngerAggression: null,

    sleepQualityOptions: keyValueToValueType(sleepQuality),
    sleepAidUseOptions: keyValueToValueType(sleepAidUse),
    socialSupportSystemOptions: keyValueToValueType(socialSupportSystem),
    previousMentalHealthTreatmentOptions: keyValueToValueType(previousMentalHealthTreatment),
    previousMentalHealthIssuesOptions: keyValueToValueType(mentalHealthIssues),
    hospitalizationHistoryOptions: keyValueToValueType(hospitalizationHistory),
    previousRelativesMentalHealthIssuesOptions: keyValueToValueType(mentalHealthIssues),
    traumaticExperienceOptions: keyValueToValueType(traumaticExperienceOptions),
    traumaticFlashbacksOptions: keyValueToValueType(traumaticFlashbacksOptions),
    guiltOptions: keyValueToValueType(guiltOptions),
    isolationOptions: keyValueToValueType(isolationOptions),
    suicideIdeationOptions: keyValueToValueType(suicideIdeationOrPlanning),
    suicidePlanningOptions: keyValueToValueType(suicideIdeationOrPlanning),
    homicidalIdeationOrPlanningOptions: keyValueToValueType(homicidalIdeationOrPlanning),
    angerAggressionOptions: keyValueToValueType(angerAggression),
  };
};
