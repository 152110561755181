import { FunctionComponent, Dispatch, SetStateAction, MouseEvent } from 'react';
import { TextDS, TouchableView, useEmotionTheme } from '@talkspace/react-toolkit';

import { EmotionStyle } from '@/core/styled/styled';
import styled from '@/core/styled';

interface InputSendViewProps {
  text: string;
  hide?: boolean;
  onPress(): void;
  disabled?: boolean;
  style?: EmotionStyle;
  setIsSendClicked: Dispatch<SetStateAction<boolean>>;
}

const Wrapper = styled(TouchableView)<{ hide?: boolean }>(({ hide }) => {
  return {
    alignItems: 'center',
    justifyContent: 'center',
    display: hide ? 'none' : 'flex',
  };
});

const StyledText = styled(TextDS)<Partial<InputSendViewProps>>(({ disabled }) => {
  return {
    opacity: disabled ? 0.4 : 1,
    fontSize: 17,
    transition: 'all 0.15s ease-in-out',
    fontWeight: 500,
  };
});

const InputSendView: FunctionComponent<InputSendViewProps> = ({
  text,
  hide,
  onPress,
  disabled,
  style,
  setIsSendClicked,
}) => {
  const { colorRoles } = useEmotionTheme();
  const onPressHandler = (e: MouseEvent<HTMLDivElement>) => {
    // e.detail is the number of clicks that caused the event to fire
    // this prevents a focus outline from rendering on the input after a user clicks the message send button
    if (e?.detail > 0) {
      setIsSendClicked(true);
    }
    onPress();
  };
  return (
    <Wrapper disabled={disabled} onPress={onPressHandler} hide={hide} style={style}>
      <StyledText
        variant="headingSm"
        style={{
          color: disabled
            ? colorRoles.button.brandSecondaryTextDisabled
            : colorRoles.button.brandSecondaryTextDefault,
        }}
      >
        {text}
      </StyledText>
    </Wrapper>
  );
};

export default InputSendView;
