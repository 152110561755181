import { WizardScreen, useWizardActions, useWizardState } from 'stepWizard';
import { Screen } from '@talkspace/react-toolkit';
import { Route, Switch } from '@/core/routerLib';
import CancelNonSubscriptionContainer from './containers/CancelNonSubscriptionContainer';
import useWindowWidth from '../hooks/useWindowWidth';
import b2bCancellationWizardScheme from './b2bCancellationWizardScheme';
import KeepNonSubscriptionContainer from './containers/KeepNonSubscriptionContainer';
import noMatchesCancellationWizardScheme from './noMatchesCancellationWizardSchema';

const Routes = () => {
  const { hasProvider } = useWizardState();
  const { isMobile } = useWindowWidth();
  const { onExitClick } = useWizardActions();

  return (
    <Switch>
      <Screen showBackButton={false} onCloseButtonClick={onExitClick} title={isMobile ? ' ' : ''}>
        <Route
          exact
          path="/cancel-non-subscription/cancel"
          component={CancelNonSubscriptionContainer}
        />
        <Route
          exact
          path="/cancel-non-subscription/keep-non-subscription"
          component={KeepNonSubscriptionContainer}
        />
        <Route
          exact
          path={[
            '/cancel-non-subscription/room/:roomID/source/:source/context-id/:contextID',
            '/cancel-non-subscription/room/:roomID/source/:source',
          ]}
          render={() => (
            <WizardScreen
              wizardScheme={
                hasProvider ? b2bCancellationWizardScheme : noMatchesCancellationWizardScheme
              }
            />
          )}
        />
      </Screen>
    </Switch>
  );
};

export default Routes;
