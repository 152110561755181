import { WizardScheme } from 'stepWizard';

const medicalHealthScheme: WizardScheme = {
  wizardModalTitle: 'medical intake',
  wizardVersion: 1,
  wizardType: 'medicalIntake',
  disablePersist: true,
  steps: [
    {
      title:
        'Are you currently being treated or have you ever been treated for any of the following medical issues?',
      inputType: 'searchableMultiSelect',
      inputState: 'medicalIssues',
      inputTypePlaceholder: 'Select',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'Skip',
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      inputOptions: 'medicalIssuesOptions',
      name: 'medicalIssues',
    },
    {
      title: 'Are you experiencing chronic pain?',
      inputType: 'select',
      inputState: 'hasChronicPain',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'hasChronicPainOptions',
      name: 'hasChronicPain',
      postAction: 'setSelectedHasChronicPain',
      backOption: 'back',
    },
    {
      title: 'Are you currently pregnant?',
      inputType: 'select',
      inputState: 'isPregnant',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'isPregnantOptions',
      postAction: 'setSelectedIsPregnant',
      name: 'isPregnant',
      backOption: 'back',
      displayCondition: 'showPregnancyQuestion',
    },
    {
      title: 'Are you currently taking any of the following psychiatric medications?',
      inputType: 'creatableSearchableMultiSelect',
      inputState: 'medications',
      inputTypePlaceholder: 'Select',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'Skip',
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      inputOptions: 'medicationsOptions',
      name: 'medications',
      backOption: 'back',
    },
    {
      title:
        "List any over-the-counter medications, supplements, vitamins, or herbs you're currently taking",
      inputType: 'multilineText',
      inputState: 'otcMedications',
      inputTypePlaceholder: 'Over-the-counter medication...',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'Skip',
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      name: 'otcMedications',
      backOption: 'back',
    },
    {
      title: 'Please provide your preferred pharmacy address',
      inputType: 'address',
      inputState: 'pharmacyAddress',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'Skip',
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      name: 'pharmacyAddress',
      backOption: 'back',
    },
    {
      title: 'Please describe any drug allergies you have and your reaction(s) to them',
      inputType: 'multilineText',
      inputState: 'drugAllergies',
      inputTypePlaceholder: 'Drug allergies...',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'Skip',
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      name: 'drugAllergies',
      backOption: 'back',
    },
    {
      title:
        'Do you currently use or have you previously used any of the following controlled substances?',
      inputType: 'searchableMultiSelect',
      inputState: 'controlledSubstances',
      inputTypePlaceholder: 'Select',
      nextButton: {
        displayText: 'Submit',
        actionDispatch: (_, wizardContext) => {
          if (wizardContext.source === 'onboarding') {
            return `/room/${wizardContext.roomID}/onboarding/medical-health/source/onboarding/submit`;
          }
          return '/medical-health-wizard/submit';
        },
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: `I don't use any controlled substances`,
        actionDispatch: (_, wizardContext) => {
          if (wizardContext.source === 'onboarding') {
            return `/room/${wizardContext.roomID}/onboarding/medical-health/source/onboarding/submit`;
          }
          return '/medical-health-wizard/submit';
        },
        whenVisible: 'always',
      },
      inputOptions: 'controlledSubstancesOptions',
      name: 'controlledSubstances',
      backOption: 'back',
    },
  ],
};

export default medicalHealthScheme;
