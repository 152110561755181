import Svg, { Path } from 'svgs';

const Pause = ({ width = 20, height = 20, color = '#4B4B4B4B', ...otherProps }) => {
  const titleText = 'pause';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      x="0px"
      y="0px"
      viewBox="0 0 12.6 14.5"
      {...otherProps}
    >
      <Path fill={color} d="M8.6 0H12.6V14.5H8.6z" />
      <Path fill={color} d="M0 0H4V14.5H0z" />
    </Svg>
  );
};

export default Pause;
