import { TextDS, View, useEmotionTheme, Button, spacing } from '@talkspace/react-toolkit';
import TeenspaceGroupChat from '../../assets/illustrations/TeenspaceGroupChat';
import useOpenModalSafe from '../../../hooks/useOpenModalSafe';

const { space150, space200, space300, space400 } = spacing;

const TeenspaceWelcomeScreen = () => {
  const { colorRoles } = useEmotionTheme();
  const openModal = useOpenModalSafe();

  const onPress = () => {
    openModal('/community/teenspace/onboarding/sign-up');
  };
  return (
    <View align="center" justify="space-between" style={{ textAlign: 'center', height: '100%' }}>
      <View align="center">
        <TextDS variant="heading2xl" style={{ marginTop: space400, marginBottom: space150 }}>
          You belong here
        </TextDS>
        <TextDS variant="body" style={{ color: colorRoles.typography.textSubtle }}>
          Join a safe community for teens, by teens, and express yourself by answering a new
          question every day!
        </TextDS>
        <View style={{ marginTop: space300 }}>
          <TeenspaceGroupChat />
        </View>
      </View>
      <Button
        text="Continue"
        onPress={onPress}
        style={{
          width: '100%',
          marginBottom: space400,
          padding: `${space200}px ${space300}px`,
          maxWidth: undefined,
        }}
        dataQa="teenspaceWelcomeScreenContinueButton"
      />
    </View>
  );
};

export default TeenspaceWelcomeScreen;
