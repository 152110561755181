/* eslint-disable camelcase */
import { FunctionComponent, useEffect, useState, useRef } from 'react';

import * as React from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import View from '../View';
import styled, { useEmotionTheme } from '../../core/styled';
import Input from '../Input';
import Label from '../Label';
import { useUniqueID } from '../../hooks/a11yHelper';
import { webOnlyStyle } from '../../core/styleHelpers';
import commonStyles from '../../constants/commonStyles';

const { crossBrowserFocusRing } = commonStyles;

type TypeNames =
  | 'street_number'
  | 'route'
  | 'locality'
  | 'sublocality'
  | 'administrative_area_level_1'
  | 'administrative_area_level_3'
  | 'postal_code'
  | 'country'
  | 'postal_town';

interface ParseAddressResultType {
  streetNumber?: string;
  route?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  address?: string;
  fullAddress?: string;
}
interface AddressComponent {
  fullAddress: string;
  state?: string;
  zipcode?: string;
  city?: string;
  address?: string;
  long_name?: string;
  short_name?: string;
  types: TypeNames[];
}

export interface AddressInputValue {
  addressFromApi: {
    address?: string;
    city?: string;
    state?: string;
    fullAddress?: string;
    zipcode?: string;
    country?: string;
  };
  addressString: string;
}
interface Props {
  value: AddressInputValue;
  setValue: (value: AddressInputValue) => void;
}

const OptionsContainerView = styled(View)({
  position: 'absolute',
  top: '100%',
  width: '100%',
  borderRadius: 9,
  overflow: 'hidden',
  boxShadow: '2px 10px 20px rgba(0, 0, 0, .15)',
});

const OptionsView = styled(View)({
  cursor: 'pointer',
  justifyContent: 'center',
  display: 'flex',
  height: 50,
  padding: 10,
  textAlign: 'center',
  alignItems: 'center',
});

const OptionsText = styled(View)({
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
});

const StyledInput = styled(Input)<{ disabled?: boolean; isError?: boolean }>(
  ({ disabled, isError, theme: { colors } }) => {
    return {
      height: 50,
      backgroundColor: disabled ? colors.permaLinkWaterGrey : undefined,
      borderRadius: 10,
      borderStyle: 'solid',
      borderColor: isError ? colors.red : colors.permaLividBlueNew,
      borderWidth: 1,
      color: disabled ? colors.periwinkleGreyText : undefined,
      padding: 20,
      ...webOnlyStyle({
        boxSizing: 'border-box',
        ':focus': {
          outline: 'none',
          caretColor: colors.green,
        },
        '::placeholder': {
          color: colors.permaWaikawaGreyNew,
        },
      }),
    };
  }
);

const InputWrapper = styled(View)<{
  clicked?: boolean;
  outlineOffset?: number;
}>(({ clicked, outlineOffset }) => {
  return {
    ...webOnlyStyle({
      '&:hover:focus-within': {
        outline: 'none',
      },
      ':focus-within': clicked
        ? { outline: 'none' }
        : {
            ...crossBrowserFocusRing,
            outlineOffset: outlineOffset || 0,
          },
    }),
  };
});

const InputGroup = styled(View)({ marginBottom: 8 });

const revertTextToCurrentValue = (
  setSearchText: React.Dispatch<React.SetStateAction<string>>,
  value: AddressInputValue
) => {
  setSearchText(
    value && value.addressFromApi && value.addressFromApi.fullAddress
      ? value.addressFromApi.fullAddress
      : ''
  );
};

const parseAddress = (addressComponents: AddressComponent[]) => {
  let result: ParseAddressResultType = {};

  addressComponents.forEach(({ long_name: longName, short_name: shortName, types }) => {
    // this is the 'address_street' portion of the address
    if (types.includes('street_number')) {
      result = { ...result, streetNumber: longName };
    }

    if (types.includes('route')) {
      result = { ...result, route: longName };
    }

    if (types.includes('locality')) {
      result = { ...result, city: longName };
    }

    // City sometimes appears in 'sublocality', like when the address is in Brooklyn, NY (go BK!)
    // City sometimes appears in 'administrative_area_level_3' like in Williams Township, PA
    if (
      (types.includes('sublocality') ||
        types.includes('postal_town') ||
        types.includes('administrative_area_level_3')) &&
      !result.city
    ) {
      result = { ...result, city: longName };
    }

    if (types.includes('administrative_area_level_1')) {
      result = { ...result, state: shortName };
    }

    if (types.includes('postal_code')) {
      result = { ...result, zipcode: longName };
    }

    if (types.includes('country')) {
      result = { ...result, country: shortName };
    }
  });

  result.address = `${result.streetNumber ?? ''} ${result.route ?? ''}`.trim();

  return result;
};

const AddressInput: FunctionComponent<Props> = ({ value, setValue }) => {
  const [searchText, setSearchText] = useState('');
  const [clickedAddress1, setClickedAddress1] = useState(false);
  const [clickedAddress2, setClickedAddress2] = useState(false);
  const onClick1 = () => {
    setClickedAddress1(true);
    return null;
  };
  const handleBlur1 = () => {
    setClickedAddress1(false);
  };
  const onClick2 = () => {
    setClickedAddress2(true);
    return null;
  };
  const handleBlur2 = () => {
    setClickedAddress2(false);
  };

  const { colors } = useEmotionTheme();
  const textInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    revertTextToCurrentValue(setSearchText, value);
  }, [value]);

  const onBlur = () => {
    revertTextToCurrentValue(setSearchText, value);
  };

  const handleAddressChange = (address: string) => {
    setSearchText(address);
  };

  const handleAddressSelect = (firstAddress: string) => {
    geocodeByAddress(firstAddress).then((results) => {
      const [
        { address_components: addressComponents = [], formatted_address: fullAddress = '' } = {},
      ] = results;
      const addressFromApi = parseAddress(addressComponents);
      addressFromApi.fullAddress = fullAddress;
      textInputRef.current?.focus();
      setValue({ ...value, addressFromApi });
    });
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue({
      ...value,
      addressString: newValue,
    });
  };

  const addressID = useUniqueID('addressID');
  const address2ID = useUniqueID('address2ID');
  const addressHelpStringID = useUniqueID('addressHelpStringID');
  return (
    <>
      <View>
        <PlacesAutocomplete
          aria-required="true"
          value={searchText}
          onChange={handleAddressChange}
          onSelect={handleAddressSelect}
          searchOptions={{
            types: ['address'],
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <View
              style={{
                position: 'relative',
              }}
            >
              <InputGroup>
                <Label id={addressID}>Address</Label>
                <InputWrapper clicked={clickedAddress1}>
                  <StyledInput
                    aria-labelledby={`${addressID} ${addressHelpStringID}`}
                    type="text"
                    id="address"
                    onClick={onClick1}
                    onBlur={handleBlur1}
                    {...getInputProps({
                      placeholder: 'Address',
                      onBlur,
                    })}
                  />
                </InputWrapper>
                <Label id={addressHelpStringID} style={{ margin: '4px 0 8px' }}>
                  (e.g. home, shelter, or most recent address)
                </Label>
              </InputGroup>

              {suggestions && suggestions.length > 0 && (
                <OptionsContainerView
                  style={{ border: `1px solid ${colors.edgewater}`, backgroundColor: colors.white }}
                >
                  {suggestions.map((suggestion) => (
                    <OptionsView
                      key={suggestion}
                      style={{
                        backgroundColor: suggestion.active ? colors.swansDown : colors.white,
                      }}
                      {...getSuggestionItemProps(suggestion)}
                    >
                      <OptionsText>{suggestion.description}</OptionsText>
                    </OptionsView>
                  ))}
                </OptionsContainerView>
              )}
            </View>
          )}
        </PlacesAutocomplete>
        <InputGroup>
          <Label id={address2ID}>Apartment, suite, etc. (optional)</Label>
          <InputWrapper clicked={clickedAddress2}>
            <StyledInput
              aria-labelledby={address2ID}
              ref={textInputRef}
              maxLength={64}
              type="text"
              value={value ? value.addressString : ''}
              onChange={handleChange}
              placeholder="Apartment, suite, etc."
              onClick={onClick2}
              onBlur={handleBlur2}
            />
          </InputWrapper>
        </InputGroup>
      </View>
    </>
  );
};

export default AddressInput;
