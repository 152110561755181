import MGrid from '../../../components/grid/Grid';
import { useWindowWidthState } from '../../../hooks/windowWidthContext';
import { GridContextProvider } from './gridContext';
import { HybridType } from './gridTypes';
import { getBreakpoint, getBreakpoints, getContainerMaxWidth, getGridConfig } from './gridUtils';
import { EmotionStyle } from '../../../core/styled';
import { SpacingToken, spacing } from '../../tokens';

type GridProps = {
  children: React.ReactNode;
  hybridType?: HybridType;
  fluid?: boolean;
  className?: string;
  style?: EmotionStyle;
  rowSpacing?: SpacingToken;
};

const Grid = ({ children, fluid, hybridType, rowSpacing, className, style }: GridProps) => {
  const { width } = useWindowWidthState();
  const breakpoints = getBreakpoints(hybridType);
  const maxWidth = getContainerMaxWidth(hybridType);
  const breakpoint = getBreakpoint(width, hybridType || undefined, breakpoints);
  const { columns, gutter, sideMargin } = getGridConfig(breakpoint, hybridType || undefined);

  return (
    <MGrid
      fluid={fluid}
      breakpoints={breakpoints}
      columns={columns}
      gutterWidth={gutter}
      containerMaxWidths={maxWidth}
      sideMargin={sideMargin}
      className={className}
      style={style}
    >
      <GridContextProvider
        rowSpacing={rowSpacing ? spacing[rowSpacing] : 0}
        breakpoints={breakpoints}
        columns={columns}
        gutterWidth={gutter}
        containerMaxWidths={maxWidth}
        hybridType={hybridType || null}
      >
        {children}
      </GridContextProvider>
    </MGrid>
  );
};

export default Grid;
