import Svg, { G, Path, Circle } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const Microphone = ({
  recording = false,
  fillColor,
  ...otherProps
}: {
  recording?: boolean;
  fillColor?: string;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'microphone';
  return (
    <Svg
      width="18px"
      height="28px"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 18 28"
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill={recording ? colors.rose : 'none'} fillRule="evenodd">
        <Path
          d="M3,27 C3,26.4477153 3.45576096,26 4.00247329,26 L13.9975267,26 C14.5511774,26 15,26.4438648 15,27 L15,27 C15,27.5522847 14.544239,28 13.9975267,28 L4.00247329,28 C3.44882258,28 3,27.5561352 3,27 L3,27 Z"
          fill={recording ? colors.rose : fillColor || colors.green}
        />
        <Path
          fill={recording ? colors.rose : fillColor || colors.green}
          d="M8 21.7058824H10V27.08588251H8z"
        />
        <Path
          d="M18 3.953v10.888c0 4.796-4.033 8.688-9 8.688-4.975 0-9-3.884-9-8.688V7.466v3.512h2v3.77c0 3.796 3.13 6.86 7 6.86 3.862 0 7-3.073 7-6.86v-3.77h2V3.953z"
          fill={recording ? colors.rose : fillColor || colors.green}
          fillRule="nonzero"
        />
        <Circle fill={recording ? colors.rose : fillColor || colors.green} cx={1} cy={11} r={1} />
        <Circle fill={recording ? colors.rose : fillColor || colors.green} cx={17} cy={11} r={1} />
        <Path
          d="M4.579 14.625c0 2.35 1.968 4.255 4.42 4.255 2.444 0 4.42-1.914 4.42-4.255v-9.37C13.419 2.905 11.45 1 8.999 1c-2.444 0-4.42 1.914-4.42 4.255v9.37z"
          stroke={recording ? colors.rose : fillColor || colors.green}
          strokeWidth={2}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

export default Microphone;
