import Svg, { Path } from 'svgs';

const Play = ({ width = 20, height = 20, color = '#4B4B4B4B', ...otherProps }) => {
  const titleText = 'play';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      x="0px"
      y="0px"
      viewBox="0 0 12.6 14.5"
      {...otherProps}
    >
      <Path fill={color} d="M12.6 7.3L0 14.5V0l12.6 7.3z" />
    </Svg>
  );
};

export default Play;
