import { FunctionComponent, useEffect, useState } from 'react';
import { getVWOWidgetsHeight } from 'ts-analytics/VWO/helpers';
import { Breadcrumbs, useWindowWidthState, useUniqueID } from '@talkspace/react-toolkit';
import Screen from '@talkspace/react-toolkit/src/components/Screen';
import StickyHeader from '../components/StickyHeader';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { RouteComponentProps, useHistory } from '@/core/routerLib';
import CheckoutContainer from '../containers/CheckoutContainer';
import { RoomOfferRouteParams } from '../types';
import { useRoomOffersState } from '../hooks/roomOfferContext';
import { TALK100_COUPON } from '../../checkout/utils/constants';

const CheckoutBreadcrumbs = () => (
  <Breadcrumbs
    activeIndex={1}
    items={[{ label: 'Choose plan' }, { label: 'Review details' }, { label: 'Checkout' }]}
  />
);

/**
 * Despite what this component is named, this component renders the "Review Details" screen.
 */
const CheckoutScreen: FunctionComponent<RouteComponentProps<RoomOfferRouteParams>> = () => {
  const { coupon, isChangePlan, showCloseButton } = useRoomOffersState();
  const { validatedCoupon } = coupon;
  const validatedCouponCode = validatedCoupon ? validatedCoupon.code : undefined;
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [stickHeader, setStickHeader] = useState<boolean>(false);
  const [isBackButtonClickedInMobile, setBackButtonClickInMobile] = useState<boolean>(false);
  const checkoutHeaderId = useUniqueID('checkoutHeader');

  const history = useHistory();
  const { isMobile } = useWindowWidthState();

  useEffect(() => {
    // Set back button click in mobile mode in case exit intent caused by timeout. (to avoid duplicate exit intent popup)
    const isExitIntentCoupons = validatedCouponCode?.trim() === TALK100_COUPON;
    if (isExitIntentCoupons) {
      setBackButtonClickInMobile(true);
    }
  }, [validatedCouponCode]);

  const handleBackButtonClick = () => {
    // We need to only allow users to go back if they are NOT in mobile view or if they are in control variant. And, if they are in mobile view then we check if isBackButtonClickedInMobile is true.
    if (!isMobile || isBackButtonClickedInMobile) {
      history.goBack();
      setBackButtonClickInMobile(false);
    }
    setBackButtonClickInMobile(true);
  };

  const handleOnScrollChange = (scrollTopVal: number) => {
    setStickHeader(scrollTopVal > 50);
    setScrollTop(scrollTopVal);
  };

  const closeModal = useCloseModal();

  const VWOWidgetsHeight = getVWOWidgetsHeight();

  return (
    <Screen
      additionalHeaderTopPadding={VWOWidgetsHeight}
      onBackButtonClick={() => handleBackButtonClick()}
      showBackButton
      showCloseButton={showCloseButton}
      onScrollChange={handleOnScrollChange}
      title={isChangePlan ? 'Change plan' : undefined}
      titleId={checkoutHeaderId}
      onCloseButtonClick={() => closeModal()}
    >
      {!isChangePlan ? (
        <StickyHeader stickHeader={stickHeader}>
          <CheckoutBreadcrumbs />
        </StickyHeader>
      ) : (
        <StickyHeader
          stickHeader={stickHeader}
          style={{ height: 66, backgroundColor: 'white', boxShadow: 'unset' }}
        />
      )}
      <CheckoutContainer
        scrollTop={scrollTop}
        isBackButtonClickedInMobile={isBackButtonClickedInMobile}
        titleId={checkoutHeaderId}
      />
    </Screen>
  );
};

export default CheckoutScreen;
