import { Button, useWindowWidthState, spacing, useEmotionTheme } from '@talkspace/react-toolkit';
import { PenToSquare } from '@talkspace/react-toolkit/src/designSystems/icons';
import IconButton from '@talkspace/react-toolkit/src/designSystems/components/IconButton';
import { useHistory } from '@/core/routerLib';

import useNavShellWindowHeight from '../../hooks/useNavShellWindowHeight';
import useRotateOptionsArray from '../hooks/useRotateOptionsArray';
import { useCommunityState } from '../hooks/CommunityContext';
import { formatPlaceholder } from '../utils/helpers';

const { space200 } = spacing;

const OpenPostCreatorButtons = () => {
  const history = useHistory();
  const { colorRoles } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const { outsideFooterHeight } = useNavShellWindowHeight();

  const { dailyPrompt } = useCommunityState();
  const { placeholders = [] } = dailyPrompt || {};

  const onPress = () => {
    history.push('/community/teenspace/create-post');
  };

  const placeholder = useRotateOptionsArray<string>({ options: placeholders, intervalSeconds: 3 });

  return (
    <>
      <Button
        onPress={onPress}
        dataQa="openPostCreatorButton"
        sizeDS={isMobile ? 'large' : 'medium'}
        style={{ width: '100%' }}
        text={placeholder && formatPlaceholder(placeholder)}
        Icon={() => <PenToSquare colorType="inverse" />}
        disabled={!dailyPrompt}
        isLoading={!dailyPrompt}
      />
      {isMobile && ( // NOTE: extra floating button on mobile
        <IconButton
          onPress={onPress}
          dataQa="openPostCreatorCircleButton"
          Icon={<PenToSquare size="huge" colorType="inverse" />}
          iconWrapperStyle={{ width: 'fit-content' }}
          style={{
            position: 'fixed',
            bottom: (outsideFooterHeight || 0) + space200,
            right: space200,
            borderRadius: '50%',
            width: 64,
            height: 64,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: colorRoles.button.brandPrimarySurfaceDefault,
            zIndex: 1,
          }}
        />
      )}
    </>
  );
};

export default OpenPostCreatorButtons;
