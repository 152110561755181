import React, { createContext, useContext, useEffect, useState } from 'react';
import { useEmotionTheme, EmotionStyle } from '../../../core/styled';
import withContextProvider from '../../../hoc/withContextProvider';
import useComponentSize from '../../../hooks/useComponentSize';
import View from '../../../components/View';

type StickyDrawerStateContextType = {
  isOpen: boolean;
  drawerHeight: number | undefined;
  drawerWidth: number | undefined;
};

type StickyDrawerActionsContextType = {
  setIsOpen: (isOpen: boolean) => void;
  setDrawerHeight: (height: number | undefined) => void;
  setDrawerWidth: (width: number | undefined) => void;
};

const StickyDrawerStateContext = createContext<StickyDrawerStateContextType>({
  isOpen: false,
  drawerHeight: undefined,
  drawerWidth: undefined,
});

const NOOP = () => undefined;

const StickyDrawerActionsContext = createContext<StickyDrawerActionsContextType>({
  setDrawerHeight: NOOP,
  setDrawerWidth: NOOP,
  setIsOpen: NOOP,
});

const StickyDrawerProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState<number | undefined>();
  const [drawerWidth, setDrawerWidth] = useState<number | undefined>();

  useEffect(() => {
    if (!isOpen) {
      setDrawerHeight(undefined);
    }
  }, [isOpen]);
  return (
    <StickyDrawerStateContext.Provider value={{ isOpen, drawerHeight, drawerWidth }}>
      <StickyDrawerActionsContext.Provider value={{ setIsOpen, setDrawerHeight, setDrawerWidth }}>
        {children}
      </StickyDrawerActionsContext.Provider>
    </StickyDrawerStateContext.Provider>
  );
};

const StickyDrawerParentContainer = withContextProvider(StickyDrawerProvider)(
  ({
    children,
    style,
    drawerWidthFollowsContainer,
    ...props
  }: React.ComponentProps<typeof View> & { drawerWidthFollowsContainer?: boolean }) => {
    const { isOpen, drawerHeight } = useContext(StickyDrawerStateContext);
    const { setDrawerWidth } = useContext(StickyDrawerActionsContext);
    const [ref, { width }] = useComponentSize<HTMLDivElement>(children);

    useEffect(() => {
      // sync drawer width with StickyDrawerParentContainer width
      if (drawerWidthFollowsContainer) {
        setDrawerWidth(width);
      } else {
        setDrawerWidth(undefined);
      }
    }, [drawerWidthFollowsContainer, setDrawerWidth, width]);

    return (
      <View
        ref={ref}
        style={{ paddingBottom: isOpen ? drawerHeight : undefined, ...style }}
        {...props}
      >
        {children}
      </View>
    );
  }
);

export interface StickyDrawerProps {
  children: React.ReactNode;
  contentWrapperStyle?: EmotionStyle;
  noBorder?: boolean;
}
const StickyDrawerComponent = ({ children, contentWrapperStyle, noBorder }: StickyDrawerProps) => {
  const { colors } = useEmotionTheme();
  const { setDrawerHeight, setIsOpen } = useContext(StickyDrawerActionsContext);
  const { drawerWidth } = useContext(StickyDrawerStateContext);
  const [ref, { height }] = useComponentSize<HTMLDivElement>(children);

  useEffect(() => {
    setDrawerHeight(height);
  }, [setDrawerHeight, height]);

  useEffect(() => {
    setIsOpen(true);
    return () => {
      setIsOpen(false);
    };
  }, [setIsOpen]);

  return (
    // Set the ref on the parent View, the one without the padding, to get the full height of the drawer.
    <View
      ref={ref}
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        width: drawerWidth || '100vw',
      }}
    >
      <View
        style={{
          padding: '24px 20px',
          alignItems: 'center',
          borderTop: noBorder ? undefined : `1px solid ${colors.permaMischkaNew}`,
          background: colors.white,
          ...contentWrapperStyle,
        }}
      >
        {children}
      </View>
    </View>
  );
};

export {
  /**
   * Must be used above the container rendering StickyDrawer. It will create a Context for StickyDrawer to use.
   */
  StickyDrawerParentContainer,
  StickyDrawerProvider as ContextProvider,
  StickyDrawerStateContext as StateContext,
};
export default StickyDrawerComponent;
