import { forwardRef } from 'react';

import View from '../../../components/View';
import TouchableView, { TouchableViewProps } from '../../../components/TouchableView';
import styled, { EmotionStyle } from '../../../core/styled';
import { getSurfaceDefaultStyles } from '../../styles/interactiveStateStyles';

const IconButtonWrapper = styled(View)<{ isActive?: boolean }>(
  ({ isActive, theme: { colorRoles } }) => {
    return {
      padding: '4px 4px',
      borderRadius: 8,
      ...getSurfaceDefaultStyles({ colorRoles, active: isActive }),
    };
  }
);

interface IconButtonProps extends TouchableViewProps {
  isActive?: boolean;
  Icon: React.ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  hide?: boolean;
  iconWrapperStyle?: EmotionStyle;
}
const IconButton = forwardRef<HTMLDivElement, IconButtonProps>((props: IconButtonProps, ref) => {
  const {
    isActive,
    Icon,
    onMouseEnter,
    hide,
    onMouseLeave,
    iconWrapperStyle,
    ...touchableViewProps
  } = props;
  if (hide) return null;
  return (
    <TouchableView
      {...touchableViewProps}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={ref}
    >
      <IconButtonWrapper isActive={isActive} style={iconWrapperStyle}>
        {Icon}
      </IconButtonWrapper>
    </TouchableView>
  );
});

export default IconButton;
