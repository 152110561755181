import {
  ClientAwaitingSessionResponse,
  ClientReactivationAPIResponse,
  ChatBannerType,
} from 'chat/redux/constants/chatTypes';
import { EmailVerificationStatus } from '../../entities/Me';

export interface ClientInfo {
  id: number;
  nickname: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  emailVerificationStatus: EmailVerificationStatus;
  pendingEmail: string;
}

export interface EmailVerificationPayload {
  email: ClientInfo['email'];
  emailStatus?: ClientInfo['emailVerificationStatus'];
  pendingEmail: ClientInfo['pendingEmail'];
}

export interface ChatBannerState {
  showBanner: boolean;
  banners: ChatBannerType[];
  creditCardDeclinedPayload?: boolean;
  partnerMissingPayload?: boolean;
  eligiblityWidgetPayload?: boolean;
  psychiatristReminderPayload?: boolean;
  introLVSReminderPayload?: boolean;
  reactivationPayload?: ClientReactivationAPIResponse;
  awaitingSessionPayload?: ClientAwaitingSessionResponse;
  emailVerificationPayload: EmailVerificationPayload;
  matchWithNewProviderPayload?: boolean;
  matchWithSameProviderPayload?: boolean;
  is2faOptIn?: boolean;
  hasPhoneNumber?: boolean;
  isTeenFunnelUser?: boolean;
  roomExpirationDate?: Date | null;
  hasPendingInvoices?: boolean;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string;
}

export type ChatBannerActionTypes =
  | {
      type: 'getClientReactivation';
      payload: Pick<ChatBannerState, 'reactivationPayload'>;
    }
  | {
      type: 'receiveClientReactivation';
      payload: Partial<Pick<ChatBannerState, 'reactivationPayload'>>;
    }
  | {
      type: 'getClientAwaitingSession';
    }
  | {
      type: 'receiveClientAwaitingSession';
      payload: Pick<ChatBannerState, 'awaitingSessionPayload'>;
    }
  | {
      type: 'receiveUpdateRoomExpiration';
      payload: Pick<ChatBannerState, 'roomExpirationDate'>;
    }
  | {
      type: 'get2FAClientStatus';
      payload: Pick<ChatBannerState, 'is2faOptIn' | 'hasPhoneNumber'>;
    }
  | {
      type: 'receive2FAClientStatus';
      payload: Pick<ChatBannerState, 'is2faOptIn' | 'hasPhoneNumber'>;
    }
  | {
      type: 'getEmailVerificationStatus';
      payload: Pick<ChatBannerState, 'emailVerificationPayload'>;
    }
  | {
      type: 'receiveEmailVerificationStatus';
      payload: Pick<ChatBannerState, 'emailVerificationPayload'>;
    }
  | {
      type: 'sendEmailVerification';
    }
  | {
      type: 'emailVerificationSent';
    }
  | {
      type: 'dismissReactivationBanner';
      payload: Pick<ChatBannerState, 'reactivationPayload'>;
    }
  | { type: 'setIsError'; payload: string }
  | {
      type: 'setEmailVerificationPayload';
      payload: Pick<ChatBannerState, 'emailVerificationPayload'>;
    }
  | {
      type: 'partnerMissing';
      payload: Pick<ChatBannerState, 'partnerMissingPayload'>;
    }
  | {
      type: 'creditCardDeclined';
      payload: Pick<ChatBannerState, 'creditCardDeclinedPayload'>;
    }
  | {
      type: 'psychiatristReminder';
      payload: Pick<ChatBannerState, 'psychiatristReminderPayload'>;
    }
  | {
      type: 'eligibilityWidget';
      payload: Pick<ChatBannerState, 'eligiblityWidgetPayload'>;
    }
  | {
      type: 'introLVSReminder';
      payload: Pick<ChatBannerState, 'introLVSReminderPayload'>;
    }
  | {
      type: 'pendingInvoices';
      payload: Pick<ChatBannerState, 'hasPendingInvoices'>;
    }
  | {
      type: 'matchWithNewProvider';
      payload: Pick<ChatBannerState, 'matchWithNewProviderPayload'>;
    }
  | {
      type: 'matchWithSameProvider';
      payload: Pick<ChatBannerState, 'matchWithSameProviderPayload'>;
    }
  | {
      type: 'setIsTeenFunnelUser';
      payload: Pick<ChatBannerState, 'isTeenFunnelUser'>;
    };

export const chatBannerInitialState: ChatBannerState = {
  showBanner: false,
  reactivationPayload: undefined,
  awaitingSessionPayload: undefined,
  roomExpirationDate: undefined,
  creditCardDeclinedPayload: undefined,
  partnerMissingPayload: undefined,
  eligiblityWidgetPayload: undefined,
  psychiatristReminderPayload: undefined,
  introLVSReminderPayload: undefined,
  matchWithNewProviderPayload: undefined,
  matchWithSameProviderPayload: undefined,
  emailVerificationPayload: {
    email: '',
    emailStatus: undefined,
    pendingEmail: '',
  },
  banners: [],
  isTeenFunnelUser: false,
  hasPhoneNumber: false,
  hasPendingInvoices: undefined,
  isError: false,
  isLoading: false,
  errorMessage: '',
};

const getBanners = (state: ChatBannerState): ChatBannerType[] => {
  const bannerMappings: { isActive: boolean; banner: ChatBannerType }[] = [
    {
      isActive: Boolean(state.reactivationPayload),
      banner: 'reactivation',
    },
    {
      isActive: Boolean(state.awaitingSessionPayload),
      banner: 'awaitingSession',
    },
    {
      isActive:
        Boolean(state.emailVerificationPayload.email) &&
        state.emailVerificationPayload.emailStatus === 'unverified_existing',
      banner: 'emailVerification',
    },
    {
      // Disable 2fa banner for teens without phone numbers
      isActive: !state.is2faOptIn && !(state.isTeenFunnelUser && !state.hasPhoneNumber),
      banner: 'enable2fa',
    },
    {
      isActive: Boolean(state.partnerMissingPayload),
      banner: 'partnerMissing',
    },
    {
      isActive: Boolean(state.eligiblityWidgetPayload),
      banner: 'eligiblityWidget',
    },
    {
      isActive: Boolean(state.creditCardDeclinedPayload),
      banner: 'creditCardDeclined',
    },
    {
      isActive: Boolean(state.psychiatristReminderPayload),
      banner: 'psychiatristReminder',
    },
    {
      isActive: Boolean(state.introLVSReminderPayload),
      banner: 'introLVSReminder',
    },
    {
      isActive: Boolean(state.hasPendingInvoices),
      banner: 'pendingInvoices',
    },
    {
      isActive: Boolean(state.matchWithNewProviderPayload),
      banner: 'matchWithNewProvider',
    },
    {
      isActive: Boolean(state.matchWithSameProviderPayload),
      banner: 'matchWithSameProvider',
    },
  ];

  return bannerMappings.filter(({ isActive }) => isActive).map(({ banner }) => banner);
};

export const chatBannerReducer = (
  state: ChatBannerState = chatBannerInitialState,
  action: ChatBannerActionTypes
): ChatBannerState => {
  switch (action.type) {
    case 'sendEmailVerification':
    case 'getClientAwaitingSession':
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: '',
      };
    case 'getClientReactivation':
    case 'getEmailVerificationStatus':
    case 'get2FAClientStatus':
      return {
        ...state,
        ...action.payload,
        banners: getBanners({ ...state, ...action.payload }),
        showBanner: !!getBanners({ ...state, ...action.payload }).length,
        isLoading: true,
        isError: false,
        errorMessage: '',
      };
    case 'creditCardDeclined':
    case 'dismissReactivationBanner':
    case 'receiveUpdateRoomExpiration':
    case 'receiveClientReactivation':
    case 'receiveClientAwaitingSession':
    case 'receiveEmailVerificationStatus':
    case 'setEmailVerificationPayload':
    case 'receive2FAClientStatus':
    case 'psychiatristReminder':
    case 'introLVSReminder':
    case 'partnerMissing':
    case 'eligibilityWidget':
    case 'pendingInvoices':
    case 'matchWithNewProvider':
    case 'matchWithSameProvider':
    case 'setIsTeenFunnelUser':
      return {
        ...state,
        ...action.payload,
        banners: getBanners({ ...state, ...action.payload }),
        showBanner: !!getBanners({ ...state, ...action.payload }).length,
        isLoading: false,
        isError: false,
        errorMessage: '',
      };
    case 'emailVerificationSent':
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: '',
      };
    case 'setIsError':
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: action.payload,
        reactivationPayload: undefined,
        emailVerificationPayload: {
          email: '',
          emailStatus: undefined,
          pendingEmail: '',
        },
      };
    default:
      return state;
  }
};
