import { FunctionComponent } from 'react';
import { View, BaseButton, Tiny, Large, Avatar, useEmotionTheme } from '@talkspace/react-toolkit';
import capitalize from 'lodash/capitalize';
import {
  BillingPrice,
  PlanData,
  PaymentDetails,
  CouponState,
  DescriptionStyle,
  SessionModality,
} from 'ts-frontend/types';
import { billingCycleValueDescription } from 'ts-frontend/helpers/billingUtils';
import styled, { EmotionStyle } from '@/core/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import PlanDescription from '../PlanDescription';
import CreditCardDetails from '../CreditCardDetails';
import CouponInput from '../CouponInput';

const PlanReviewCardWrapper = styled(View)<{
  style?: EmotionStyle;
}>(({ theme: { window } }) => {
  const { isMobile, isLarge } = window;
  const nonMobileHorizontalPadding = isLarge ? 20 : 30;
  const nonMobileWidth = isLarge ? 375 : 475;
  return isMobile
    ? {
        position: 'relative',
        alignItems: 'stretch',
        paddingTop: 24,
        paddingRight: 0,
        paddingBottom: 24,
        paddingLeft: 0,
        width: '100%',
        borderStyle: 'solid',
        borderColor: '#E0E8F3',
        borderWidth: 0,
        borderTopWidth: 1,
        borderBottomWidth: 1,
      }
    : {
        position: 'relative',
        alignItems: 'stretch',
        minWidth: nonMobileWidth,
        borderRadius: 5,
        paddingTop: 44,
        paddingLeft: nonMobileHorizontalPadding,
        paddingBottom: 50,
        paddingRight: nonMobileHorizontalPadding,
        ...webOnlyStyle({
          border: '1px solid #E0E8F3',
          outline: 'none',
          textAlign: 'unset',
        }),
      };
});

const Section = styled(View)<{ removeMargin?: boolean }>(({ removeMargin }) => {
  return {
    marginBottom: removeMargin ? 0 : 25,
  };
});

const SectionHeader = styled(Tiny)({
  marginBottom: 3,
});

const PlanTitleWrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    flexDirection: isMobile ? 'column' : 'row',
  };
});

const PlanTitle: FunctionComponent<{
  discountPercent?: string;
  displayName: string;
  billingPrice: BillingPrice;
  initialCouponCode?: string;
}> = ({ discountPercent, displayName, billingPrice, initialCouponCode }) => (
  <Large variant="largeBoldWide">
    {discountPercent ? (
      <>
        {`${displayName}, ${billingPrice.cycleValue} ${capitalize(billingPrice.unit)}`}
        {initialCouponCode ? '' : ` - Save ${discountPercent.replace('off', '')}`}
      </>
    ) : (
      <>
        {displayName}
        {billingPrice.unit !== 'one-time' && billingPrice.cycleValue
          ? `, ${billingCycleValueDescription[billingPrice.cycleValue]}`
          : ''}
      </>
    )}
  </Large>
);

const GreenTextButton: FunctionComponent<{
  onPress: () => void;
  label: string;
}> = ({ onPress, label }) => {
  const { colors } = useEmotionTheme();
  return (
    <BaseButton
      style={{ borderRadius: 5 }}
      onPress={onPress}
      roundedFocusStyle
      primaryColor={colors.permaTalkspaceDarkGreen}
    >
      <Tiny
        style={{
          color: colors.accessibilityGreenDark,
          marginLeft: 5,
          fontSize: 14,
        }}
        variant="tinyBoldGreen"
      >
        {label}
      </Tiny>
    </BaseButton>
  );
};

const TherapistDetailsRow: FunctionComponent<{
  therapistDetails: {
    firstName: string;
    lastName: string;
    therapistID: number;
    therapistLicenses: string[];
    therapistImage: string;
  };
  onChangeTherapist: () => void;
}> = ({ therapistDetails: { firstName, lastName, therapistImage }, onChangeTherapist }) => (
  <View row justify="space-between">
    <View>
      <View row align="start">
        <SectionHeader>Provider</SectionHeader>
        <GreenTextButton label="Change" onPress={onChangeTherapist} />
      </View>
      <Large variant="largeBoldWide">
        {firstName} {lastName}
      </Large>
    </View>
    <Avatar image={therapistImage} height={67} width={67} />
  </View>
);

interface Props {
  title: string;
  initialCouponCode?: string;
  plan: PlanData;
  paymentDetails?: PaymentDetails;
  therapistDetails?: {
    firstName: string;
    lastName: string;
    therapistID: number;
    therapistLicenses: string[];
    therapistImage: string;
  };
  hideProviderInfo: boolean;
  couponState?: CouponState;
  onChangePlan?: () => void;
  onChangePayment: () => void;
  onChangeTherapist?: () => void;
  onApplyCoupon?: (code: string) => void;
  onCouponChange?: () => void;
  style?: EmotionStyle;
  showCouponField: boolean;
  isCouponInputDisabled?: boolean;
  modality?: SessionModality;
  flowID?: number | null;
  isBackButtonClickedInMobile?: boolean;
  isOutOfNetwork?: boolean;
}

const PlanReviewCard: FunctionComponent<Props> = ({
  title,
  plan: { displayName, description, sessionDetails, discountPercent, billingPrice },
  paymentDetails,
  therapistDetails,
  hideProviderInfo,
  onChangePayment,
  onChangePlan,
  onChangeTherapist,
  onApplyCoupon,
  style = {},
  initialCouponCode,
  showCouponField,
  isCouponInputDisabled,
  couponState,
  onCouponChange,
  modality,
  flowID,
  isBackButtonClickedInMobile,
  isOutOfNetwork,
}) => (
  <PlanReviewCardWrapper style={style}>
    {showCouponField && onApplyCoupon && couponState && (
      <Section>
        <SectionHeader>Apply a coupon</SectionHeader>
        <CouponInput
          initialValue={initialCouponCode}
          onApplyCoupon={onApplyCoupon}
          isDisabled={isCouponInputDisabled}
          couponState={couponState}
          onChange={onCouponChange}
          flowID={flowID}
          isBackButtonClickedInMobile={isBackButtonClickedInMobile}
          isOutOfNetwork={isOutOfNetwork}
        />
      </Section>
    )}
    {!hideProviderInfo &&
      therapistDetails &&
      therapistDetails.therapistID > 0 &&
      onChangeTherapist && (
        <Section>
          <TherapistDetailsRow
            therapistDetails={therapistDetails}
            onChangeTherapist={onChangeTherapist}
          />
        </Section>
      )}

    <Section>
      <View row align="start">
        <SectionHeader>{title}</SectionHeader>
        {onChangePlan && <GreenTextButton label="Change" onPress={onChangePlan} />}
      </View>
      <PlanTitleWrapper>
        <PlanTitle
          discountPercent={discountPercent}
          displayName={(modality && sessionDetails?.[modality]?.title) || displayName}
          billingPrice={billingPrice}
          initialCouponCode={initialCouponCode}
        />
      </PlanTitleWrapper>
      <PlanDescription
        description={
          modality && sessionDetails?.[modality]?.subtitle
            ? [
                {
                  text: sessionDetails[modality].subtitle,
                  style: DescriptionStyle.none,
                },
              ]
            : description
        }
      />
    </Section>
    {paymentDetails && (
      <Section removeMargin>
        <View row align="start" justify="space-between">
          <SectionHeader>Payment details</SectionHeader>
          <GreenTextButton label="Change" onPress={onChangePayment} />
        </View>
        <CreditCardDetails paymentDetails={paymentDetails} cardColor="#2C46A4" />
      </Section>
    )}
  </PlanReviewCardWrapper>
);

export default PlanReviewCard;
