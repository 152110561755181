import { Button } from '@talkspace/react-toolkit';
import { SVGConfig } from '@talkspace/react-toolkit/src/designSystems/components/Button/Button';
import { IconProps } from '@talkspace/react-toolkit/src/designSystems/icons';

const BookingsListControlButton = ({
  Icon,
  text,
  onPress,
  dataQa,
}: {
  Icon: (props: IconProps) => JSX.Element;
  dataQa?: string;
  text: string;
  onPress: () => void;
}) => {
  const iconConfig: SVGConfig = { path: ['fill'] };
  return (
    <Button
      dataQa={dataQa}
      sizeDS="slim"
      variant="tertiary"
      Icon={() => <Icon colorType="brand" />}
      iconConfig={iconConfig}
      text={text}
      onPress={onPress}
    />
  );
};

export default BookingsListControlButton;
