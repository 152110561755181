import { FunctionComponent } from 'react';
import { View, Large, Standard, useEmotionTheme } from '@talkspace/react-toolkit';
import styled from '../../../core/styled';

const Wrapper = styled(View)({
  paddingTop: 6,
  paddingBottom: 6,
  width: 'calc(100% - 50px)',
});

const Subtitle = styled(View)<{ isMobile: boolean; height: string | number }>(
  ({ isMobile, height }) => {
    return {
      width: 'auto',
      maxWidth: isMobile ? 278 : 409,
      height,
    };
  }
);

const CustomStandardText = styled(Standard)<{ isMobile: boolean }>(
  ({ isMobile, theme: { colors } }) => {
    return {
      fontSize: isMobile ? 13 : 15,
      color: colors.osloGrey,
      fontWeight: 300,
      maxWidth: isMobile ? 256 : 278,
    };
  }
);

interface Props {
  title: string;
  subTitle: string;
  isMobile: boolean;
  isOpen?: boolean;
  height?: number | string;
}

const NotificationTitle: FunctionComponent<Props> = ({
  title,
  subTitle,
  isMobile,
  isOpen = false,
  height = 'auto',
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Wrapper>
      <Large>{title}</Large>
      <Subtitle isMobile={isMobile} height={height}>
        {isOpen ? (
          <Large style={{ color: colors.darkGray }}>{subTitle}</Large>
        ) : (
          <CustomStandardText isMobile={isMobile}>{subTitle}</CustomStandardText>
        )}
      </Subtitle>
    </Wrapper>
  );
};

export default NotificationTitle;
