import { useEffect, useState } from 'react';

import { UserRepository } from '@amityco/ts-sdk';

interface Props {
  userID: string | undefined;
}
const useCommunityUser = ({ userID }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [user, setUser] = useState<Amity.User>();

  useEffect(() => {
    if (userID) {
      const unsubscribe = UserRepository.getUser(userID, ({ data, loading, error }) => {
        setIsLoading(loading);
        setIsError(error);
        setUser(data);
      });
      unsubscribe();
    }
  }, [userID]);

  return { user, isLoading, isError };
};

export default useCommunityUser;
