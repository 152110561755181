import { FunctionComponent, useEffect } from 'react';
import { View } from '@talkspace/react-toolkit';

import { useStripeTs } from 'stripe/stripeContext';
import { PaymentDetails as PaymentDetailsView } from 'checkout';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import useAccountDetails from '@/myAccount/hooks/useAccountDetails';
import { deleteLastElementInPath } from '../../utils/url';
import { ConfirmBookingOptions } from '../../types';
import {
  useInRoomSchedulingState,
  useInRoomSchedulingActions,
} from '../../hooks/inRoomSchedulingContext';
import useRepeatingBooking from '../../hooks/useRepeatingBooking';
import InRoomSchedulingError from '../inRoomSchedulingError';

const getSubmitText = ({ isBH, isBHAdHoc }: { isBH: boolean; isBHAdHoc: boolean }): string => {
  let submitText = 'Complete purchase';

  if (isBH) {
    submitText = 'Book session';
  }

  if (isBHAdHoc) {
    submitText = 'Join session';
  }

  return submitText;
};

interface OwnProps {
  handleConfirmAppointment: (options: ConfirmBookingOptions) => void;
  onClosePress: () => void;
  useStripeLink: boolean;
  onLoadStripeLink: () => void;
  onStripeLinkError: (error: any) => void;
  bookingSuccessRoute: 'confirm-booking' | 'booking-success';
}

type Props = OwnProps & RouteComponentProps;

const PaymentDetails: FunctionComponent<Props> = ({
  match,
  history,
  handleConfirmAppointment,
  onClosePress,
  useStripeLink,
  onStripeLinkError,
  onLoadStripeLink,
  bookingSuccessRoute,
}) => {
  const {
    selectedCreditOption,
    planInfo,
    savings,
    shouldShowBookingSuccess,
    isLoading,
    isError,
    errorMessage,
    modality,
    total,
    room,
    isBHAdHoc,
    selectedConfirmBooking,
  } = useInRoomSchedulingState();

  const { dispatchResetError } = useInRoomSchedulingActions();

  const { isRecurringBooking } = useRepeatingBooking({
    selectedBooking: selectedConfirmBooking,
    onlyConfirmedOrTentative: true,
  });

  const [{ paymentDetails }] = useAccountDetails();

  const stripe = useStripeTs();

  const isBH = Boolean(room?.isSessionBased);

  useEffect(() => {
    if (shouldShowBookingSuccess) {
      history.push(`${deleteLastElementInPath(match.url)}/${bookingSuccessRoute}`);
    }
  }, [bookingSuccessRoute, history, match.url, shouldShowBookingSuccess]);

  if (!selectedCreditOption) {
    history.push(deleteLastElementInPath(match.url));
    return null;
  }

  const handleSubmit = (token: string) => {
    handleConfirmAppointment({
      isPurchase: true,
      isReschedule: false,
      shouldRedeemCredit: false,
      creditCardToken: token,
      modality,
      isRecurringBooking,
    });
  };

  if (isError && !errorMessage) {
    return <InRoomSchedulingError onClosePress={onClosePress} />;
  }

  return (
    <View align="center" style={{ marginTop: 30, paddingLeft: 16, paddingRight: 16 }}>
      <PaymentDetailsView
        onSubmit={handleSubmit}
        resetError={dispatchResetError}
        provider={paymentDetails?.provider || 'Stripe'}
        currency={planInfo?.billingPrice.currency || 'USD'}
        submitText={getSubmitText({ isBH, isBHAdHoc })}
        total={total}
        savings={savings}
        isProcessing={isLoading}
        totalsVariant={isBH ? 'none' : 'standard'}
        errorMessage={errorMessage}
        stripe={stripe}
        useStripeLink={useStripeLink}
        onStripeLinkError={onStripeLinkError}
        onLoadStripeLink={onLoadStripeLink}
        showSavings={!isBH}
        totalDueText={isBH ? 'Co-pay due today' : undefined}
      />
    </View>
  );
};

export default withRouter(PaymentDetails);
