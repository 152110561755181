import { Button, TextArea, View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';

export const TopRow = styled(View)<{ marginBottom: number }>(({ marginBottom }) => {
  return {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    height: 39,
    marginBottom,
  };
});

export const LeftCol = styled(View)<{ isVisible: boolean }>(({ isVisible = false }) => {
  return {
    flexDirection: 'column',
    height: 14,
    width: 8,
    visibility: isVisible ? 'visible' : 'hidden',
  };
});

export const RightCol = styled(View)({
  flexDirection: 'column',
  alignItems: 'center',
  width: 326,
});

export const SecondaryButton = styled(Button)<{
  displayStyle?: string;
}>(({ displayStyle = 'secondary', theme: { colors } }) => {
  return {
    width: 320,
    height: 'auto',
    backgroundColor: displayStyle === 'primary' ? colors.permaTalkspaceDarkGreen : colors.white,
    border: displayStyle === 'secondaryWithBorder' ? `1px solid ${colors.slateGrey}` : '',
    color: displayStyle === 'primary' ? colors.white : colors.slateGrey,
    fontWeight: displayStyle === 'primary' ? 700 : undefined,
    letterSpacing: displayStyle === 'primary' ? 0.6 : undefined,
  };
});

export const TitlesView = styled(View)({
  minHeight: 76,
  textAlign: 'center',
  width: 'auto',
});

export const MultiLineTextArea = styled(TextArea)(({ theme: { colors } }) => {
  return {
    width: '100%',
    height: 130,
    maxHeight: 130,
    resize: 'none',
    marginBottom: 35,
    padding: 20,
    borderRadius: 10,
    fontSize: 17,
    borderStyle: 'solid',
    borderColor: colors.periwinkleGrey,
    borderWidth: 1,
    ...webOnlyStyle({
      boxSizing: 'border-box',
      ':focus': {
        outline: 'none',
        caretColor: colors.green,
      },
      '::placeholder': {
        color: colors.placeholderGrey,
        fontSize: 17,
      },
    }),
  };
});

export const DateInputStyles = (
  borderColor: string,
  caretColor: string,
  placeHolderColor: string
) => {
  return {
    height: 50,
    borderRadius: 10,
    borderStyle: 'solid',
    borderColor,
    borderWidth: 0.5,
    padding: 20,
    ...webOnlyStyle({
      boxSizing: 'border-box',
      ':focus': {
        outline: 'none',
        caretColor,
      },
      '::placeholder': {
        color: placeHolderColor,
      },
    }),
  };
};

export const NativeDateInputStyles = (
  backgroundColor: string,
  borderColor: string,
  caretColor: string,
  placeHolderColor: string
) => {
  return {
    width: '100%',
    letterSpacing: '-1px',
    backgroundColor,
    mozAppearance: 'none',
    webkitAppearance: 'none',
    outline: 'none',
    ...DateInputStyles(borderColor, caretColor, placeHolderColor),
  };
};
