import {
  Button,
  SimpleHeader,
  Spinner,
  StickyDrawer,
  TextDS,
  View,
  spacing,
  OneColumnLayout,
  ResponsiveLayoutWithHeader,
} from '@talkspace/react-toolkit';

import HandWithHeartIllustration from '../assets/illustrations/HandWithHeartIllustration';

const { space050, space150, space200, space300, space400 } = spacing;

interface Props {
  isLoading: boolean;
  userPostCount: number | undefined;
  communityDisplayString: string;
  onPressShareWithTherapist: () => void;
  onPressSkip: () => void;
  onClose: () => void;
}
const CommunityCreatePostSuccess = ({
  isLoading,
  userPostCount,
  communityDisplayString,
  onPressShareWithTherapist,
  onPressSkip,
  onClose,
}: Props) => (
  <ResponsiveLayoutWithHeader
    renderHeader={() => <SimpleHeader title="Teenspace" onClose={onClose} />}
  >
    <OneColumnLayout>
      <View align="center" justify="space-between" style={{ height: '100%' }}>
        <TextDS variant="heading2xl" style={{ marginTop: space400 }}>
          Great work!
        </TextDS>
        <View style={{ marginTop: space150 }}>
          <TextDS inline style={{ textAlign: 'center' }}>
            So far, you’ve shared{' '}
            {isLoading || !userPostCount
              ? null
              : `${userPostCount} ${userPostCount > 1 ? 'posts' : 'post'}`}{' '}
            in the {communityDisplayString} community. Do you want to share it with your therapist,
            too?
          </TextDS>
        </View>
        <View style={{ position: 'relative', marginTop: space300 }}>
          <HandWithHeartIllustration />
          <View align="center" style={{ width: '100%', position: 'absolute', top: 75 }}>
            {/* // TODO: custom decorative font for the number */}
            {isLoading ? (
              <Spinner />
            ) : (
              <TextDS variant="heading3xl" colorRole="textInverse">
                {userPostCount}
              </TextDS>
            )}
          </View>
        </View>
        <StickyDrawer noBorder contentWrapperStyle={{ padding: `${space300}px 0` }}>
          <OneColumnLayout>
            <Button
              style={{ marginTop: space200, width: '100%', maxWidth: undefined }}
              text="Share with therapist"
              onPress={onPressShareWithTherapist}
            />
            <Button
              style={{ marginTop: space050, width: '100%', maxWidth: undefined }}
              variant="tertiary"
              text="Maybe later"
              onPress={onPressSkip}
            />
          </OneColumnLayout>
        </StickyDrawer>
      </View>
    </OneColumnLayout>
  </ResponsiveLayoutWithHeader>
);

export default CommunityCreatePostSuccess;
