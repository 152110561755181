import {
  BaseButton,
  Button,
  ExtraHuge,
  Large,
  SparklingEnvelope,
  Standard,
  View,
} from '@talkspace/react-toolkit';
import { useEffect } from 'react';

import { trackBookFirstSessionPrompt } from '../../utils/analytics/events';
import MeetYourProviderWrapper from './MeetYourProviderWrapper';

interface Props {
  roomID: number | null;
  handleStartMessagingSessionClick: () => void;
  handleBookLiveSessionClick: () => void;
}

const BookAsyncSessionView = ({
  roomID,
  handleBookLiveSessionClick,
  handleStartMessagingSessionClick,
}: Props) => {
  useEffect(() => {
    if (roomID) {
      trackBookFirstSessionPrompt({
        roomID,
        modalityNudge: 'Match Based on Availability',
      });
    }
  }, [roomID]);

  return (
    <MeetYourProviderWrapper titleText="Book Session">
      <View align="center" justify="space-between" style={{ maxWidth: 335, textAlign: 'center' }}>
        <View style={{ marginBottom: 60 }}>
          <SparklingEnvelope />
        </View>
        <ExtraHuge style={{ marginBottom: 12 }}>Book your first messaging session</ExtraHuge>
        <Large variant="largeDarkGrey" style={{ marginBottom: 30 }}>
          Due to a nationwide shortage of Providers and live availability, the fastest path to
          getting help is messaging therapy
        </Large>
        <Button
          dataQa="startAMessagingSessionButton"
          disabled={!roomID}
          text="Start a messaging session"
          onClick={handleStartMessagingSessionClick}
          style={{ width: '100%', marginBottom: 24 }}
        />
        <BaseButton
          dataQa="bookALiveSessionButton"
          disabled={!roomID}
          onClick={handleBookLiveSessionClick}
          style={{ width: '100%' }}
        >
          <Standard variant="standardBoldGreen">Book a live session</Standard>
        </BaseButton>
      </View>
    </MeetYourProviderWrapper>
  );
};

export default BookAsyncSessionView;
