import { useMemo } from 'react';

import { OverflowMenu, View } from '@talkspace/react-toolkit';

import { useCommunityState } from '../hooks/CommunityContext';
import useIsCommunityEntityReported from '../hooks/useIsCommunityEntityReported';
import { deletePost, deleteComment, createReport } from '../utils/amity';
import { CommunityReferenceTypeEnum } from '../types';
import useSharePostWithTherapist from '../hooks/useSharePostWithTherapist';

interface Props {
  reference: Amity.Post | Amity.Comment;
  referenceType: CommunityReferenceTypeEnum;
}
const CommunityReferenceThreeDotMenu = ({ reference, referenceType }: Props) => {
  const { userID, dailyPrompt } = useCommunityState();
  const {
    _id: referenceID,
    creator: { userId: creatorUserID },
    flagCount,
  } = reference;

  const { isReportedByMe } = useIsCommunityEntityReported({
    referenceType,
    referenceID,
    flagCount,
  });
  const isOwnReference = useMemo(() => String(userID) === creatorUserID, [userID, creatorUserID]);

  const { isLoading: isLoadingShareWithTherapist, sharePostWithTherapist } =
    useSharePostWithTherapist({
      userID,
      dailyPrompt,
      responseText: reference.data.text,
    });

  const menuOptions = useMemo(() => {
    if (isOwnReference) {
      if (referenceType === CommunityReferenceTypeEnum.POST) {
        return [
          {
            label: 'Delete post',
            dataQa: 'deletePost',
            callback: async () => {
              await deletePost({ postID: referenceID });
            },
          },
          {
            label: 'Share with my therapist',
            dataQa: 'sharePostWithTherapist',
            callback: async () => {
              if (!isLoadingShareWithTherapist) {
                await sharePostWithTherapist();
              }
            },
          },
        ];
      }

      if (referenceType === CommunityReferenceTypeEnum.COMMENT) {
        return [
          {
            label: 'Delete comment',
            dataQa: 'deleteComment',
            callback: async () => {
              await deleteComment({ commentID: referenceID });
            },
          },
        ];
      }
    }

    if (!isReportedByMe) {
      if (referenceType === CommunityReferenceTypeEnum.POST) {
        return [
          {
            label: 'Report post',
            dataQa: 'reportPost',
            callback: async () => {
              await createReport({ referenceType, referenceID });
            },
          },
        ];
      }
      if (referenceType === CommunityReferenceTypeEnum.COMMENT) {
        return [
          {
            label: 'Report comment',
            dataQa: 'reportComment',
            callback: async () => {
              await createReport({ referenceType, referenceID });
            },
          },
        ];
      }
    }
    return [];
  }, [
    isLoadingShareWithTherapist,
    isOwnReference,
    isReportedByMe,
    referenceID,
    referenceType,
    sharePostWithTherapist,
  ]);

  if (menuOptions.length === 0) return <View style={{ width: 28, height: 28 }} />;

  return <OverflowMenu options={menuOptions} dataQa={`${referenceType}-three-dots-menu`} />;
};

export default CommunityReferenceThreeDotMenu;
