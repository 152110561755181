import { ReactNode, useEffect, useState, MouseEvent } from 'react';
import styled, { EmotionStyle } from '../../core/styled';
import View from '../View';
import CloseButton from '../CloseButton';
import TouchableView from '../TouchableView';
import BaseButton from '../BaseButton';
import { ExtraBig } from '../Typography';
import { ChevronLeft, IconColorType } from '../../designSystems/icons';

interface AnimatedSwipeDownModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  containerStyles?: EmotionStyle;
  closeModalText?: 'close' | 'cancel' | 'dismiss';
  closeTextStyle?: EmotionStyle;
  isDraggable?: boolean;
  shouldDisplayCloseButton?: boolean;
  title?: string;
  onBackButtonPress?: () => void;
  iconsColorType?: IconColorType;
}

const Wrapper = styled(View)<{ isOpen: boolean }>(({ isOpen }) => {
  return {
    background: 'rgba(104, 118, 141, 0.5)',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    position: 'fixed',
    display: isOpen ? 'flex' : 'none',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    zIndex: '99 !important',
    overflow: 'hidden',
  };
});

const Container = styled(View)<{ isOpen: boolean }>(({ isOpen, theme: { colors } }) => {
  return {
    background: colors.white,
    width: '100%',
    borderRadius: '20px 20px 0px 0px',
    padding: 25,
    flexDirection: 'column',
    paddingTop: 55,
    position: 'relative',
    zIndex: 9999,
    maxHeight: isOpen ? 932 : 0,
    transition: 'max-height 0.6s ease-out',
    WebkitTransition: 'max-height 0.2s ease-out',
    WebkitUserSelect: 'none',
    userSelect: 'none',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    WebkitOverflowScrolling: 'touch',
  };
});

const CloseText = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 700,
    color: colors.permaGrayLike,
    marginTop: 40,
    textTransform: 'capitalize',
  };
});

const closeButtonStyle = {
  position: 'absolute',
  left: 'auto',
  right: 10,
  top: 10,
};

const backButtonStyle = { position: 'absolute', right: 'auto', left: 16, top: 16 };

const AnimatedSwipeDownModal = ({
  isOpen,
  onClose,
  children,
  containerStyles,
  closeModalText,
  closeTextStyle,
  isDraggable = true,
  shouldDisplayCloseButton = true,
  title,
  onBackButtonPress,
  iconsColorType = 'default',
}: AnimatedSwipeDownModalProps) => {
  const [startY, setStartY] = useState<number>(0);
  const [currentY, setCurrentY] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setIsExpanded(isOpen);
    }, 100);
  }, [isOpen]);

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    if (isDraggable) {
      setStartY(event.touches[0].clientY);
      setIsDragging(false);
    }
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (isDraggable) {
      setCurrentY(event.touches[0].clientY);
      setIsDragging(true);
    }
  };

  const handleTouchEnd = () => {
    if (isDragging) {
      setIsDragging(false);
      const distance = currentY - startY;

      // Calculate the distance proportionally to the screen height
      const screenHeight = window.innerHeight;
      const closingDistance = screenHeight * 0.1; // 10% of the screen height

      // Close the modal if the user swiped down by at least the calculated closing distance
      if (distance > closingDistance) {
        setStartY(0);
        setCurrentY(0);
        onClose();
      }
    }
  };

  const handleTouchCancel = () => {
    setIsDragging(false);
  };

  const handleCloseModal = (e: MouseEvent) => {
    e.preventDefault();

    onClose();
  };

  return (
    <Wrapper isOpen={isOpen} onClick={handleCloseModal} tabIndex={0}>
      <Container
        isOpen={isExpanded}
        style={{
          transform: `translateY(${Math.max(0, currentY - startY)}px)`,
          ...containerStyles,
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchCancel}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        {isExpanded && (
          <>
            {onBackButtonPress && (
              <BaseButton style={backButtonStyle} onPress={onBackButtonPress}>
                <ChevronLeft size="major" colorType={iconsColorType} />
              </BaseButton>
            )}

            {shouldDisplayCloseButton && (
              <CloseButton
                onPress={handleCloseModal}
                style={closeButtonStyle}
                xColorType={iconsColorType}
              />
            )}

            {title && (
              <View row justify="center" align="center">
                <ExtraBig>{title}</ExtraBig>
              </View>
            )}

            {children}

            {closeModalText && (
              <CloseText style={closeTextStyle} onPress={handleCloseModal}>
                {closeModalText}
              </CloseText>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  );
};
export default AnimatedSwipeDownModal;
