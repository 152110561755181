import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';

const patchClientInfo = (clientUserID: number, updatedClientInfo: object) =>
  apiWrapper.patch(`${apiHelper().apiEndpoint}/api/v1/clients/${clientUserID}`, updatedClientInfo);

export const allowReview = async (roomID: number): Promise<{ allow: boolean }> =>
  apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/allow-review`)
    .then((res) => res.data.data)
    .catch(() => {
      return { allow: false };
    });

interface MatchingLanguage {
  id: number;
  name: string;
}
interface PostLanguagesForMatchingResponse {
  data: {
    languages: MatchingLanguage[];
  };
}

interface PostLanguagesForMatchingPayload {
  clientState?: string;
  clientCountry: string;
}

export const postLanguagesForMatching = (
  roomID: number,
  payload: PostLanguagesForMatchingPayload
) =>
  apiWrapper.post<PostLanguagesForMatchingResponse>(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/languages-for-matching`,
    payload
  );

const switchWizardApiHelper = {
  patchClientInfo,
};

export default switchWizardApiHelper;
