import { FunctionComponent } from 'react';

import { ExtraTiny, Link, useEmotionTheme } from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import styled from '@/core/styled';
import BaseAccordion from './Base';
import NotificationTitle from '../NotificationTitle';
import NotificationPanelRow from '../NotificationPanelRow';
import { EmailEnvelope, CellPhone, DailyReminder } from '../../../components/icons';
import { useClientAuthState } from '../../../hooks/clientAuthContext';

const arrowContainerStyles = {
  marginTop: 21,
};

const Accordion = styled(BaseAccordion)<{
  isMobile: boolean;
  useNewNav: boolean;
}>(({ isMobile, useNewNav, theme: { colors } }) => {
  const oldNavStyling = {
    borderBottomLeftRadius: isMobile ? 0 : 10,
    borderBottomRightRadius: isMobile ? 0 : 10,
    borderBottom: `1px solid ${colors.iconLightGrey}`,
  };
  const newNavStyling = {
    borderBottom: 0,
  };
  return useNewNav ? newNavStyling : oldNavStyling;
});

interface Props {
  isOpen: boolean;
  handlePress: (value: boolean) => void;
  isMobile: boolean;
  marketingPromotions: { email: boolean; push: boolean; sms: boolean };
  marketingNews: { email: boolean; push: boolean };
  marketingEducation: { email: boolean; push: boolean };
  marketingBlog: { email: boolean; push: boolean };
  handleEmailToggle: (value: number) => void;
  handleSMSToggle: (value: number) => void;
  handlePushToggle: (value: number) => void;
  handlePromotionsEmailToggle: (value: number) => void;
  handleNewsEmailToggle: (value: number) => void;
  handleEducationEmailToggle: (value: number) => void;
  handleBlogEmailToggle: (value: number) => void;
}

const MarketingCommunicationsAccordion: FunctionComponent<Props> = ({
  isOpen,
  handlePress,
  isMobile,
  marketingPromotions,
  marketingNews,
  marketingEducation,
  marketingBlog,
  handleEmailToggle,
  handleSMSToggle,
  handlePushToggle,
  handlePromotionsEmailToggle,
  handleNewsEmailToggle,
  handleEducationEmailToggle,
  handleBlogEmailToggle,
}) => {
  const { colors, colorRoles } = useEmotionTheme();
  const {
    adminConfigs: { isCognitoLoginEnabled },
  } = useClientAuthState();
  const marketingDescription = [
    marketingNews,
    marketingPromotions,
    marketingBlog,
    marketingEducation,
  ].some(({ email }) => email)
    ? 'On'
    : 'Off';
  const useNewNav = useNewMemberNav();

  return (
    <Accordion
      isMobile={isMobile}
      useNewNav={useNewNav}
      open={isOpen}
      onPress={handlePress}
      disabled={false}
      titleComponent={() => (
        <NotificationTitle
          title="Marketing communications"
          isOpen={isOpen}
          isMobile={isMobile}
          subTitle={
            isOpen ? 'Receive promotions, feature news, and content.' : marketingDescription
          }
        />
      )}
      arrowStyles={{
        fill: useNewNav ? colorRoles.icons.iconDefaultDefault : colors.periwinkleGrey,
      }}
      arrowContainerStyles={arrowContainerStyles}
    >
      {isCognitoLoginEnabled ? (
        <>
          <NotificationPanelRow
            label="Push"
            iconComponent={() => <DailyReminder />}
            isOn={marketingPromotions.push}
            onToggle={handlePushToggle}
            name="sessionRemindersPush"
          />
          <NotificationPanelRow
            label="Email"
            iconComponent={() => <EmailEnvelope />}
            isOn={marketingPromotions.email}
            onToggle={handleEmailToggle}
            name="news"
          />
          <NotificationPanelRow
            label="SMS"
            iconComponent={() => <CellPhone style={{ marginRight: 7 }} />}
            isOn={marketingPromotions.sms}
            onToggle={handleSMSToggle}
            name="promotions"
          />
          <ExtraTiny style={{ marginLeft: 34, marginBottom: 16 }} inline>
            By enabling SMS, I agree to receive recurring marketing and promotional text messages
            (SMS and MMS) from Talkspace up to 5msgs/mo. Message and data rates may apply. For more
            information, see our
            <Link
              target="_blank"
              href="https://www.talkspace.com/public/terms"
              style={{ color: colors.green, fontWeight: 700 }}
              roundedFocusStyle
              primaryColor={colors.green}
            >
              Terms of Use
            </Link>
            and
            <Link
              target="_blank"
              href="https://www.talkspace.com/public/privacy-policy"
              style={{ color: colors.green, fontWeight: 700 }}
              roundedFocusStyle
              primaryColor={colors.green}
            >
              Privacy Policy
            </Link>
            .
          </ExtraTiny>
        </>
      ) : (
        <>
          <NotificationPanelRow
            label="Promotions and events"
            isOn={marketingPromotions.email}
            onToggle={handlePromotionsEmailToggle}
            name="promotions"
          />
          <NotificationPanelRow
            label="Product news"
            isOn={marketingNews.email}
            onToggle={handleNewsEmailToggle}
            name="news"
          />
          <NotificationPanelRow
            label="Therapy tips"
            isOn={marketingEducation.email}
            onToggle={handleEducationEmailToggle}
            name="tips"
          />
          <NotificationPanelRow
            label="Blog updates"
            isOn={marketingBlog.email}
            onToggle={handleBlogEmailToggle}
            name="updates"
          />
        </>
      )}
    </Accordion>
  );
};

export default MarketingCommunicationsAccordion;
