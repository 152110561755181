import { useEffect } from 'react';
import { trackBookFirstSessionPrompt } from '../../utils/analytics/events';
import { useHistory, useParams } from '@/core/routerLib';
import OnboardingPage from './OnboardingPage';
import BookFirstSession from '../assets/BookFirstSession';
import CameraSparkles from '../assets/CameraSparkles';
import { useOnboardingState } from '../hooks/onboardingContext';

const OnboardingPageBookFirstSession = ({
  totalSteps,
  currentStep,
}: {
  totalSteps: number;
  currentStep: number;
}) => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string | undefined }>();
  const { steps } = useOnboardingState();
  const { metadata: { isMessagingFirstVideoRequired = false } = {} } = steps
    ? steps[currentStep]
    : {};

  useEffect(() => {
    if (roomID) {
      trackBookFirstSessionPrompt({
        roomID: Number(roomID),
        modalityNudge: 'Match Based on Availability',
      });
    }
  }, [roomID]);

  let illustration = <BookFirstSession />;
  let title = 'Book your first session';
  let description =
    'Direct interaction with your provider is clinically proven to help you quickly form a meaningful bond with them.';
  // for messaging only plans in states that require video show different intro information
  if (isMessagingFirstVideoRequired) {
    illustration = <CameraSparkles />;
    title = 'Book your intro video call';
    description =
      'Your state requires you to meet your provider over video before messaging therapy begins. Direct interaction with your provider is clinically proven to help you quickly form a meaningful bond with them.';
  }
  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={currentStep}
      illustration={illustration}
      title={title}
      description={description}
      ctaText="Continue"
      onPress={() => history.push(`/room/${roomID}/onboarding/book-session`)}
    />
  );
};

export default OnboardingPageBookFirstSession;
