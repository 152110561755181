import {
  EmotionThemeProvider,
  Grid,
  Row,
  Col,
  SimpleHeader,
  ResponsiveLayoutWithHeader,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { Route, Switch, useHistory } from '../../core/routerLib';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import TeenspaceProfileSetupContainer from './containers/TeenspaceProfileSetupContainer';
import TeenspaceWelcomeScreen from './components/TeenspaceWelcomeScreen';
import Guidelines from './components/TeenspaceCommunityGuidelines';
import DailyQuestion from './components/DailyQuestion';

const Routes = () => {
  const { isMobile, isTablet } = useWindowWidthState();
  const closeModal = useCloseModal();
  const history = useHistory();
  const handleBackButtonClick = () => {
    history.goBack();
  };
  const columnParams = { xs: 4, sm: 6, lg: 4 };
  const isInWelcomeScreen = history.location.pathname === '/community/teenspace/onboarding/welcome';
  const showBackButton =
    isMobile && !isInWelcomeScreen
      ? history.location.pathname.includes('/community/teenspace/onboarding')
      : false;
  return (
    <EmotionThemeProvider version="2.0.0">
      <ResponsiveLayoutWithHeader
        renderHeader={() => (
          <SimpleHeader
            onBack={showBackButton ? handleBackButtonClick : undefined}
            title="Teenspace"
            onClose={() => closeModal({ navigateTo: 'home', metadata: { path: '/' } })}
          />
        )}
      >
        <Grid style={{ height: '100%' }}>
          <Row style={{ height: '100%' }}>
            {(!isMobile || isTablet) && (
              <Col xs={0} sm={1} lg={4}>
                {null}
              </Col>
            )}
            <Col {...columnParams}>
              <Switch>
                <Route
                  exact
                  path="/community/teenspace/onboarding/welcome"
                  component={TeenspaceWelcomeScreen}
                />
                <Route
                  exact
                  path="/community/teenspace/onboarding/sign-up"
                  component={TeenspaceProfileSetupContainer}
                />
                <Route
                  exact
                  path="/community/teenspace/onboarding/daily-question"
                  component={DailyQuestion}
                />
                <Route
                  exact
                  path="/community/teenspace/onboarding/guidelines"
                  component={Guidelines}
                />
              </Switch>
            </Col>
          </Row>
        </Grid>
      </ResponsiveLayoutWithHeader>
    </EmotionThemeProvider>
  );
};

export default Routes;
