import configs from '../../configs';

const initSDK = (callback?: (success: boolean) => void) => {
  const d = document;
  const g = d.createElement('script');
  const s = d.getElementsByTagName('script')[0];
  g.type = 'text/javascript';
  g.defer = true;
  g.src = `https://maps.googleapis.com/maps/api/js?key=${configs.googleMaps.publicKey}&libraries=places`;
  if (callback) g.onload = () => callback(true);
  if (callback) g.onerror = () => callback(false);
  if (s.parentNode) s.parentNode.insertBefore(g, s);
};

const googleMapSetup = {
  initSDK,
};

export default googleMapSetup;
