import {
  Grid,
  Col,
  View,
  SpacingView,
  Card,
  CardContent,
  TextDS,
  SwitchCard,
  spacing,
  Spinner,
} from '@talkspace/react-toolkit';
import styled from 'core/styled';
import AppDetails from 'ts-ionic/components/AppDetails';
import { NativeFeaturesEnum, shouldShowNativeFeature } from 'ts-ionic';
import { profileCards, planAndCoverageCards, settingsCards, moreCards } from './cards';
import { AccountScreenProps } from '../../types';
import { trackCTAClick } from '../../../utils/analytics/events';
import AccountScreenBannerPrompt from './AccountScreenBannerPrompt';

const { space400, space200 } = spacing;
const HeaderStyledView = styled(View)({
  marginTop: space200,
  marginBottom: space400,
});

const AccountScreen: React.VFC<AccountScreenProps> = ({
  hasPendingMatchRoom,
  isHighContrast,
  isMobile,
  isLoadingUserSettings,
  userSettings,
  onPressReadReceipts,
  onPressColorContrast,
  onPress,
}) => {
  if (isLoadingUserSettings) return <Spinner />;
  return (
    <Grid fluid rowSpacing={isMobile ? 'space0' : 'space400'}>
      <Col sm={8} lg={8}>
        <SpacingView itemSpacing="space200" row={false}>
          <TextDS variant="heading2xl">Account</TextDS>
          {shouldShowNativeFeature(NativeFeaturesEnum.PUSH_NOTIFICATIONS) && (
            <AccountScreenBannerPrompt isMobile={isMobile} />
          )}
          <HeaderStyledView>
            <SpacingView itemSpacing="space0" row={false}>
              <TextDS variant="headingLg">Profile</TextDS>
              {profileCards.map((card) => (
                <Card variant="noBorder" padding="slim" onPress={() => onPress(card)} {...card}>
                  <CardContent isAccountCard variant="slim" {...card} />
                </Card>
              ))}
            </SpacingView>
          </HeaderStyledView>
          <View>
            <SpacingView itemSpacing="space0" row={false}>
              <TextDS variant="headingLg">Plan and coverage</TextDS>
              {planAndCoverageCards.map((card) => {
                if (card.hideIfHasPendingMatchRoom && hasPendingMatchRoom) return null;
                return (
                  <Card variant="noBorder" padding="slim" onPress={() => onPress(card)} {...card}>
                    <CardContent variant="slim" {...card} />
                  </Card>
                );
              })}
            </SpacingView>
          </View>
          <SpacingView itemSpacing="space0" row={false}>
            <HeaderStyledView>
              <TextDS variant="headingLg">Settings</TextDS>
            </HeaderStyledView>
            <Card
              variant="noBorder"
              padding="slim"
              onPress={() => onPress(settingsCards[0])}
              {...settingsCards[0]}
            >
              <CardContent isAccountCard variant="slim" hideCaret={false} {...settingsCards[0]} />
            </Card>
            <SwitchCard
              isAccountCard
              cardVariant="noBorder"
              contentVariant="slim"
              isOn={!!userSettings?.sendMessagesReceipts}
              handleOnPress={onPressReadReceipts}
              forceColumn
              {...settingsCards[1]}
            />
            <SwitchCard
              isAccountCard
              cardVariant="noBorder"
              contentVariant="slim"
              isOn={isHighContrast}
              handleOnPress={onPressColorContrast}
              forceColumn
              {...settingsCards[2]}
            />
          </SpacingView>

          <SpacingView itemSpacing="space0" row={false}>
            <HeaderStyledView>
              <TextDS variant="headingLg">More</TextDS>
            </HeaderStyledView>
            {moreCards.map((card) => (
              <Card
                variant="noBorder"
                padding="slim"
                onPress={() => {
                  trackCTAClick(card.titleText, 'account');
                  onPress(card);
                }}
                {...card}
              >
                <TextDS variant="headingLg">
                  <CardContent isAccountCard variant="slim" {...card} />
                </TextDS>
              </Card>
            ))}
          </SpacingView>
          <AppDetails />
        </SpacingView>
      </Col>
    </Grid>
  );
};

export default AccountScreen;
