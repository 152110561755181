import { LiveSessionModality } from 'ts-frontend/types';
import { useEffect, useState, VFC } from 'react';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import {
  CalendarIcon,
  ArrowRight,
  VideoCalendar,
  AudioCalendar,
  ChatCalendar,
  FilledEnvelope,
  useEmotionTheme,
  ExclamationCircle,
  usePanelManagerActions,
  View,
} from '@talkspace/react-toolkit';

import { useHistory } from 'react-router';
import useIsClientDischargeV3OnForPlan from 'ts-frontend/hooks/useIsClientDischargeV3OnForPlan';

import { useNewMemberNav } from 'launchDarkly/hooks';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { Props as ChatBannerProps } from '../ChatBanner/ChatBanner';
import ChatBanner from '../ChatBanner';
import {
  TherapistType,
  SessionStatus,
  ChatBannerType,
  ClientReactivationAPIResponse,
  ClientAwaitingSessionResponse,
} from '../../redux/constants/chatTypes';
import useQueryParentalConsent from '../../../ts-frontend/hooks/parentalConsent/useQueryParentalConsent';
import useWindowWidth from '../../../../apps/client-web/src/hooks/useWindowWidth';

const modalityElement: Partial<Record<LiveSessionModality, VFC>> = {
  video: VideoCalendar,
  audio: AudioCalendar,
  chat: ChatCalendar,
};

interface Props {
  banner?: ChatBannerType;
  reactivationProps?: ClientReactivationAPIResponse;
  awaitingSessionProps?: ClientAwaitingSessionResponse;
  psychiatristReminderProps?: ChatBannerProps;
  sessionStatusProps?: SessionStatus;
  roomExpirationDate?: Date | null;
  onCancelPress?: () => void;
  therapistFirstName?: string;
  therapistType: TherapistType;
  onPress?: () => void;
  isTherapist?: boolean;
  therapistID?: number;
  roomID?: number | string;
  clientUserID?: number;
}

const ChatBannerManager = ({
  banner,
  onPress,
  onCancelPress,
  therapistFirstName,
  therapistType = 'primary',
  roomExpirationDate,
  awaitingSessionProps,
  psychiatristReminderProps,
  sessionStatusProps,
  isTherapist = false,
  therapistID,
  roomID,
  clientUserID,
}: Props) => {
  const isCT = therapistType.includes('consultation');
  const { colors } = useEmotionTheme();
  const [isBookingReminderEventSent, setIsBookingReminderEventSent] = useState(false);
  const history = useHistory();

  const { data: parentConsentData } = useQueryParentalConsent(clientUserID, isTherapist);

  const { isMobile } = useWindowWidth();

  const { setIsLeftPanelOnTop } = usePanelManagerActions();
  const isClientDischargeV3OnForPlan = useIsClientDischargeV3OnForPlan(roomID!);
  const useNewNav = useNewMemberNav();
  let twoFactorAuthStyle;
  if (useNewNav) {
    if (!isMobile) {
      twoFactorAuthStyle = { marginLeft: 220, justify: 'center' };
    } else {
      twoFactorAuthStyle = { marginLeft: 50, marginRight: 20, justify: 'center', width: '100%' };
    }
  }

  useEffect(() => {
    if (
      !isBookingReminderEventSent &&
      banner === 'sessionStatus' &&
      !sessionStatusProps?.currentMessagingSession &&
      !sessionStatusProps?.nextLiveSession &&
      !isTherapist &&
      therapistID &&
      roomID
    ) {
      trackEvent('Booking Reminder Sent', {
        source: 'In-room banner',
        therapistID,
        roomID,
      });
      setIsBookingReminderEventSent(true);
    }
  }, [
    banner,
    isBookingReminderEventSent,
    isTherapist,
    roomID,
    sessionStatusProps?.currentMessagingSession,
    sessionStatusProps?.nextLiveSession,
    therapistID,
  ]);

  const sendBookingReminderCTAEvent = () => {
    if (therapistID && roomID) {
      trackEvent('Booking Reminder CTA', {
        source: 'In-room banner',
        therapistID,
        roomID,
      });
    }
  };

  const handlePressOnBookSession = () => {
    if (onPress) {
      sendBookingReminderCTAEvent();
      onPress();
    }
  };

  const parentConsentBannerProps = {
    onPress: () => {
      setIsLeftPanelOnTop(true);
      history.push(`/room/${roomID}/informed-consent`);
    },
    titleStyle: { color: colors.permaFuchsia, fontSize: 17 },
    boldTitle: true,
    Icon: ExclamationCircle,
    bannerStyles: { background: colors.permaProvincialPink, height: '100%' },
    isIconNextToTitle: true,
    alwaysShowArrow: isMobile,
  };

  if (banner === 'matchWithNewProvider') {
    return (
      <ChatBanner
        onPress={onPress}
        dataQa="chatBannerMatchNewProvider"
        boldTitle
        title={
          isClientDischargeV3OnForPlan
            ? 'Restart therapy with a new provider'
            : 'Match me with a new provider'
        }
        subtitle={
          isClientDischargeV3OnForPlan
            ? 'Your previous provider is unavailable'
            : 'Your provider is unavailable, please continue with a new provider'
        }
      />
    );
  }

  if (banner === 'matchWithSameProvider') {
    return (
      <ChatBanner
        onPress={onPress}
        dataQa="chatBannerMatchNewProvider"
        boldTitle
        title="Restart therapy"
        subtitle="Your provider is ready to hear from you"
      />
    );
  }

  if (banner === 'eligiblityWidget') {
    return (
      <ChatBanner
        dataQa="chatBannerEligibilityWidget"
        boldTitle
        onPress={onPress}
        title="Check coverage to start treatment"
      />
    );
  }

  if (banner === 'reactivation' && !isCT)
    return (
      <ChatBanner
        dataQa="chatBannerReactivation"
        boldTitle
        onPress={onPress}
        title={isClientDischargeV3OnForPlan ? 'Restart therapy' : 'Resubscribe to continue therapy'}
        subtitle="This room is currently closed"
      />
    );
  if (banner === 'emailVerification')
    return (
      <ChatBanner
        dataQa="chatBannerEmailVerification"
        boldTitle
        onPress={onPress}
        title="Verify your email address"
        subtitle="For added security, please verify your email"
      />
    );
  if (banner === 'enable2fa')
    return (
      <View style={twoFactorAuthStyle}>
        <ChatBanner
          dataQa="chatBanner2faReminder"
          boldTitle
          onPress={onPress}
          title="Enable two-factor authentication"
          subtitle="To keep your account extra secure, please enable two-factor authentication"
        />
      </View>
    );
  if (banner === 'awaitingSession' && awaitingSessionProps)
    return (
      <ChatBanner
        dataQa="chatBannerAwaitingSession"
        boldTitle
        onPress={onPress}
        title="Continue to your next session"
        subtitle={
          awaitingSessionProps.lastCompletedSessionDate
            ? `Your last session ended on ${moment(
                awaitingSessionProps.lastCompletedSessionDate
              ).format('MMM D, YYYY')}`
            : ''
        }
      />
    );
  if (banner === 'partnerMissing')
    return (
      <ChatBanner
        dataQa="chatBannerPartnerMissing"
        boldTitle
        onPress={onPress}
        title="Add your partner to the room"
        subtitle="Invite your partner to begin couples therapy"
      />
    );
  if (banner === 'creditCardDeclined')
    return (
      <ChatBanner
        dataQa="chatBannerCreditCardDeclined"
        boldTitle
        onPress={onPress}
        title="Your card was declined"
        subtitle="Update payment details to avoid cancellation"
      />
    );

  if (banner === 'psychiatristReminder' && !!psychiatristReminderProps) {
    return <ChatBanner dataQa="chatBannerPsychiatristReminder" {...psychiatristReminderProps} />;
  }

  if (banner === 'introLVSReminder') {
    return (
      <ChatBanner
        boldTitle
        onPress={onPress}
        title="Book your first live intro session"
        subtitle={`Discuss your therapy goals with ${therapistFirstName || 'your provider'}`}
      />
    );
  }

  if (parentConsentData?.consentStatus === 'emailSent') {
    const isResent = parentConsentData.previouslySentTimes > 1;
    const emailSentDate = moment(parentConsentData.emailSentAt).format('MMM D, YYYY');
    const subtitle = `Form ${
      isResent ? 'resent' : 'sent'
    } to parent or guardian on ${emailSentDate}. See more details.`;
    return (
      <ChatBanner
        {...parentConsentBannerProps}
        title="Waiting for parent consent"
        subtitle={subtitle}
      />
    );
  }

  if (parentConsentData?.consentStatus === 'consentSkippedByTeen') {
    return (
      <ChatBanner
        {...parentConsentBannerProps}
        title="Verify parent consent exemption"
        subtitle="Teen may not need parent or guardian consent. See more details."
      />
    );
  }
  if (banner === 'sessionStatus') {
    if (sessionStatusProps?.currentMessagingSession) {
      const { startDate } = sessionStatusProps.currentMessagingSession;
      return (
        <ChatBanner
          Icon={FilledEnvelope}
          title="Messaging Session in progress"
          subtitle={`Session started on ${moment(startDate).format('MMM D, YYYY')}`}
        />
      );
    }
    if (sessionStatusProps?.nextLiveSession) {
      const { modality, date, duration } = sessionStatusProps.nextLiveSession;
      const endTime = moment(date).add(duration, 'minutes');
      return (
        <ChatBanner
          Icon={modalityElement[modality]}
          tinyTitle
          isLargeText
          title={`Next Live ${capitalize(modality)} session is scheduled for`}
          subtitle={moment(date).format('dddd, MMM D - h:mma-') + endTime.format('h:mma')}
          iconStyle={{ marginLeft: 10 }}
        />
      );
    }
    if (isTherapist) {
      return (
        <ChatBanner
          boldTitle
          isLargeText
          onPress={onPress}
          titleStyle={{ color: colors.red }}
          title="No messaging session in progress"
          subtitle={
            sessionStatusProps?.previousMessagingSession
              ? `The last session ended on ${moment(
                  sessionStatusProps.previousMessagingSession.endDate
                ).format('MMM D')}`
              : ''
          }
        />
      );
    }
    if (sessionStatusProps?.previousMessagingSession || sessionStatusProps?.previousLiveSession) {
      let endDate: string;
      if (sessionStatusProps.previousMessagingSession && sessionStatusProps.previousLiveSession) {
        ({ endDate } = moment(sessionStatusProps.previousMessagingSession.endDate).isAfter(
          sessionStatusProps.previousLiveSession.endDate
        )
          ? sessionStatusProps.previousMessagingSession
          : sessionStatusProps.previousLiveSession);
      } else {
        endDate = (sessionStatusProps.previousMessagingSession?.endDate ||
          sessionStatusProps.previousLiveSession?.endDate) as string;
      }

      return (
        <ChatBanner
          boldTitle
          alwaysShowArrow
          overrideRightIcon={<ArrowRight />}
          onPress={handlePressOnBookSession}
          title="Book or Start a session"
          subtitle={`Your last session ended on ${moment(endDate).format('MMM D')}`}
        />
      );
    }
    return (
      <ChatBanner
        Icon={CalendarIcon}
        iconStyle={{ width: 40 }}
        boldTitle
        alwaysShowArrow
        overrideRightIcon={<ArrowRight />}
        onPress={handlePressOnBookSession}
        title="Book or Start a session"
      />
    );
  }

  if (banner === 'pendingInvoices') {
    return (
      <ChatBanner
        onPress={onPress}
        title="Pay your outstanding balance"
        subtitle="You have an outstanding balance from recent Talkspace services"
        dataQa="pendingInvoicesChatBanner"
        boldTitle
      />
    );
  }

  return null;
};

export default ChatBannerManager;
