import { OptionType } from '@talkspace/react-toolkit';
import { OtherWizardGenericActions } from 'utils/genericWizardActions';
import { State } from '../reducers/switchWizardState';
import therapistAPI from '../../therapistReview/utils/therapistReviewApiHelper';
import ApiHelper from '../../utils/ApiHelper';
import { allowReview } from '../utils/switchWizardApiHelper';

const initState = async (api, state) => {
  const existingPreferences = await api.getMatchingPreferences(state.roomID.toString());
  const customerInfo = await api.getCustomerInformation(state.clientUserID);
  const therapistInfo = await therapistAPI.getTherapistInfo(state.roomID);
  let therapistGender = existingPreferences && existingPreferences.therapistGender;
  if (!therapistGender && customerInfo.therapistGenderPreference)
    therapistGender = customerInfo.therapistGenderPreference.toLowerCase();
  const allowReviewResponse = await allowReview(state.roomID);

  const isReviewAllowed = allowReviewResponse?.allow || false;

  const presentingProblems = (await api.getPresentingProblems())
    .filter((problem) => !!problem.expertiseID)
    .map((problem): OptionType => {
      return { label: problem.value, value: problem.expertiseID };
    })
    .sort((firstOption, secondOption) =>
      firstOption.label.toLowerCase().localeCompare(secondOption.label.toLowerCase())
    );
  return {
    therapistInfo,
    existingPreferences,
    newTherapistFocus: existingPreferences ? existingPreferences.fieldsOfExpertise : [],
    countryState: customerInfo.countryState,
    therapistGender,
    therapistEthnicity: existingPreferences && existingPreferences.therapistEthnicity,
    presentingProblemsOptions: presentingProblems,
    displayTherapistReview: state.displayTherapistReview && isReviewAllowed,
  };
};

const persist: OtherWizardGenericActions<State, ApiHelper>['persist'] = async (
  api,
  state,
  wizardCompleted
) => {
  // save only if wizard completed
  if (wizardCompleted) {
    const preferences = {
      ...state.existingPreferences,
      therapistGender: state.therapistGender,
      therapistEthnicity: state.therapistEthnicity,
      fieldsOfExpertise: state.newTherapistFocus,
    };
    if (!preferences.therapistGender) delete preferences.therapistGender;
    if (!preferences.therapistEthnicity) delete preferences.therapistEthnicity;
    await api.saveMatchingPreferences(state.roomID || 0, preferences).catch(api.dismissIfCancelled);
  }
};

const prePersist: OtherWizardGenericActions<State, ApiHelper>['prePersist'] = async (state) => {
  if (state.therapistReview) {
    const reviewPayload = {
      ...state.therapistReview,
      ratingSource: `${state.wizardType} wizard`,
    };
    await therapistAPI.postTherapistReview(reviewPayload);
  }
};

const switchWizardActions: OtherWizardGenericActions<State, ApiHelper> = {
  prePersist,
  initState,
  persist,
};

export default switchWizardActions;
