/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import localStorage from '@/core/storage/localStorage';
import {
  TEENSPACE_DAY_END_SHIFT_HOURS,
  LOCAL_STORAGE_LAST_CHECKED_DAILY_PROMPT_TIMESTAMP,
} from '../constants';
import { getAdjustedLocalTime } from './helpers';

export const hasSeenDailyPromptToday = () => {
  const lastSeenUTC = localStorage.getItem(LOCAL_STORAGE_LAST_CHECKED_DAILY_PROMPT_TIMESTAMP);
  const timezone = moment.tz.guess();
  const shiftHours = TEENSPACE_DAY_END_SHIFT_HOURS;
  if (!lastSeenUTC) return false;

  const nowLocal = getAdjustedLocalTime({
    utcTime: moment.utc().toString(),
    timezone,
    shiftHours,
  });
  const lastSeenLocal = getAdjustedLocalTime({
    utcTime: lastSeenUTC.toString(),
    timezone,
    shiftHours,
  });

  return nowLocal.day() === lastSeenLocal.day();
};
