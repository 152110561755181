import StickyDrawer, { StickyDrawerProps } from './StickyDrawer';
import { useWindowWidthState } from '../../../hooks/windowWidthContext';

const StickyDrawerV1 = (props: StickyDrawerProps) => {
  const { isMobile } = useWindowWidthState();
  return (
    <StickyDrawer
      contentWrapperStyle={{ padding: isMobile ? '16px 20px 34px' : '24px 20px', gap: 8 }}
      {...props}
    />
  );
};

export default StickyDrawerV1;
