import { isStripeUsingLink, useStripeTs } from 'stripe/stripeContext';
import { useEffect, useState } from 'react';
import {
  InRoomSchedulingProvider,
  InRoomSchedulingContainer,
  useInRoomSchedulingActions,
} from 'inRoomScheduling';

import paymentAPI from 'offer/utils/paymentApiHelper';
import { FlowVariant } from 'ts-frontend/types';
import { extractRoomEntitiesFromState } from '../../utils/extractRoomEntitiesFromState';
import { useMainActions, useMainState } from '../../hooks/mainContext';
import { RouteComponentProps, withRouter } from '../../core/routerLib';
import { getUserData } from '../../auth/helpers/token';
import useQueryOnboarding from '../../onboarding/hooks/useQueryOnboarding';
import useQueryClientUsageStats from '../../hooks/useQueryClientUsageStats';

type Props = RouteComponentProps<
  {
    roomID: string;
  },
  {},
  {
    roomID: number;
  }
> & {
  hideHeader?: boolean;
  isCloseable?: boolean;
  flowVariant?: FlowVariant;
  bookWithIntroSession?: boolean;
  isOnboarding?: boolean;
  dismissOnboarding?: () => void;
  fetchOnboarding?: () => Promise<any>;
};

const InRoomSchedulingWrapperContainer = ({
  match,
  location,
  hideHeader = false,
  isCloseable = true,
  isOnboarding = false,
  bookWithIntroSession = false,
  flowVariant,
  dismissOnboarding,
  fetchOnboarding,
}: Props) => {
  const mainState = useMainState();
  const { getAllRooms } = useMainActions();
  const roomID = Number(match.params.roomID) || location.state.roomID;
  const { id: clientUserID } = getUserData();
  const [useStripeLink, setUseStripeLink] = useState(true);
  const stripe = useStripeTs();
  const { data: onboardingData } = useQueryOnboarding({
    roomID: Number(roomID),
    userID: clientUserID,
  });

  const step = onboardingData?.steps?.find(
    ({ step: onboardStep }) => onboardStep === 'BOOK_AND_ACTIVATE'
  );
  const { sessionModality, shouldSkipModalitySelection } = step?.metadata || {
    sessionModality: undefined,
    shouldSkipModalitySelection: false,
  };

  useEffect(() => {
    getAllRooms(clientUserID);
    setUseStripeLink(isStripeUsingLink(stripe));
  }, [clientUserID, getAllRooms, stripe]);

  const onLoadStripeLink = async () => {
    const { id } = getUserData();
    const res = await paymentAPI.getSetupIntent({ userID: id });
    return res;
  };

  const { dispatchSetClientUsageStats } = useInRoomSchedulingActions();

  const { therapist } = extractRoomEntitiesFromState(mainState, roomID);

  const { data: clientUsageStats, isLoading: isLoadingClientUsageStats } = useQueryClientUsageStats(
    {
      clientID: clientUserID,
      therapistID: therapist?.id,
    }
  );

  useEffect(() => {
    if (!isLoadingClientUsageStats) {
      dispatchSetClientUsageStats(clientUsageStats);
    }
  }, [isLoadingClientUsageStats, clientUsageStats, dispatchSetClientUsageStats]);

  return (
    <InRoomSchedulingContainer
      hideAddToCalendar={false}
      room={extractRoomEntitiesFromState(mainState, roomID).room}
      therapist={therapist}
      clientUserID={clientUserID}
      hideHeader={hideHeader}
      isCloseable={isCloseable}
      isOnboarding={isOnboarding}
      bookWithIntroSession={bookWithIntroSession}
      useStripeLink={useStripeLink}
      preRegistrationSelectedModality={sessionModality}
      shouldSkipModalitySelection={shouldSkipModalitySelection}
      flowVariant={flowVariant}
      onLoadStripeLink={onLoadStripeLink}
      dismissOnboarding={dismissOnboarding}
      fetchOnboarding={fetchOnboarding}
    />
  );
};

const InRoomSchedulingWrapper = (props: Props) => (
  <InRoomSchedulingProvider>
    <InRoomSchedulingWrapperContainer {...props} />
  </InRoomSchedulingProvider>
);

export default withRouter(InRoomSchedulingWrapper);
