import { useMemo } from 'react';
import { SpacingView } from '@talkspace/react-toolkit';
import useNavShellData from '../../hooks/useNavShellData';
import YourSupportTeamCardController from './YourSupportTeamCardController';
import YourSupportTeamLoader from '../components/YourSupportTeamLoader';

interface YourSupportTeamListProps {}

const YourSupportTeamListController = (props: YourSupportTeamListProps) => {
  const {
    aggregatedRoomData: { dataByRoomID },
    queries: { isLoadingRoomsList },
  } = useNavShellData();

  const roomIDsSorted = useMemo(
    () =>
      dataByRoomID &&
      (Object.values(dataByRoomID)
        .sort(
          (room1, room2) =>
            (room2?.lastMessage ? new Date(room2.lastMessage.createdAt).getTime() : 0) -
            (room1?.lastMessage ? new Date(room1.lastMessage.createdAt).getTime() : 0)
        )
        .map((room) => room.room?.roomID)
        .filter(Boolean) as number[]), // as number because .filter wasn't removing undefineds from type
    [dataByRoomID]
  );

  if (!dataByRoomID || !roomIDsSorted || !Object.keys(dataByRoomID).length || isLoadingRoomsList) {
    return <YourSupportTeamLoader />;
  }

  return (
    <SpacingView itemSpacing="space200" flex={1} asList>
      {roomIDsSorted.map((roomID) => (
        <YourSupportTeamCardController
          key={`your-support-team-card-${roomID}`}
          aggregatedRoomData={dataByRoomID[roomID]}
          roomID={roomID}
        />
      ))}
    </SpacingView>
  );
};

export default YourSupportTeamListController;
