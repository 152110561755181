import { FunctionComponent, useCallback } from 'react';
import * as React from 'react';
import {
  Avatar,
  Big,
  Large,
  View,
  TherapistSearch,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import { useFlag } from 'launchDarkly/FlagsProvider';

import { Subscription } from 'ts-frontend/types';
import { roomHasRealProvider } from 'ts-frontend/helpers';
import { isDummyCanceledNoMatchesProvider } from 'ts-frontend/entities/Therapist';
import { useNewMemberNav } from 'launchDarkly/hooks';
import useOpenModalSafe from '@/hooks/useOpenModalSafe';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { getDefaultOfferByRoomType } from '@/utils/offerHelper';
import useSubscriptions from '@/myAccount/hooks/useSubscriptions';
import styled from '../../../core/styled';
import getPanelWidth from '../../utils/design';
import SubscriptionActionButtons from '../../../components/SubscriptionActionButtons';
import {
  RoundedPausedPlan,
  CancelledPlan,
  FreeConsultationPlan,
  PastDuePlan,
} from '../../../components/icons';
import LiveVideoCredits from '../LiveVideoCredits';
import BillingCycle from '../BillingCycle';
import NextRenewal from '../NextRenewal';
import SummaryCardActionBanner from '../SummaryCardActionBanner/SummaryCardActionBanner';
import useBookingsOutsideBillingCycle from '../../hooks/useBookingsOutsideBillingCycle';
import { useMainState } from '@/hooks/mainContext';

const DATE_FORMAT_STRING = 'MMM DD, YYYY';

const ActionBanner: FunctionComponent<{
  isMobile: boolean;
  room: Subscription;
  resumeSubscriptionOnPress?: () => void;
  undoCancelSubscriptionOnPress?: () => void;
  updatePaymentDetailsOnPress?: () => void;
  handleCancelPlanButtonPress?: () => void;
  handleNeedHelpPress?: () => void;
}> = ({
  isMobile,
  room,
  resumeSubscriptionOnPress,
  undoCancelSubscriptionOnPress,
  updatePaymentDetailsOnPress,
  handleCancelPlanButtonPress,
  handleNeedHelpPress,
}) => {
  const { status, therapist, subscription } = room;

  const openModal = useOpenModalSafe();
  const useNewNav = useNewMemberNav();
  const closeModal = useCloseModal();
  const isPastDate = moment(room.subscription.endSubscriptionDate).isBefore(moment());
  const { isBH, isB2B, showNeedHelp } = room.subscription;
  const isCancelledPendingSubscription = isDummyCanceledNoMatchesProvider({
    therapistName: therapist.fullName,
  });
  const reactivateRoom = useCallback(
    () =>
      useNewNav && isMobile
        ? closeModal({ navigateTo: 'reactivateRoom', metadata: { roomID: Number(room.id) } })
        : openModal(`/room-reactivation/${room.id}`),
    [useNewNav, isMobile, openModal, closeModal, room.id]
  );
  switch (status) {
    case 4:
      if ((isB2B || isBH) && !isPastDate) {
        return (
          <SummaryCardActionBanner
            isMobile={isMobile}
            title={isBH ? 'Stand by' : 'Expired'}
            subtitle={() =>
              isBH ? (
                <>Your provider is waiting to hear from you.</>
              ) : (
                <>Your plan expired due to inactivity.</>
              )
            }
            iconComponent={
              <View>
                <CancelledPlan />
              </View>
            }
            buttonTitle="Cancel my plan"
            primaryOnPress={handleCancelPlanButtonPress}
          />
        );
      }
      return (
        <SummaryCardActionBanner
          isMobile={isMobile}
          title="Expired"
          subtitle={() => (
            <>
              {isPastDate
                ? `Your subscription expired on ${moment(
                    room.subscription.endSubscriptionDate
                  ).format(DATE_FORMAT_STRING)}`
                : 'Your subscription expired due to inactivity.'}
            </>
          )}
          iconComponent={
            <View>
              <CancelledPlan />
            </View>
          }
        />
      );
    case 5:
      return room.subscription.isFreeConsultation ? (
        <SummaryCardActionBanner
          isMobile={isMobile}
          title="Subscribe now"
          subtitle={() => (
            <>Start improving your life today by selecting a subscription that fits your needs.</>
          )}
          iconComponent={
            <View>
              <FreeConsultationPlan />
            </View>
          }
          buttonTitle="Subscribe"
          primaryOnPress={() => {
            const offerID = getDefaultOfferByRoomType(room.roomType);
            openModal(`/room-offer/${room.id}/offer/${offerID}`);
          }}
        />
      ) : null;
    case 6:
      return (
        <SummaryCardActionBanner
          isMobile={isMobile}
          title="Cancelled"
          subtitle={() => (
            <>
              Your subscription will end
              {subscription.endSubscriptionDate
                ? ` on ${moment(subscription.endSubscriptionDate).format(DATE_FORMAT_STRING)}`
                : ' soon.'}
            </>
          )}
          secondaryTitle="Undo your cancellation?"
          secondarySubtitle="Your subscription renewal will be reactivated."
          iconComponent={
            <View>
              <CancelledPlan />
            </View>
          }
          secondaryOnPress={undoCancelSubscriptionOnPress}
          buttonTitle="Undo cancellation"
          secondaryButtonTitle="Continue"
          alternativeButtonTitle={showNeedHelp ? 'Need more help?' : ''}
          alternativeOnPress={handleNeedHelpPress}
        />
      );
    case 7:
      if (isB2B || isCancelledPendingSubscription) {
        return (
          <SummaryCardActionBanner
            isMobile={isMobile}
            title="Cancelled"
            subtitle={() => <>Your plan has been cancelled.</>}
            secondaryTitle="Undo your cancellation?"
            secondarySubtitle="Your plan will be reactivated."
            iconComponent={
              <View>
                <CancelledPlan />
              </View>
            }
            // Show confirmation if isCancelledPendingSubscription, otherwise just open webview
            primaryOnPress={isCancelledPendingSubscription ? undefined : reactivateRoom}
            secondaryOnPress={
              isCancelledPendingSubscription ? undoCancelSubscriptionOnPress : undefined
            }
            buttonTitle={isPastDate ? '' : 'Undo cancellation'}
            secondaryButtonTitle="Continue"
            alternativeButtonTitle={showNeedHelp ? 'Need more help?' : ''}
            alternativeOnPress={handleNeedHelpPress}
          />
        );
      }
      return (
        <SummaryCardActionBanner
          isMobile={isMobile}
          title="Cancelled"
          subtitle={() => (
            <>
              Your subscription ended
              {subscription.endSubscriptionDate
                ? ` on ${moment(subscription.endSubscriptionDate).format(DATE_FORMAT_STRING)}`
                : '.'}
            </>
          )}
          iconComponent={
            <View>
              <CancelledPlan />
            </View>
          }
          buttonTitle="Subscribe"
          primaryOnPress={() => {
            reactivateRoom();
          }}
          alternativeButtonTitle={showNeedHelp ? 'Need more help?' : ''}
          alternativeOnPress={handleNeedHelpPress}
        />
      );
    case 11:
      return (
        <SummaryCardActionBanner
          isMobile={isMobile}
          title="Your card was declined"
          subtitle={() => <>Update your payment details to avoid cancellation.</>}
          iconComponent={
            <View>
              <PastDuePlan />
            </View>
          }
          buttonTitle="Update payment details"
          primaryOnPress={updatePaymentDetailsOnPress}
        />
      );
    case 13:
      return (
        <SummaryCardActionBanner
          isMobile={isMobile}
          title="Paused"
          subtitle={() => (
            <>
              Your subscription is paused
              {room.subscription.endOnHold
                ? ` through ${moment(room.subscription.endOnHold).format(DATE_FORMAT_STRING)}`
                : '.'}
            </>
          )}
          secondaryTitle="Ready to resume therapy?"
          secondarySubtitle={`${
            therapist.fullName.split(' ')[0]
          } will be notified that you're back!`}
          iconComponent={
            <View>
              <RoundedPausedPlan />
            </View>
          }
          secondaryOnPress={resumeSubscriptionOnPress}
          buttonTitle="Resume therapy"
          secondaryButtonTitle="Continue"
        />
      );
    case 14:
      return (
        <SummaryCardActionBanner
          isMobile={isMobile}
          title="Trial"
          subtitle={() => (
            <>
              Your trial period will end on{' '}
              {moment(subscription.trialEnd).format(DATE_FORMAT_STRING)} and you will continue with{' '}
              {room.subscription.name}.
            </>
          )}
          iconComponent={
            <View>
              <RoundedPausedPlan />
            </View>
          }
        />
      );
    default:
      return null;
  }
};

const Card = styled(View)<{ isFirst: boolean }>(({ theme: { window, colors } }) => {
  return {
    paddingTop: 30,
    minWidth: 335,
    maxWidth: getPanelWidth(window),
    borderStyle: 'solid',
    borderColor: colors.periwinkleGrey,
    borderWidth: 0.5,
    borderRadius: 10,
    borderTopColor: colors.periwinkleGrey,
    borderRightColor: colors.periwinkleGrey,
    borderLeftColor: colors.periwinkleGrey,
    marginBottom: 15,
  };
});

const HeaderRow = styled(View)(() => {
  return {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
});

const CardTopSection = styled(View)(() => {
  return {
    padding: '0px 24px 0 24px',
  };
});

const CardHeaderAvatar = ({
  therapistImageUrl = '',
  isMobile = false,
  showPlaceholder = false,
}) => {
  const { colors } = useEmotionTheme();
  if (isMobile) return null;

  if (showPlaceholder) {
    return (
      <View
        justify="center"
        align="center"
        style={{
          width: 67,
          height: 67,
          borderRadius: '50%',
          overflow: 'hidden',
          backgroundColor: colors.permaHawkesBlue,
          marginTop: 25,
        }}
      >
        <TherapistSearch width={34} height={31} />
      </View>
    );
  }

  return <Avatar height={67} width={67} image={therapistImageUrl} style={{ marginTop: 25 }} />;
};

const CardHeader = ({
  planName = '',
  therapistName = '',
  therapistImageUrl = '',
  isMobile = false,
  showPlaceholder = false,
}) => (
  <HeaderRow>
    <View style={{ width: 'calc(100% - 72px)' }}>
      <Big style={{ marginBottom: 3 }} as="h2" dataQa="paymentAndPlanHeading">
        {planName}
      </Big>
      <Large as="h3" style={{ fontWeight: 400 }}>
        {therapistName}
      </Large>
    </View>
    <CardHeaderAvatar
      therapistImageUrl={therapistImageUrl}
      showPlaceholder={showPlaceholder}
      isMobile={isMobile}
    />
  </HeaderRow>
);

interface ManageSubscriptionCardProps {
  room: Subscription;
  offerID: number;
  isMobile: boolean;
  handlePauseButtonPress?: () => void;
  handleViewSuperbills?: () => void;
  handleChangePlanButtonPress?: () => void;
  handleCancelPlanButtonPress: (
    roomID: number,
    therapistID: number,
    willCancelSessions: boolean
  ) => void;
  handleChangeProviderButtonOnPress?: () => void;
  handleNeedHelpPress?: () => void;
  resumeSubscriptionOnPress?: () => void;
  undoCancelSubscriptionOnPress?: () => void;
  updatePaymentDetailsOnPress?: () => void;
  handleUpdateCoverageButtonPress?: () => void;
  isFirst: boolean;
  cardRef?: React.RefObject<HTMLDivElement>;
  index: number;
}

const ManageSubscriptionCard: FunctionComponent<ManageSubscriptionCardProps> = ({
  room,
  cardRef,
  offerID,
  isMobile,
  handlePauseButtonPress,
  handleViewSuperbills,
  handleChangePlanButtonPress,
  handleCancelPlanButtonPress,
  handleChangeProviderButtonOnPress,
  handleUpdateCoverageButtonPress,
  handleNeedHelpPress,
  resumeSubscriptionOnPress,
  undoCancelSubscriptionOnPress,
  updatePaymentDetailsOnPress,
  isFirst,
  index,
}) => {
  const b2BMemberInactivity = useFlag('b2BMemberInactivity');

  const { bookings: sessionsOutsideBillingCycle, isLoading } = useBookingsOutsideBillingCycle(
    room.participantID,
    room.id
  );
  const willCancelSessions = sessionsOutsideBillingCycle.length > 0;

  const providerTitle = room.therapist.title
    ? `${room.therapist.title} ${room.therapist.fullName}`
    : room.therapist.fullName;
  const isRoomWithRealProvider = roomHasRealProvider({
    room,
    provider: room.therapist,
  });
  const [{ superbillsFlag }] = useSubscriptions();
  const { roomsByID } = useMainState();

  const { accountType } = roomsByID?.[room.id] || {};

  const allowSwitchProviderOnB2bInactivity =
    b2BMemberInactivity &&
    ['bh', 'eap', 'dte'].includes(accountType) &&
    !!room.subscription?.isClosedInactive;

  const canCancelPlan = () => {
    const { renewalDate, isPendingSubscription, isB2B, isB2C, isActive } = room.subscription;
    return (
      !!renewalDate ||
      (isPendingSubscription && !(isB2C && room.roomType === 'psychiatryRoom')) ||
      (isB2B && isActive)
    );
  };

  const canViewSuperbills =
    isRoomWithRealProvider &&
    room.subscription.isB2C &&
    room.participantCountry === 'US' &&
    superbillsFlag;

  return (
    <Card ref={cardRef} isFirst={isFirst} data-qa={`paymentAndPlanContainer-${index}`}>
      <input type="hidden" data-qa={`offerID-${index}`} value={offerID} />
      <CardTopSection>
        <CardHeader
          planName={room.subscription.name}
          therapistName={providerTitle}
          therapistImageUrl={room.therapist.image}
          isMobile={isMobile}
          showPlaceholder={!isRoomWithRealProvider}
        />
        {room.videoCredits ? <LiveVideoCredits videoCredits={room.videoCredits} /> : null}
        <BillingCycle subscriptionInfo={room.subscription} />
        <NextRenewal subscriptionInfo={room.subscription} roomStatus={room.status} />
        <SubscriptionActionButtons
          canChangePlan={room.subscription.canChangePlan}
          canViewSuperbills={canViewSuperbills}
          canChangeProvider={
            room.subscription.canChangeProvider || allowSwitchProviderOnB2bInactivity
          }
          canCancelPlan={!isLoading && canCancelPlan()}
          canPauseService={room.subscription.canPauseService}
          isPendingSubscription={room.subscription.isPendingSubscription}
          isB2B={room.subscription.isB2B}
          handlePauseButtonPress={handlePauseButtonPress}
          handleViewSuperbills={handleViewSuperbills}
          handleChangePlanButtonPress={handleChangePlanButtonPress}
          handleCancelPlanButtonPress={() =>
            handleCancelPlanButtonPress(room.id, room.therapistID, willCancelSessions)
          }
          handleChangeProviderButtonPress={handleChangeProviderButtonOnPress}
          handleUpdateCoverageButtonPress={handleUpdateCoverageButtonPress}
        />
      </CardTopSection>
      <ActionBanner
        isMobile={isMobile}
        room={room}
        resumeSubscriptionOnPress={resumeSubscriptionOnPress}
        undoCancelSubscriptionOnPress={undoCancelSubscriptionOnPress}
        updatePaymentDetailsOnPress={updatePaymentDetailsOnPress}
        handleCancelPlanButtonPress={() =>
          handleCancelPlanButtonPress(room.id, room.therapistID, willCancelSessions)
        }
        handleNeedHelpPress={handleNeedHelpPress}
      />
    </Card>
  );
};

export default ManageSubscriptionCard;
