import { EmotionStyle, TextDS, View, spacing } from '@talkspace/react-toolkit';

const { space050 } = spacing;

interface Props {
  post: Amity.Post;
  style?: EmotionStyle;
}
const PostText = ({ post, style }: Props) => {
  const {
    data: { text },
  } = post;
  return (
    <View style={style}>
      {text.split('\n').map((line, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <TextDS key={`line-${i}`} style={{ marginTop: i > 0 ? space050 : undefined }}>
          {line}
        </TextDS>
      ))}
    </View>
  );
};
export default PostText;
