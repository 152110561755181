import { FunctionComponent } from 'react';
import { Standard, View, Huge } from '@talkspace/react-toolkit';
import SymptomTrackerCard from '../components/SymptomTrackerCard';
import { useSurveysState } from '../hooks/surveysContext';
import { withRouter, RouteComponentProps } from '../../core/routerLib';
import NoItemsCard from '../components/NoItemsCard';
import extractRoomSurveyResultsByLabel from '../utils/extractRoomSurveyResultsByLabel';
import styled from '../../core/styled';
import SymptomIcon from '../components/icons/SymptomIcon';

const ResponsiveWrapper = styled(View)(({ theme: { window } }) => {
  const { isDesktop } = window;
  return {
    flexDirection: isDesktop ? 'column' : 'row',
    overflowX: 'auto',
    paddingLeft: isDesktop ? 0 : 24,
  };
});

const ResponsiveIconWrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return isMobile
    ? {
        marginTop: -14,
      }
    : {
        marginRight: 24,
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
      };
});

const SymptomTrackerContainer: FunctionComponent<RouteComponentProps<{ roomID: number }>> = ({
  match,
}) => {
  const { surveyResultsByRoomID } = useSurveysState();

  const surveyResultsByLabel = extractRoomSurveyResultsByLabel(
    surveyResultsByRoomID,
    match.params.roomID
  );
  const labels = Object.keys(surveyResultsByLabel);

  return (
    <View style={{ marginTop: 20, marginBottom: 20 }}>
      <Huge as="h2" style={{ marginLeft: 24, marginBottom: 20 }}>
        Symptom tracker
      </Huge>
      {labels.length ? (
        <ResponsiveWrapper>
          {labels.map((label) => (
            <SymptomTrackerCard surveyResults={surveyResultsByLabel[label]} key={label} />
          ))}
        </ResponsiveWrapper>
      ) : (
        <NoItemsCard disabled>
          <ResponsiveIconWrapper>
            <SymptomIcon />
          </ResponsiveIconWrapper>
          <Standard variant="standardDarkGrey">
            After your therapist determines which symptoms to evaluate, you’ll take an assessment
            every 3 weeks to track your progress over time.
          </Standard>
        </NoItemsCard>
      )}
    </View>
  );
};
export default withRouter(SymptomTrackerContainer);
